// Renamed source from
// https://github.com/mui-org/material-ui/blob/a2e0e32b25b171e0359d80a685e8dede24975254/packages/material-ui-utils/src/getDisplayName.js

import { ForwardRef, Memo } from 'react-is';
import { getFunctionName } from '../../utils';

function getFunctionComponentName(Component, fallback = '') {
  return Component.name || Component.displayName || getFunctionName(Component) || fallback;
}

function getWrappedName(outerType, innerType, wrapperName) {
  const functionName = getFunctionComponentName(innerType);
  return (
    outerType.displayName || (functionName !== '' ? `${wrapperName}(${functionName})` : wrapperName)
  );
}

function getComponentName(Component) {
  if (Component == null) {
    return undefined;
  }

  if (typeof Component === 'string') {
    return Component;
  }

  if (typeof Component === 'function') {
    return getFunctionComponentName(Component, 'Component');
  }

  if (typeof Component === 'object') {
    switch (Component.$$typeof) {
      case ForwardRef:
        return getWrappedName(Component, Component.render, 'ForwardRef');
      case Memo:
        return getWrappedName(Component, Component.type, 'memo');
      default:
        return undefined;
    }
  }

  return undefined;
}



export { getComponentName };
