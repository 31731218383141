import { isNull, omit } from '../../utils';

function createStylePropsFunction(styleProps) {
  if (Array.isArray(styleProps)) {
    const func = (props, { include: more, omit: andOmit } = {}) => {
      let styles = {};
      let sProps = more ? [...styleProps, ...more] : styleProps;
      if (andOmit) {
        sProps = omit(sProps, andOmit);
      }
      for (const key of sProps) {
        if (!isNull(props[key])) {
          styles[key] = props[key];
        }
      }
      return styles;
    };
    func.filterProps = styleProps;
    const stylePropsObject = {};
    for (const key of styleProps) {
      stylePropsObject[key] = key;
    }
    func.props = stylePropsObject;

    return func;
  }

  const func = (props, { include: more, omit: andOmit } = {}) => {
    let styles = {};
    let sProps = more ? { ...styleProps, ...more } : styleProps;
    if (andOmit) {
      sProps = omit(sProps, andOmit);
    }
    for (const key in sProps) {
      if (!isNull(props[key])) {
        styles[key] = props[key];
      }
    }
    return styles;
  };
  func.filterProps = Object.keys(styleProps);
  func.props = styleProps;

  return func;
}

export { createStylePropsFunction };
