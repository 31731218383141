import React, {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
} from 'react';
import equal from 'fast-deep-equal/react';
import deepClone from 'lodash.clonedeep';
import { isNull } from '../utils';
import { getBreakpointValue } from '../system';
import { useMemoCompare } from './useMemoCompare';
import { useTheme, useThemeBreakpoints } from './useTheme';

// TODO: refactor useBreakpoint/useBreakpoints. What's the best way to use breakpoints functionally ignoring context
// and what is the best way to override theme breakpoints. Should it be with a new theme provider? useBreakpoints and use props?
export function useBreakpoint() {
  // eslint-disable-next-line
  const [breakpoint, setBreakpoint, breakpoints] = useThemeBreakpoints();
  return [breakpoint, breakpoints];
}

export const removeEmptyBreakpoints = (obj) => {
  if (!isNull(obj)) {
    const next = deepClone(obj);
    for (const key in obj) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete next[key];
      }
    }
    return next;
  }
  return obj;
};


export function useMatchBreakpoint(obj, { ignoreEmpty = false } = {}) {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const matching = useMemoCompare(obj, equal);
  const currBreakpoint = useMemoCompare(breakpoint[0], equal);
  const breakpoints = useMemoCompare(breakpoint[1], equal);

  const [breakpointsFunc, setBreakpointsFunc] = useState(() =>
    matching ? breakpoints(ignoreEmpty ? removeEmptyBreakpoints(matching) : matching) : null);

  useEffect(() => {
    const m = ignoreEmpty ? removeEmptyBreakpoints(matching) : matching;
    if (m) {
      setBreakpointsFunc(() => breakpoints(m))
    } else {
      setBreakpointsFunc(null);
    }
  }, [matching, breakpoints, ignoreEmpty])

  const propsRef = useRef(null);
  propsRef.current = { breakpoint: currBreakpoint, breakpoints, theme };

  const [match, setMatch] = useState(() => breakpointsFunc ? getBreakpointValue(breakpointsFunc, propsRef.current) : null);

  useEffect(() => {
    // console.log('updateingbp');
    setMatch(() => breakpointsFunc ? getBreakpointValue(breakpointsFunc, propsRef.current) : null)
  }, [currBreakpoint, breakpointsFunc])

  return useMemoCompare(match, equal);
}