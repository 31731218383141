function resolveNode(el) {
  let node = typeof el === 'function' ? el() : el;
  if (node) {
    if (!node.measure && node.getNode) {
      node = node.getNode();
    }
    if (
      node &&
      !node.measure &&
      node.ref &&
      node.ref.current &&
      node.ref.current.getNode
    ) {
      node = node.ref.current.getNode();
    }
  }
  return node;
}

export { resolveNode };
