import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { Heading } from '../Heading';
import { toolbarFormatYear, toolbarFormatDate } from './formats';

const DateRangePickerToolbar = React.forwardRef(function DateRangePickerToolbar(props, ref) {
  const {
    date,
    startDate,
    endDate,
    view,
    views,
    changeView,
    initialView,
    background,
    backgroundColor,
    bg = '$primary',
    rowWidth,
    rowPadding,
    columnPadding,
    formatYear = toolbarFormatYear,
    formatDate = toolbarFormatDate,
    renderYear,
    renderDate,
    onPress,
    ...rest
  } = props;

  const color = background || backgroundColor || bg;

  const handlePress = onPress
    ? (d) => onPress(d, props)
    : changeView && initialView
    ? (d) => changeView(initialView, d)
    : null;

  return (
    <Box
      ref={ref}
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg={color}
      {...rest}
    >
      <Box
        flexDirection="row"
        width={rowWidth}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width={rowWidth / 2 - 1}
          justifyContent="center"
          alignItems="flex-start"
          py="$2"
          padLeft={columnPadding}
          onPress={handlePress ? () => handlePress(startDate) : null}
        >
          {renderYear ? (
            renderYear(startDate, { formatYear, onColor: color })
          ) : (
            <Text small dim bold onColor={color} selectable={false}>
              {formatYear && formatYear(startDate)}
            </Text>
          )}
          {renderDate ? (
            renderDate(startDate, { formatDate, onColor: color })
          ) : (
            <Heading level={4} small onColor={color} selectable={false}>
              {formatDate && formatDate(startDate)}
            </Heading>
          )}
        </Box>
        <Box
          width={rowWidth / 2 - 1}
          justifyContent="center"
          alignItems="flex-end"
          py="$2"
          padRight={columnPadding}
          onPress={handlePress ? () => handlePress(endDate) : null}
        >
          {renderYear ? (
            renderYear(endDate, { formatYear, onColor: color })
          ) : (
            <Text small dim bold onColor={color} selectable={false}>
              {formatYear && formatYear(endDate)}
            </Text>
          )}
          {renderDate ? (
            renderDate(endDate, { formatDate, onColor: color })
          ) : (
            <Heading level={4} small onColor={color} selectable={false}>
              {formatDate && formatDate(endDate)}
            </Heading>
          )}
        </Box>
      </Box>
    </Box>
  );
});

export { DateRangePickerToolbar };
