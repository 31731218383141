import React from 'react';
import { Platform } from 'react-native';
import { Box } from '../Box';
import { Text } from '../Text';
import { useTheme } from '../../hooks';

const Day = React.memo(function Day(props) {
  const {
    isToday,
    selected,
    focused, // focused primarily used for multiple selected dates in a date range where only the start and end dates are focused and rest are not
    disabled = false,
    hidden = false, // use for days not renderable (6th row days if month only has 5 weeks)
    minDate,
    maxDate,
    date,
    isCurrentMonth,
    hideDaysOutsideCurrentMonth,
    HighlightProps,
    TextProps,
    disableHighlightToday,
    onSelect,
    containerProps,
    size,
    columnPadding,
    rowPadding,
    ...rest
  } = props;

  const theme = useTheme();

  let opacity = 1;
  if (disabled) {
    opacity = 0.22;
  } else if (!isCurrentMonth) {
    opacity = 0.35;
  }

  let backgroundColor = 'transparent';
  if (selected) {
    backgroundColor = theme.colors.primary;
  } else if (focused) {
    backgroundColor = theme.colors.opacity(theme.colors.primary, 0.5);
  }

  if (HighlightProps) {
    const { bg, background, backgroundColor: bColor } = HighlightProps;
    backgroundColor = bg || background || bColor || backgroundColor;
  }

  backgroundColor = backgroundColor === 'transparent' ? backgroundColor : theme.colors.opacity(backgroundColor, opacity);

  return (
    <Box
      onPress={disabled || !onSelect || hidden ? null : () => onSelect(date)}
      disabled={disabled}
      justifyContent="center"
      alignItems="center"
      zIndex={selected ? 2 : 1}
      opacity={hidden || hideDaysOutsideCurrentMonth ? 0 : 1}
      width={size}
      height={size}
      px={columnPadding}
      my={rowPadding}
      {...rest}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        borderRadius={9999999}
        width={size}
        height={size}
        zIndex={0}
        {...HighlightProps}
        backgroundColor={backgroundColor}
      />
      <Text
        center
        bold
        small
        opacity={!selected ? opacity : 1}
        onColor={backgroundColor}
        accessibility={{ accessible: !hidden }}
        selectable={false}
        lineHeight={Platform.OS === 'web' && '50%'}
        zIndex={2}
        {...TextProps}
      >
        {!hidden && date ? date.date() : ' '}
      </Text>
      {!hidden && isToday && !disableHighlightToday ? (
        <Box
          position="absolute"
          top={0}
          left={0}
          width={size}
          height={size}
          justifyContent="flex-end"
          alignItems="center"
          bg="transparent"
          paddingBottom="15%"
          zIndex={1}
        >
          <Box
            width="40%"
            height={3}
            borderRadius={8}
            bg={theme.colors.secondary.light}
          />
        </Box>
      ) : null}
    </Box>
  );
});

Day.displayName = 'Day';

export { Day };
