import React, { useState, useCallback } from 'react';
import { Platform } from 'react-native';
import { isHoverEnabled } from '../../utils';

function useHoverable({
  onHoverOut,
  onHoverIn,
  onHoverChange,
  onResponderGrant: propOnResponderGrant,
  onResponderRelease: propOnResponderRelease,
} = {}) {
  const [isHovered, setIsHovered] = useState(false);
  const [showHover, setShowHover] = useState(true);
  const onMouseEnter = useCallback(() => {
    if (isHoverEnabled() && !isHovered) {
      setIsHovered(true);
      if (onHoverIn) {
        onHoverIn();
      }
      if (onHoverChange) {
        onHoverChange(true);
      }
    }
  }, [onHoverIn, onHoverChange, isHovered]);

  const onMouseLeave = useCallback(() => {
    if (isHovered) {
      setIsHovered(false);
      if (onHoverOut) {
        onHoverOut();
      }
      if (onHoverChange) {
        onHoverChange(false);
      }
    }
  }, [onHoverOut, onHoverChange, isHovered]);

  const onResponderGrant = useCallback(() => {
    setShowHover(false);
    if (propOnResponderGrant) {
      propOnResponderGrant();
    }
  }, [propOnResponderGrant]);

  const onResponderRelease = useCallback(() => {
    setShowHover(true);
    if (propOnResponderRelease) {
      propOnResponderRelease();
    }
  }, [propOnResponderRelease]);

  const hovered = isHovered && showHover;
  // console.log(`isHovered: ${isHovered ? 'true' : 'false'}  showHover: ${showHover ? 'true' : 'false'}`);

  return Platform.OS === 'web' ? {
    hovered,
    onMouseEnter,
    onMouseLeave,
    onResponderGrant,
    onResponderRelease,
  } : { hovered };
};

const Hoverable = (props) => {
  const {
    onHoverIn,
    onHoverOut,
    onHoverChange,
    onResponderGrant,
    onResponderRelease,
    children,
  } = props;

  const { hovered, ...handlers } = useHoverable({
    onHoverIn,
    onHoverOut,
    onHoverChange,
    onResponderGrant,
    onResponderRelease,
  });

  const child =
    typeof children === 'function' ? children({ hovered }) : children;

  return React.cloneElement(React.Children.only(child), { ...handlers });
};

Hoverable.displayName = 'Hoverable';

export { Hoverable, useHoverable };