import React, { useEffect, useCallback, useState, useRef } from 'react';
import { AppState } from 'react-native';
import equal from 'fast-deep-equal/react';
import { useForkRef, useEventCallback, useAppState } from '../../hooks';
import { debounce, resolveNode } from '../../utils';
import { withStyles } from '../../styling';
import { Box } from '../Box';
import { Text } from '../Text';

const ToastContent = withStyles(
  ({ onColor, color, theme, ...props }) => {
    return {
      root: {
        backgroundColor: props.backgroundColor,
        bg: props.backgroundColor,
        background: props.backgroundColor,
        color: color || theme.colors.on(onColor || 'background'),
        // flexDirection: 'row',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        // padX: theme.spacing(2),
        // padY: theme.spacing(1),
        // borderRadius: 4,
        // maxWidth: '100%',
        // minWidth: 200,
        // flexWrap: 'wrap',
      },
      // [Text]: {
      //   padY: theme.spacing(1),
      //   padX: 0,
      // },
      // action: {
      //   alignItems: 'center',
      //   paddingLeft: theme.spacing(2),
      //   marginRight: theme.spacing(-1),
      //   marginLeft: 'auto',
      // },
    };
  },
  {
    name: 'ToastContent',
    filterProps: ['backgroundColor', 'color', 'bg', 'background', 'onColor'],
    asProp: { backgroundColor: true, color: true }
  }
)(React.forwardRef(function ToastContent(props, ref) {
  const {
    color,
    backgroundColor,
    action,
    message,
    ...rest
  } = props;

  const c = backgroundColor || color;
  return (
    <Box
      ref={ref}
      backgroundColor={c}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padX="$2"
      padY="$1"
      borderRadius={4}
      minWidth={200}
      maxWidth="100%"
      flexWrap="wrap"
      {...rest}
    >
      <Text small bold onColor={c} padY="$0.75">{message}</Text>
      {
        action ? (
          <Box alignItems="center" padLeft="$2" marginRight="$-1" marginLeft="auto">
            {action}
          </Box>
        ) : null
      }
    </Box>
  );
}));

export { ToastContent };
