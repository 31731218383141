import React, { useCallback, useMemo } from 'react';
import { FormGroup } from '../FormGroup';
import { useControlledState, useId } from '../../hooks';

const RadioGroupContext = React.createContext();

function useRadioGroup() {
  return React.useContext(RadioGroupContext);
}

const RadioGroup = React.forwardRef(function RadioGroup(props, ref) {
  const {
    name: nameProp,
    value: controlledValue,
    defaultValue,
    onChange,
    children,
    ...rest
  } = props;

  const [value, setValue] = useControlledState({
    controlledValue,
    defaultValue,
  });

  const handleChange = useCallback((nextValue, ...args) => {
    setValue(nextValue);

    if (onChange) {
      onChange(nextValue, ...args);
    }
  }, [setValue, onChange]);

  const name = useId(nameProp);

  const context = useMemo(() => ({
    name,
    onChange: handleChange,
    value,
  }), [name, handleChange, value]);

  return (
    <RadioGroupContext.Provider value={context}>
      <FormGroup ref={ref} {...rest}>
        {children}
      </FormGroup>
    </RadioGroupContext.Provider>
  );
});

export { RadioGroup, RadioGroupContext, useRadioGroup };
