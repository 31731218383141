import React from 'react';
import equal from 'fast-deep-equal/react';
import { Content, Box, Heading, Paragraph, BackgroundImage } from '../../ui';
import { useTheme, useMatchBreakpoint, useMemoCompare } from '../../ui/hooks';
import { memo, get } from '../../ui/utils';
import { GroupBlock } from '../GroupBlock';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
  alignContentOptions
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { BlocksControls } from '../BlocksControls';
import { InlineTextarea } from '../InlineTextarea';



export const GridBoxesItemBlock = memo(({ data, index, containerBlock, numColumns, ...rest }) => {
  const { imageSrc } = data;
  const { breakpoints, spacing } = useTheme();

  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      width={breakpoints({ xs: `${100/numColumns}%`, sm: `${100/numColumns - 10}%`})}
      p={spacing(1.5)}
      mb={spacing(4.5)}
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection="row"
    >
      {imageSrc ? (
        <Box
          width="50"
          height="60"
          mr={spacing(4)}
        >
          <BackgroundImage src={imageSrc} alignImage="center" />
        </Box>
      ) : null}
      <Box
        justifyContent="flex-start"
        alignItems="stretch"
        flex={1}
        maxWidth={320}>
        <Heading level={4} spaceAfter><InlineTextarea name="headingText" focusRing={false} /></Heading>
        <Paragraph><InlineTextarea name="bodyText" focusRing={false} /></Paragraph>
      </Box>
    </BlocksControls>
  )
})

export const gridBoxesItemBlockTemplate = {
  type: 'gridBoxesItemBlockTemplate',
  name: 'gridBoxesItemBlock',
  key: 'grid-boxes-item',
  label: 'Grid Box',
  component: 'grouped',
  defaultItem: {
    imageSrc: '/assets/__img_placeholder.png',
    headingText: 'Item Heading',
    bodyText: 'Description of item...',
  },
  fields: [
    {
      name: 'headingText',
      label: 'Heading',
      component: 'textarea',
    },
    {
      name: 'bodyText',
      label: 'Body Paragraph',
      component: 'textarea',
    },
    {
      name: 'imageSrc',
      label: 'Image',
      component: 'image',
      uploadDir: (props) => {
        // console.log('??', props);
        return '/static/assets';
      },
      parse: filename => {
        // console.log('fn', filename);
        return `/assets/${filename}`
      },
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
        const imageSrc = get(formValues, pathName);
        // console.log(imageSrc);
        if (imageSrc) {
          if (typeof imageSrc === 'string') {
            return imageSrc;
          }
          if (typeof imageSrc === 'object' && imageSrc.childImageSharp) {
            return imageSrc.childImageSharp.fluid.src;
          }
        }
        return '';
      }
    },
  ]
}
