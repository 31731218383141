import React, { useCallback, useMemo } from 'react';
import { Button, Box } from '../../ui';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';
import { applyEnabledToggles, layoutFields, styleFields, settingsDefaultItems, applyEnabled } from '../fieldConfigs';
import { useBreakpointLayouts, getUnitValue } from '../useBreakpointLayouts';
import { navigate } from '@reach/router';
import { useCMS } from 'tinacms';
import { usePathLocale, getLocaleLinkInDevelopment } from '../../editing/useEditingLocale';

export const useButtonLinkToHandler = (dataLinkTo) => {
  const cms = useCMS();
  const locale = usePathLocale();
  const onPressButton = useCallback((e, linkToOption = null) => {
    const linkTo = getLocaleLinkInDevelopment(dataLinkTo ? dataLinkTo : linkToOption, locale);
    if ((!cms.enabled || process.env.NODE_ENV === 'production') && linkTo) {
      if (linkTo.startsWith('#')) {
        const element = document.getElementById(linkTo.substring(1));
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        } else {
          navigate(`/${linkTo}`);
        }
      } else {
        navigate(linkTo)
      }
    } else {
      console.log(`click!`);
    }
  }, [cms, dataLinkTo, locale]);
  return onPressButton;
}

export const BaseButtonBlock = memo(React.forwardRef(function BaseButtonBlock(props, ref) {
  const {
    data,
    containerBlock = { },
    inlined,
    index,
    ...rest
  } = props;
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const { buttonType, buttonSize, buttonColor, bolded, padding, alignSelfStyle, shadowStyle } = settings;
  const blockStyles = { ...alignSelfStyle };
  if (padding) blockStyles.padding = padding;

  const Container = useMemo(() => inlined ? BlocksControls : Box, [inlined]);

  const buttonSizeStyle = { };
  let bWidth = null;
  if (settings.width) bWidth = getUnitValue(settings.width);
  if (bWidth) {
    buttonSizeStyle.width = '100%';
    blockStyles.width = bWidth;
  }
  const { text } = data;
  return (
    <Container {...blockStyles} index={index}>
      <Button
        variant={buttonType}
        size={buttonSize}
        color={buttonColor ? `$${buttonColor}` : null}
        regular={bolded ? false : true}
        ref={ref}
        {...shadowStyle}
        {...buttonSizeStyle}
        {...rest}
        >
        {text}
      </Button>
    </Container>
  )
}))

export const ButtonBlock = React.forwardRef(function ButtonBlock(props, ref) {
  const { data } = props;
  const { linkTo } = data;
  const onPressButton = useButtonLinkToHandler(linkTo);
  const cms = useCMS();

  const accessiblility = (!cms.enabled || process.env.NODE_ENV === 'production') && linkTo ? {
    accessibilityRole: 'link',
    href: linkTo,
  } : null;
  return (
    <BaseButtonBlock
      onPress={onPressButton}
      accessibility={accessiblility}
      ref={ref}
      {...props}
    />
  )
});

ButtonBlock.displayName = 'ButtonBlock';
ButtonBlock.defaultProps = { inlined: true };

const buttonSettingFields = [
  {
    name: 'buttonType',
    label: 'Type',
    component: 'select',
    options: [
      { label: 'Contained', value: 'contained' },
      { label: 'Outlined', value: 'outlined' },
      { label: 'Text', value: 'text' },
    ],
  },
  {
    name: 'buttonSize',
    label: 'Size',
    component: 'select',
    options: [
      { label: 'Large', value: 'large' },
      { label: 'Medium', value: 'medium' },
      { label: 'Small', value: 'small' },
    ]
  },
  {
    ...styleFields.backgroundColor,
    name: 'buttonColor',
    label: 'Color',
  },
  {
    name: 'bolded',
    label: 'Bold Text',
    component: 'toggle',
  },
  layoutFields.unitBased.width,
  layoutFields.padding,
  layoutFields.alignSelf,
  styleFields.shadow,
]

const defaultButtonBlockSettings = {
  buttonType: 'contained',
  buttonSize: 'large',
  buttonColor: {
    name: '',
    alpha: '',
  },
  bolded: true,
  width: {
    value: '',
    unit: '%',
  },
  padding: settingsDefaultItems.padding,
  alignSelf: settingsDefaultItems.alignSelf,
  shadow: settingsDefaultItems.shadow
}

export const ButtonBlockTemplate = {
  type: 'ButtonBlockTemplate',
  name: 'button',
  key: 'button-block',
  label: 'Button',
  component: 'grouped',
  defaultItem: {
    text: 'Click me',
    linkTo: '/',
    settings: {
      sm: defaultButtonBlockSettings,
      xs: applyEnabled(defaultButtonBlockSettings, false, ['padding', 'shadow'])
    },
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'text'
    },
    {
      name: 'linkTo',
      label: 'Link',
      description: 'If it\'s a link within the site (www.understoryweather.com + YOUR_LINK) make sure to prefix it with a "/". The link to the home page is just "/".',
      component: 'text',
    },
    // TODO: onClick field for button, text, and group blocks... most likely a dropdown and different fields per option
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: buttonSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: buttonSettingFields
        })
      ]
    }
  ]
}
