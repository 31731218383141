import { useTheme, useBreakpoint } from '../hooks';
import { getStyle } from './createStyles';

function useStyleSystemProps(props = {}) {
  const { style: styleProp } = props;
  const theme = useTheme();
  const [breakpoint, breakpoints] = useBreakpoint();
  const style = getStyle(styleProp);
  const styleSystemProps = {
    breakpoint,
    breakpoints,
    theme,
    ...props,
    style,
    getStyle,
  };

  return styleSystemProps;
}

export { useStyleSystemProps };
