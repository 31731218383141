import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { mergeAndConcat } from 'merge-anything';
import { useJsonForm } from 'gatsby-tinacms-json';
import { useFormScreenPlugin } from 'tinacms';
import { navigate } from '@reach/router';
import cloneDeep from 'lodash.clonedeep';
import {
  Box,
  Content,
} from '../ui';
import { useTheme, useMatchBreakpoint } from '../ui/hooks';
import {
  ImageBlockTemplate,
  ImageBlock,
  TextBlock,
  TextBlockTemplate,
  HeadingBlock,
  HeadingBlockTemplate,
  TextLinkBlock,
  textLinkBlockTemplate,
  layoutFields,
  styleFields,
  textLinkSettings,
  defaultImageBlockSettings,
  applyEnabledToggles,
  useBreakpointLayouts,
  marginWidthStyles,
} from '../blocks';
import { useMatchLocalizedData } from '../editing/useEditingLocale';
import { SiteLocaleSelect } from './SiteLocaleSelect';

export const Footer = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      settingsJson(fileRelativePath: { eq: "/content/settings/footer.json" }) {
        ...footer
        defaultLocale
        rawJson
        fileRelativePath
      }
    }
  `);

  const { settingsJson: json } = footerData;

  const [data, localeIndex] = useMatchLocalizedData(json.configs, { locale: json.defaultLocale });
  const footerFormConfig = useMemo(() => {
    return getFooterForm(localeIndex);
  }, [localeIndex])

  const [, form] = useJsonForm(json, footerFormConfig);
  useFormScreenPlugin(form);

  const settings = useBreakpointLayouts(data.settings);
  const theme = useTheme();
  const { colors, spacing, breakpoints } = theme;

  const blockStyles = {
    ...settings.sizeStyle,
    ...settings.overflowStyle,
    ...settings.shadowStyle
  }
  if (settings.backgroundColor) blockStyles.backgroundColor = settings.backgroundColor;
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;

  const { bottomFooter } = data;
  const bottomFooterDirection = useMatchBreakpoint({ xs: 'column', sm: 'row' });
  const containerBlock = {
    direction: bottomFooterDirection,
    alignContent: settings.alignContent,
    backgroundColor: settings.backgroundColor
  };


  return (
    <Content {...blockStyles} width="100%">
      <Content
        flexDirection={breakpoints({ sm: 'column', md: 'row' })}
        width="100%"
        {...settings.layoutStyle}
        {...(settings.marginWidth ? marginWidthStyles[settings.marginWidth](theme) : null)}
      >
        {data.logoImage && data.logoImage.imageSrc ? <ImageBlock data={data.logoImage} inlined={false} mb={spacing(5)} /> : null}
        <Box
          flexDirection={breakpoints({ xs: 'column', sm: 'row' })} gap={breakpoints({xs: 0, sm: spacing(6) })}>
        {
          data.columns && data.columns.length ? (
            data.columns.map((column, columnIndex) => {
              if (column && column.length) {
                const columnKey = `footer.column.${columnIndex}`;
                return (
                  <Box key={columnKey}>
                    {
                      column.map((blockData, blockIndex) => {
                        const template = blockData._template;
                        const blockProps = {
                          key: `${columnKey}.block.${blockIndex}-${template}`,
                          data: blockData,
                          inlined: false
                        }
                        switch (template) {
                          case HeadingBlockTemplate.type:
                            return <HeadingBlock {...blockProps} />
                          case TextBlockTemplate.type:
                            return <TextBlock {...blockProps} />
                          case textLinkBlockTemplate.type:
                            return <TextLinkBlock {...blockProps} />
                          default:
                            return true;
                        }
                      })
                    }
                  </Box>
                )
              }
              return true;
            })
          ) : null
        }
        </Box>
      </Content>
      <Content
        py={spacing(3)}
        border={{
          top: {
            width: 1,
            color: colors.gray['200']
          }
        }}
        variant="container"
        flexDirection={breakpoints({ xs: 'column-reverse', sm: 'row' })}
        justifyContent="space-between"
        alignItems="center"
        gap={spacing(2)}>
        <Box flexDirection={bottomFooterDirection} gap={breakpoints({ xs: 0, sm: spacing(2) })}>
          <TextBlock
            inlined={false}
            data={{ settings: bottomFooter.settings, text: bottomFooter.copyrightText }}
            containerBlock={containerBlock}
          />
          <Box flexDirection="row" justifyContent="center" gap={spacing(2)}>
            {
              bottomFooter.links && bottomFooter.links.length ? (
                bottomFooter.links.map((linkData, i) => (
                  <TextLinkBlock
                    inlined={false}
                    key={`bottomFooterLink${i}`}
                    containerBlock={{ ...containerBlock, direction: 'row' }}
                    data={{ settings: bottomFooter.settings, text: linkData.linkText, href: linkData.linkUrl }}
                  />
                ))
              ) : null
            }
          </Box>
        </Box>
        <Box flexDirection="row" justifyContent="center" alignItems="center" gap={spacing(3)}>
          {
            // removed due to issues taken from French gov on wording. Keep removed until otherwise noted.
            // <SiteLocaleSelect
            //   menuProps={{
            //     anchorOrigin: { vertical: 'top', horizontal: 'left' },
            //     transformOrigin: { vertical: 'bottom', horizontal: 'left' },
            //     disableScrollLock: false,
            //   }}
            // />
          }
          {
            bottomFooter.socialButtons && bottomFooter.socialButtons.length ? (
              bottomFooter.socialButtons.map((socialImageData, i) => (
                <ImageBlock
                  key={`bottomFooterSocialImageButton${i}`}
                  inlined={false}
                  containerBlock={{ ...containerBlock, direction: 'row' }}
                  data={socialImageData}
                  onPress={() => socialImageData.socialUrl && navigate(socialImageData.socialUrl)}
                  accessibility={socialImageData.socialUrl ? {
                    accessibilityRole: "link",
                    href: socialImageData.socialUrl
                  } : null}
                />
              ))
            ) : null
          }
        </Box>
      </Content>
    </Content>
  )

}


const footerSettingsFields = [
  layoutFields.marginWidth,
  layoutFields.height,
  layoutFields.maxHeight,
  layoutFields.alignContent,
  layoutFields.justifyContent,
  layoutFields.contentSpacing,
  layoutFields.padding,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]


const templates = {
  [HeadingBlockTemplate.type]: cloneDeep(HeadingBlockTemplate),
  [TextBlockTemplate.type]: cloneDeep(TextBlockTemplate),
  [textLinkBlockTemplate.type]: cloneDeep(textLinkBlockTemplate),
}
templates[HeadingBlockTemplate.type].defaultItem.settings.sm.headingType = '6';
templates[HeadingBlockTemplate.type].defaultItem.settings.xs.headingType = '6';
templates[HeadingBlockTemplate.type].defaultItem.settings.sm.textSpaceAfter = false;
templates[HeadingBlockTemplate.type].defaultItem.settings.xs.textSpaceAfter = false;
templates[TextBlockTemplate.type].defaultItem.settings.sm.textSize = 'medium';
templates[TextBlockTemplate.type].defaultItem.settings.xs.textSize = 'medium';
templates[TextBlockTemplate.type].defaultItem.settings.sm.textSpaceAfter = true;
templates[TextBlockTemplate.type].defaultItem.settings.xs.textSpaceAfter = true;
templates[textLinkBlockTemplate.type].defaultItem.settings.sm.textSize = 'medium';
templates[textLinkBlockTemplate.type].defaultItem.settings.xs.textSize = 'medium';
templates[textLinkBlockTemplate.type].defaultItem.settings.sm.textSpaceAfter = true;
templates[textLinkBlockTemplate.type].defaultItem.settings.xs.textSpaceAfter = true;

export const getFooterForm = (index = 0) => ({
  label: "Site Footer",
  fields: [
    {
      label: "Logo",
      name: `rawJson.configs.${index}.logoImage`,
      component: "grouped",
      fields: [
        ...ImageBlockTemplate.fields
      ]
    },
    {
      label: "Column 1",
      name: `rawJson.configs.${index}.columns.0`,
      component: "blocks",
      templates
    },
    {
      label: 'Column 2',
      name: `rawJson.configs.${index}.columns.1`,
      component: "blocks",
      templates
    },
    {
      label: 'Bottom Footer',
      name: `rawJson.configs.${index}.bottomFooter`,
      component: 'grouped',
      fields: [
        {
          label: 'Copyright',
          name: 'copyrightText',
          component: 'textarea',
        },
        {
          label: 'Links',
          name: 'links',
          component: 'blocks',
          templates: {
            footerLinkBlock: {
              type: 'footerLinkBlock',
              name: 'footerLink',
              key: 'footer-link',
              label: 'Link',
              component: 'grouped',
              itemProps: item => ({
                label: item.linkText
              }),
              defaultItems: {
                linkText: 'Link',
                linkUrl: '',
              },
              fields: [
                {
                  label: 'Text',
                  name: 'linkText',
                  component: 'textarea',
                },
                {
                  label: 'URL',
                  name: 'linkUrl',
                  component: 'text'
                }
              ]
            }
          }
        },
        {
          label: 'Social',
          name: 'socialButtons',
          component: 'blocks',
          templates: {
            footerSocialImageBlock: {
              type: 'footerSocialImageBlock',
              name: 'footerSocialImage',
              key: 'footer-social-image',
              label: 'Social Item',
              component: 'grouped',
              defaultItem: {
                imageSrc: '/assets/ic-twitter1.svg',
                socialUrl: '',
                settings: {
                  sm: mergeAndConcat(defaultImageBlockSettings, {
                    width: { value: '24', unit: 'px' },
                    height: '24'
                  }),
                  xs: mergeAndConcat(defaultImageBlockSettings, {
                    width: { value: '24', unit: 'px' },
                    height: '24'
                  }),
                }
              },
              fields: [
                {
                  label: 'URL',
                  name: 'socialUrl',
                  component: 'text'
                },
                ...ImageBlockTemplate.fields
              ]
            }
          }
        },
        {
          name: 'settings',
          label: 'Settings',
          component: 'tabGroup',
          fields: [
            {
              name: 'sm',
              label: 'Desktop',
              component: 'grouped',
              fields: textLinkSettings
            },
            applyEnabledToggles({
              name: 'xs',
              label: 'Mobile',
              // description: 'Configure this first. Desktop will always default to these settings if a value is empty or automatic',
              component: 'grouped',
              fields: textLinkSettings
            }),
          ]
        }
      ]
    },
    {
      name: `rawJson.configs.${index}.settings`,
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: footerSettingsFields
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          // description: 'Configure this first. Desktop will always default to these settings if a value is empty or automatic',
          component: 'grouped',
          fields: footerSettingsFields
        }),
      ]
    }
  ]
})

export const footerFragment = graphql`
  fragment footer on SettingsJson {
    configs {
      locale
      logoImage {
        imageSrc
        settings {
          sm {
            alignImage
            alignSelf
            backgroundColor {
              name
              alpha
            }
            border {
              bottom
              color {
                name
                alpha
              }
              left
              right
              style
              top
              width
            }
            borderRadius {
              bottomLeft
              bottomRight
              topLeft
              topRight
              value
            }
            clipContent
            height
            opacity
            padding {
              bottom
              left
              right
              top
            }
            resizeMode
            shadow {
              color {
                name
                alpha
              }
              elevation
              invert
              opacity
            }
            width {
              unit
              value
            }
          }
          xs {
            alignImage
            alignImage_enabled
            alignSelf
            alignSelf_enabled
            backgroundColor {
              name
              alpha
            }
            backgroundColor_enabled
            border {
              bottom
              bottom_enabled
              color {
                name
                alpha
              }
              color_enabled
              left
              left_enabled
              right
              right_enabled
              style
              style_enabled
              top
              top_enabled
              width
              width_enabled
            }
            borderRadius {
              bottomLeft
              bottomLeft_enabled
              bottomRight
              bottomRight_enabled
              topLeft
              topLeft_enabled
              topRight
              topRight_enabled
              value
              value_enabled
            }
            clipContent
            clipContent_enabled
            height
            height_enabled
            opacity
            opacity_enabled
            padding {
              bottom
              bottom_enabled
              left
              left_enabled
              right
              right_enabled
              top
              top_enabled
            }
            resizeMode
            resizeMode_enabled
            shadow {
              color {
                name
                alpha
              }
              color_enabled
              elevation
              elevation_enabled
              invert
              invert_enabled
              opacity
              opacity_enabled
            }
            width {
              unit
              value
            }
            width_enabled
          }
        }
      }
      columns {
        _template
        href
        text
        settings {
          sm {
            alignSelf
            bolded
            headingType
            hoverStyle {
              color {
                name
                alpha
              }
              underlined
            }
            maxLines
            width {
              unit
              value
            }
            maxWidth {
              unit
              value
            }
            opacity
            padding {
              bottom
              left
              right
              top
            }
            textAlign
            textColor {
              name
              alpha
            }
            textSize
            textSpaceAfter
            underlined
            uppercased
          }
          xs {
            alignSelf
            alignSelf_enabled
            bolded
            bolded_enabled
            headingType
            headingType_enabled
            hoverStyle {
              color {
                name
                alpha
              }
              underlined
            }
            hoverStyle_enabled
            maxLines
            maxLines_enabled
            width {
              unit
              value
            }
            width_enabled
            maxWidth {
              unit
              value
            }
            maxWidth_enabled
            opacity
            opacity_enabled
            padding {
              bottom
              bottom_enabled
              left
              left_enabled
              right
              right_enabled
              top
              top_enabled
            }
            textAlign
            textAlign_enabled
            textColor {
              name
              alpha
            }
            textColor_enabled
            textSize
            textSize_enabled
            textSpaceAfter
            textSpaceAfter_enabled
            underlined
            underlined_enabled
            uppercased
            uppercased_enabled
          }
        }
      }
      bottomFooter {
        copyrightText
        links {
          _template
          linkText
          linkUrl
        }
        settings {
          sm {
            alignSelf
            bolded
            hoverStyle {
              color {
                name
                alpha
              }
              underlined
            }
            maxLines
            width {
              unit
              value
            }
            maxWidth {
              unit
              value
            }
            opacity
            padding {
              bottom
              left
              right
              top
            }
            textAlign
            textColor {
              name
              alpha
            }
            textSize
            textSpaceAfter
            underlined
            uppercased
          }
          xs {
            alignSelf
            alignSelf_enabled
            bolded
            bolded_enabled
            hoverStyle {
              color {
                name
                alpha
              }
              underlined
            }
            hoverStyle_enabled
            maxLines
            maxLines_enabled
            width {
              unit
              value
            }
            width_enabled
            maxWidth {
              unit
              value
            }
            maxWidth_enabled
            opacity
            opacity_enabled
            padding {
              bottom
              bottom_enabled
              left
              left_enabled
              right
              right_enabled
              top
              top_enabled
            }
            textAlign
            textAlign_enabled
            textColor {
              name
              alpha
            }
            textColor_enabled
            textSize
            textSize_enabled
            textSpaceAfter
            textSpaceAfter_enabled
            underlined
            underlined_enabled
            uppercased
            uppercased_enabled
          }
        }
        socialButtons {
          _template
          imageSrc
          socialUrl
          settings {
            sm {
              alignImage
              alignSelf
              backgroundColor {
                name
                alpha
              }
              border {
                bottom
                color {
                  name
                  alpha
                }
                left
                right
                style
                top
                width
              }
              borderRadius {
                bottomLeft
                bottomRight
                topLeft
                topRight
                value
              }
              clipContent
              height
              opacity
              padding {
                bottom
                left
                right
                top
              }
              resizeMode
              shadow {
                color {
                  name
                  alpha
                }
                elevation
                invert
                opacity
              }
              width {
                unit
                value
              }
            }
            xs {
              alignImage
              alignImage_enabled
              alignSelf
              alignSelf_enabled
              backgroundColor {
                name
                alpha
              }
              backgroundColor_enabled
              border {
                bottom
                bottom_enabled
                color {
                  name
                  alpha
                }
                color_enabled
                left
                left_enabled
                right
                right_enabled
                style
                style_enabled
                top
                top_enabled
                width
                width_enabled
              }
              borderRadius {
                bottomLeft
                bottomLeft_enabled
                bottomRight
                bottomRight_enabled
                topLeft
                topLeft_enabled
                topRight
                topRight_enabled
                value
                value_enabled
              }
              clipContent
              clipContent_enabled
              height
              height_enabled
              opacity
              opacity_enabled
              padding {
                bottom
                bottom_enabled
                left
                left_enabled
                right
                right_enabled
                top
                top_enabled
              }
              resizeMode
              resizeMode_enabled
              width {
                unit
                value
              }
              width_enabled
              shadow {
                color {
                  name
                  alpha
                }
                color_enabled
                elevation
                elevation_enabled
                invert
                invert_enabled
                opacity
                opacity_enabled
              }
            }
          }
        }
      }
      settings {
        sm {
          alignContent
          backgroundColor {
            name
            alpha
          }
          backgroundImage {
            alignImage
            height {
              unit
              value
            }
            resizeMode
            src
            width {
              unit
              value
            }
          }
          border {
            bottom
            color {
              name
              alpha
            }
            left
            right
            style
            top
            width
          }
          borderRadius {
            bottomLeft
            bottomRight
            topLeft
            topRight
            value
          }
          clipContent
          contentSpacing
          height
          justifyContent
          marginWidth
          maxHeight
          opacity
          padding {
            bottom
            left
            right
            top
          }
          shadow {
            color {
              name
              alpha
            }
            elevation
            invert
            opacity
          }
        }
        xs {
          alignContent
          alignContent_enabled
          backgroundColor {
            name
            alpha
          }
          backgroundColor_enabled
          backgroundImage {
            alignImage
            alignImage_enabled
            height {
              unit
              value
            }
            height_enabled
            resizeMode
            resizeMode_enabled
            src
            src_enabled
            width {
              unit
              value
            }
            width_enabled
          }
          border {
            bottom
            bottom_enabled
            color {
              name
              alpha
            }
            color_enabled
            left
            left_enabled
            right
            right_enabled
            style
            style_enabled
            top
            top_enabled
            width
            width_enabled
          }
          borderRadius {
            bottomLeft
            bottomLeft_enabled
            bottomRight
            bottomRight_enabled
            topLeft
            topLeft_enabled
            topRight
            topRight_enabled
            value
            value_enabled
          }
          clipContent
          clipContent_enabled
          contentSpacing
          contentSpacing_enabled
          height
          height_enabled
          justifyContent
          justifyContent_enabled
          marginWidth
          marginWidth_enabled
          maxHeight
          maxHeight_enabled
          opacity
          opacity_enabled
          padding {
            bottom
            bottom_enabled
            left
            left_enabled
            right
            right_enabled
            top
            top_enabled
          }
          shadow {
            color {
              name
              alpha
            }
            color_enabled
            elevation
            elevation_enabled
            invert
            invert_enabled
            opacity
            opacity_enabled
          }
        }
      }
    }
  }
`;
