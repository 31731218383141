const transforms = {
  'perspective': 1000, // TODO: <- why is this 1000 and not true
  'rotate': true,
  'rotateX': true,
  'rotateY': true,
  'rotateZ': true,
  'scale': true,
  'scaleX': true,
  'scaleY': true,
  'translateY': true,
  'translateX': true,
  'skewX': true,
  'skewY': true,
};

const shadowOffsetProps = {
  'shadowOffsetY': 'height',
  'shadowOffsetX': 'width',
}

function fromReactNativeStyles(style) {
  if (!style) {
    return style;
  }
  const { shadowOffset, transform, ...rest } = style;
  let result = { ...rest };
  if (shadowOffset) {
    const { width = 0, height = 0 } = shadowOffset;
    result.shadowOffset = shadowOffset;
    result.shadowOffsetX = width;
    result.shadowOffsetY = height;
  }
  if (Array.isArray(transform) && transform.length) {
    for (const tObj of transform) {
      for (const key in tObj) {
        result[key] = tObj[key];
      }
    }
  }
  return result;
}

function resolveLineHeight(lineHeight, fontSize) {
  let resolved = lineHeight;
  if (typeof lineHeight === 'string') {
    if (lineHeight.endsWith('%') && fontSize) {
      resolved = Number(lineHeight.substring(0, lineHeight.length - 1));
      if (!isNaN(resolved)) {
        return (resolved / 100) * fontSize;
      }
    }
    return null;
  }
  return resolved;
}

function toReactNativeStyles(styles) {
  const next = { };
  const transform = styles.transform || [ ];
  let shadowOffset = null;
  let hasPerspective = false;
  if (transform.length) {
    for (const t of transform) {
      if (t.perspective !== undefined) {
        hasPerspective = true;
      }
    }
  }
  let lineHeight = resolveLineHeight(styles.lineHeight, styles.fontSize);
  for (const key in styles) {
    if (key === 'transform' || key === 'lineHeight') continue;
    if (transforms[key]) {
      if (!hasPerspective && key === 'perspective') hasPerspective = true;
      const t = { };
      t[key] = styles[key];
      transform.push(t);
      continue;
    }
    if (shadowOffsetProps[key]) {
      if (!shadowOffset) shadowOffset = { width: 0, height: 0 };
      shadowOffset[shadowOffsetProps[key]] = styles[key];
      continue;
    }
    next[key] = styles[key];
  }
  if (transform.length) {
    if (!hasPerspective) {
      transform.push({ perspective: 1000 });
    }
    next.transform = transform;
  }
  if (shadowOffset) {
    next.shadowOffset = shadowOffset;
  }
  if (lineHeight) {
    next.lineHeight = lineHeight;
  }
  return next;
}

function flattenStyles(styles) {
  if (Array.isArray(styles)) {
    let flattened = { };
    for (let i = 0; i < styles.length; i += 1) {
      flattened = { ...flattened, ...styles[i] };
    }
    return toReactNativeStyles(flattened);
  }
  return toReactNativeStyles(styles);
};


export {
  flattenStyles,
  toReactNativeStyles,
  resolveLineHeight,
  fromReactNativeStyles,
};
