import { isSystemStyle } from './isSystemStyle';

export function parseSystemStyle(value) {
  if (isSystemStyle(value)) {
    if (typeof value === 'string') {
      return parseSystemStyle(value.slice(1))
    }
    if (Array.isArray(value)) {
      return parseSystemStyle(value[0]);
    }
  }
  return value;
}
