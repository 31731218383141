import React from 'react';
import styled from 'styled-components';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';


const HtmlWrapper = styled.div`
  font-family: 'Centra No2', 'Open Sans', sans-serif;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  text-align: left;

  p {
    color: #404141;
  	font-size: 16px;
  	text-align: left;
    margin-bottom: 24px;
    font-weight: 300;
  }

  ol {
    padding-left: 40px;
  }

  li {
  	color: #404141;
  	font-size: 16px;
  	text-align: left;
    margin-bottom: 8px;
    font-weight: 300;
  }

  h1 {
  	color: #404141;
  	font-size: 42px;
  	letter-spacing: 5px;
  	padding: 60px 0 30px;
  	text-transform: uppercase;
  	font-weight: 500;
  	text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  h2 {
  	color: #404141;
  	font-size: 24px;
  	margin: 10px auto;
  	font-weight: 500;
  	text-align: left;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  @media (max-width: 780px) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    li {
      font-size: 14px;
    }
  }
`

export const HtmlBlock = memo(({
  index,
  data,
  containerBlock = { },
  ...rest
}) => {
  // const settings = useBreakpointLayouts(data.settings, containerBlock);
  const html = data.innerHtml || "";
  return (
    <BlocksControls index={index} maxWidth={960} marginX="auto" paddingBottom="200">
      <HtmlWrapper
        style={{ minHeight: !html ? 500 : 0, minWidth: !html ? 500 : 0 }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </BlocksControls>
  )
})

export const htmlBlockTemplate = {
  type: 'htmlBlockTemplate',
  name: 'html-block-template',
  key: 'html-block',
  label: 'HTML Block',
  component: 'grouped',
  defaultItem: {
    innerHtml: '<div style="color: salmon">This is html</div>',
  },
  fields: [
    {
      name: 'innerHtml',
      label: 'HTML',
      component: 'textarea'
    }
  ]
}
