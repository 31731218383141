export const colorScales = {
  color: 'colors',
  backgroundColor: 'colors',
  borderColor: 'colors',
  tintColor: 'colors',
  borderTopColor: 'colors',
  borderBottomColor: 'colors',
  borderXColor: 'colors',
  borderLeftColor: 'colors',
  borderRightColor: 'colors',
  borderYColor: 'colors',
  outlineColor: 'colors',
  shadowColor: 'colors',
  textShadowColor: 'colors',
};

export const spacingScales = {
  margin: 'spacing',
  marginTop: 'spacing',
  marginRight: 'spacing',
  marginBottom: 'spacing',
  marginLeft: 'spacing',
  marginX: 'spacing',
  marginY: 'spacing',
  padding: 'spacing',
  paddingTop: 'spacing',
  paddingRight: 'spacing',
  paddingBottom: 'spacing',
  paddingLeft: 'spacing',
  paddingX: 'spacing',
  paddingY: 'spacing',
  top: 'spacing',
  right: 'spacing',
  bottom: 'spacing',
  left: 'spacing',
  gridGap: 'spacing',
  gridColumnGap: 'spacing',
  gridRowGap: 'spacing',
  gap: 'spacing',
  columnGap: 'spacing',
  rowGap: 'spacing',
  // custom
  translateX: 'spacing',
  translateY: 'spacing',
};

export const bordersScales = {
  border: 'borders',
  borderTop: 'borders',
  borderRight: 'borders',
  borderBottom: 'borders',
  borderLeft: 'borders',
};

export const borderWidthsScales = {
  borderWidth: 'borderWidths',
  borderTopWidth: 'borderWidths',
  borderBottomWidth: 'borderWidths',
  borderLeftWidth: 'borderWidths',
  borderRightWidth: 'borderWidths',
  borderXWidth: 'borderWidths',
  borderYWidth: 'borderWidth',
};

export const borderStylesScales = {
  borderStyle: 'borderStyles',
  borderTopStyle: 'borderStyles',
  borderBottomStyle: 'borderStyles',
  borderLeftStyle: 'borderStyles',
  borderRightStyle: 'borderStyles',
  borderXStyle: 'borderStyles',
  borderYStyle: 'borderStyles',
};

export const radiiScales = {
  borderRadius: 'radii',
  borderTopRightRadius: 'radii',
  borderTopLeftRadius: 'radii',
  borderBottomRightRadius: 'radii',
  borderBottomLeftRadius: 'radii',
};

export const sizesScales = {
  width: 'sizes',
  minWidth: 'sizes',
  maxWidth: 'sizes',
  height: 'sizes',
  minHeight: 'sizes',
  maxHeight: 'sizes',
  size: 'sizes',
  minSize: 'sizes',
  maxSize: 'sizes',
  flexBasis: 'sizes',
};

export const scales = {
  ...colorScales,
  ...spacingScales,
  ...bordersScales,
  ...borderWidthsScales,
  ...borderStylesScales,
  ...radiiScales,
  ...sizesScales,
  fontFamily: 'fonts',
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  lineHeight: 'lineHeights',
  letterSpacing: 'letterSpacings',
  shadow: 'shadows',
  textShadow: 'textShadows',
  zIndex: 'zIndex',
};
