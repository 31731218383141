import React from 'react';

export const CopyIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5 23.6V23.1H10H7C5.61929 23.1 4.5 21.9807 4.5 20.6V5C4.5 3.61929 5.61929 2.5 7 2.5H19C20.3807 2.5 21.5 3.61929 21.5 5V8V8.5H22H25C26.3807 8.5 27.5 9.61929 27.5 11V26.6C27.5 27.9807 26.3807 29.1 25 29.1H13C11.6193 29.1 10.5 27.9807 10.5 26.6V23.6Z" stroke="black"/>
    <rect x="10.5" y="8.5" width="17" height="20.6" rx="2.5" stroke="black"/>
  </svg>
)
