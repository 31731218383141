import React, { useContext, useState, useMemo } from 'react';
import cloneDeep from 'lodash.clonedeep';

export const copyBlockDataContext = React.createContext([{}, () => null]);

export function useCopyBlockData(field) {
  const [data, setData] = useContext(copyBlockDataContext);
  const canPaste = useMemo(() => {
    if (!data || !field || typeof field !== 'object' || !field.templates) {
      return false;
    }

    for (const key in field.templates) {
      const template = field.templates[key];
      // only paste if the field were pasting into handles copied template data
      if (template.type === data._template) {
        // if the template data contains sub templates which are not handled when nested then return false
        for (let i = 0; i < template.fields.length; i += 1) {
          if (template.fields[i].templates && typeof template.fields[i].templates === 'object') {
            if (data[template.fields[i].name] && Array.isArray(data[template.fields[i].name])) {
              for (let j = 0; j < data[template.fields[i].name].length; j += 1) {
                const subBlockData = data[template.fields[i].name][j];
                if (subBlockData && typeof subBlockData === 'object' && subBlockData._template) {
                  if (!template.fields[i].templates[subBlockData._template]) {
                    return false;
                  }
                }
              }
            }
          }
        }
        return true;
      }
    }
    return false;
  }, [field, data])

  return {
    data,
    setData,
    canPaste
  }
};

export const ProvideCopyBlockData = ({ children }) => {
  const [data, setData] = useState(null);

  const updateData = (dataToCopy) => {
    if (!dataToCopy || typeof dataToCopy !== 'object' || !dataToCopy._template) {
      setData(null);
    }
    setData(cloneDeep(dataToCopy));
  }

  return (
    <copyBlockDataContext.Provider value={[data, updateData]}>
      {children}
    </copyBlockDataContext.Provider>
  )
}
