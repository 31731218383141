import React from 'react';
import { mergeAndConcat } from 'merge-anything';
import { memo } from '../../ui/utils';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { BlocksControls } from '../BlocksControls';
import { TextField } from '../../ui';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
} from '../fieldConfigs';

export const BaseFormFieldBlock = memo(({
  index,
  data,
  containerBlock = { },
  field,
  updateField,
  formLoading,
  formDisabled,
  inputProps = { },
  component: Component,
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const {
    activeColor,
    sizeStyle,
    padding,
    alignSelfStyle,
  } = settings;

  const blockStyles = {
    padding: padding,
    ...sizeStyle,
    // alignSelf: 'stretch',
    ...alignSelfStyle
  }


  const value = field ? field.value : data.defaultValue;
  const placeholder = field ? field.placeholder : data.placeholder;
  const label = field ? field.label : data.fieldLabel;
  const required = field ? field.required : data.required;
  const helperText = field ? field.helperText : data.helperText;
  const error = field ? field.error : false;

  const inputStyle = { }
  if (sizeStyle && sizeStyle.height) inputStyle.height = sizeStyle.height;

  return (
    <BlocksControls {...blockStyles} index={index}>
      <Component
        width="100%"
        disabled={formDisabled}
        color={activeColor ? `${activeColor}` : null}
        value={value}
        onChangeText={(value) => !formLoading && updateField(index, value)}
        placeholder={placeholder}
        label={label}
        required={required}
        error={error}
        helperText={helperText || ""}
        {...inputProps}
        {...inputStyle}
      />
    </BlocksControls>
  )
});

BaseFormFieldBlock.defaultProps = {
  component: TextField
}


const fieldSettingsFields = [
  {
    ...styleFields.textColor,
    name: 'activeColor',
    label: 'Active Color',
  },
  layoutFields.unitBased.width,
  layoutFields.unitBased.maxWidth,
  layoutFields.height,
  layoutFields.alignSelf,
  layoutFields.padding,
]

export const defaultFieldSettings = {
  activeColor: {
    name: 'secondary',
    alpha: '',
  },
  width: settingsDefaultItems.width,
  maxWidth: settingsDefaultItems.maxWidth,
  height: '',
  alignSelf: settingsDefaultItems.alignSelf,
  padding: settingsDefaultItems.padding,
}


export const baseFormFieldBlockTemplate = {
  type: '',
  name: '',
  key: '',
  label: '',
  component: 'grouped',
  defaultItem: {
    fieldName: '',
    fieldLabel: '',
    placeholder: '',
    defaultValue: '',
    required: false,
    requiredMessage: '* Field required',
    invalidMessage: '',
    helperText: '',
    settings: {
      sm: defaultFieldSettings,
      xs: applyEnabled(defaultFieldSettings, false, ['padding'])
    },
  },
  fields: [
    {
      name: 'fieldName',
      label: 'Name',
      component: 'text'
    },
    {
      name: 'fieldLabel',
      label: 'Label',
      component: 'text',
    },
    {
      name: 'placeholder',
      label: 'Placeholder',
      component: 'text',
    },
    {
      name: 'defaultValue',
      label: 'Default Value',
      component: 'text',
    },
    {
      name: 'required',
      label: 'Required',
      component: 'toggle',
    },
    {
      name: 'requiredMessage',
      label: 'Required Input Message',
      component: 'text',
    },
    {
      name: 'invalidMessage',
      label: 'Invalid Input Message',
      component: 'text',
    },
    {
      name: 'helperText',
      label: 'Helper Message',
      component: 'text',
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: fieldSettingsFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: fieldSettingsFields
        })
      ]
    }
  ]
}

export function createFormFieldTemplate(template = { }, resolverFunc) {
  if (!template.type || !template.name || !template.key || !template.label) {
    throw new Error('Form Field Templates must have the following defined: type, name, key, label');
  }
  const mergedTemplate = mergeAndConcat(baseFormFieldBlockTemplate, template);
  if (resolverFunc && typeof resolverFunc === 'function') {
    return resolverFunc(mergedTemplate);
  }
  return mergedTemplate;
}