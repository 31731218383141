import React, { useState } from 'react';
import { Platform } from 'react-native';
import { Text, TextProps } from '../Text';
import { Box } from '../Box';
import { Hoverable } from '../Hoverable';
import { BaseInput } from './BaseInput';
import { NumericInput } from './NumericInput';
import { TextCount } from './TextCount';
import { withStyles } from '../../styling';
import {
  flexBox,
  margin,
  position,
  transforms,
  padding,
  sizes,
  appearance,
  typography,
  createStylePropsFunction,
} from '../../system/props';

const textProps = createStylePropsFunction(TextProps);
const filterProps = [
  ...typography.filterProps,
  ...appearance.filterProps,
  ...textProps.filterProps,
  ...flexBox.filterProps,
  ...margin.filterProps,
  ...position.filterProps,
  ...transforms.filterProps,
  ...sizes.filterProps,
  ...padding.filterProps,
  'includeLabelSpacing',
];

export const TextInput = withStyles(
  ({ size, variant, variantPrefix, color, onColor, multiline, ...props }) => {
    // ^^ dont want to parse this into root (size, variants, and colors are only thing possibly shared...)
    return {
      root: {
        ...flexBox({ flexDirection: 'column', ...props }),
        ...margin(props),
        ...position(props),
        ...transforms(props),
      },
      textBox: {
        overflow: 'visible',
        flexDirection: 'row',
        ...appearance(props, { omit: transforms.filterProps }),
        ...padding({ padY: '$2', ...props }),
        ...sizes(props),
      },
      focused: {},
      hovered: {},
      disabled: {},
      error: {},
      input: {
        flex: 1,
        borderColor: 'transparent',
        borderWidth: 0,
        height: '100%',
        props: {
          ...typography({
            textAlignVertical: multiline === true ? 'top' : null,
            ...props,
          }),
          ...textProps({
            align: 'left',
            center: false,
            size: 'large',
            // large: true, // TODO: stop using the shortcut booleans. when text is refactored this change this to size="large"
            maxLines: 1,
            variant,
            variantPrefix,
            color,
            onColor,
            ...props,
          }),
          ...(Platform.OS === 'web' ? null : { lineHeight: 0 }),
        },
      },
      inputFocused: {},
      inputDisabled: {},
      inputFilled: {},
    };
  },
  {
    name: 'TextInput',
    filterProps,
    preserveStyleProp: true,
  }
)(
  React.forwardRef(function TextInput(props, ref) {
    const {
      value,
      placeholder,
      onFocus: onFocusProp,
      onBlur: onBlurProp,
      onChange,
      onChangeText,
      onChangeValue,
      textAlignVertical,
      multiline = false,
      error = false,
      // TODO: add the rest of TextInput props...
      type = 'text', // dont pass
      InputProps = {}, // TODO: rename to InputProps
      TextBoxProps = {},
      textBoxRef,
      inputRef,
      disabled = false,
      ignoreValue = true,
      accessibility,
      InputComponent,
      children,
      startAdornment,
      endAdornment,
      renderBefore,
      renderAfter,
      renderAbove,
      renderBelow,
      style: styleProp,
      styles,
      animate: animateProp,
      showTextCount = false,
      renderCount,
      // number input props
      onChangeNumber,
      parse,
      format,
      precision,
      min,
      max,
      step,
      disableAnimate,
      onColor,
      filled: filledProp = false,
      ...rest
    } = props;

    const [focused, setFocused] = useState(false);

    const component = InputComponent
      ? InputComponent
      : type === 'number'
      ? NumericInput
      : BaseInput;

    const handlers = {
      onFocus: () => {
        setFocused(true);
        if (onFocusProp) {
          onFocusProp();
        }
      },
      onBlur: () => {
        setFocused(false);
        if (onBlurProp) {
          onBlurProp();
        }
      },
    };

    if (onChange) {
      handlers.onChange = (...args) => onChange(...args);
    }
    if (onChangeValue) {
      if (component === BaseInput) {
        handlers.onChangeText = (...args) => {
          onChangeValue(...args);
          if (onChangeText) {
            onChangeText(...args);
          }
        };
      } else {
        handlers.onChangeValue = (...args) => onChangeValue(...args);
      }
    }
    if (onChangeText && !handlers.onChangeText) {
      handlers.onChangeText = (...args) => onChangeText(...args);
    }

    const filled = filledProp || (props.value !== null && props.value !== undefined && props.value !== '');

    const textInputStyle = [styles.input];

    if (disabled) {
      textInputStyle.push(styles.inputDisabled);
    } else if (focused) {
      textInputStyle.push(styles.inputFocused);
    }
    if (filled) {
      textInputStyle.push(styles.inputFilled);
    }

    const textInputProps = {
      ...styles.props.input,
      ...(type === 'number' ? {
            onChangeNumber,
            parse,
            format,
            precision,
            min,
            max,
            step,
          }
        : null),
      ...(focused && !disabled ? styles.props.inputFocused : null),
      ...(filled ? styles.props.inputFilled : null),
      ...InputProps,
      ...handlers,
      style: [...textInputStyle, InputProps.style],
    };

    const renderProps = { onColor, error, styles, focused, disabled, filled };

    return (
      <Box
        disableAnimationDefaults
        disabled={disabled}
        style={styleProp}
        ref={ref}
        {...rest}
      >
        {typeof renderAbove === 'function' ? renderAbove(renderProps) : renderAbove}
        <Hoverable>
          {({ hovered }) => {

            // TODO: NATIVE only issue: animation seems to not happen even tho the animate prop is updated
            let animate = ['default'];
            if (!disabled) {
              if (focused) {
                animate.push('focused');
              } else if (hovered) {
                animate.push('hovered');
              }
              if (error) {
                animate.push('error');
              }
            }
            if (animateProp) {
              animate.push(animateProp);
            }

            const textBoxStyle = TextBoxProps.style;
            let style = error ? [styles.error, textBoxStyle] : [textBoxStyle];
            if (disabled) {
              style = [styles.textBox, styles.disabled, ...style];
            } else if (focused) {
              style = [styles.textBox, styles.focused, ...style];
            } else if (hovered) {
              style = [styles.textBox, styles.hovered, ...style];
            } else {
              style = [styles.textBox, ...style];
            }

            return (
              <Box
                disableAnimationDefaults
                disabled={disabled}
                ref={textBoxRef}
                {...styles.props.textBox}
                {...TextBoxProps}
                animate={animate}
                style={style}
              >
                {startAdornment}
                {renderBefore ? renderBefore({ startAdornment, textBoxStyle: style, ...renderProps }) : null}
                <Text
                  value={value}
                  placeholder={placeholder}
                  ignoreValue={ignoreValue}
                  disabled={disabled}
                  component={component}
                  textAlignVertical={textAlignVertical}
                  multiline={multiline}
                  ref={inputRef}
                  accessibility={{
                    accessible: true,
                    accessibilityRole: null,
                    ...accessibility,
                  }}
                  {...textInputProps}
                />
                {children}
                {endAdornment}
                {showTextCount ? (
                  <TextCount
                    value={props.value}
                    maxLength={props.maxLength}
                    renderCount={renderCount}
                    onColor={onColor}
                    styles={styles}
                  />
                ) : null}
                {renderAfter ? renderAfter({ endAdornment,  textBoxStyle: style, ...renderProps }) : null}
              </Box>
            );
          }}
        </Hoverable>
        {typeof renderBelow === 'function' ? renderBelow(renderProps) : renderBelow}
      </Box>
    );
  })
);
