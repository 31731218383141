import { createStylePropsFunction } from './createStylePropsFunction';
import {
  paddingAliases,
  marginAliases,
  backgroundColorAliases,
  borderRadiusAliases,
  borderWidthAliases,
  borderColorAliases,
  borderStyleAliases,
  translateAliases,
} from '../aliases';
import {
  colorScales,
  spacingScales,
  bordersScales,
  borderWidthsScales,
  borderStylesScales,
  radiiScales,
  sizesScales,
} from '../scales';
import {
  FlexPropTypes,
  PositionPropTypes,
  ShadowPropTypes,
  TransformPropTypes,
  TextPropTypes,
} from '../StylePropTypes';

const padding = createStylePropsFunction({
  padding: 'padding',
  paddingX: 'paddingX',
  paddingY: 'paddingY',
  paddingLeft: 'paddingLeft',
  paddingRight: 'paddingRight',
  paddingTop: 'paddingTop',
  paddingBottom: 'paddingBottom',
  ...paddingAliases,
});

const margin = createStylePropsFunction({
  margin: 'margin',
  marginX: 'marginX',
  marginY: 'marginY',
  marginLeft: 'marginLeft',
  marginRight: 'marginRight',
  marginTop: 'marginTop',
  marginBottom: 'marginBottom',
  ...marginAliases,
});

const backgroundColor = createStylePropsFunction({
  backgroundColor: 'backgroundColor',
  ...backgroundColorAliases,
});

const borderRadius = createStylePropsFunction({
  borderRadius: 'borderRadius',
  borderLeftRadius: 'borderLeftRadius',
  borderTopRadius: 'borderTopRadius',
  borderRightRadius: 'borderRightRadius',
  borderBottomRadius: 'borderBottomRadius',
  ...borderRadiusAliases,
});

const borderWidth = createStylePropsFunction({
  borderWidth: 'borderWidth',
  borderLeftWidth: 'borderLeftWidth',
  borderRightWidth: 'borderRightWidth',
  borderTopWidth: 'borderTopWidth',
  borderBottomWidth: 'borderBottomWidth',
  borderXWidth: 'borderXWidth',
  borderYWidth: 'borderYWidth',
  ...borderWidthAliases,
});

const borderColor = createStylePropsFunction({
  borderColor: 'borderColor',
  borderLeftColor: 'borderLeftColor',
  borderRightColor: 'borderRightColor',
  borderTopColor: 'borderTopColor',
  borderBottomColor: 'borderBottomColor',
  borderXColor: 'borderXColor',
  borderYColor: 'borderYColor',
  ...borderColorAliases,
});

const borderStyle = createStylePropsFunction({
  borderStyle: 'borderStyle',
  borderLeftStyle: 'borderLeftStyle',
  borderRightStyle: 'borderRightStyle',
  borderTopStyle: 'borderTopStyle',
  borderBottomStyle: 'borderBottomStyle',
  borderXStyle: 'borderXStyle',
  borderYStyle: 'borderYStyle',
  ...borderStyleAliases,
});

const transformTranslate = createStylePropsFunction({
  translateX: 'translateX',
  translateY: 'translateY',
  ...translateAliases,
});

const colors = createStylePropsFunction({
  ...backgroundColorAliases,
  ...borderColorAliases,
  ...colorScales,
});

const spacing = createStylePropsFunction({
  ...marginAliases,
  ...paddingAliases,
  ...translateAliases,
  ...spacingScales,
});

const border = createStylePropsFunction({
  ...borderRadius.props,
  ...borderWidth.props,
  ...borderColor.props,
  ...borderWidth.props,
  ...bordersScales,
  ...borderWidthsScales,
  ...borderStylesScales,
  ...radiiScales,
});

const sizes = createStylePropsFunction(sizesScales);

const flexBox = createStylePropsFunction(FlexPropTypes);

const position = createStylePropsFunction(PositionPropTypes);

const shadow = createStylePropsFunction(ShadowPropTypes);

const transforms = createStylePropsFunction(TransformPropTypes);

const typography = createStylePropsFunction(TextPropTypes);

const layout = createStylePropsFunction({
  ...padding.props,
  ...margin.props,
  ...transformTranslate.props,
  ...sizes.props,
  ...flexBox.props,
  ...position.props,
  ...transforms.props,
  overflow: 'overflow',
});

const appearance = createStylePropsFunction({
  ...shadow.props,
  ...border.props,
  ...transforms.props,
  ...backgroundColor.props,
  opacity: 'opacity',
  display: 'display',
});

export {
  //aliases
  padding,
  margin,
  backgroundColor,
  borderRadius,
  borderWidth,
  borderColor,
  borderStyle,
  transformTranslate,
  // scales
  colors,
  spacing,
  border,
  sizes,
  // additional for convenience
  flexBox,
  position,
  shadow,
  transforms,
  typography,
  layout,
  appearance,
};
