import React from 'react';
import { InlineImage } from '../InlineImage';
import { BlocksControls } from '../BlocksControls';
import { memo, get } from '../../ui/utils';
import { Box, BackgroundImage } from '../../ui';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
  alignContentOptions
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { useCMS } from 'tinacms';
import { useButtonLinkToHandler } from '../ButtonBlock';

export const ImageBlock = memo(({ data, index, inlined, containerBlock, ...rest }) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const {
    resizeMode,
    alignImage,
    sizeStyle,
    alignSelfStyle,
    padding,
    backgroundColor,
    border,
    borderRadius,
    overflowStyle,
    shadowStyle,
    opacity
  } = settings;

  const { linkTo } = data;
  const onPress = useButtonLinkToHandler(linkTo);
  const cms = useCMS();

  const imageProps = {
    src: data.imageSrc,
    resizeMode,
    alignImage,
    ...overflowStyle
  }

  const blockStyles = {
    ...sizeStyle,
    ...alignSelfStyle,
    ...overflowStyle,
    ...shadowStyle
  }

  if (padding) blockStyles.margin = padding;
  if (backgroundColor) blockStyles.backgroundColor = backgroundColor;
  if (border) blockStyles.border = border;
  if (borderRadius) {
    blockStyles.radius = borderRadius;
    imageProps.radius = borderRadius;
  }
  if (opacity === 0 || (opacity && opacity < 1)) blockStyles.opacity = opacity;

  const accessiblility = (!cms.enabled || process.env.NODE_ENV === 'production') && linkTo ? {
    accessibilityRole: 'link',
    href: linkTo,
  } : undefined;

  if (!inlined) {
    return (
      <Box {...blockStyles} onPress={!cms.enabled && typeof linkTo === 'string' && linkTo.trim() ? onPress : null} accessibility={accessiblility} {...rest}>
        <BackgroundImage {...imageProps} />
      </Box>
    )
  }

  return (
    <BlocksControls onPress={!cms.enabled && typeof linkTo === 'string' && linkTo.trim() ? onPress : null} accessibility={accessiblility} focusRing={{ offset: 0 }} insetControls {...blockStyles} index={index} {...rest}>
      <InlineImage
        {...imageProps}
        component={BackgroundImage}
        name="imageSrc"
        previewSrc={(formValues, fieldProps) => {
          // console.log('PREIVESSRC', formValues, fieldProps);
          // return formValues.imageSrc;
          const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
          const imageSrc = get(formValues, pathName);
          // console.log(imageSrc);
          if (imageSrc) {
            if (typeof imageSrc === 'string') {
              return imageSrc;
            }
            if (typeof imageSrc === 'object' && imageSrc.childImageSharp) {
              return imageSrc.childImageSharp.fluid.src;
            }
          }
          return '';
        }}
        parse={filename => {
          // console.log('fn', filename);
          return `/assets/${filename}`
        }}
        uploadDir={(props) => {
          // console.log('??', props);
          return '/static/assets';
        }}
      />
    </BlocksControls>
  )
});

ImageBlock.displayName = "ImageBlock";

ImageBlock.defaultProps = { inlined: true };

export const imageSettingFields = [
  {
    name: 'resizeMode',
    label: 'Scale Mode',
    component: 'select',
    options: [
      { label: 'Fit', value: 'contain' },
      { label: 'Fill', value: 'cover' },
    ]
  },
  { name: 'alignImage', label: 'Align Image', component: 'select', options: alignContentOptions },
  layoutFields.unitBased.width,
  layoutFields.height,
  layoutFields.alignSelf,
  layoutFields.padding,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.shadow,
  styleFields.clipContent,
]

export const defaultImageBlockSettings = {
  resizeMode: 'contain',
  alignImage: 'center',
  width: {
    value: '50',
    unit: '%',
  },
  height: '200',
  alignSelf: settingsDefaultItems.alignSelf,
  padding: settingsDefaultItems.padding,
  backgroundColor: {
    name: '',
    alpha: '',
  },
  opacity: '100',
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  shadow: settingsDefaultItems.shadow,
  clipContent: false,
}

export const ImageBlockTemplate = {
  type: 'ImageBlockTemplate',
  name: 'image',
  key: 'image-block',
  label: 'Image',
  component: 'grouped',
  defaultItem: {
    imageSrc: '/assets/__img_placeholder.png',
    linkTo: '',
    settings: {
      sm: defaultImageBlockSettings,
      xs: applyEnabled(defaultImageBlockSettings, false, ['padding', 'border', 'borderRadius', 'shadow'])
    }
  },
  fields: [
    {
      name: 'imageSrc',
      label: 'Image',
      component: 'image',
      uploadDir: (props) => {
        // console.log('??', props);
        return '/static/assets';
      },
      parse: filename => {
        // console.log('fn', filename);
        return `/assets/${filename}`
      },
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
        const imageSrc = get(formValues, pathName);
        // console.log(imageSrc);
        if (imageSrc) {
          if (typeof imageSrc === 'string') {
            return imageSrc;
          }
          if (typeof imageSrc === 'object' && imageSrc.childImageSharp) {
            return imageSrc.childImageSharp.fluid.src;
          }
        }
        return '';
      }
    },
    {
      name: 'linkTo',
      label: 'Link',
      description: 'If it\'s a link within the site (www.understoryweather.com + YOUR_LINK) make sure to prefix it with a "/". The link to the home page is just "/".',
      component: 'text',
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: imageSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: imageSettingFields
        })
      ]
    }

  ]
}
