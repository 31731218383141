import React from 'react';
import equal from 'fast-deep-equal/react';
import { Content, BackgroundImage } from '../../ui';
import { useTheme, useMatchBreakpoint, useMemoCompare } from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { mergeAndConcat } from 'merge-anything';
import cloneDeep from 'lodash.clonedeep';
import { VsRowBlock, vsRowBlockTemplate, VsRowHeadingBlock, vsRowHeadingBlockTemplate } from './VsRowBlock';
import { InlineBlocks } from '../InlineBlocks';
import { BlocksControls } from '../BlocksControls';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
  alignContentOptions
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { textSettingFields, defaultTextBlockSettings, useTextBlockSettings } from '../TextBlock';
import { headingSettingFields, defaultHeadingBlockSettings } from '../HeadingBlock';

function useGetComponentStyles({ rowSettings = { }, rowHeadingSettings = { }, columnSettings = [{ }, { }] } = { }, containerBlock = { }) {
  const row = useBreakpointLayouts(rowSettings, containerBlock);
  const container = {
    ...containerBlock,
    backgroundColor: row.backgroundColor ? row.backgroundColor : containerBlock.backgroundColor,
    textColor: row.textColor ? row.textColor : containerBlock.textColor,
    direction: 'row',
  }
  const rowHeading = useBreakpointLayouts(rowHeadingSettings, container);
  const columnHeading = useBreakpointLayouts(columnSettings[0], container);
  const columnItems = useBreakpointLayouts(columnSettings[1], container);


  const rowHeadingStyle = useTextBlockSettings(rowHeading, { ...container, backgroundColor: containerBlock.backgroundColor, textColor: containerBlock.textColor });
  rowHeadingStyle.level = rowHeading.headingType;
  const columnHeadingStyle = useTextBlockSettings(columnHeading, container, true)
  const columnItemsStyle = useTextBlockSettings(columnItems, container, true);
  const styles = {
    altBackgroundColor: row.altBackgroundColor || null,
    altTextColor: row.altTextColor || null,
    row: { ...row.layoutStyle, ...(row.backgroundColor ? { backgroundColor: row.backgroundColor } : null) },
    rowHeadingText: rowHeadingStyle,
    columnHeadingText: columnHeadingStyle,
    columnItemText: columnItemsStyle,
    columnHeading: columnHeading.sizeStyle,
    columnHeadingBorder: columnHeading.border || null,
    columnItems: columnItems.sizeStyle,
    columnItemBorder: columnItems.border || null,
  }

  return styles;


}

export const VsBlock = memo(({ data, index, containerBlock = { }, ...rest }) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const direction = 'column';
  const blockStyles = { ...settings.sizeStyle, ...settings.alignSelfStyle, ...settings.overflowStyle, ...settings.shadowStyle };
  if (settings.backgroundColor) blockStyles.backgroundColor = settings.backgroundColor;
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;

  const headings = [];
  const altItems = [];
  let lastHeadings = ['', ''];
  let alternate = false;
  for (let i = 0; i < data.vsItems.length; i += 1) {
    if (data.vsItems[i]._template === 'vsRowHeadingBlockTemplate') {
      headings.push(data.vsItems[i].items)
      lastHeadings = data.vsItems[i].items;
      altItems.push(false);
    } else {
      altItems.push(alternate);
      alternate = !alternate;
      headings.push(lastHeadings);
    }
  }


  const itemProps = {
    containerBlock: {
      direction,
      headings,
      altItems,
      textColor: settings.textColor,
      backgroundColor: settings.backgroundColor && settings.backgroundColor !== 'transparent' && settings.backgroundColor !== '$transparent' ? settings.backgroundColor : containerBlock.backgroundColor,
      numRows: data.vsItems.length,
      // not used anymore TODO: remove these
      mainColor: data.mainColor && data.mainColor.name ? `$${data.mainColor.name}` : settings.textColor,
      rowBorderColor: data.rowBorderColor && data.rowBorderColor.name ? `$${data.rowBorderColor.name}` : null,
      rowBorderStyle: data.rowBorderStyle ? `${data.rowBorderStyle}` : null,
    }
  }

  itemProps.componentStyles = useGetComponentStyles(data, itemProps.containerBlock);

  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      renderAs={Content}
      {...blockStyles}
    >
      {settings.backgroundImage && settings.backgroundImage.src ? <BackgroundImage {...settings.backgroundImage} /> : null}
      <InlineBlocks
        name="vsItems"
        blocks={VS_ITEM_BLOCKS}
        itemProps={itemProps}
        direction={direction}
        wrapperProps={{
          width: '100%',
          height: '100%',
          flexDirection: direction,
          padding: settings.padding,
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      />
    </BlocksControls>
  )
})

VsBlock.displayName = 'VsBlock';

const VS_ITEM_BLOCKS = {
  [vsRowHeadingBlockTemplate.type]: {
    Component: VsRowHeadingBlock,
    template: vsRowHeadingBlockTemplate
  },
  [vsRowBlockTemplate.type]: {
    Component: VsRowBlock,
    template: vsRowBlockTemplate
  }
}

const vsBlockSettingFields = [
  layoutFields.unitBased.width,
  layoutFields.alignSelf,
  layoutFields.padding,
  styleFields.textColor,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]

const defaultVsBlockSettings = {
  width: {
    value: '100',
    unit: '%',
  },
  alignSelf: settingsDefaultItems.alignSelf,
  padding: settingsDefaultItems.padding,
  textColor: settingsDefaultItems.textColor,
  backgroundColor: settingsDefaultItems.backgroundColor,
  opacity: settingsDefaultItems.opacity,
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  backgroundImage: settingsDefaultItems.backgroundImage,
  shadow: settingsDefaultItems.shadow,
  clipContent: settingsDefaultItems.clipContent
}

const vsRowSettingFields = [
  {
    ...styleFields.backgroundColor,
    name: 'backgroundColor',
    label: 'Row Color',
  },
  {
    ...styleFields.backgroundColor,
    name: 'altBackgroundColor',
    label: 'Alternate Row Color',
  },
  {
    ...styleFields.textColor,
    name: 'textColor',
    label: 'Text Color',
  },
  {
    ...styleFields.textColor,
    name: 'altTextColor',
    label: 'Alternate Text Color',
  },
  layoutFields.padding,
]



const vsRowDefaultSettings = {
  backgroundColor: {
    name: '',
    alpha: ''
  },
  altBackgroundColor: {
    name: '',
    alpha: '',
  },
  textColor: {
    name: '',
    alpha: '',
  },
  altTextColor: {
    name: '',
    alpha: '',
  },

  padding: {
    top: '',
    bottom: '',
    left: '5',
    right: ''
  }
}

const vsColumnSettingFields = [
  styleFields.border,
  ...textSettingFields,
]

const vsColumnItemDefaults = {
  sm: {
    border: {
      color: {
        name: 'gray.200',
        alpha: '',
      },
      width: '1',
      style: 'solid',
      top: false,
      bottom: false,
      left: true,
      right: false,
    },
    ...mergeAndConcat(defaultTextBlockSettings, {
      width: {
        value: "100",
        unit: '%',
      },
      textSize: 'large',
      textAlign: 'left',
      padding: {
        top: '4',
        bottom: '3',
        left: '3',
        right: '3'
      }
    })
  }
}

vsColumnItemDefaults.xs = mergeAndConcat(applyEnabled(vsColumnItemDefaults.sm, false, ['padding', 'border']), {
  textSize: 'small',
  textSize_enabled: true,
  textAlign: 'center',
  textAlign_enabled: true,
})

const vsColumnHeadingDefaults = {
  sm: {
    border: {
      color: {
        name: 'gray.200',
        alpha: '',
      },
      width: '1',
      style: 'solid',
      top: false,
      bottom: true,
      left: false,
      right: false,
    },
    ...mergeAndConcat(defaultTextBlockSettings, {
      width: {
        value: "28",
        unit: '%',
      },
      textSize: 'medium',
      bolded: true,
      textAlign: 'right',
      textSpaceAfter: true,
      padding: {
        top: '4',
        bottom: '3',
        left: '',
        right: '3'
      }
    })
  }
}

vsColumnHeadingDefaults.xs = mergeAndConcat(applyEnabled(vsColumnHeadingDefaults.sm, false, ['padding', 'border']), {
  width: {
    value: "100",
    unit: '%',
  },
  width_enabled: true,
  textSize: 'large',
  textSize_enabled: true,
  textAlign: 'center',
  textAlign_enabled: true,
  padding: {
    bottom: '0',
    bottom_enabled: true,
    right: '0',
    right_enabled: true
  }
})

const vsRowHeadingFields = cloneDeep(headingSettingFields);
const vsRowHeadingDefaults = {
  sm: mergeAndConcat(defaultHeadingBlockSettings, {
    headingType: '3',
    textSize: 'medium',
    bolded: true,
    textSpaceAfter: true,
    textAlign: 'left',
    padding: {
      top: '5',
      bottom: '',
      left: '3',
      right: '3'
    }
  }),
}

vsRowHeadingDefaults.xs = mergeAndConcat(applyEnabled(vsRowHeadingDefaults.sm, false, ['padding']), {
  textSize: 'xSmall',
  textSize_enabled: true,
  bolded: false,
  bolded_enabled: true,
  textAlign: 'center',
  textAlign_enabled: true,
  padding: {
    top: '0',
    top_enabled: true,
  },
  opacity: '75',
  opacity_enabled: true,
})

export const vsBlockTemplate = {
  type: 'vsBlockTemplate',
  name: 'vsBlock',
  key: 'vs-block',
  label: 'VS/Compare Grid',
  component: 'grouped',
  defaultItem: {
    vsItems: [],
    rowSettings: {
      sm: vsRowDefaultSettings,
      xs: mergeAndConcat(applyEnabled(vsRowDefaultSettings, false, ['padding']), {
        padding: {
          left: '0',
          left_enabled: true
        }
      })
    },
    rowHeadingSettings: vsRowHeadingDefaults,
    columnSettings: [
      vsColumnHeadingDefaults,
      vsColumnItemDefaults
    ],
    mainColor: {
      name: 'secondary',
      alpha: '',
    },
    settings: {
      sm: defaultVsBlockSettings,
      xs: applyEnabled(defaultVsBlockSettings, false, ['padding', 'border', 'borderRadius', 'backgroundImage', 'shadow']),
    },
    rowBorderColor: {
      name: 'gray.200',
      alpha: '',
    },
    rowBorderStyle: 'dashed',
  },
  fields: [
    {
      name: 'vsItems',
      label: 'Row Items',
      component: 'blocks',
      templates: {
        vsRowHeadingBlockTemplate,
        vsRowBlockTemplate,
      },
    },
    {
      name: 'rowSettings',
      label: 'Row Style',
      component: 'tabGroup',
      asPanel: true,
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: vsRowSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: vsRowSettingFields
        })
      ]
    },
    {
      name: 'rowHeadingSettings',
      label: 'Column Headings Style',
      component: 'tabGroup',
      asPanel: true,
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: vsRowHeadingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: vsRowHeadingFields
        })
      ]
    },
    {
      name: 'columnSettings.0',
      label: 'Row Heading Style',
      component: 'tabGroup',
      asPanel: true,
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: vsColumnSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: vsColumnSettingFields
        })
      ]
    },
    {
      name: 'columnSettings.1',
      label: 'Row Items Style',
      component: 'tabGroup',
      asPanel: true,
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: vsColumnSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: vsColumnSettingFields
        })
      ]
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: vsBlockSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: vsBlockSettingFields
        })
      ]
    },
    {
      ...styleFields.backgroundColor,
      description: 'NO LONGER USED',
      name: 'mainColor',
      label: 'Main Color',
    },
    {
      ...styleFields.backgroundColor,
      description: 'NO LONGER USED',
      name: 'rowBorderColor',
      label: 'Row Border Color',
    },
    { name: 'rowBorderStyle', label: 'Row Border Style', component: 'select',
      options: ['solid', 'dashed', 'dotted'],
      description: 'NO LONGER USED',
    },
  ]
}
