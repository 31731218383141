import React from 'react';
import { withStyles } from '../../styling';
import { Box } from '../Box';

const filterProps = [
  'variant',
  'color',
  'large',
  'medium',
  'small',
  'outlined',
  'contained',
  'text',
  'regular',
  'fullWidth',
  'fullHeight',
  'vertical',
];

const ButtonGroup = withStyles((props) => {
  const {
    theme,
    size,
    large,
    small,
    color,
    variant,
    outlined,
    text,
    regular,
    fullWidth = false,
    fullHeight = false,
    vertical = false,
  } = props;

  let buttonSize = size;
  if (!size || !theme.button.sizes[size]) {
    if (large) {
      buttonSize = 'large';
    } else if (small) {
      buttonSize = 'small';
    } else {
      buttonSize = 'medium';
    }
  }

  let buttonVariant = variant;
  if (!variant || !theme.button[variant]) {
    if (outlined) {
      buttonVariant = 'outlined';
    } else if (text) {
      buttonVariant = 'text';
    } else {
      buttonVariant = 'contained';
    }
  }

  let buttonStyle = { backgroundColor: 'transparent' };
  let buttonColor = color;
  if (buttonVariant === 'contained') {
    buttonColor = color || '$primary';
  } else if (buttonVariant === 'outlined') {
    buttonColor = color || '$secondary';
  } else if (buttonVariant === 'text') {
    buttonColor = color || '$text';
  } else if (!color) {
    buttonColor = 'blue';
  }

  if (fullWidth) {
    buttonStyle.width = '100%';
  }

  if (fullHeight) {
    buttonStyle.height = '100%';
  }

  const s = {
    first: { },
    middle: { },
    last: { },
  };
  if (buttonVariant === 'outlined') {
    s.first.props = vertical ? {
      borderBottomRadius: 0,
    } : {
      borderRightRadius: 0,
    };
    s.middle.props = vertical ? {
      marginTop: -1,
      borderTopRadius: 0,
      borderBottomRadius: 0,
    } : {
      marginLeft: -1,
      borderLeftRadius: 0,
      borderRightRadius: 0,
    };
    s.last.props = vertical ? {
      marginTop: -1,
      borderTopRadius: 0,
    } : {
      marginLeft: -1,
      borderLeftRadius: 0,
    };
  } else if (buttonVariant === 'contained') {
    const borderColor = theme.colors.darken(buttonColor, 0.25);
    s.first.props = vertical ? {
      borderBottomWidth: 1,
      borderBottomColor: borderColor,
      borderBottomRadius: 0,
    } : {
      borderRightWidth: 1,
      borderRightColor: borderColor,
      borderRightRadius: 0,
    };
    s.middle.props = vertical ? {
      borderTopRadius: 0,
      ...s.first.props,
    } : {
      borderLeftRadius: 0,
      ...s.first.props,
    };
    s.last.props = vertical ? {
      borderTopRadius: 0,
    } : {
      borderLeftRadius: 0,
    };
  } else if (buttonVariant === 'text') {
    s.first.props = vertical ? {
      borderBottomWidth: 1,
      borderBottomRadius: 0,
      borderBottomColor: buttonColor,
    } : {
      borderRightWidth: 1,
      borderRightRadius: 0,
      borderRightColor: buttonColor,
    };
    s.middle.props = s.first.props;
  }

  return {
    root: {
      ...buttonStyle,
      flexDirection: vertical ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
    },
    ...s,
    buttons: {
      props: {
        size: buttonSize,
        variant: buttonVariant,
        fullWidth,
        fullHeight,
        color,
        regular,
      },
    },
  };
  },
  { name: 'ButtonGroup', filterProps }
)(React.forwardRef(function ButtonGroup(props, ref) {
  const { styles, children, disabled = false, ...rest } = props;
  let lastIndex = children && children.length ? children.length - 1 : 0;
  return (
    <Box ref={ref} {...rest}>
      {React.Children.map(children, (child, i) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        const onlyOne = lastIndex === 0;
        const isFirst = i === 0 && !onlyOne;
        const isLast = i === lastIndex && !onlyOne;
        let groupStyle = null;
        if (isFirst) {
          groupStyle = styles.props.first;
        } else if (isLast) {
          groupStyle = styles.props.last;
        } else if (!onlyOne) {
          groupStyle = styles.props.middle;
        };
        // TODO: what to do if first child or last child is invalid...
        // TODO: display warning in production if a fragment is passed. Doesnt work
        return React.cloneElement(child, {
          ...styles.props.buttons,
          ...groupStyle,
          ...child.props,
          disabled: child.props.disabled || disabled,
        });
      })}
    </Box>
  );
}));

export { ButtonGroup };
