import React, { useState, useEffect, useRef } from 'react';
import { useJsonForm } from "gatsby-tinacms-json"
import equal from 'fast-deep-equal/react';
import { useMemoCompare } from '../ui/hooks';

const stored = {}

function addSub(id, handler) {
  if (!id) return;
  if (!stored[id]) {
    stored[id] = {
      subscribers: [],
      form: null
    }
  }
  stored[id].subscribers.push(handler);
}

function removeSub(id, handler) {
  if (!id) return;
  if (!stored[id]) {
    stored[id] = {
      subscribers: [],
      form: null
    }
    return;
  }
  let index = -1;
  for (let i = 0; i < stored[id].subscribers.length; i += 1) {
    if (stored[id].subscribers[i] === handler) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    stored[id].subscribers.splice(index, 1);
  }
}

function updateSubs(form) {
  if (!form) return;
  if (!stored[form.id]) {
    stored[form.id] = {
      subscribers: [],
      form: form
    }
  }

  stored[form.id].form = form;
  for (let i = 0; i < stored[form.id].subscribers.length; i += 1) {
    stored[form.id].subscribers[i](form);
  }
}

export function useJsonForms(data, template) {
  const id = useRef(null);
  const updateHandler = useRef(null);
  const [formData, nextForm] = useJsonForm(data, template);
  const [formState, setFormState] = useState(null);

  if (!updateHandler.current) {
    updateHandler.current = (newState) => {
      setFormState(newState)
    };
  }




  const form = useMemoCompare(nextForm, equal);

  useEffect(() => {
    const prevId = id.current;
    const nextId = form ? form.id : null;
    if (prevId !== nextId) {
      removeSub(prevId, updateHandler.current);
      if (nextId) {
        addSub(nextId, updateHandler.current);
      }
      id.current = nextId;
    }
    // console.log('stored', stored);
    updateSubs(form);
  }, [form])

  useEffect(() => {
    // addSub(id.current, updateHandler.current)

    return () => {
      removeSub(id.current, updateHandler.current);
    }
  }, [])

  // if (nextForm) {
  //   if (!stored[nextForm.id]) {
  //     stored[nextForm.id] = nextForm;
  //   }
  // }



  return [formData, formState || nextForm];
}
