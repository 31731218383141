import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextInput } from '../TextInput';
import { isNull } from '../../utils';
import { withStyles } from '../../styling';
// TODO: clean this up a bit...
const NumericInput = withStyles(
  { root: {} },
  { name: 'NumericInput', forward: true }
)(
  React.forwardRef(function NumericInput(props, ref) {
    const {
      onChange,
      onChangeText,
      onChangeNumber,
      value = null,
      parse = parseNumberInput, // parse string to convert to valid number (will use parseFloat as default)
      format = formatNumberInput, // format display of number in input as string (1.3 could format to $1.30)
      precision,
      // TODO: implement these later
      min,
      max,
      step,
      ...rest
    } = props;

    const [numString, setNumString] = useState(format(value));
    const lastNum = useRef(value);
    const expectedValue = useRef(value);

    const formatRef = useRef(null);
    formatRef.current = format;

    const handleChange = useCallback((e) => {
        if (e && e.nativeEvent && typeof e.nativeEvent.text === 'string') {
          const { text } = e.nativeEvent;
          const num = parse(text, precision, lastNum.current);
          const val = format(num, precision);
          lastNum.current = num;
          setNumString(val);
          if (!isNaN(num) && (typeof num === 'number' || num === null)) {
            expectedValue.current = num;
            if (onChange) {
              onChange(e, num, val);
            }
            if (onChangeNumber) {
              onChangeNumber(num);
            }
            if (onChangeText) {
              onChangeText(val);
            }
          }
        }
      },
      [onChange, onChangeText, onChangeNumber, format, precision, parse]
    );

    useEffect(() => {
      if (value !== expectedValue.current) {
        expectedValue.current = value;
        const formatted = formatRef.current(value);
        lastNum.current = value;
        setNumString(formatted);
      }
    }, [value]);

    return (
      <TextInput value={numString} ref={ref} onChange={handleChange} {...rest} />
    );
  })
);

const floatRegex = /[^0-9.]/g;
const intRegex = /[^0-9]/g;
const parseNumberInput = (text, precision, lastNum = null) => {
  if (typeof text === 'number' && !isNaN(text)) {
    if (!isNull(precision)) {
      return Number(text.toFixed(precision));
    }
    return text;
  }
  let num = `${text}`.trim();
  if (!num) {
    return null;
  }
  if (precision !== 0) {
    const decMatches = (num.match(/\./g) || []);
    if (decMatches.length > 1) {
      if (lastNum !== null) {
        return lastNum;
      }
      const str = num.split('.');
      str.splice(1, 0, '.');
      num = '';
      for (let i = 0; i < str.length; i += 1) {
        num = `${num}${str[i]}`;
      }
    }
    if (num.endsWith('.') || num.endsWith('0')) {
      return num;
    }
  } else if (typeof num === 'string') {
    num = num.replace(intRegex, '');
  }
  num = parseFloat(num);
  if (!isNull(precision) && typeof num === 'number' && !isNaN(num)) {
    num = Number(num.toFixed(precision));
  }
  return num;
};

const formatNumberInput = (value, precision) => {
  if (isNull(value)) return '';
  let val = `${value}`.trim();
  if (val === '.') {
    return val;
  }
  const num = Number(value);
  if (isNaN(num)) {
    return '';
  }
  if (val.endsWith('.0')) {
    const fixedNum = `${num}.0`;
    const retest = Number(fixedNum);
    if (!isNaN(retest)) {
      return fixedNum;
    }
  }
  if (precision !== 0 && val.endsWith('.')) {
    const decMatches = (val.match(/\./g) || []);
    if (decMatches.length > 1) {
      const str = val.split('.');
      str.splice(1, 0, '.');
      val = '';
      for (let i = 0; i < str.length; i += 1) {
        val = `${val}${str[i]}`;
      }
    }
    return val;
  }
  if (precision !== 0) {
    const decMatches = (val.match(/\./g) || []);
    if (decMatches.length > 1) {
      const str = val.split('.');
      str.splice(1, 0, '.');
      val = '';
      for (let i = 0; i < str.length; i += 1) {
        val = `${val}${str[i]}`;
      }
    }
  }
  val = val.replace(precision === 0 ? intRegex : floatRegex, '');
  if (precision) {
    const decimalIndex = val.indexOf('.');
    if (decimalIndex !== -1) {
      val = val.substring(0, decimalIndex + precision + 1);
    }
  }
  return val;
};

NumericInput.parse = parseNumberInput;
NumericInput.format = formatNumberInput;

export { NumericInput };
