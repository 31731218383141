import { useState, useRef } from 'react';
import { useOnViewportChange } from './useOnViewportChange';
import { createBreakpoints } from '../system';

export function useBreakpoints(breakpoints) {
  const breakpointsRef = useRef(null);
  const last = useRef(null);
  if (!breakpointsRef.current) {
    breakpointsRef.current = createBreakpoints(breakpoints);
  }
  const [breakpoint, setBreakpoint] = useState(() => ({ value: 0, key: 'xs', index: 0 }));

  useOnViewportChange(viewport => {
    // console.log(viewport, breakpointsRef);
    if (!viewport.width || !breakpointsRef.current.keys) return 0;
    // if (breakpointsRef.current.cache[viewport.width]) {
    //   const cachedBp = breakpointsRef.current.cache[viewport.width];
    //   if (!last.current || last.current.key !== cachedBp.key) {
    //     setBreakpoint({ ...cachedBp });
    //   }
    //   return;
    // }
    const numBreakpoints = breakpointsRef.current.keys.length;
    for (let i = 1; i <= numBreakpoints; i += 1) {
      const index = numBreakpoints - i;
      const key = breakpointsRef.current.keys[index]; // xs, md, lg , etc.
      const value = breakpointsRef.current.values[key]; // 1100, 960, etc.
      if (viewport.width >= value) {
        // if (!breakpointsRef.current.cache[viewport.width]) {
        //   breakpointsRef.current.cache[viewport.width] = {key, value, index};
        // }
        if (!last.current || last.current.key !== key || last.current.value !== value || last.current.index !== index) {
          last.current = { key, value, index };
          setBreakpoint({key, value, index});
          return;
        }
        return;
      }
    }
    const index = 0;
    const key = breakpointsRef.current.keys[index]; // xs, md, lg , etc.
    const value = breakpointsRef.current.values[key]; // 1100, 960, etc.
    // if (!breakpointsRef.current.cache[viewport.width]) {
    //   breakpointsRef.current.cache[viewport.width] = {key, value, index};
    // }
    if (!last.current || last.current.key !== key || last.current.value !== value || last.current.index !== index) {
      last.current = { key, value, index };
      setBreakpoint({key, value, index});
    }
  })
  return [breakpoint, setBreakpoint, breakpointsRef.current];
}
