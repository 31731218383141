import { useEffect, useCallback } from 'react'

const listeners = new Set()

function onStartShouldSetResponder(evt) {
  evt.persist();
  listeners.forEach((listener) => listener(evt))
}

export function useTouchAwayViewProps() {
  const props = { onStartShouldSetResponder };
  return props;
}

export function useTouchAwayHandler(handler, ref) {
  // Listen for updates
  const handleTouch = useCallback((evt) => {
    if (ref && ref.current) {
      if (ref && ref.current.getNode) {
        // console.log(ref.current);
        const node = ref.current.getNode();
        // console.log(node)
        // // console.log(node._children);
        // console.log(evt, evt.target);
        // console.log(evt.target === node);
        handler(evt, node);
        return;
      }
    }
    handler(evt);
  }, [handler, ref]);

  useEffect(() => {
    listeners.add(handleTouch)

    return () => {
      listeners.delete(handleTouch)
    }
  }, [handleTouch])
}
