
import * as React from 'react'
import styled, { css } from 'styled-components'
import { Box, BoxDiv } from '../ui';

import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
  ReorderIcon,
} from '@tinacms/icons'
import { useCMS } from 'tinacms'
import { Draggable } from 'react-beautiful-dnd'

import {
  useInlineBlocks,
  useInlineForm,
  InlineFieldContext,
  BlocksEmptyState
} from 'react-tinacms-inline';

import { AddBlockMenu } from './AddBlockMenu';

import { InlineSettings } from './InlineSettings';
import { useCopyBlockData } from '../editing/useCopyBlockData';
import { CopyIcon } from '../editing/icons/CopyIcon';
import { get } from '../ui/utils';


export const BlocksControls = ({
  children,
  index,
  insetControls,
  focusRing = {},
  renderAs: Component,
  canCopy = true,
  ...rest
}) => {
  const cms = useCMS()
  const { focussedField, setFocussedField, parentName: pName } = useInlineForm()
  const { name, template } = React.useContext(InlineFieldContext)
  const fullName = pName ? `${pName}${name ? `.${name}` : ''}` : name;

  const {
    insert,
    move,
    remove,
    blocks,
    count,
    direction,
    min,
    max,
    // canPaste,
    // setCopyBlockData,
    // copyBlockData
  } = useInlineBlocks()
  const isFirst = index === 0
  const isLast = index === count - 1
  const blockMenuRef = React.useRef(null)
  const blockMoveUpRef = React.useRef(null)
  const blockMoveDownRef = React.useRef(null)

  const addBeforePosition = direction === 'horizontal' ? 'left' : 'top'
  const addAfterPosition = direction === 'horizontal' ? 'right' : 'bottom'
  if (cms.disabled) {
    return (
      <Component component={BoxDiv} {...rest}>
        {children}
      </Component>
    );
  }

  const removeBlock = (event) => {
    event.stopPropagation()
    event.preventDefault()
    /* eslint-disable-next-line */
    if (confirm(`Are you sure you want to delete this block?`)) {
      remove(index)
    }
  }

  const moveBlockUp = (event) => {
    move(index, index - 1)
    event.stopPropagation()
    event.preventDefault()
  }

  const moveBlockDown = (event) => {
    move(index, index + 1)
    event.stopPropagation()
    event.preventDefault()
  }

  const isActive = fullName === focussedField

  const childIsActive = focussedField.startsWith(fullName)

  const handleSetActiveBlock = (event) => {
    if (
      blockMenuRef.current?.contains(event.target) ||
      blockMoveUpRef.current?.contains(event.target) ||
      blockMoveDownRef.current?.contains(event.target)
    ) {
      return
    }
    event.stopPropagation()
    event.preventDefault()
    setFocussedField(fullName)
  }

  let offset = typeof focusRing === 'object' ? focusRing.offset : undefined
  let inset = typeof insetControls === 'object' ? insetControls : undefined;

  const parentName = fullName
    .split('.')
    .slice(0, -1)
    .join('.')

  function withinLimit(limit) {
    if (!limit) return true

    return !(limit === count || (max === count && min === count))
  }

  let addBeforeOffset = offset;
  let addAfterOffset = offset;
  if (inset && (typeof offset === 'number' || typeof offset === 'object')) {
    offset = typeof offset === 'number' ? { x: offset, y: offset } : offset;
    inset = { left: inset.left || 0, right: inset.right || 0, top: inset.top || 0, bottom: inset.bottom || 0 };
    if (direction === 'horizontal') {
      addBeforeOffset = {
        x: inset.left + offset.x,
        y: offset.y,
      }
      addAfterOffset = {
        x: inset.right + offset.x,
        y: offset.y,
      }
    } else {
      addBeforeOffset = {
        x: offset.x,
        y: inset.top + offset.y,
      }
      addAfterOffset = {
        x: offset.x,
        y: inset.bottom + offset.y
      }
    }
  }

  const zindexStyle = focusRing && (isActive || childIsActive) ? { zIndex: 99999 } : null;

  return (
    <Draggable type={parentName} draggableId={fullName} index={index}>
      {provider => {
        return (
          <FocusRing
            as={Component}
            component={BoxDiv}
            disableAnimate
            innerRef={provider.innerRef}
            onClick={handleSetActiveBlock}
            $active={focusRing && isActive}
            $offset={offset}
            $borderRadius={
              typeof focusRing === 'object' ? focusRing.borderRadius : undefined
            }
            $disableHover={focusRing === false ? true : childIsActive}
            $disableChildren={!isActive && !childIsActive}
            {...zindexStyle}
            {...provider.draggableProps}
            {...rest}
          >
            {withinLimit(max) && isActive ? (
              <AddBlockMenuWrapper active={isActive}>
                <AddBlockMenu
                  addBlock={block => insert(index, block)}
                  blocks={blocks}
                  index={index}
                  offset={addBeforeOffset}
                  position={addBeforePosition}
                />
                <AddBlockMenu
                  addBlock={block => insert(index + 1, block)}
                  blocks={blocks}
                  index={index}
                  offset={addAfterOffset}
                  position={addAfterPosition}
                />
              </AddBlockMenuWrapper>
            ) : null}
            <BlockMenuWrapper
              offset={offset}
              ref={blockMenuRef}
              index={index}
              active={isActive}
              inset={inset || insetControls}
            >
              <BlockMenu>
                <BlockAction
                  ref={blockMoveUpRef}
                  onClick={moveBlockUp}
                  disabled={isFirst}
                >
                  {direction === 'vertical' && <ChevronUpIcon />}
                  {direction === 'horizontal' && <ChevronLeftIcon />}
                </BlockAction>
                <BlockAction
                  ref={blockMoveDownRef}
                  onClick={moveBlockDown}
                  disabled={isLast}
                >
                  {direction === 'vertical' && <ChevronDownIcon />}
                  {direction === 'horizontal' && <ChevronRightIcon />}
                </BlockAction>
                <BlockAction hide {...provider.dragHandleProps}>
                  {direction === 'vertical' && <ReorderIcon />}
                  {direction === 'horizontal' && <ReorderRowIcon />}
                </BlockAction>
                { canCopy && <CopyButton /> }
                <InlineSettings fields={template.fields} />
                {withinLimit(min) && (
                  <BlockAction onClick={removeBlock}>
                    <TrashIcon />
                  </BlockAction>
                )}
              </BlockMenu>
            </BlockMenuWrapper>
            {children}
          </FocusRing>
        )
      }}
    </Draggable>
  )
}

BlocksControls.defaultProps = {
  renderAs: Box
}

const CopyButton = () => {
  const { form } = useInlineForm();
  const { name } = React.useContext(InlineFieldContext)
  const data = get(form.values, name);
  const { setData } = useCopyBlockData();
  const copyItem = React.useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    if (data && typeof data === 'object' && data._template) {
      setData(data);
    }
  }, [setData, data])
  if (!data) {
    return null;
  }
  return (
    <BlockAction onClick={copyItem}>
      <CopyIcon/>
    </BlockAction>
  )
}

export const ReorderRowIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7605 15.3012L21.5199 11.0607L22.5806 10L26.8211 14.2406C27.5018 14.9213 27.5174 16.0233 26.8211 16.7196L22.5806 20.9602L21.5199 19.8995L25.7605 15.6589C25.8633 15.5561 25.8631 15.4039 25.7605 15.3012Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12679 15.6988L10.3674 19.9393L9.3067 21L5.06613 16.7594C4.38543 16.0787 4.36985 14.9767 5.06613 14.2804L9.3067 10.0398L10.3674 11.1005L6.12679 15.3411C6.02398 15.4439 6.02416 15.5961 6.12679 15.6988Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.88727 14.6569L14.201 14.6569L14.201 16.1569L4.88727 16.1569L4.88727 14.6569Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6863 14.6569L27 14.6569L27 16.1569L17.6863 16.1569L17.6863 14.6569Z"
    />
  </svg>
)


export const AddBlockMenuWrapper = styled.div(
  p => css`
    opacity: 0;
    transition: all 120ms ease-out;
    pointer-events: none;

    ${p.active &&
      css`
        opacity: 1;
        pointer-events: all;
        z-index: 999999999;
      `}
  `
)


export const BlockMenuWrapper = styled.div(p => {
  const offset = getOffset(p.offset)
  return css`
    position: absolute;
    font-family: 'Inter', sans-serif;
    top: calc(-${getOffsetY(offset)}px - 16px);
    right: calc(-${getOffsetX(offset)}px - 1px);
    opacity: 0;
    transition: all 120ms ease-out;
    z-index: calc(var(--tina-z-index-1) - ${p.index ? p.index : 0});
    pointer-events: none;
    transform: translate3d(0, -100%, 0);

    ${p.inset &&
      css`
        top: calc(14px - ${getOffsetY(offset, p.inset && p.inset.top ? p.inset.top : 0)}px);
        right: calc(14px - ${getOffsetX(offset, p.inset && p.inset.right ? p.inset.right : 0)}px);
        transform: translate3d(0, 0, 0);
      `}

    ${p.active &&
      css`
        opacity: 1;
        pointer-events: all;
      `}
  `
})

export const BlockMenu = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  top: 0;
  background-color: white;
  border-radius: var(--tina-radius-small);
  box-shadow: var(--tina-shadow-big);
  border: 1px solid var(--tina-color-grey-2);
  overflow: hidden;
`

export const BlockAction = styled.div`
  background-color: ${p =>
    p.active ? 'rgba(53, 50, 50, 0.05)' : 'transparent'};
  color: ${p =>
    p.active ? 'var(--tina-color-primary)' : 'var(--tina-color-grey-8)'};
  fill: ${p =>
    p.active ? 'var(--tina-color-primary)' : 'var(--tina-color-grey-8)'};
  outline: none;
  border: none;
  padding: 4px 6px;
  transition: all 85ms ease-out;
  display: ${p => p.hide ? 'none !important' : 'inherit'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    background-color: rgba(53, 50, 50, 0.09);
  }
  &:active {
    color: var(--tina-color-primary);
    fill: var(--tina-color-primary);
    background-color: rgba(53, 50, 50, 0.05);
  }
  &:not(:last-child) {
    border-right: 1px solid var(--tina-color-grey-2);
  }
  svg {
    width: 26px;
    height: auto;
  }

  ${props =>
    props.active &&
    css`
      color: var(--tina-color-primary);
      fill: var(--tina-color-primary);
      background-color: rgba(53, 50, 50, 0.05);
    `};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      color: #d1d1d1;
      fill: #d1d1d1;
    `};
`

export const FocusRing = styled(Box)(props => {
  const offset = getOffset(props.$offset)

  return css`

    ${!props.$disableHover &&
      css`
        &:hover {
          &:after {
            opacity: 0.3;
          }
        }
      `}

    ${props.$disableChildren &&
      css`
        > * {
          pointer-events: none;
        }

        ${BlocksEmptyState} {
          opacity: 0;
          pointer-events: none;
        }
      `}

    &:after {
      content: '';
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: calc(-1 * ${getOffsetX(offset)}px);
      top: calc(-1 * ${getOffsetY(offset)}px);
      width: calc(100% + ${getOffsetX(offset) * 2}px);
      height: calc(100% + ${getOffsetY(offset) * 2}px);
      border: 1px solid var(--tina-color-primary);
      border-radius: ${props.$borderRadius !== undefined ? props.$borderRadius : `10`}px;
      opacity: 0;
      pointer-events: none;
      transition: all var(--tina-timing-medium) ease-out;
      box-shadow: var(--tina-shadow-big);
    }

    ${props.$active &&
      css`
        &:hover:after,
        &:after {
          opacity: 1;
        }
      `};
  `
})

const DEFAULT_OFFSET = 16

export function getOffset(offset) {
  let result = DEFAULT_OFFSET
  const axis = { x: DEFAULT_OFFSET, y: DEFAULT_OFFSET }

  if (typeof offset === 'number') {
    result = offset
  } else if (typeof offset === 'object') {
    axis.x = offset.x
    axis.y = offset.y
    result = axis
  }

  return result
}

export const getOffsetX = (offset, mod = 0) => {
  let x = typeof offset === 'object' ? offset.x + mod : offset + mod
  return x;
}


export const getOffsetY = (offset, mod = 0) => {
  let y = typeof offset === 'object' ? offset.y + mod : offset + mod;
  return y;
}
