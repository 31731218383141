import { useMemo } from 'react';
import equal from 'fast-deep-equal/react';
import cloneDeep from 'lodash.clonedeep';
import { mergeAndConcat } from 'merge-anything';
import { useMatchBreakpoint, useMemoCompare, useTheme } from '../ui/hooks';
import { removeEmptySettings, getAlignContentStyles, getAlignSelfStyles, resolveColorData } from './fieldConfigs';

export function getUnitValue(value) {
  if (typeof value === 'object') {
    if (!value.unit || value.value === '' || value.value === undefined || value.value === null) return '';
    return `${value.value}${value.unit !== 'px' ? value.unit : ''}`;
  }
  return value;
}

export function useMatchSettings(settingsObj) {
  const settings = useMemoCompare(settingsObj, equal);

  const enabledSettings = useMemo(() => {
    if (!settings) return null;
    const xs = removeEmptySettings(settings.xs);
    const sm = removeEmptySettings(settings.sm, false);
    return {
      xs: mergeAndConcat(sm, xs),
      sm: mergeAndConcat(xs, sm),
    }
  }, [settings]);

  const match = useMatchBreakpoint(enabledSettings, { ignoreEmpty: true });

  return match;
}

// export function useEditStyleSettings(obj, { direction: parentDir } = { }) {
//   const
// }

export function useBreakpointLayouts(obj, { direction: parentDir } = { }) {
  const parentDirection = parentDir || 'column';
  const theme = useTheme();

  const settings = useMemoCompare(obj, equal);
  const layouts = useMemo(() => {
    if (!settings) return null;
    const xs = removeEmptySettings(settings.xs);
    const sm = removeEmptySettings(settings.sm, false);
    return {
      xs: mergeAndConcat(sm, xs),
      sm: mergeAndConcat(xs, sm),
    }
  }, [settings]);

  const match = useMatchBreakpoint(layouts, { ignoreEmpty: true });

  const result = useMemo(() => {
    if (!match) return null;
    const result = resolveColorData(match, theme);
    const direction = result.direction || 'column';
    result.direction = direction;
    result.sizeStyle = { };
    result.shadowStyle = null;

    if (result.width) {
      if (typeof result.width === 'object' && result.width.unit) {
        if (result.width.unit === '%') {
          if (parentDirection === 'row') {
            result.sizeStyle.flex = result.width.value / 100;
          } else {
            result.sizeStyle.width = `${result.width.value}%`;
          }
        } else if (result.width.unit === 'px') {
          result.sizeStyle.width = result.width.value;
          result.sizeStyle.minWidth = result.width.value;
        }
      }
    }
    if (result.height) result.sizeStyle.height = getUnitValue(result.height);
    if (result.maxWidth) result.sizeStyle.maxWidth = getUnitValue(result.maxWidth);
    if (result.maxHeight) result.sizeStyle.maxHeight = getUnitValue(result.maxHeight);
    result.alignSelfStyle = getAlignSelfStyles(result.alignSelf);

    result.overflowStyle = result.clipContent === true ? { overflow: 'hidden' }
                            : (result.clipContent === false ? { overflow: 'visible' } : null);

    const alignContentStyle = getAlignContentStyles(result.alignContent, direction);
    if (alignContentStyle && result.justifyContent) {
      alignContentStyle.justifyContent = result.justifyContent;
    }
    result.layoutStyle = { ...alignContentStyle };
    if (result.padding) {
      result.layoutStyle.padding = {
        top: theme.spacing(result.padding.top || 0),
        bottom: theme.spacing(result.padding.bottom || 0),
        left: theme.spacing(result.padding.left || 0),
        right: theme.spacing(result.padding.right || 0),
      }
      result.padding = result.layoutStyle.padding;
    }
    if (result.contentSpacing) result.layoutStyle.gap = theme.spacing(result.contentSpacing);

    if (result.backgroundColor) result.backgroundColor = `$${result.backgroundColor}`
    if (result.textColor) result.textColor = `$${result.textColor}`;

    if (result.backgroundImage) {
      const { width, height, ...rest } = result.backgroundImage;
      result.backgroundImage = rest;
      if (width && width.value) result.backgroundImage.width = getUnitValue(width);
      if (height && width.value) result.backgroundImage.height = getUnitValue(height);
    }

    if (result.border) {
      let { width, color, style, top, bottom, left, right } = result.border;
      result.border = { };
      if (width) {
        color = color ? `$${color}` : color;
        if (top) result.border.top = { width, color, style };
        if (bottom) result.border.bottom = { width, color, style };
        if (left) result.border.left = { width, color, style };
        if (right) result.border.right = { width, color, style };
      }
    }

    if (result.borderRadius) {
      let { value: val, topLeft, topRight, bottomLeft, bottomRight } = result.borderRadius;
      result.borderRadius = { };
      const value = val ? val * 1 : 0;
      if (value) {
        if (topLeft) result.borderRadius.topLeft = value;
        if (topRight) result.borderRadius.topRight = value;
        if (bottomLeft) result.borderRadius.bottomLeft = value;
        if (bottomRight) result.borderRadius.bottomRight = value;
      }
    }

    if (result.shadow) {
      result.shadow.elevation = result.shadow.elevation ? result.shadow.elevation * 1 : 0;
      if (result.shadow.color) result.shadow.color = `$${result.shadow.color}`
      if (result.shadow.opacity) result.shadow.opacity = result.shadow.opacity / 100;
      result.shadowStyle = theme.shadows.elevation(result.shadow.elevation, { theme, ...result.shadow });
    }

    if (result.opacity) {
      result.opacity = result.opacity / 100;
    }

    return result;
  }, [match, theme, parentDirection])

  // console.log(result);


  return useMemoCompare(result, equal);
}
