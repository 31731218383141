import React from 'react';
import { InlineTextarea } from '../InlineTextarea';
import { TextLink } from '../../ui';
import { BlocksControls } from '../BlocksControls';
import {
  textSettingFields,
  defaultTextBlockSettings,
  useTextBlockSettings,
} from '../TextBlock';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { applyEnabledToggles, styleFields, applyEnabled } from '../fieldConfigs';
import { memo } from '../../ui/utils';
import { navigate } from '@reach/router';
import { useCMS } from 'tinacms';
import { useLocaleLinkInDevelopment } from '../../editing/useEditingLocale';

// TODO: use <Link> instead and hoverStyle is no longer hoverStyle. it's styles.hovered...
export const TextLinkBlock = memo(({
  index,
  data,
  containerBlock = { },
  inlined,
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const textProps = useTextBlockSettings(settings, containerBlock);

  if (settings.hoverStyle) {
    textProps.styles = {
      hovered: {
        props: {
          color: settings.hoverStyle.color ? `$${settings.hoverStyle.color}` : null,
          underline: settings.hoverStyle.underlined,
        }
      }
    }
  }

  const cms = useCMS();

  textProps.href = useLocaleLinkInDevelopment((!cms.enabled || process.env.NODE_ENV === 'production') ? data.href : null);

  const linkHandler = React.useCallback(href => {
    if ((!cms.enabled || process.env.NODE_ENV === 'production') && href) {
      if (href.startsWith('#')) {
        const element = document.getElementById(href.substring(1));
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        } else {
          navigate(`/${href}`);
        }
      } else if (typeof href === 'string' && href.endsWith('.pdf') && window !== undefined) {
        window.open(href, '_blank');
      } else {
        navigate(href)
      }
    } else {
      console.log('clicked text link!');
    }
  }, [cms]);

  textProps.handler = linkHandler;
  if (!inlined) {
    return <TextLink {...textProps}>{data.text}</TextLink>
  }

  return (
    <TextLink {...textProps}>
      <BlocksControls index={index}>
        <InlineTextarea uppercase={textProps.uppercase || false} name="text" focusRing={false} />
      </BlocksControls>
    </TextLink>
  )
})

TextLinkBlock.defaultProps = { inlined: true };

export const defaultTextLinkSettings = {
  hoverStyle: {
    color: {
      name: 'secondary',
      alpha: '',
    },
    underlined: true,
  },
  ...defaultTextBlockSettings,
  textSpaceAfter: false,
}

export const textLinkSettings = [
  {
    name: 'hoverStyle',
    label: 'Hover Style',
    component: 'group-dropdown',
    relatedSubfields: true,
    fields: [
      {
        ...styleFields.textColor,
        name: 'color',
        label: 'Color'
      },
      {
        name: 'underlined',
        label: 'Underline',
        component: 'toggle'
      },
    ]
  },
  ...textSettingFields
]

export const textLinkBlockTemplate = {
  type: 'textLinkBlockTemplate',
  name: 'text-link-block-template',
  key: 'text-link-block',
  label: 'Text Link',
  component: 'grouped',
  defaultItem: {
    text: 'Text link',
    href: '',
    settings: {
      sm: defaultTextLinkSettings,
      xs: applyEnabled(defaultTextLinkSettings, false, ['padding'])
    },
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'textarea'
    },
    {
      name: 'href',
      label: 'Link / URL',
      component: 'text',
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: textLinkSettings,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: textLinkSettings
        })
      ]
    }
  ]
}
