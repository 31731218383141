import React, { useRef, useState, useMemo, useCallback } from 'react';
import {
  TextInput,
  UnderlinedInput,
  FilledInput,
  OutlinedInput,
} from '../TextInput';
import { Text } from '../Text';
import { Menu } from '../Menu';
import { useForkRef } from '../../hooks';
import { useLayout } from '../../hooks';
import { withStyles } from '../../styling';

const DropdownArrow = () => null;

const variantInputComponents = {
  filled: FilledInput,
  underlined: UnderlinedInput,
  outlined: OutlinedInput,
};

const Select = withStyles({ textInput: {}, textBox: {}, input: {}, menu: {} }, { name: 'Select' })(
  React.forwardRef(function Select(props, ref) {
    const {
      // Select props
      autoWidth = false,
      IconComponent = DropdownArrow,
      children, // MenuItems
      multiple,
      renderValue,
      // Menu props
      onOpen,
      open: openProp,
      MenuProps,
      onClose,
      // shared input props,
      onChange, // prevent passing
      onChangeText, // prevent passing
      onChangeValue,
      value,
      placeholder,
      InputComponent = Text,
      TextInputComponent,
      variant = 'filled',
      onLayout: onLayoutProp,
      textBoxRef,
      inputRef: inputRefProp,
      disabled,
      accessibility,
      styles,
      style,
      ...rest
    } = props;
    const inputRef = useRef(null);
    const handleRef = useForkRef(inputRef, textBoxRef);

    const { current: isOpenControlled } = React.useRef(openProp != null);
    const [openState, setOpenState] = useState(false);


    const updateMenuState = (open, event) => {
      if (open) {
        if (onOpen) {
          onOpen(event);
        }
      } else if (onClose) {
        onClose(event);
      }

      if (!isOpenControlled) {
        setOpenState(open);
      }
    }

    const handleClose = event => {
      updateMenuState(false, event);
    }

    const handleItemPress = (child) => (event) => {
      let next;

      if (multiple) {
        next = Array.isArray(value) ? value.slice() : [];
        const itemIndex = value.indexOf(child.props.value);
        if (itemIndex === -1) {
          next.push(child.props.value);
        } else {
          next.splice(itemIndex, 1);
        }
      } else {
        next = child.props.value;
      }

      if (child.props.onPress) {
        child.props.onPress(event);
      }

      if (value !== next) {
        if (onChangeValue) {
          onChangeValue(next);
        } else if (onChangeText) {
          onChangeText(next); // TODO: fix this...
        }
      }

      if (!multiple) {
        updateMenuState(false, event);
      }
    }

    const childrenArray = React.Children.toArray(children);

    let valueText = renderValue ? (renderValue(value) || '') : '';
    const selectedValues = [];

    const items = childrenArray.map((child) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      let selected;

      if (multiple) {
        if (!Array.isArray(value)) {
          console.warn('Select with multiple prop true expects value prop to be an array');
          return null;
        }

        selected = value.some((v) => areEqualValues(v, child.props.value));
        if (selected && !renderValue) {
          selectedValues.push(child.props.children)
        }

      } else {
        selected = areEqualValues(value, child.props.value);
        if (selected && !renderValue) {
          selectedValues.push(child.props.children);
        }
      }

      const noStringChild = typeof child.props.children === 'string' && isEmpty(child.props.children);
      return React.cloneElement(child, {
        onPress: handleItemPress(child),
        selected: !child.props.value ? false : selected,
        value: undefined,
        children: noStringChild ? (placeholder ? placeholder : '----') : child.props.children,
        TextProps: noStringChild ? (placeholder ? { dim: true } : { color: 'transparent' }) : null,
      });
    })

    if (!renderValue) {
      valueText = multiple ? selectedValues.join(', ') :
        (selectedValues.length ? selectedValues[0] : '');
    }

    const textInputProps = { };
    if (InputComponent === Text) {
      if (isEmpty(valueText)) {
        if (placeholder) {
          textInputProps.value = placeholder;
          textInputProps.dim = true;
        } else {
          textInputProps.value = '--';
          textInputProps.color = 'transparent';
        }
      } else {
        textInputProps.value = valueText;
      }
    } else {
      textInputProps.value = valueText;
      textInputProps.placeholder = placeholder;
    }
    if (isEmpty(valueText)) {
      valueText = '';
    }

    const open = isOpenControlled ? openProp : openState;
    const { onLayout, width: inputWidth } = useLayout();
    const handleOnLayout = useCallback((e) => {
      onLayout(e);
      if (onLayoutProp) {
        onLayoutProp(e);
      }
    }, [onLayout, onLayoutProp])
    const menuListStyleProps = useMemo(() => {
      if (!autoWidth && inputWidth && open) {
        return { width: inputWidth, maxWidth: inputWidth };
      }
      return null;
    }, [inputWidth, autoWidth, open])

    const handleSelectInputPress = (event) => {
      if (disabled) return;
      updateMenuState(true, event);
    }

    const Component = TextInputComponent || variantInputComponents[variant];

    return (
      <>
        <Component
          textBoxRef={handleRef}
          inputRef={inputRefProp}
          ref={ref}
          InputComponent={InputComponent}
          ignoreValue={false}
          {...textInputProps}
          onLayout={handleOnLayout}
          disabled={disabled}
          onPress={handleSelectInputPress}
          accessibility={{
            accessibilityRole: 'button',
            ...accessibility,
          }}
          styles={styles}
          {...rest}
        >
          { IconComponent ? <IconComponent /> : null }
        </Component>
        <Menu
          open={open}
          anchorNode={inputRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorOffset={{ vertical: -4, horizontal: 0 }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          {...MenuProps}
          style={styles.menu}
          {...styles.props.menu}
          MenuListProps={{
            ...menuListStyleProps,
            ...(MenuProps && MenuProps.MenuListProps ? MenuProps.MenuListProps : null)
          }}
        >
          {items}
        </Menu>
      </>
    )
  })
);

function areEqualValues(a, b) {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  return String(a) === String(b);
}

function isEmpty(display) {
  return display == null || (typeof display === 'string' && !display.trim());
}

export { Select }