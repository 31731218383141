import React from 'react';
import styled, { css } from 'styled-components';
import { useCMS } from 'tinacms';
import { Box } from '../../ui';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';
import { applyEnabled, applyEnabledToggles, settingsDefaultItems, layoutFields, styleFields } from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';

export const VideoEmbedBlock = memo(({
  index,
  data,
  containerBlock = { },
  inlined,
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const cms = useCMS();
  const {
    backgroundColor,
    border,
    borderRadius,
    opacity,
    overflowStyle,
    sizeStyle,
    layoutStyle,
  } = settings;
  if (layoutStyle && layoutStyle.padding) {
    layoutStyle.margin = layoutStyle.padding;
    delete layoutStyle.padding;
  }

  const blockStyles = {
    ...layoutStyle,
    ...sizeStyle,
    ...overflowStyle,
  };

  if (backgroundColor) {
    blockStyles.backgroundColor = backgroundColor;
  }
  if (border) {
    blockStyles.border = border;
  }
  if (borderRadius) {
    blockStyles.borderRadius = borderRadius;
  }
  if (opacity) {
    blockStyles.opacity = opacity;
  }
  const vidProps = {
    src: data.embedSrc,
    title: data.title ? data.title : '',
    frameBorder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: data.allowfullscreen === false ? false : true,
  };

  return (
    <BlocksControls focusRing={{ offset: 0 }} insetControls {...blockStyles} index={index} {...rest}>
      <StyledWrapper inlined={cms.enabled}>
        <iframe {...vidProps} />
      </StyledWrapper>
    </BlocksControls>
  )

})

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.inlined &&
    css`
      pointer-events: none;
    `
  }
`


const defaultSettings = {
  width: {
    value: '50',
    unit: '%',
  },
  height: '315',
  maxWidth: {
    value: '',
    unit: '%',
  },
  maxHeight: '',
  padding: settingsDefaultItems.padding,
  alignSelf: settingsDefaultItems.alignSelf,
  backgroundColor: settingsDefaultItems.backgroundColor,
  opacity: settingsDefaultItems.opacity,
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  clipContent: settingsDefaultItems.clipContent,
}

const settingsFields = [
  layoutFields.unitBased.width,
  layoutFields.height,
  layoutFields.unitBased.maxWidth,
  layoutFields.maxHeight,
  layoutFields.padding,
  layoutFields.alignSelf,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.clipContent,
]

export const videoEmbedBlockTemplate = {
  type: 'videoEmbedBlockTemplate',
  name: 'video-embed-block-template',
  key: 'video-embed-block',
  label: 'Video Embed Block',
  component: 'grouped',
  defaultItem: {
    embedSrc: '',
    title: '',
    allowfullscreen: true,
    settings: {
      sm: defaultSettings,
      xs: applyEnabled(defaultSettings, false, ['borderRadius', 'border', 'padding']),
    }
  },
  fields: [
    {
      name: 'embedSrc',
      label: 'Embed URL',
      component: 'text',
    },
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'allowfullscreen',
      label: 'Allow Fullscreen',
      component: 'toggle',
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: settingsFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: settingsFields,
        })
      ]
    }
  ]
}