import React, { useMemo } from 'react';
import { Text } from '../../ui';
import { useTheme } from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';
import { InlineTextarea } from '../InlineTextarea';
import { applyEnabledToggles, layoutFields, styleFields, settingsDefaultItems, applyEnabled } from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';



export function useTextBlockSettings(settings, containerBlock = { }, ignoreSizeStyle = false) {
  const {
    textSize,
    textAlign,
    textColor,
    textSpaceAfter,
    maxLines,
    bolded,
    underlined,
    italic,
    uppercased,
    sizeStyle,
    padding,
    alignSelfStyle,
    opacity,
  } = settings;
  // console.log(containerBlock);
  const contentAlign = containerBlock.alignContent;
  const onColor = containerBlock.backgroundColor;
  const parentTextColor = containerBlock.textColor;
  const theme = useTheme();

  const align = useMemo(() => {
    if (textAlign) return textAlign;
    if (contentAlign) {
      if (contentAlign.endsWith('left')) {
        return 'left';
      }
      if (contentAlign.endsWith('center')) {
        return 'center';
      }
      if (contentAlign.endsWith('right')) {
        return 'right';
      }
    }
    return 'left';
  }, [textAlign, contentAlign])

  const textProps = {
    size: textSize,
    align: align,
    color: textColor ? textColor : (parentTextColor ? parentTextColor : null),
    onColor: !textColor && onColor ? `${onColor}` : null,
    weight: bolded ? theme.fontWeights.bold : theme.fontWeights.regular,
    spaceAfter: textSpaceAfter ? true : 0,
    maxLines: maxLines || null,
    underline: underlined,
    uppercase: uppercased,
    padding: padding,
    italic,
    opacity: opacity,
    ...(sizeStyle && !ignoreSizeStyle ? sizeStyle : null),
    // alignSelf: 'stretch',
    ...alignSelfStyle
  }

  const direction = containerBlock.direction || 'row';

  if (direction.startsWith('column')) {
    // textProps.width = '100%';
  }
  return textProps;
}

export const TextBlock = memo(({
  index,
  data,
  containerBlock = { },
  inlined,
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const textProps = useTextBlockSettings(settings, containerBlock);
  if (!inlined) {
    return <Text {...textProps}>{data.text}</Text>
  }
  return (
    <Text {...textProps}>
      <BlocksControls index={index}>
        <InlineTextarea name="text" focusRing={false} />
      </BlocksControls>
    </Text>
  )
})

TextBlock.defaultProps = { inlined: true };

export const textSettingFields = [
  {
    name: 'textSize',
    label: 'Size',
    component: 'select',
    options: [
      {
        label: 'Default',
        value: 'default'
      },
      {
        label: 'xxLarge',
        value: 'xxLarge'
      },
      {
        label: 'xLarge',
        value: 'xLarge'
      },
      {
        label: 'Large',
        value: 'large'
      },
      {
        label: 'Medium',
        value: 'medium'
      },
      {
        label: 'Small',
        value: 'small'
      },
      {
        label: 'xSmall',
        value: 'xSmall'
      },
      {
        label: 'xxSmall',
        value: 'xxSmall'
      }
    ]
  },
  {
    name: 'bolded',
    label: 'Bold',
    component: 'toggle',
  },
  {
    name: 'underlined',
    label: 'Underline',
    component: 'toggle'
  },
  {
    name: 'italic',
    label: 'Italic',
    component: 'toggle',
  },
  styleFields.textColor,
  {
    name: 'textAlign',
    label: 'Align Text',
    component: 'select',
    options: [
      {
        label: 'auto',
        value: '',
      },
      {
        label: 'left',
        value: 'left',
      },
      {
        label: 'center',
        value: 'center',
      },
      {
        label: 'right',
        value: 'right',
      }
    ]
  },
  {
    name: 'textSpaceAfter',
    label: 'Paragraph Spacing',
    component: 'toggle',
  },
  {
    name: 'maxLines',
    label: 'Max Number of Lines',
    description: 'If the text will wrap beyond the set number of lines the text will \'...\' elipsis',
    component: 'number-string',
    placeholder: 'any',
  },
  {
    name: 'uppercased',
    label: 'Uppercase',
    component: 'toggle'
  },
  layoutFields.unitBased.width,
  layoutFields.unitBased.maxWidth,
  layoutFields.padding,
  layoutFields.alignSelf,
  styleFields.opacity,
]

export const defaultTextBlockSettings = {
  textSize: 'large',
  textAlign: '',
  textColor: {
    name: '',
    alpha: '',
  },
  textSpaceAfter: false,
  maxLines: '0',
  bolded: false,
  underlined: false,
  italic: false,
  uppercased: false,
  width: {
    value: '',
    unit: '%'
  },
  maxWidth: {
    value: '',
    unit: 'px'
  },
  padding: settingsDefaultItems.padding,
  alignSelf: 'stretch',
  opacity: settingsDefaultItems.opacity,
}


export const TextBlockTemplate = {
  type: 'TextBlockTemplate',
  name: 'text',
  key: 'text-block',
  label: 'Text',
  component: 'grouped',
  defaultItem: {
    text: 'Add some text...',
    settings: {
      sm: defaultTextBlockSettings,
      xs: applyEnabled(defaultTextBlockSettings, false, ['padding'])
    },
    // maxWidth: '',
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'textarea'
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: textSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: textSettingFields
        })
      ]
    }
  ]
}
