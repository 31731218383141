import React, {
  createContext,
  useRef,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Platform } from 'react-native';
import {
  useTheme,
  useLayoutEffect,
  useOnKeyPress,
  useBackHandler,
  useWhyDidYouUpdate,
} from '../../hooks';
import { uniqueId, isNull } from '../../utils';
import { PortalDestination, Portal } from '../Portal';
import { Box } from '../Box';
import { ModalManager } from './ModalManager';
import { ModalFocusTrap } from './ModalFocusTrap';
import { ModalBackdrop } from './ModalBackdrop';
import { Transition, withStyles } from '../../styling';
import { sizes, position, flexBox } from '../../system/props';
const defaultManager = new ModalManager();

const filterProps = [
  ...sizes.filterProps,
  ...position.filterProps,
  ...flexBox.filterProps,
];

const ModalEvents = {
  backdropPress: 'backdropPress',
  escapeKeyDown: 'escapeKeyDown',
  backPress: 'backPress',
};

const Modal = withStyles((props) => {
  return {
    root: {
      ...position({
        zIndex: '$modal',
        top: 0,
        left: 0,
        ...props,
        position: Platform.OS === 'web' ? 'fixed' : 'absolute',
      }),
      ...sizes({
        width: '100%',
        height: '100%',
        ...props,
      }),
      ...flexBox({
        justifyContent: 'center',
        alignItems: 'center',
        ...props,
      }),
    },
  };
}, { name: 'Modal', filterProps, asProp: { zIndex: true } })(
  React.forwardRef(function Modal(props, ref) {
    const {
      portalName, // uses name from modalContainerContext if null/undefined (preferred)
      disablePortal = false,
      manager = defaultManager,
      BackdropComponent = ModalBackdrop,
      BackdropProps,
      hideBackdrop = false,
      disableBackdropPress = false,
      disableEscapeKeyDown = false,
      disableBackPress = false,
      disableBackPressPropagation = false,
      disableScrollLock = false,
      disableAnimate = false,
      disableTrapFocus = false,
      keepMounted = false,
      onKeyDown,
      onPressBack,
      onPressBackdrop,
      onClose,
      onEntered,
      onExited,
      open,
      children,
      animate, // dont pass
      animations, // used for <Transition>
      accessibility,
      zIndex: z = 'modal', // theme zIndex key string or number. Important for layering
      onEnter,
      onLeave,
      styles,
      ...rest
    } = props;

    const modalId = useRef(uniqueId('__modal__'));
    const mounted = useRef(true);
    const hasOpened = useRef(false);
    const transitionState = useRef(null);
    const lastTransitionState = useRef(null);
    const [isOpen, setIsOpen] = useState(() => open);
    const [isTransitioning, setTransitioning] = useState(() => open && !disableAnimate);

    useEffect(() => { return () => { mounted.current = false; } }, []);

    useEffect(() => {
      const id = modalId.current;
      return () => {
        manager.remove(id);
      }
    }, [manager])

    useLayoutEffect(() => {
      if (open && mounted.current) {
        manager.add(modalId.current, () => {
          if (mounted.current) {
            setTransitioning(() => !disableAnimate);
            setIsOpen(() => true);
            transitionState.current = 'enter';
            hasOpened.current = true;
          }
        }, { disableScrollLock });
      } else {
        manager.remove(modalId.current, () => {
          if (mounted.current) {
            setIsOpen(() => false);
            if (hasOpened.current) {
              transitionState.current = 'leave';
              setTransitioning(() => !disableAnimate);
            }
          }
        });
      }
    }, [open, manager, disableScrollLock, disableAnimate]);

    useOnKeyPress('Escape', (event) => {
      if (!manager.isTopModal(modalId.current)) {
        return;
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
      if (!disableEscapeKeyDown) {
        event.stopPropagation();
        if (onClose) {
          onClose(event, ModalEvents.escapeKeyDown);
        }
      }
    });

    useBackHandler((event) => {
      if (!manager.isTopModal(modalId.current)) {
        return;
      }
      if (onPressBack) {
        onPressBack(event);
      }
      if (!disableBackPress) {
        if (onClose) {
          onClose(event, ModalEvents.backPress);
        }
        // prevent bubbling by returning true
        return true;
      }
      // allow event to bubble up by setting this to false (default)
      return disableBackPressPropagation;
    });

    const handlePressBackdrop = (event) => {
      if (onPressBackdrop) {
        onPressBackdrop(event);
      }
      if (!disableBackdropPress && onClose) {
        onClose(event, ModalEvents.backdropPress);
      }
    }

    const theme = useTheme();
    const zIndex = useMemo(() => {
      let zIndex = z;
      if (typeof zIndex === 'string') {
        if (theme && theme.zIndex && !isNull(theme.zIndex[z])) {
          zIndex = theme.zIndex[z] * 1;
        } else {
          zIndex = zIndex * 1;
        }
      }
      if (typeof zIndex !== 'number' || isNaN(zIndex)) {
        zIndex = 99999999;
      }
      return zIndex;
    }, [z, theme]);

    const handleTransitionState = useCallback((node, transitionStateKey) => {
      if (transitionState.current && lastTransitionState.current !== transitionState.current) {
        lastTransitionState.current = transitionState.current;
        if (transitionStateKey === 'enter') {
          if (onEnter) {
            onEnter(node);
          }
        } else if (transitionStateKey === 'leave') {
          if (onLeave) {
            onLeave(node);
          }
        }
      }
    }, [onEnter, onLeave]);

    const handleTransitionRest = useCallback(() => {
      setTransitioning(false);
      if (transitionState.current === 'enter') {
        if (onEntered) {
          onEntered();
        }
      } else if (transitionState.current === 'leave') {
        if (onExited) {
          onExited();
        }
      }
      transitionState.current = null;
    }, [onEntered, onExited]);

    const transitionAnimations = useMemo(() => {
      return {
        from: { opacity: 0 },
        enter: {
          opacity: keepMounted ? (isOpen ? 1 : 0) : 1,
        },
        leave: {
          opacity: 0,
        },
        config: {
          clamp: true,
          tension: 500,
          friction: 50,
        },
        ...(keepMounted ? { update: { opacity: isOpen ? 1 : 0 } } : null),
        ...animations,
      };
    }, [keepMounted, isOpen, animations]);

    let content = null;
    if (keepMounted || isTransitioning || isOpen) {
      const zedPlus = zIndex + manager.getIndex(modalId.current);
      content = (
        <ModalFocusTrap active={isOpen} zIndex={zedPlus} disableTrapFocus={disableTrapFocus}>
          <Box
            pointerEvents={!isOpen ? 'none' : 'box-none'}
            disableAnimate
            zIndex={zedPlus}
            ref={ref}
            accessibility={{
              accessibilityRole: isOpen && Platform.OS === 'web' ? 'dialog' : null,
              accessibilityViewIsModal: isOpen, // ios
              accessibilityElementsHidden: !isOpen, // ios - similar to android no-hide-descendants
              importantForAccessibility: isOpen ? 'yes' : 'no-hide-descendants', // android
              ...accessibility,
            }}
            aria-modal
            {...rest}
          >
            {!hideBackdrop && (
              <BackdropComponent
                open={isOpen}
                onPress={handlePressBackdrop}
                disableAnimate={disableAnimate}
                {...BackdropProps}
              />
            )}
            {!disableAnimate ? (
              <Transition
                animations={transitionAnimations}
                key={modalId.current}
                onRest={handleTransitionRest}
                onStart={handleTransitionState}
              >
                {isOpen || keepMounted ? children : null}
              </Transition>
            ) : (
              children
            )}
          </Box>
        </ModalFocusTrap>
      );
    }

    return (
      <ModalPortal portalName={portalName} disablePortal={disablePortal}>
        {content}
      </ModalPortal>
    );
  })
);

const ModalPortal = ({
  portalName, // uses name from modalContainerContext if null/undefined (preferred)
  disablePortal = false,
  children,
}) => {
  const containerName = useModalContainerName(portalName);

  return disablePortal ? (
    children
  ) : (
    <Portal name={containerName}>{children}</Portal>
  );
};

const modalContainerContext = createContext('_modal_portal_');

function useModalContainerName(portalName) {
  const name = useContext(modalContainerContext);
  return portalName || name;
}

function ModalContainer({ children, ...props }) {
  const name = useRef(uniqueId('_modal_portal_'));
  return (
    <modalContainerContext.Provider value={name.current}>
      {children}
      <PortalDestination name={name.current} {...props} />
    </modalContainerContext.Provider>
  );
}

ModalContainer.displayName = 'ModalContainer';

export {
  modalContainerContext,
  useModalContainerName,
  ModalContainer,
  ModalEvents,
  Modal,
};