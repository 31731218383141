import React, { useMemo, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FieldsBuilder } from './FieldsBuilder'
import { LeftArrowIcon, RightArrowIcon } from '@tinacms/icons'
import { useFormPortal } from '@tinacms/react-forms'


const TabGroup = ({ meta, ...props }) => {
  const [isExpanded, setExpanded] = useState(false)
  return (
    <FieldWrapper>
      {
        props.field.asPanel ? (
          <Header onClick={() => setExpanded(p => !p)}>
            {props.field.label || props.field.name}
            <RightArrowIcon />
          </Header>
        ) : null
      }
      <TabPanel {...props} isExpanded={isExpanded} setExpanded={setExpanded} />
      {props.field.description && <FieldDescription>{props.field.description}</FieldDescription>}
      {meta.error && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  )
}

const TabPanel = props => {
  const FormPortal = useFormPortal()
  const { field, tinaForm, isExpanded, setExpanded } = props;
  const { asPanel } = field;
  const nameLength = field.name.length;

  const [activeIndex, setActiveIndex] = useState(() => 0);
  const fields = useMemo(() => {
    return field.fields.map((subField) => ({
      ...subField,
      name: `${field.name}.${subField.name}`,
    }))
  }, [field.fields, field.name])

  const activeFields = useMemo(() => {
    return fields[activeIndex].fields.map((subField) => ({
      ...subField,
      name: `${fields[activeIndex].name}.${subField.name}`,
    }))
  }, [activeIndex, fields]);


  if (asPanel !== true) {
    return (
      <>
      <Tabs>
        <TabButtons>
          {fields.map((subField, index) => (
            <Tab
              $isActive={index === activeIndex}
              onClick={() => setActiveIndex(index)}
              key={`${subField.name}`}
            >{subField.label}</Tab>
          ))}
        </TabButtons>
      </Tabs>
      <FieldsBuilder form={tinaForm} fields={activeFields} />
      </>
    )
  }


  // console.log(field.name);
  // return isExpanded ? <FieldsBuilder form={tinaForm} fields={fields} /> : null;
  return (
    <FormPortal>
      <GroupPanel isExpanded={isExpanded} nameLength={nameLength}>
        <PanelHeader onClick={() => setExpanded(false)}>
          <LeftArrowIcon /> <span>{field.label || field.name}</span>
        </PanelHeader>
        <Tabs>
          <TabButtons>
            {fields.map((subField, index) => (
              <Tab
                $isActive={index === activeIndex}
                onClick={() => setActiveIndex(index)}
                key={`${subField.name}`}
              >{subField.label}</Tab>
            ))}
          </TabButtons>
        </Tabs>
        <PanelBody>
          {isExpanded ? (
            <FieldsBuilder form={tinaForm} fields={activeFields} />
          ) : null}
        </PanelBody>
      </GroupPanel>
    </FormPortal>
  )
}

export const TabGroupFieldPlugin = {
  name: 'tabGroup',
  Component: TabGroup,
}

const Tabs = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  background-color: white;
  padding: 8px 16px 8px 16px;
  border-bottom: 1px solid var(--tina-color-grey-2);
  margin: 0;
`;

const TabButtons = styled.div`
  display: flex;
  flex: 1 1 auto;
  border-radius: 2px;
  border: 1px solid var(--tina-color-grey-2);
`

const Tab = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  user-select: none;
  cursor: pointer;
  padding: 8px;
  background-color: ${props => props.$isActive ? 'var(--tina-color-primary)' : 'transparent'};
  color: ${props => props.$isActive ? 'white' : 'var(--tina-color-primary)'};
  justify-content: center;
  align-items: center;
  font-size: var(--tina-font-size-1);
  font-weight: ${props => props.$isActive ? 600 : 400 };
  letter-spacing: 0.01em;
  line-height: 1.35;
  text-overflow: ellipsis;
`

const FieldWrapper = styled.div`
  position: relative;
  ${props => props.margin && css`margin-bottom: var(--tina-padding-big);`};
`

const FieldLabel = styled.label`
  all: unset;
  font-family: 'Inter', sans-serif;
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.35;
  color: var(--tina-color-grey-8);
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const FieldDescription = styled.span`
  all: unset;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: var(--tina-font-size-0);
  font-style: italic;
  font-weight: lighter;
  color: var(--tina-color-grey-6);
  padding-top: 4px;
  white-space: normal;
  margin: 0;
`

const FieldError = styled.span`
  display: block;
  color: red;
  font-size: var(--tina-font-size-1);
  margin-top: 8px;
  font-weight: var(--tina-font-weight-regular);
`

const Header = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--tina-color-grey-2);
  border-radius: var(--tina-radius-small);
  margin: 0 0 24px 0;
  overflow: visible;
  line-height: 1.35;
  padding: 12px;
  color: var(--tina-color-grey-10);
  background-color: white;

  svg {
    width: 24px;
    height: auto;
    fill: var(--tina-color-grey-3);
    transition: all var(--tina-timing-short) ease-out;
  }

  &:hover {
    svg {
      fill: var(--tina-color-grey-8);
    }
    color: #0084ff;
  }
`

export const PanelHeader = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 6px 18px 6px 18px;
  color: inherit;
  font-size: var(--tina-font-size-3);
  transition: color var(--tina-timing-medium) ease-out;
  user-select: none;
  border-bottom: 1px solid var(--tina-color-grey-2);
  margin: 0;
  span {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex: 0 0 auto;
    width: 24px;
    fill: var(--tina-color-grey-3);
    height: auto;
    transform: translate3d(-4px, 0, 0);
    transition: transform var(--tina-timing-medium) ease-out;
  }
  :hover {
    color: var(--tina-color-primary);
    svg {
      fill: var(--tina-color-grey-8);
      transform: translate3d(-7px, 0, 0);
      transition: transform var(--tina-timing-medium) ease-out;
    }
  }
`

export const PanelBody = styled.div`
  background: var(--tina-color-grey-1);
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
`

const GroupPanelKeyframes = keyframes`
  0% {
    transform: translate3d( 100%, 0, 0 );
  }
  100% {
    transform: translate3d( 0, 0, 0 );
  }
`

export const GroupPanel = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: ${p => p.isExpanded ? 1000 + p.nameLength : 1000};
  pointer-events: ${p => (p.isExpanded ? 'all' : 'none')};

  > * {
    ${p =>
      p.isExpanded &&
      css`
        animation-name: ${GroupPanelKeyframes};
        animation-duration: 150ms;
        animation-delay: 0;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-fill-mode: backwards;
      `};

    ${p =>
      !p.isExpanded &&
      css`
        transition: transform 150ms ease-out;
        transform: translate3d(100%, 0, 0);
      `};
  }
`
