import React, { useMemo } from 'react';
import equal from 'fast-deep-equal/react';
import { Image, getImageResizeMode } from '../Image';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { useLayout, useImageDimensions, useMemoCompare } from '../../hooks';
import { isNull } from '../../utils';

function getAlignStyles(value, direction) {
  const attr = direction === 'row' ? ['alignItems', 'justifyContent'] : ['justifyContent', 'alignItems'];
  switch(value) {
    case 'top-left':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-start' }
    case 'top-center':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'center' }
    case 'top-right':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-end' }
    case 'center-left':
      return { [attr[0]]: 'center', [attr[1]]: 'flex-start' }
    case 'center':
      return { [attr[0]]: 'center', [attr[1]]: 'center' }
    case 'center-right':
      return { [attr[0]]: 'center', [attr[1]]: 'flex-end' }
    case 'bottom-left':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'flex-start' }
    case 'bottom-center':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'center'}
    case 'bottom-right':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'flex-end' }
    default:
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-start' }
  }
}

const BackgroundImage = withStyles((props) => {
  const {
    width = '100%',
    height = '100%',
    overflow = 'hidden',
    position = 'absolute',
    top = 0,
    left = 0,
    zIndex = -1,
  } = props;

  return {
    root: { width, height, overflow, zIndex, position, top, left },
    image: {},
  };
}, { name: 'BackgroundImage' })(React.forwardRef(function BackgroundImage(props, ref) {
  const {
    alignImage: align = { y: 'center', x: 'center' },
    src,
    image, // TODO: deprecate
    ImageProps = { },
    styles,
    resizeMode: rmProp,
    description,
    ...rest
  } = props;

  const { onLayout, width: layoutWidth, height: layoutHeight } = useLayout();
  const { aspectRatio, width: imageWidth, height: imageHeight } = useImageDimensions(src);
  let resizeMode = rmProp ? rmProp : getImageResizeMode(image);


  const imageSize = useMemo(() => {
    if (resizeMode === 'contain') {
      let width = '100%';
      let height = '100%';
      if (!isNull(aspectRatio) && layoutWidth && layoutHeight) {
        height = ~~(layoutWidth / aspectRatio);
        width = ~~(layoutHeight * aspectRatio);
      }
      return {
        width,
        height
      }
    } else if (resizeMode === 'cover') {
      let width = '100%';
      let height = '100%';
      if (!isNull(aspectRatio) && layoutWidth && layoutHeight) {
        const containerAspectRatio = layoutWidth / layoutHeight;
        if (containerAspectRatio > aspectRatio) {
          // match width and oversize image height
          width = layoutWidth;
          height = ~~(layoutWidth / aspectRatio);
        } else {
          // match height and oversize image width
          height = layoutHeight;
          width = ~~(layoutHeight * aspectRatio);
        }
        // if (containerAspectRatio > 1) {
        //   height = ~~(layoutWidth / containerAspectRatio);
        //   if (imageWidth && imageWidth < layoutWidth) {
        //     width = layoutWidth;
        //   }
        // } else {
        //   width = ~~(layoutHeight * containerAspectRatio);
        //   if (imageHeight && imageHeight < layoutHeight) {
        //     height = layoutHeight;
        //   }
        // }
      }

      return { width, height, minWidth: width, minHeight: height, maxWidth: width, maxHeight: height };
    }

  }, [layoutWidth, layoutHeight, aspectRatio, resizeMode])

  if (resizeMode === 'cover') {
    // resizeMode = 'center';
  }

  const alignImage = useMemoCompare(align, equal);

  // assumes flexDirection = column
  const alignImageStyleProps = useMemo(() => {
    if (alignImage && typeof alignImage === 'string') {
      return getAlignStyles(alignImage, 'column')
    }
    if (alignImage && typeof alignImage === 'object') {
      const { x, y } = alignImage;
      let justifyContent;
      let alignItems;
      switch (y) {
        case 'center':
          justifyContent = 'center';
          break;
        case 'top':
          justifyContent = 'flex-start';
          break;
        case 'bottom':
          justifyContent = 'flex-end';
          break;
        default:
          justifyContent = 'center';
          break;
      }

      switch (x) {
        case 'center':
          alignItems = 'center';
          break;
        case 'left':
          alignItems = 'flex-start';
          break;
        case 'right':
          alignItems = 'flex-end';
          break;
        default:
          alignItems = 'center';
      }

      return { justifyContent, alignItems };
    }

  }, [alignImage]);

  const imageProps = { ...image, ...ImageProps, resizeMode };
  if (imageProps.style) {
    imageProps.style = [styles.image, imageProps.style];
  } else {
    imageProps.style = styles.image;
  }
  return (
    <Box
      {...alignImageStyleProps}
      {...rest}
      onLayout={onLayout}
      ref={ref}
      flexDirection="column">
      <Image
        src={src}
        description={description}
        {...imageSize}
        {...imageProps}/>
    </Box>
  );
}));

export { BackgroundImage };
