import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import '../../global.css';
import { v4 as uuid } from 'uuid';
// import { MDXProvider } from "@mdx-js/react";
import Helmet from 'react-helmet';
// import { View } from 'react-native-web';
import { Box, ModalContainer } from '../ui';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { JsonCreatorPlugin } from "gatsby-tinacms-json"
import { withPlugin } from "tinacms"
import { EditToolbar } from './EditToolbar';
import { useCMS } from 'tinacms';
import {
  EditingLocaleLocationUpdater,
  SupportedLocales,
  DEFAULT_LOCALE
} from '../editing/useEditingLocale';
// import slugify from "react-slugify"

const MasterLayout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query MasterLayoutQuery {
  //     site: settingsJson(
  //       fileRelativePath: { eq: "/content/settings/site.json" }
  //     ) {
  //       title
  //     }
  //   }
  // `);
  return (
    <>
      <Helmet>
      </Helmet>
      <Site>
        <ModalContainer>
          <Header />
          {children}
          <Footer />
          <EditToolbar />
        </ModalContainer>
        <EditingLocaleLocationUpdater />
      </Site>
    </>
  )
}

const Site = ({ children }) => {
  const cms = useCMS();
  return (
    <Box
      flex={1}
      overflow="hidden"
      flexDirection="column"
      backgroundColor={({ theme }) => theme.colors.gray['50']}
      paddingTop={cms.enabled && process.env.NODE_ENV !== 'production' ? 65 : 0}
    >
      {children}
    </Box>
  )
}

const formatPath = value => value ? value.replace(/\s+/g, '').toLowerCase() : '';

const validatePath = (value, data, meta, field) => {
  const path = formatPath(value);
  if (!path) {
    return 'Required';
  } else if (!path.startsWith('/')) {
    return 'Path must start with \'/\'';
  }
  return undefined;
}

const CreatePageButton = new JsonCreatorPlugin({
  label: "New Page",
  filename(form) {
    return `content/pages/${uuid()}.json`
  },
  fields: [
    { name: "title", label: "Title", description: "SEO Title", component: "text", required: false },
    {
      label: "Draft",
      description: "If draft is on then this will not be published to the live versions of the site.",
      name: "draft",
      component: "toggle",
    },
    {
      label: "Localized for:",
      name: "locale",
      description: "For which site localization is this page for?",
      component: 'select',
      options: [
        ...SupportedLocales
      ],
    },
    { name: "path", label: "Path", component: "text", parse: formatPath, format: formatPath, validate: validatePath, required: true },
  ],
  data(form) {
    return {
      title: form.title || '',
      path: form.path,
      locale: form.locale || DEFAULT_LOCALE,
      draft: form.draft || false,
      pageBlocks: []
    }
  },
})

export default withPlugin(MasterLayout, [CreatePageButton]);
