export function modulate(value, fromRange, toRange, limit = true) {
  const fromLow = fromRange[0];
  const fromHigh = fromRange[1];
  const toLow = toRange[0];
  const toHigh = toRange[1];

  const result = toLow + (((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow));

  if (Number.isNaN(Number(result))) {
    return toLow;
  }

  if (limit) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow;
      }
      if (result > toHigh) {
        return toHigh;
      }
    } else {
      if (result > toLow) {
        return toLow;
      }
      if (result < toHigh) {
        return toHigh;
      }
    }
  }

  return result;
}
