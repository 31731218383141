import React from 'react';
import {
  Box,
  Heading,
  Text,
  Overline,
} from '../../ui';
import {
  useTheme,
} from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { InlineTextarea } from '../InlineTextarea';



export const VsRowItems = memo(({
  headline,
  leftBody,
  rightBody,
  headings,
  componentStyles,
  isLast,
}) => {
  const { breakpoints } = useTheme();

  const {
    rowHeadingText,
    columnHeading,
    columnHeadingText,
    columnItems,
    columnItemText,
    columnItemBorder,
    columnHeadingBorder
  } = componentStyles;


  const headingBottomBorderWidth = columnHeadingBorder && columnHeadingBorder.bottom ? columnHeadingBorder.bottom.width : 0;
  const itemXBorders = {
    left: columnItemBorder && columnItemBorder.left ? columnItemBorder.left.width : 0,
    right: columnItemBorder && columnItemBorder.right ? columnItemBorder.right.width : 0,
    bottom: columnItemBorder && columnItemBorder.bottom ? columnItemBorder.bottom.width : 0,
  }

  return (
    <>
      <Box
        {...columnHeading}
        border={columnHeadingBorder}
        borderBottomWidth={breakpoints({ xs: headingBottomBorderWidth, sm: isLast ? 0 : headingBottomBorderWidth })}
      >
        <Text {...columnHeadingText}>
          <InlineTextarea name="headingText" focusRing={false} />
        </Text>
        <Box
          display={breakpoints({ xs: 'flex', sm: 'none' })}
          flexDirection="row"
          justifyContent="space-evenly"
          width="100%"
        >
          <Text flex={1} {...rowHeadingText}>
            {headings[0]}
          </Text>
          <Text flex={1} {...rowHeadingText}>
            {headings[1]}
          </Text>
        </Box>
      </Box>
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        alignItems={'stretch'}
        {...columnItems}
        overflow="hidden"
      >
        <Box
          flex={1}
          justifyContent="flex-start"
          alignItems={breakpoints({ xs: 'center', sm: 'flex-start' })}
          border={columnItemBorder}
          borderLeftWidth={breakpoints({ xs: 0, sm: itemXBorders.left })}
          borderBottomWidth={isLast ? 0 : itemXBorders.bottom}
        >
          <Text {...columnItemText} width="100%">
            <InlineTextarea name="items.0" focusRing={false} />
          </Text>
        </Box>
        <Box
          flex={1}
          justifyContent="flex-start"
          alignItems={breakpoints({ xs: 'center', sm: 'flex-start' })}
          border={columnItemBorder}
          borderRightWidth={breakpoints({ xs: 0, sm: itemXBorders.right })}
          borderBottomWidth={isLast ? 0 : itemXBorders.bottom}
        >
          <Text {...columnItemText} width="100%">
            <InlineTextarea name="items.1" focusRing={false} />
          </Text>
        </Box>
      </Box>
    </>
  )
})

export const VsRowHeader = memo(({
  headline,
  leftBody,
  rightBody,
  headings,
  componentStyles,
}) => {
  const { breakpoints } = useTheme();

  const {
    rowHeadingText,
    columnHeading,
    columnItems,
  } = componentStyles;
  return (
    <>
      <Box {...columnHeading} />
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        alignItems={'stretch'}
        {...columnItems}
        overflow="hidden"
      >
        <Box
          flex={1}
          justifyContent="flex-start"
          alignItems={breakpoints({ xs: 'center', sm: 'flex-start' })}
        >
          <Heading {...rowHeadingText}>
            <InlineTextarea name="items.0" focusRing={false} /></Heading>
          </Box>
        <Box
          flex={1}
          justifyContent="flex-start"
          alignItems={breakpoints({ xs: 'center', sm: 'flex-start' })}
        >
        <Heading {...rowHeadingText}>
          <InlineTextarea name="items.1" focusRing={false} /></Heading>
        </Box>
      </Box>
    </>
  )
})


// export const VsRowContent = memo(({
//   headline,
//   leftBody,
//   rightBody,
//   bodyAsHeadline,
//   last,
//   first,
//   borderColor,
//   borderStyle,
//   getTextProps: removeme,
//   headings
// }) => {
//   const { colors, spacing, breakpoints } = useTheme();
//   const getTextProps = () => null;
//   return (
//     <>
//       <Box
//         flex={1}
//         justifyContent={breakpoints({ xs: 'center', sm: 'flex-start' })}
//         alignItems={breakpoints({ xs: 'center', sm: 'flex-end' })}
//         padTop={breakpoints({ xs: spacing(6), sm: spacing(4) })}
//         padBottom={breakpoints({ xs: spacing(0), sm: spacing(4) })}
//       >
//         <Text
//           variant={breakpoints({ xs: 'text.large', sm: 'text.medium' })}
//           bold
//           spaceAfter
//           align={breakpoints({ xs: 'center', sm: 'right' })}
//           width="100%"
//           {...getTextProps()}
//           padRight={breakpoints({ xs: 0, sm: spacing(3) })}>
//           {!bodyAsHeadline ? <InlineTextarea name="headingText" focusRing={false} /> : null}
//         </Text>
//         <Box
//           display={breakpoints({ xs: 'flex', sm: 'none' })}
//           flexDirection="row"
//           justifyContent="space-evenly"
//           width="100%"
//         >
//           <Overline center weight="$regular" opacity={0.75} flex={1} {...getTextProps(true)}>{headings[0]}</Overline>
//           <Overline center weight="$regular" opacity={0.65} flex={1}>{headings[1]}</Overline>
//         </Box>
//       </Box>
//       <Box
//         flexDirection="row"
//         justifyContent="flex-start"
//         alignItems={bodyAsHeadline ? 'flex-end' : 'stretch'}
//         flex={2.6}
//         overflow="hidden"
//         gap={spacing(6)}
//         gapBorder={{ width: 1, color: borderColor ? colors.dim(borderColor, 0.8) : 'transparent', style: borderStyle }}
//         padX={breakpoints({ xs: spacing(1), sm: spacing(3) })}
//         borderLeftWidth={breakpoints({ xs: 0, sm: 1 })}
//         borderLeftColor={headline && borderColor ? colors.dim(borderColor, 0.8) : 'transparent'}
//         borderLeftStyle={borderStyle}
//       >
//         <Box
//           flex={1}
//           justifyContent="flex-start"
//           alignItems={breakpoints({ xs: 'center', sm: 'flex-start' })}
//           padTop={spacing(4)}
//           padBottom={spacing(3)}
//         >
//           { bodyAsHeadline ?
//               <Heading
//                 level={3}
//                 align={breakpoints({ xs: 'center', sm: 'left' })}
//                 {...getTextProps(true)}
//                 width="100%"
//                 spaceAfter
//                 ><InlineTextarea name="items.0" focusRing={false} /></Heading>
//             : <Text
//                 variant={breakpoints({ xs: 'text.small', sm: 'text.large' })}
//                 {...getTextProps(true)}
//                 width="100%"
//                 weight={breakpoints({ xs: '$bold', sm: '$regular' })}
//                 align="left">
//                 <InlineTextarea name="items.0" focusRing={false} />
//               </Text>
//           }
//         </Box>
//         <Box
//           flex={1}
//           justifyContent="flex-start"
//           alignItems={breakpoints({ xs: 'center', sm: 'flex-start' })}
//           padTop={spacing(4)}
//           padBottom={spacing(3)}
//         >
//           { bodyAsHeadline ?
//               <Heading
//                 level={3}
//                 spaceAfter
//                 width="100%"
//                 {...getTextProps()}
//                 align={breakpoints({ xs: 'center', sm: 'left' })}
//                 dim={0.95}
//                 ><InlineTextarea name="items.1" focusRing={false} /></Heading>
//             : <Text
//                 variant={breakpoints({ xs: 'text.small', sm: 'text.large' })}
//                 dim={0.9}
//                 width="100%"
//                 {...getTextProps()}
//                 align="left">
//                 <InlineTextarea name="items.1" focusRing={false} />
//               </Text>
//           }
//         </Box>
//       </Box>
//     </>
//   )
// })
