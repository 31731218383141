import * as React from 'react'
import styled, { css } from 'styled-components'
import { IconButton } from '@tinacms/styles'
import { AddIcon } from '@tinacms/icons'
import {
  useInlineBlocks,
} from 'react-tinacms-inline';
import { useCopyBlockData } from '../editing/useCopyBlockData';

export function AddBlockMenu({
  blocks,
  addBlock,
  position,
  index,
  offset,
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const addBlockButtonRef = React.useRef(null)
  const [openTop, setOpenTop] = React.useState(false)
  const [openLeft, setOpenLeft] = React.useState(false);

  const { templates } = useInlineBlocks();
  const { data: copyBlockData, canPaste } = useCopyBlockData({ templates });

  const handleOpenBlockMenu = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const addBlockButtonElem = addBlockButtonRef.current

    if (addBlockButtonElem !== null) {
      const menuBounding = addBlockButtonElem.getBoundingClientRect()
      const halfWindowHeight =
        (window.innerHeight || document.documentElement.clientHeight) / 2
      const halfWindowWidth =
        (window.innerWidth || document.documentElement.clientWidth) / 2

      const offsetTop = menuBounding.top
      const offsetLeft = menuBounding.left;


      if (offsetTop < halfWindowHeight) {
        setOpenTop(false)
      } else {
        setOpenTop(true)
      }

      if (offsetLeft < halfWindowWidth) {
        setOpenLeft(false);
      } else {
        setOpenLeft(true);
      }
    }

    setIsOpen(isOpen => !isOpen)

    // if (Object.keys(blocks).length === 1) {
    //   const blockId = Object.keys(blocks)[0]
    //   addBlock({
    //     _template: blockId,
    //     ...blocks[blockId].template.defaultItem,
    //   })
    // } else {
    //   setIsOpen(isOpen => !isOpen)
    // }
  }

  React.useEffect(() => {
    const inactivateBlockMenu = () => setIsOpen(false)
    document.addEventListener('mouseup', inactivateBlockMenu, false)
    return () => document.removeEventListener('mouseup', inactivateBlockMenu)
  }, [])

  return (
    <AddBlockWrapper
      index={index}
      offset={offset}
      position={position}
      isOpen={isOpen}
    >
      <AddBlockButton
        ref={addBlockButtonRef}
        onClick={handleOpenBlockMenu}
        isOpen={isOpen}
        primary
        small
      >
        <AddIcon />
      </AddBlockButton>
      <BlocksMenu openTop={openTop} openLeft={openLeft} isOpen={isOpen}>
        {copyBlockData ? (
          <BlockOption
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              if (canPaste && copyBlockData && typeof copyBlockData === 'object' && copyBlockData._template) {
                addBlock(copyBlockData)
              }
            }}
            disabled={!canPaste}
            $notABlock
          >
            Paste Copied Item
          </BlockOption>
        ) : null}
        {Object.keys(blocks).map((key) => {
          const template = blocks[key].template
          return (
            <BlockOption
              key={template.label}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                addBlock({
                  _template: key,
                  ...template.defaultItem,
                })
              }}
            >
              {template.label}
            </BlockOption>
          )
        })}
      </BlocksMenu>
    </AddBlockWrapper>
  )
}

const AddBlockButton = styled(IconButton)`
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;

  &:focus {
    outline: none !important;
  }

  svg {
    transition: all 150ms ease-out;
  }

  ${props =>
    props.isOpen &&
    css`
      pointer-events: none;

      svg {
        transform: rotate(45deg);
      }
    `};
`

const AddBlockWrapper = styled.div(p => {
  const offset = getOffset(p.offset)

  return css`
  position: absolute;
  z-index: calc(var(--tina-z-index-2) - ${p.index !== undefined ? p.index : 0});

  ${p.position === 'top' &&
    css`
      top: calc(-1 * ${getOffsetY(offset)}px);
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    `}

  ${p.position === 'left' &&
    css`
      top: 50%;
      left: calc(-1 * ${getOffsetX(offset)}px);
      transform: translate3d(-50%, -50%, 0);
    `}

  ${p.position === 'bottom' &&
    css`
      bottom: calc(-1 * ${getOffsetY(offset)}px);
      left: 50%;
      transform: translate3d(-50%, 50%, 0);
    `}

  ${p.position === 'right' &&
    css`
      top: 50%;
      right: calc(-1 * ${getOffsetX(offset)}px);
      transform: translate3d(50%, -50%, 0);
    `}

  ${p.position === undefined &&
    css`
      position: relative;
    `}

  ${p.isOpen &&
    css`
      z-index: calc(1 + var(--tina-z-index-2) - ${p.index ? p.index : 0});
    `}
`
})

const BlocksMenu = styled.div`
  min-width: 192px;
  border-radius: 3px;
  border: 1px solid var(--tina-color-grey-2);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: absolute;
  z-index: var(--tina-z-index-2);
  top: 20px;
  left: 50%;
  transform: translate3d(-50%, 0, 0) scale3d(0.5, 0.5, 1);
  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease-out;
  transform-origin: 50% 0;
  box-shadow: var(--tina-shadow-big);
  background-color: white;
  max-height: 300px;
  overflow-y: ${p => p.isOpen ? 'scroll' : 'hidden'};

  ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      pointer-events: all;
      ${props.openLeft ? (
        css`transform: translate3d(-100%, 16px, 0) scale3d(1, 1, 1)`
      ) : (
        css`transform: translate3d(0%, 16px, 0) scale3d(1, 1, 1)`
      )};
    `};

  ${props =>
    props.openTop &&
    css`
      top: auto;
      bottom: 20px;
      transform-origin: 50% 100%;

      ${props.isOpen && (
        props.openLeft ? (
          css`
            transform: translate3d(-100%, -16px, 0) scale3d(1, 1, 1);
          `
        ) : (
          css`
            transform: translate3d(0%, -16px, 0) scale3d(1, 1, 1);
          `
        )
        )};
    `};
`

const BlockOption = styled.button`
  flex-shrink: 0;
  font-family: 'Inter', sans-serif;
  position: relative;
  text-align: left;
  font-size: var(--tina-font-size-1);
  padding: 0 12px 0 32px;
  height: ${p => p.$notABlock ? '60px' : '40px' };
  font-weight: ${p => p.$notABlock ? 'bold' : 'var(--tina-font-weight-regular)' };
  width: 100%;
  background: none;
  cursor: pointer;
  outline: none;
  border: 0;
  transition: all 85ms ease-out;
  user-select: none;

  color: ${p => p.disabled ? '#cccccc' : 'inherit' };
  ${p => !p.disabled && css`
    &:hover {
      color: var(--tina-color-primary);
      background-color: #f6f6f9;
    }`}

  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`

export function getOffset(offset) {
  const DEFAULT_OFFSET = 16
  let result = DEFAULT_OFFSET
  const axis = { x: DEFAULT_OFFSET, y: DEFAULT_OFFSET }

  if (typeof offset === 'number') {
    result = offset
  } else if (typeof offset === 'object') {
    axis.x = offset.x
    axis.y = offset.y
    result = axis
  }

  return result
}

export const getOffsetX = (offset) =>
  typeof offset === 'object' ? offset.x : offset

export const getOffsetY = (offset) =>
  typeof offset === 'object' ? offset.y : offset
