import * as React from 'react'
import styled, { css } from 'styled-components'

// Wraps the Field component in labels describing the field's meta state
// Add any other fields that the Field component should expect onto the ExtraFieldProps generic type
export function wrapFieldsWithMeta(Field) {
  return (props) => (
    <FieldMeta
      name={props.input.name}
      label={props.field.label}
      description={props.field.description}
      error={props.meta.error}
      hideLabel={props.field.hideLabel === true || props.hideLabel}
    >
      <Field {...props} />
    </FieldMeta>
  )
}

export const FieldMeta = ({
  name,
  label,
  description,
  error,
  margin = true,
  children,
  hideLabel
}) => {
  return (
    <FieldWrapper margin={margin}>
      {!hideLabel && (
        <FieldLabel htmlFor={name}>
          {label || name}
          {description && <FieldDescription>{description}</FieldDescription>}
        </FieldLabel>
      )}
      {children}
      {error && <FieldError>{error}</FieldError>}
    </FieldWrapper>
  )
}

// Styling
const FieldWrapper = styled.div`
  position: relative;

  ${props =>
    props.margin &&
    css`
      margin-bottom: var(--tina-padding-big);
    `};
`

const FieldLabel = styled.label`
  all: unset;
  font-family: 'Inter', sans-serif;
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.35;
  color: var(--tina-color-grey-8);
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const FieldDescription = styled.span`
  all: unset;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: var(--tina-font-size-0);
  font-style: italic;
  font-weight: lighter;
  color: var(--tina-color-grey-6);
  padding-top: 4px;
  white-space: normal;
  margin: 0;
`

const FieldError = styled.span`
  display: block;
  color: red;
  font-size: var(--tina-font-size-1);
  margin-top: 8px;
  font-weight: var(--tina-font-weight-regular);
`
