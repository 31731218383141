import React from 'react';
import { Spacing } from '../../ui';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';
import { applyEnabledToggles } from '../fieldConfigs';
import { useMatchSettings } from '../useBreakpointLayouts';

const spacingFields = [
  {
    name: 'spacingAmount',
    label: 'Amount',
    component: 'number-string',
    step: 1,
    placeholder: '0',
  }
]

export const SpacingBlock = memo(({ data, containerBlock = { }, index, ...rest }) => {
  const settings = useMatchSettings(data.settings);



  const isColumnChild = containerBlock.direction !== 'row' && containerBlock.direction !== 'row-reverse';
  const spacingProps = { };
  const type = isColumnChild ? 'vertical' : 'horizontal';
  spacingProps[type] = settings.spacingAmount || 0;

  return (
    <BlocksControls alignSelf="stretch" index={index}>
      <Spacing {...spacingProps} />
    </BlocksControls>
  )
})

export const SpacingBlockTemplate = {
  type: 'SpacingBlockTemplate',
  name: 'spacing',
  key: 'spacing-block',
  label: 'Spacing',
  component: 'grouped',
  defaultItem: {
    settings: {
      xs: {
        spacingAmount: '2',
        spacingAmount_enabled: false,
      },
      sm: {
        spacingAmount: '2',
      }
    }
  },
  fields: [
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: spacingFields
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: spacingFields
        })
      ]
    },
  ]
}
