import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { withStyles } from '../../styling';
import { padding } from '../../system/props';

const filterProps = [
  'justifyContent',
  'alignItems',
  'alignSelf',
  ...padding.filterProps,
];

const MenuItem = withStyles((props) => {
  const {
    px = '$3',
    py = '$2',
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    alignSelf = 'stretch',
  } = props;

  return {
    root: {
      justifyContent,
      alignItems,
      alignSelf,
      ...padding({ ...props, px, py }),
    },
    text: {},
  };
}, { name: 'MenuItem', filterProps })(
  React.forwardRef(function MenuItem(props, ref) {
    const {
      children,
      selected,
      TextProps,
      accessibility = {},
      styles,
      ...rest
    } = props;
    return (
      <Box
        ref={ref}
        animations={{
          hovered: {
            bg: `rgba(0, 0, 0, ${selected ? 0.15 : 0.08})`,
          },
          pressed: {
            bg: `rgba(0, 0, 0, ${selected ? 0.15 : 0.12})`,
          },
          default: {
            bg: `rgba(0, 0, 0, ${selected ? 0.15 : 0})`,
          }
        }}
        disableAnimationDefaults
        accessibility={{
          accessible: true,
          accessibilityRole: 'menuitem',
          ...accessibility,
          accessibilityState: {
            selected,
            ...accessibility.accessibilityState,
          },
        }}
        {...rest}
      >
        {typeof children === 'string' ? (
          <Text
            noWrap
            style={styles.text}
            {...styles.props.text}
            {...TextProps}
          >
            {children}
          </Text>
        ) : children}
      </Box>
    )
  })
);

export { MenuItem };
