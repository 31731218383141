module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-native-web/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/circleci/project/node_modules/gatsby-tinacms-json","id":"acc053f9-4f17-5fb2-9948-4bd93063a73c","name":"gatsby-tinacms-json","version":"0.24.0","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/circleci/project/node_modules/gatsby-transformer-json","id":"6aa321d5-19bf-5a36-99ac-3b6091585e24","name":"gatsby-transformer-json","version":"2.4.4","pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]}],"manualInit":true,"enabled":false,"sidebar":false,"toolbar":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/project/src/components/SiteLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"","short_name":"","start_url":"/","background_color":"#5F2994","theme_color":"#5F2994","display":"minimal-ui","icon":"static/assets/Understory Secondary Icon Primary-KO.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fb0689ee66dcb3c2a44f99063dfca140"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
