export const multiples = {
  marginX: ['marginLeft', 'marginRight'],
  marginY: ['marginTop', 'marginBottom'],
  margin: ['marginLeft', 'marginRight', 'marginBottom', 'marginTop'],
  paddingX: ['paddingLeft', 'paddingRight'],
  paddingY: ['paddingTop', 'paddingBottom'],
  padding: ['paddingLeft', 'paddingRight', 'paddingBottom', 'paddingTop'],
  size: ['width', 'height'],
  minSize: ['minWidth', 'minHeight'],
  maxSize: ['maxWidth', 'maxHeight'],
  borderXWidth: ['borderLeftWidth', 'borderRightWidth'],
  borderXColor: ['borderLeftColor', 'borderRightColor'],
  borderXStyle: ['borderLeftStyle', 'borderRightStyle'],
  borderYWidth: ['borderTopWidth', 'borderBottomWidth'],
  borderYColor: ['borderTopColor', 'borderBottomColor'],
  borderYStyle: ['borderTopStyle', 'borderBottomStyle'],
  borderTopRadius: ['borderTopLeftRadius', 'borderTopRightRadius'],
  borderBottomRadius: ['borderBottomLeftRadius', 'borderBottomRightRadius'],
  borderLeftRadius: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
  borderRightRadius: ['borderTopRightRadius', 'borderBottomRightRadius'],
  shadowOffset: ['shadowOffetX', 'shadowOffsetY'],
}
