import { Platform } from 'react-native';
import { Box } from '../Box';
import { styled } from '../../styling';

const Header = styled(Box, ({ name: 'Header', filterProps: ['fixed'] }))({
  position: props => props.fixed ? (Platform.OS === 'web' ? 'fixed' : 'absolute') : 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '100%',
  width: Platform.OS === 'web' ? '100vw' : '100%',
  maxWidth: Platform.OS === 'web' ? '100vw' : '100%',
  zIndex: ({ theme }) => theme.zIndex.header,
  props: ({ theme }) => ({
    gap: theme.spacing(3),
  })
});

export { Header }
