import * as React from 'react'
import { wrapFieldsWithMeta } from './components/wrapFieldsWithMeta'
import { BaseTextField } from './components/TextField'

export const NumberField = wrapFieldsWithMeta(({ input, field }) => {
  const { onChange, value, ...rest } = input;
  const [temp, setTemp] = React.useState(() => value || '');

  React.useEffect(() => {
    setTemp(value);
  }, [value])

  return (
    <BaseTextField
      onChange={(event) => {
        if (typeof event.target.value === 'string') {
          let val = event.target.value.trim();
          if (val.endsWith('.')) {
            if (((val).match(/\./g) || []).length > 1) {
              val = val.substring(0, val.length - 1);
            }
            setTemp(val);
            return;
          }
          setTemp(parse(val));
        }
        if (onChange) onChange(event);
      }}
      value={temp}
      {...rest}
      placeholder={field.placeholder}
    />
  );
})

const regex = /[^0-9.]/g;

const parse = value => {
  if (typeof value !== 'string' || !value.trim()) return '';
  const numVal = value.replaceAll(regex, '');
  return numVal;
}

export const NumberStringFieldPlugin = {
  name: 'number-string',
  Component: NumberField,
  parse,
  format: value => {
    if (!value) return '';
    const num = Number(value);
    if (isNaN(num)) {
      return '';
    }
    if (value.trim().endsWith('.0')) {
      const fixedNum = `${num}.0`;
      const retest = Number(fixedNum);
      if (!isNaN(retest)) {
        return fixedNum;
      }
    }
    return `${num}`;
  },
}
