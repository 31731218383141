

import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { FieldsBuilder } from './FieldsBuilder'
import { LeftArrowIcon, RightArrowIcon, ChevronDownIcon, ChevronUpIcon } from '@tinacms/icons'
import { useFormPortal } from '@tinacms/react-forms'

export const Group = ({ tinaForm, field, form, meta, input, asDropdown = false }) => {
  const [isExpanded, setExpanded] = React.useState(false)
  return (
    <FieldWrapper>
      <FieldWrapperExpanded>
      <Header onClick={() => setExpanded(p => !p)} $asDropdown={asDropdown} $isExpanded={isExpanded}>
        {field.label || field.name}
        {asDropdown ? (isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />) : <RightArrowIcon />}
      </Header>
      <Panel
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        field={field}
        tinaForm={tinaForm}
        asDropdown={asDropdown}
      />
      </FieldWrapperExpanded>
      {field.description && <FieldDescription>{field.description}</FieldDescription>}
      {meta.error && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  )
}

export function GroupField(props) {
  return <div>Subfield: {props.field.label || props.field.name}</div>
}

export const CustomGroupFieldPlugin = {
  name: 'group',
  Component: Group,
}


const Panel = function Panel({
  setExpanded,
  isExpanded,
  tinaForm,
  field,
  asDropdown,
}) {
  const FormPortal = useFormPortal()
  const fields = React.useMemo(() => {
    return field.fields.map((subField) => ({
      ...subField,
      name: `${field.name}.${subField.name}`,
    }))
  }, [field.fields, field.name])

  const nameLength = field.name.length;
  // console.log(field.name);
  if (asDropdown && isExpanded) {
    return (<FieldsBuilder form={tinaForm} fields={fields} />)
  }
  // return isExpanded ? <FieldsBuilder form={tinaForm} fields={fields} /> : null;
  return (
    <FormPortal>
      <GroupPanel isExpanded={isExpanded} nameLength={nameLength}>
        <PanelHeader onClick={() => setExpanded(false)}>
          <LeftArrowIcon /> <span>{field.label || field.name}</span>
        </PanelHeader>
        <PanelBody>
          {isExpanded ? (
            <FieldsBuilder form={tinaForm} fields={fields} />
          ) : null}
        </PanelBody>
      </GroupPanel>
    </FormPortal>
  )
}

const Header = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--tina-color-grey-2);
  border-radius: var(--tina-radius-small);
  margin: ${p => p.$asDropdown && p.$isExpanded ? '0' : '0 0 24px 0'};
  overflow: visible;
  line-height: 1.35;
  padding: 12px;
  color: var(--tina-color-grey-10);
  background-color: white;

  svg {
    width: 24px;
    height: auto;
    fill: var(--tina-color-grey-3);
    transition: all var(--tina-timing-short) ease-out;
  }

  &:hover {
    svg {
      fill: var(--tina-color-grey-8);
    }
    color: #0084ff;
  }
`

export const PanelHeader = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 6px 18px 6px 18px;
  color: inherit;
  font-size: var(--tina-font-size-3);
  transition: color var(--tina-timing-medium) ease-out;
  user-select: none;
  border-bottom: 1px solid var(--tina-color-grey-2);
  margin: 0;
  span {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex: 0 0 auto;
    width: 24px;
    fill: var(--tina-color-grey-3);
    height: auto;
    transform: translate3d(-4px, 0, 0);
    transition: transform var(--tina-timing-medium) ease-out;
  }
  :hover {
    color: var(--tina-color-primary);
    svg {
      fill: var(--tina-color-grey-8);
      transform: translate3d(-7px, 0, 0);
      transition: transform var(--tina-timing-medium) ease-out;
    }
  }
`

export const PanelBody = styled.div`
  background: var(--tina-color-grey-1);
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
`

const GroupPanelKeyframes = keyframes`
  0% {
    transform: translate3d( 100%, 0, 0 );
  }
  100% {
    transform: translate3d( 0, 0, 0 );
  }
`

export const GroupPanel = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: ${p => {
    // if (p.log) console.log('zindex', p.isExpanded ? 1000 + p.nameLength : 1000);
    return p.isExpanded ? 1000 + p.nameLength : 1000
  }};
  pointer-events: ${p => (p.isExpanded ? 'all' : 'none')};

  > * {
    ${p =>
      p.isExpanded &&
      css`
        animation-name: ${GroupPanelKeyframes};
        animation-duration: 150ms;
        animation-delay: 0;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-fill-mode: backwards;
      `};

    ${p =>
      !p.isExpanded &&
      css`
        transition: transform 150ms ease-out;
        transform: translate3d(100%, 0, 0);
      `};
  }
`

const FieldWrapper = styled.div`
  position: relative;
  margin-bottom: var(--tina-padding-big);
  margin-top: var(--tina-padding-big);
`

const FieldWrapperExpanded = styled.div`
  background-color: var(--tina-color-grey-2);
`

const FieldLabel = styled.label`
  all: unset;
  font-family: 'Inter', sans-serif;
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.35;
  color: var(--tina-color-grey-8);
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const FieldDescription = styled.span`
  all: unset;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: var(--tina-font-size-0);
  font-style: italic;
  font-weight: lighter;
  color: var(--tina-color-grey-6);
  padding-top: 4px;
  white-space: normal;
  margin: 0;
`

const FieldError = styled.span`
  display: block;
  color: red;
  font-size: var(--tina-font-size-1);
  margin-top: 8px;
  font-weight: var(--tina-font-weight-regular);
`
