import { useEffect, useState, useRef } from 'react';
import { Image } from 'react-native';

export function useImageDimensions(src) {
  const [result, setResult] = useState({loading: true})
  const mounted = useRef(true);

  useEffect(() => {
    return () => { mounted.current = false; }
  },[])

  useEffect(() => {
    try {
      let source = src;

      if (typeof source === 'number' && mounted.current) {
        const {width, height} = Image.resolveAssetSource(source)

        setResult({
          width,
          height,
          aspectRatio: width / height,
          loading: false,
        })

        return
      }

      if (typeof source === 'string') {
        source = { uri: source };
      }

      if (typeof source === 'object' && source.uri && mounted.current) {
        setResult({loading: true})

        Image.getSize(
          source.uri,
          (width, height) => {
            if (mounted.current) {
              setResult({
                width,
                height,
                aspectRatio: width / height,
                loading: false,
              });
            }
          },
          (error) => {
            if (mounted.current) setResult({error, loading: false})
          }
        )
        return
      }

      throw new Error('not implemented')
    } catch (error) {
      if (mounted.current) setResult({error, loading: false})
    }
  }, [src])

  return result
}
