/* eslint-disable no-restricted-globals */
import React, { useState, useRef, useEffect } from 'react';
import { useCMS } from 'tinacms';
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from '@reach/router';
import { Box, Text, Button, Menu, MenuItem, Modal } from '../ui';
import { useTheme } from '../ui/hooks';
import { useEditingLocale, DEFAULT_LOCALE } from '../editing/useEditingLocale';

const States = {
  reverting: 'reverting',
  saving: 'saving',
  pushing: 'pushing'
}

const Z_INDEX = 99999999999999;

export const EditToolbar = () => {
  const mounted = useRef(true);

  useEffect(() => {
    return () => { mounted.current = false; }
  },[])
  const cms = useCMS();
  const [state, setState] = useState(() => ({
    current: null,
    busy: false,
    error: false,
    success: false,
  }));

  const { busy, current: currentState } = state;
  useEffect(() => {
    if (!cms.enabled || process.env.NODE_ENV === 'production' || !mounted.current || !currentState || busy) return;
    let callFunc;
    if (currentState === States.saving) {
      callFunc = async () => {
        const success = await cms.api.repo.saveAll();
        if (mounted.current) {
          setState(prev => ({
            ...prev,
            current: null,
            busy: false,
            error: !success,
            success: success
          }))
        }
      }
    } else if (currentState === States.reverting) {
      callFunc = async () => {
        if (confirm(
          'Are you sure you want to revert all your edits to the last save? This will put you back to the state where you last saved.'
        )) {
          const success = await cms.api.repo.removeChanges();
          if (mounted.current) {
            setState(prev => ({
              ...prev,
              current: null,
              busy: false,
              error: !success,
              success: success
            }))
          }
        } else {
          setState(prev => ({
            ...prev,
            current: null,
            busy: false,
            error: false,
            success: false
          }))
        }
      }
    } else if (currentState === States.pushing) {
      callFunc = async () => {
        if (confirm(
          'Upload all your edits and deploy to preview? \n' +
          'Please wait at least 5 minutes to upload again after. \n' +
          'Preview link'
        )) {
          let success = await cms.api.repo.pushToDev();
          if (mounted.current) {
            if (success === 'needsSaving') {
              success = false;
              confirm(`Please save all changes first.`);
            }
            setState(prev => ({
              ...prev,
              current: null,
              busy: false,
              error: !success,
              success: success
            }))
          }
        } else {
          setState(prev => ({
            ...prev,
            current: null,
            busy: false,
            error: false,
            success: false
          }))
        }
      }
    }

    if (callFunc) {
      setState(prev => ({ ...prev, busy: true }));
      callFunc();
    } else {
      setState(prev => ({
        ...prev,
        busy: false,
        current: null
      }))
    }
  }, [busy, currentState, cms.api.repo, cms.enabled])

  const [langMenuOpen, setLangMenuState] = useState(false);
  const langButtonRef = useRef(null);
  const langMenuItemRef = useRef(null);
  const [optionMenuOpen, setOptionMenuState] = useState(false);
  const optionsButtonRef = useRef(null);

  const applyActionState = (actionState) => {
    if (optionMenuOpen) setOptionMenuState(false);
    if (busy || currentState) return;
    setState(prev => ({ ...prev, current: actionState }));
  }

  const logStatus = async () => {
    if (optionMenuOpen) setOptionMenuState(false);
    let data = await cms.api.repo.status();
    console.log('Git Status: ', data);
  }

  const theme = useTheme();
  const { pathname } = useLocation() || { pathname: '/' };
  const locale = useEditingLocale();
  const navPath = DEFAULT_LOCALE === locale.current ? pathname : pathname.substring(locale.current.length + 1);
  const localeNavTitle = `${locale.current}:  ${pathname === '/' || !navPath ? 'Home' : navPath}`;

  return cms.enabled && process.env.NODE_ENV !== 'production' ? (
    <Box
      position="fixed"
      top={0}
      left={0}
      minWidth="100%"
      width="100vw"
      maxWidth="100vw"
      height={65}
      bg="$gray.50"
      zIndex={Z_INDEX}
      border={{
        bottom: {
          width: 1,
          color: 'rgba(0,0,0, 0.1)'
        }
      }}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box flexDirection="row" gap={theme.spacing(2)} alignItems="center">
        <MenuButton onPress={() => cms.sidebar.isOpen = !cms.sidebar.isOpen} />
        <Button
          ref={langButtonRef}
          onPress={() => setLangMenuState(curr => !curr)}
          color="$gray.500"
          outlined
          small
          display={theme.breakpoints({ xs: 'none', sm: 'flex' })}
        >
          {localeNavTitle}
        </Button>
      </Box>
      <Box flexDirection="row" gap={theme.spacing(2)} pr="$4" alignItems="center">
        <Button
          color="$gray.500"
          outlined
          small
          onPress={() => applyActionState(States.reverting)}
          display={theme.breakpoints({ xs: 'none', sm: 'flex' })}
        >
          {state.busy && state.current === States.reverting ? '. . .' : 'Reset'}
        </Button>
        <Button
          onPress={() => applyActionState(States.saving)}
          color="$secondary"
        >
          {state.busy && state.current === States.saving ? '. . .' : 'Save All'}
        </Button>
        <Box ml={theme.spacing(2)} width={1} height={22} bg="$gray.200" />
        <Button
          ref={optionsButtonRef}
          onPress={() => setOptionMenuState(curr => !curr)}
          color="$gray.500"
          text
        >
          MORE
        </Button>
      </Box>
      <Menu
        open={optionMenuOpen}
        anchorNode={optionsButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorOffset={{ vertical: -4, horizontal: 0 }}
        onClose={() => setOptionMenuState(false)}
        zIndex={Z_INDEX + 1}
      >
        <MenuItem
          ref={langMenuItemRef}
          onPress={() => setLangMenuState(curr => !curr)}
          display={theme.breakpoints({ xs: 'flex', sm: 'none' })}
        >{localeNavTitle}</MenuItem>
        <MenuItem
          onPress={() => applyActionState(States.reverting)}
          display={theme.breakpoints({ xs: 'flex', sm: 'none' })}
        >Reset</MenuItem>
        <MenuItem
          onPress={() => applyActionState(States.pushing)}
        >Upload and Deploy</MenuItem>
        <MenuItem
          onPress={logStatus}
        >Log Git Status</MenuItem>
        <MenuItem
          onPress={() => {
            setOptionMenuState(false)
            cms.disable()
          }}
        >Exit CMS</MenuItem>
      </Menu>
      <LocaleNavMenu
        open={langMenuOpen}
        anchorNode={optionMenuOpen ? langMenuItemRef.current : langButtonRef.current}
        onClose={() => setLangMenuState(false)}
        zIndex={Z_INDEX + (optionMenuOpen ? 2 : 1)}
        onPressMenuItem={() => {
          if (optionMenuOpen) setOptionMenuState(false);
          setLangMenuState(false);
        }}
      />
      <Modal
        open={busy}
        BackdropProps={{ bg: theme.colors.opacity(theme.colors.white, 0.8) }}
        disableBackdropPress
        disableEscapeKeyDown
        zIndex={Z_INDEX + 4}
      >
        <Text xLarge zIndex={Z_INDEX + 3} color={theme.colors.gray['700']}>Just a sec...</Text>
      </Modal>
    </Box>
  ) : null
}

const MenuButton = ({ open, onPress }) => {
  const lineProps = {
    width: '100%',
    height: 2,
    borderRadius: '$circle',
    backgroundColor: '$gray.500',
  }

  const animations = {
    opened: { rotate: '45deg' },
    default: { rotate: '0deg' }
  }

  const hiddenLineAnimations = {
    opened: { ...animations.opened, scale: 1 },
    default: { ...animations.default, scale: 0 }
  }

  const animate = open ? 'opened' : 'default';

  const otherLineProps = {
    ...lineProps,
    animations: {
      opened: { scale: 0 },
      default: { scale: 1 }
    },
    animate
  }

  return (
    <Box
      height="100%"
      pad="$2"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      onPress={() => onPress()}
      disableAnimationDefaults
    >
      <Box
        width={25}
        gap={4}
        disableAnimationDefaults
        animations={animations}
        animate={animate}
      >
        <Box {...otherLineProps} disableAnimationDefaults />
        <Box {...lineProps} disableAnimationDefaults />
        <Box {...otherLineProps} disableAnimationDefaults />
      </Box>
      <Box
        position="absolute"
        width="2"
        height="25"
        borderRadius="$circle"
        bg="$gray.500"
        disableAnimationDefaults
        animations={hiddenLineAnimations}
        animate={animate}
      />
    </Box>
  );
}

const LocaleNavMenu = ({
  anchorNode,
  open,
  zIndex,
  onPressMenuItem,
  onClose,
}) => {
  const data = useStaticQuery(graphql`
    query allPagePaths {
      allPagesJson {
        edges {
          node {
            path
            locale
          }
        }
      }
    }
  `);

  const locale = useEditingLocale();
  const [navMenuState, setNavMenuState] = useState({
    anchorNode: null,
    open: false,
    locale: locale.current,
  });

  useEffect(() => {
    if (!open && navMenuState.open) {
      setNavMenuState(state => ({ ...state, open: false }));
    }
  }, [open, navMenuState.open]);

  const pathItems = React.useMemo(() => {
    const localePathEdges = data.allPagesJson.edges.filter(edge => edge.node.locale === navMenuState.locale);
    return localePathEdges.map((edge, i) => {
      const { node } = edge;
      if (node.path === '/dummy') {
        return null;
      }
      return (
        <MenuItem
          key={`localenavmenuitem${node.locale}${node.path}`}
          onPress={() => {
            setNavMenuState(state => ({ ...state, open: false }));
            if (onPressMenuItem) onPressMenuItem();
            locale.switchTo(navMenuState.locale, node.path);
          }}
        >{node.path === '/' ? 'Home' : node.path}</MenuItem>
      )
    })
  }, [data.allPagesJson.edges, navMenuState.locale, locale, onPressMenuItem])

  return (
    <>
      <Menu
        open={open}
        anchorNode={anchorNode}
        onClose={onClose}
        zIndex={zIndex}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorOffset={{ vertical: -8, horizontal: 0 }}
      >
        {locale.list.map(loc => (
          <LocaleMenuItem
            locale={loc}
            key={`localeMenuItem-${loc}`}
            setNavMenuState={setNavMenuState}
          >{loc}</LocaleMenuItem>
        ))}
      </Menu>
      <Menu
        open={navMenuState.open}
        anchorNode={navMenuState.anchorNode}
        onClose={() => {
          setNavMenuState(state => ({ ...state, open: false }))
          if (onClose) onClose();
        }}
        zIndex={zIndex + 1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorOffset={{ vertical: -8, horizontal: 0 }}
      >
        {pathItems}
      </Menu>
    </>
  )
}

const LocaleMenuItem = ({ locale, setNavMenuState }) => {
  const btnRef = useRef(null);
  return (
    <MenuItem
      ref={btnRef}
      onPress={() => {
        setNavMenuState(state => ({
          ...state,
          locale,
          open: true,
          anchorNode: btnRef.current
        }))
      }}
    >{locale}</MenuItem>
  )
}