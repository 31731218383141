import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { FieldsBuilder, FormBuilder } from '@tinacms/form-builder'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  AddIcon,
  DragIcon,
  ReorderIcon,
  TrashIcon,
  LeftArrowIcon,
} from '@tinacms/icons'
import { DragDropContext } from 'react-beautiful-dnd'
import {
  GroupPanel,
  PanelHeader,
  PanelBody,
  GroupListHeader,
  GroupListMeta,
  GroupLabel,
} from '@tinacms/fields'
import { Dismissible } from 'react-dismissible'
import { IconButton, Button } from '@tinacms/styles'
import { useFormPortal } from '@tinacms/react-forms'
import { FieldDescription } from './components/wrapFieldsWithMeta'
import { useJsonForms } from './useJsonForms';
import { Form } from '@tinacms/forms'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalPopup,
} from '@tinacms/react-modals'
import { useCMS } from 'tinacms';
import { usePageBlocksQuery } from '../blocks';

export const FormModal = ({ plugin, template, close, callback }) => {
  const cms = useCMS()
  const form = useMemo(
    () =>
      new Form({
        label: 'create-form',
        id: 'create-form-id',
        actions: [],
        fields: plugin.fields,
        onSubmit(values) {
          plugin.onSubmit({ ...values, template }, cms, callback).then(() => {
            // close()
          })
        },
      }),
    [
      // close,
      cms,
      plugin,
      template,
      callback
    ]
  )

  return (
    <Modal>
      <FormBuilder form={form}>
        {({ handleSubmit }) => {
          return (
            <ModalPopup>
              <ModalHeader close={close}>{plugin.name}</ModalHeader>
              <ModalBody
                onKeyPress={e =>
                  e.charCode === 13 ? (handleSubmit()) : null
                }
              >
                <FieldsBuilder form={form} fields={form.fields} />
              </ModalBody>
              <ModalActions>
                <Button onClick={close}>Cancel</Button>
                <Button onClick={handleSubmit} primary>
                  Create
                </Button>
              </ModalActions>
            </ModalPopup>
          )
        }}
      </FormBuilder>
    </Modal>
  )
}

const Blocks = ({ tinaForm, form, field, input }) => {
  const callback = React.useCallback(
    (newItem) => {
      if (newItem) {
        form.mutators.insert(field.name, 0, newItem);
      }
      setModalVisible(false)
    },
    [field.name, form.mutators]
  )

  const items = input.value || [] // items were originally used for mapping the blocks below (items.map and block = data[index], vs data.map(block)). Should check again and see what differs here
  // const keysTo = field.name.replace('rawJson', 'jsonNode');
  // const data = get(tinaForm.values, keysTo, []);
  const data = usePageBlocksQuery(items);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const addItem = React.useCallback(
    (name, template) => {
      setSelectedTemplate(template);
      setModalVisible(true);
      // return;
      // let obj = {}
      // if (typeof template.defaultItem === 'function') {
      //   obj = template.defaultItem()
      // } else {
      //   obj = template.defaultItem || {}
      // }
      // obj._template = name
      // form.mutators.insert(field.name, 0, obj)
    },
    []
  )

  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <GroupListHeader>
        <GroupListMeta>
          <GroupLabel>{field.label || field.name}</GroupLabel>
          {field.description && (
            <FieldDescription>{field.description}</FieldDescription>
          )}
        </GroupListMeta>
        <IconButton
          onClick={() => setVisible(true)}
          open={visible}
          primary
          small
        >
          <AddIcon />
        </IconButton>
        <BlockMenu open={visible}>
          <Dismissible
            click
            escape
            onDismiss={() => setVisible(false)}
            disabled={!visible}
          >
            <BlockMenuList>
              {Object.entries(field.templates).map(([name, template]) => (
                <BlockOption
                  key={name}
                  onClick={() => {
                    addItem(name, template)
                    setVisible(false)
                  }}
                >
                  {template.label}
                </BlockOption>
              ))}
            </BlockMenuList>
          </Dismissible>
        </BlockMenu>
      </GroupListHeader>
      <GroupListPanel>
        <ItemList>
          <Droppable droppableId={field.name} type={field.name}>
            {provider => (
              <div ref={provider.innerRef} className="edit-page--list-parent">
                {data.length === 0 && <EmptyState />}
                {data.map((block, index) => {
                  let template = null;
                  if (block && typeof block === 'object' && block._template) {
                    template = field.templates[block._template]
                  }

                  if (!template) {
                    return (
                      <InvalidBlockListItem
                        // NOTE: Supressing warnings, but not helping with render perf
                        key={`invalid${index}`}
                        index={index}
                        field={field}
                        tinaForm={tinaForm}
                      />
                    )
                  }

                  const itemProps = (item) => {
                    if (!template.itemProps) return {}
                    return template.itemProps(item)
                  }
                  return (
                    <BlockListItem
                      // NOTE: Supressing warnings, but not helping with render perf
                      key={`${index}-${block.blockId}`}
                      block={block}
                      template={template}
                      index={index}
                      field={field}
                      tinaForm={tinaForm}
                      {...itemProps(block)}
                    />
                  )
                })}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </ItemList>
      </GroupListPanel>
      {modalVisible && field.createPlugin ? (
        <FormModal
          plugin={field.createPlugin}
          template={selectedTemplate}
          close={() => setModalVisible(false)}
          callback={callback}
        />
      ): null}
    </>
  )
}

const EmptyState = () => <EmptyList>There are no items</EmptyList>


const BlockListItem = ({
  label,
  tinaForm,
  field,
  index,
  template,
  block,
}) => {
  const FormPortal = useFormPortal()
  const [isExpanded, setExpanded] = React.useState(false)
  const cms = useCMS();

  const deleteItemRef = React.useRef(null);
  if (!deleteItemRef.current) {
    deleteItemRef.current = async (relPath, cms) => {
      console.log('deleting section located at:', relPath);
      // TODO: see deleting of a page and include the check that this block isn't used by another page...
      try {
        await cms.api.git.onDelete({ relPath });
        console.log('successfully deleted page section located at:', relPath)
      } catch (error) {
        console.log('Error deleting page section from disk', error);
      }
    }
  }

  const removeItem = React.useCallback(async () => {
    /* eslint-disable-next-line */
    if (!confirm(`Are you sure you want to delete this section?`)) {
      return;
    }
    const relPath = block.fileRelativePath;
    tinaForm.mutators.remove(field.name, index)
    console.log('deleting section located at:', relPath);

    deleteItemRef.current(relPath, cms);

  }, [tinaForm, field, index, cms, block.fileRelativePath])
  return (
    <Draggable
      key={index}
      type={field.name}
      draggableId={`${field.name}.${index}`}
      index={index}
    >
      {(provider, snapshot) => (
        <>
          <ItemHeader
            ref={provider.innerRef}
            isDragging={snapshot.isDragging}
            {...provider.draggableProps}
            {...provider.dragHandleProps}
          >
            <DragHandle />
            <ItemClickTarget onClick={() => setExpanded(true)}>
              <GroupLabel>{label || template.label}</GroupLabel>
            </ItemClickTarget>
            <DeleteButton onClick={removeItem}>
              <TrashIcon />
            </DeleteButton>
          </ItemHeader>
          <FormPortal>
            <Panel
              isExpanded={isExpanded}
              setExpanded={setExpanded}
              field={field}
              item={block}
              index={index}
              tinaForm={tinaForm}
              label={label || template.label}
              template={template}
            />
          </FormPortal>
        </>
      )}
    </Draggable>
  )
}

const Panel = function Panel({
  setExpanded,
  isExpanded,
  tinaForm,
  field,
  index,
  label,
  template,
  item,
}) {
  // const fields = React.useMemo(() => {
  //   if (!template.fields) return []
  //
  //   return template.fields.map((subField) => ({
  //     ...subField,
  //     name: `${field.name}.${index}.${subField.name}`,
  //   }))
  // }, [field.name, index, template.fields])
  const [, form] = useJsonForms(item, template);

  // let form = null;
  // if (tinaForm.childForms) {
  //   if (tinaForm.childForms[item.fileRelativePath]) {
  //     form = tinaForm.childForms[item.fileRelativePath];
  //   } else {
  //     // tinaForm.childForms[item.fileRelativePath] = jsonForm;
  //   }
  // } else {
  //   tinaForm.childForms = { };
  //   // tinaForm.childForms[item.fileRelativePath] = jsonForm;
  // }

  const moveArrayItem = React.useCallback(
    (result) => {
      if (!result.destination || !form) return
      const name = result.type
      form.mutators.move(
        name,
        result.source.index,
        result.destination.index
      )
    },
    [form]
  )
if (!form) return null;
  return (
    <GroupPanel isExpanded={isExpanded}>
      <PanelHeader onClick={() => setExpanded(false)}>
        <LeftArrowIcon />
        <GroupLabel>{label}</GroupLabel>
      </PanelHeader>
      <PanelBody>
        <DragDropContext onDragEnd={moveArrayItem}>
        <FormBuilder form={form}>
          {() => {
            if (isExpanded) {
              return <FieldsBuilder form={form} fields={form.fields} />;
            }
            return null;
          }}
        </FormBuilder>
        </DragDropContext>
      </PanelBody>
    </GroupPanel>
  )
}

const InvalidBlockListItem = ({
  tinaForm,
  field,
  index,
}) => {
  const removeItem = React.useCallback(() => {
    tinaForm.mutators.remove(field.name, index)
  }, [tinaForm, field, index])

  return (
    <Draggable
      key={index}
      type={field.name}
      draggableId={`${field.name}.${index}`}
      index={index}
    >
      {(provider, snapshot) => (
        <ItemHeader
          ref={provider.innerRef}
          isDragging={snapshot.isDragging}
          {...provider.draggableProps}
          {...provider.dragHandleProps}
        >
          <DragHandle />
          <ItemClickTarget>
            <GroupLabel error>Invalid Block</GroupLabel>
          </ItemClickTarget>
          <DeleteButton onClick={removeItem}>
            <TrashIcon />
          </DeleteButton>
        </ItemHeader>
      )}
    </Draggable>
  )
}

const EmptyList = styled.div`
  text-align: center;
  border-radius: var(--tina-radius-small);
  background-color: var(--tina-color-grey-2);
  color: var(--tina-color-grey-4);
  line-height: 1.35;
  padding: 12px 0;
  font-size: var(--tina-font-size-2);
  font-weight: var(--tina-font-weight-regular);
`

const BlockMenu = styled.div`
  min-width: 192px;
  border-radius: var(--tina-radius-big);
  border: 1px solid #efefef;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1);
  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease-out;
  transform-origin: 100% 0;
  box-shadow: var(--tina-shadow-big);
  background-color: white;
  overflow: hidden;
  z-index: var(--tina-z-index-1);
  ${props =>
    props.open &&
    css`
      opacity: 1;
      pointer-events: all;
      transform: translate3d(0, 36px, 0) scale3d(1, 1, 1);
    `};
`

const BlockMenuList = styled.div`
  display: flex;
  flex-direction: column;
`

const BlockOption = styled.button`
  position: relative;
  text-align: center;
  font-size: var(--tina-font-size-0);
  padding: var(--tina-padding-small);
  font-weight: var(--tina-font-weight-regular);
  width: 100%;
  background: none;
  cursor: pointer;
  outline: none;
  border: 0;
  transition: all 85ms ease-out;
  &:hover {
    color: var(--tina-color-primary);
    background-color: var(--tina-color-grey-1);
  }
  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`

const ItemClickTarget = styled.div`
  flex: 1 1 0;
  min-width: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`

const GroupListPanel = styled.div`
  max-height: initial;
  position: relative;
  height: auto;
  margin-bottom: 24px;
  border-radius: var(--tina-radius-small);
  background-color: var(--tina-color-grey-2);
`

const ItemList = styled.div``

const ItemHeader = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: white;
  border: 1px solid var(--tina-color-grey-2);
  margin: 0 0 -1px 0;
  overflow: visible;
  line-height: 1.35;
  padding: 0;
  font-size: var(--tina-font-size-2);
  font-weight: var(--tina-font-weight-regular);

  ${GroupLabel} {
    color: var(--tina-color-grey-10);
    align-self: center;
    max-width: 100%;
  }

  svg {
    fill: var(--tina-color-grey-3);
    width: 20px;
    height: auto;
    transition: fill 85ms ease-out;
  }

  &:hover {
    svg {
      fill: var(--tina-color-grey-8);
    }
    ${GroupLabel} {
      color: var(--tina-color-primary);
    }
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:nth-last-child(2) {
    border-radius: 0 0 4px 4px;
    &:first-child {
      border-radius: var(--tina-radius-small);
    }
  }

  ${p =>
    p.isDragging &&
    css`
      border-radius: var(--tina-radius-small);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);

      svg {
        fill: var(--tina-color-grey-8);
      }
      ${GroupLabel} {
        color: var(--tina-color-primary);
      }

      ${DragHandle} {
        svg:first-child {
          opacity: 0;
        }
        svg:last-child {
          opacity: 1;
        }
      }
    `};
`

const DeleteButton = styled.button`
  text-align: center;
  flex: 0 0 auto;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 12px 8px;
  margin: 0;
  transition: all var(--tina-timing-short) ease-out;
  &:hover {
    background-color: var(--tina-color-grey-1);
  }
`

const DragHandle = styled(function DragHandle({ ...styleProps }) {
  return (
    <div {...styleProps}>
      <DragIcon />
      <ReorderIcon />
    </div>
  )
})`
  margin: 0;
  flex: 0 0 auto;
  width: 32px;
  position: relative;
  fill: inherit;
  padding: 12px 0;
  transition: all 85ms ease-out;
  &:hover {
    background-color: var(--tina-color-grey-1);
    cursor: grab;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate3d(-50%, -50%, 0);
    transition: all var(--tina-timing-short) ease-out;
  }
  svg:last-child {
    opacity: 0;
  }
  *:hover > & {
    svg:first-child {
      opacity: 0;
    }
    svg:last-child {
      opacity: 1;
    }
  }
`

export const JsonFormBlocksFieldPlugin = {
  name: 'json-form-blocks',
  Component: Blocks,
}
