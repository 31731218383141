import { Platform } from 'react-native';

const isNative = Platform.OS !== 'web';

const skip = {
  borderBottomStyle: isNative,
  borderTopStyle: isNative,
  borderLeftStyle: isNative,
  borderRightStyle: isNative,
};

export { skip };
