import { Platform } from 'react-native';
// TODO: verify order will be maintained as the array gets smaller and items then added, but never reaches 0 again

const isBrowser = Platform.OS === 'web' && typeof window !== `undefined`;

export class ModalManager {
  open = [];
  disableScrollLockModals = [];

  getIndex(modalId) {
    const index = modalId ? this.open.indexOf(modalId) : -1;
    return index;
  }

  isTopModal(modalId) {
    return this.getIndex(modalId) === this.open.length - 1;
  }

  addToTop(modalId, callback, options) {
    if (!modalId) return;
    const index = this.getIndex(modalId);
    if (index !== -1) {
      this.open.splice(index, 0, null);
    }
    this.open.push(modalId);
    if (callback && typeof callback === 'function') {
      callback();
    }
    this.handleOptionsForModalId(modalId, options);
  }

  add(modalId, callback, options) {
    if (!modalId) return;
    this.remove(modalId);
    this.open.push(modalId);
    if (callback && typeof callback === 'function') {
      callback();
    }
    this.handleOptionsForModalId(modalId, options);
  }

  remove(modalId, callback) {
    if (!modalId) return;
    const index = this.getIndex(modalId);
    if (index !== -1) {
      this.open.splice(index, 1);
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
    this.cleanupOptionsForModalId(modalId);
  }

  handleOptionsForModalId(modalId, options) {
    if (!modalId) return;
    const optionIndex = this.disableScrollLockModals.indexOf(modalId);
    if (options && options.disableScrollLock === false) {
      if (optionIndex === -1) {
        this.disableScrollLockModals.push(modalId);
      }
      this.disableScroll();
    } else if (optionIndex !== -1) {
      this.cleanupOptionsForModalId(modalId);
    }
  }

  cleanupOptionsForModalId(modalId) {
    if (modalId) {
      const optionIndex = this.disableScrollLockModals.indexOf(modalId);
      if (optionIndex !== -1) {
        this.disableScrollLockModals.splice(optionIndex, 1);
      }
    }
    if (this.open.length === 0 || this.disableScrollLockModals.length === 0) {
      this.disableScrollLockModals = [];
      this.enableScroll();
    }
  }

  enableScroll() {
    if (!isBrowser) return;
    document.body.style.paddingRight = `0px`;
    document.body.style.overflowY = 'scroll';
  }

  disableScroll() {
    if (!isBrowser) return;
    if (isOverflowing()) {
      const scrollbarSize = getScrollbarSize();
      document.body.style.paddingRight = `${scrollbarSize}px`;
    }
    document.body.style.overflowY = 'hidden';
  }
}

function isOverflowing() {
  if (!isBrowser) return false;

  return window.innerWidth > document.documentElement.clientWidth;
}

function getScrollbarSize() {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.overflow = 'scroll';

  document.body.appendChild(scrollDiv);
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarSize;
}