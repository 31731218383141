import React from 'react';
import { wrapFieldsWithMeta } from './components/wrapFieldsWithMeta';
import styled from 'styled-components';
import { FieldsGroup, FieldsBuilder } from './FieldsBuilder';

export const RowGroup = wrapFieldsWithMeta(({ tinaForm, field }) => {
  const fields = React.useMemo(() => {
    return field.fields.map(subField => ({
      ...subField,
      name: `${field.name}.${subField.name}`
    }));
  }, [field.fields, field.name]);

  return <FieldsBuilder form={tinaForm} fields={fields} wrapper={CustomFieldsGroup} />
})

export const RowGroupFieldPlugin = {
  name: 'row-group',
  Component: RowGroup,
}

const CustomFieldsGroup = styled(FieldsGroup)`
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: nowrap;
`
