import { flatten } from './utils';
import { parseStyles } from '../system';
import { isNull } from '../utils';

const APPLIES_STYLE = 'isApplyStyle';
const CONFIG = 'isConfig';

const animationPropTypes = {
  initial: APPLIES_STYLE,
  enter: APPLIES_STYLE,
  update: APPLIES_STYLE,
  leave: APPLIES_STYLE,
  from: APPLIES_STYLE,
  to: APPLIES_STYLE,
  delay: CONFIG,
  trail: CONFIG,
  unique: CONFIG,
  onDestroyed: CONFIG,
  reset: CONFIG,
  config: CONFIG,
  immediate: CONFIG,
  reverse: CONFIG,
  onStart: CONFIG,
  onRest: CONFIG,
  onFrame: CONFIG,
};

const transitionStyleKeyProps = {
  enter: true,
  update: true,
  leave: true,
};

function resolveAnimation(animationProps, styleProps, forTransition = false) {
  const a = flatten(animationProps, styleProps);
  if (!a) {
    return null;
  }

  const animation = {};
  const stylesToAdd = {};
  let hasStylesToAdd = false;

  for (const prop in a) {
    if (animationPropTypes[prop]) {
      if (isNull(a[prop])) {
        continue;
      } else if (animationPropTypes[prop] === CONFIG) {
        animation[prop] = a[prop];
      } else {
        if (forTransition && transitionStyleKeyProps[prop]) {
          if (typeof a[prop] === 'function') {
            // TODO: ^^ any APPLIES_STYLE thing that is a function should be handled like this.
            // maybe wrap the function in a function?. otherwise expect the styles parsed beforehand.
            // ^ same goes for arrays. See useTransition/useSpring chaining animations etc...
            animation[prop] = a[prop];
            continue;
          } else {
            animation[prop] = resolveAnimation(a[prop], styleProps);
          }
        }
        animation[prop] = parseStyles(a[prop], styleProps);
      }
    } else {
      stylesToAdd[prop] = a[prop];
      hasStylesToAdd = true;
    }
  }

  if (hasStylesToAdd && !forTransition) {
    animation.to = {
      ...parseStyles(stylesToAdd, styleProps),
      ...animation.to,
    };
  }
  return animation;
}

export { resolveAnimation };
