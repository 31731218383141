import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldsBuilder, FieldsGroup } from './FieldsBuilder'


const ToggleEnabled = ({
  tinaForm,
  field,
  input,
  description,
  error,
  margin = true,
}) => {
  const enabled = !!(input.value || input.checked)

  const editWrapperProps = { };
  if (!enabled) {
    editWrapperProps.onClick = (event) => {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  const fields = useMemo(() => {
    return field.fields.map(subField => ({
      ...subField,
      name: field.name.replace('_enabled', '')
    }))
  }, [field.fields, field.name])
  return (
    <FieldWrapper>
      <FieldToggle>
        <FieldEditWrapper {...editWrapperProps} $enabled={enabled}>
          <FieldsBuilder wrapper={CustomFieldsGroup} form={tinaForm} fields={fields} />
        </FieldEditWrapper>
        <Toggle {...field} input={input} />
      </FieldToggle>
      {error && <FieldError>{error}</FieldError>}
    </FieldWrapper>
  )
}

export const ToggleEnabledFieldPlugin = {
  name: 'toggleEnabled',
  type: 'checkbox',
  Component: ToggleEnabled,
}

const FieldEditWrapper = styled.div`
  display: flex;
  flex: 1 100%;
  height: 100%;
  opacity: ${p => p.$enabled ? 1 : 0.5};
  pointer-events: ${p => (!p.$enabled ? 'none' : 'inherit')};
`

const CustomFieldsGroup = styled(FieldsGroup)`
  padding: 10px 20px 10px 0px;
`

const FieldToggle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`
const Toggle = props => {
  const checked = !!(props.input.value || props.input.checked)
  return (
    <ToggleElement>
      <ToggleInput id={props.name} type="checkbox" {...props.input} />
      <ToggleLabel htmlFor={props.name} role="switch" disabled={props.disabled}>
        <ToggleSwitch checked={checked}>
          <span></span>
        </ToggleSwitch>
      </ToggleLabel>
    </ToggleElement>
  )
}

const ToggleElement = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 40px;
  height: 24px;
  margin: 0;
  padding-top: 24px;
`

const ToggleLabel = styled.label`
  background: none;
  color: inherit;
  padding: 0;
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  outline: none;
  width: 40px;
  height: 24px;
  pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};
`

const ToggleSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: var(--tina-radius-big);
  background-color: white;
  border: 1px solid var(--tina-color-grey-2);
  pointer-events: none;
  margin-left: -2px;
  span {
    position: absolute;
    border-radius: var(--tina-radius-big);
    left: 2px;
    top: 50%;
    width: calc(24px - 6px);
    height: calc(24px - 6px);
    background: ${p =>
      p.checked ? 'var(--tina-color-primary)' : 'var(--tina-color-grey-4)'};
    border: 1px solid
      ${p =>
        p.checked
          ? 'var(--tina-color-primary-dark)'
          : 'var(--tina-color-grey-5)'};
    transform: translate3d(${p => (p.checked ? '16px' : '0')}, -50%, 0);
    transition: all 150ms ease-out;
    box-shadow: var(--tina-shadow-big);
  }
`

const ToggleInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 24px;
  opacity: 0;
  margin: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: auto;

  &:hover {
    + ${ToggleLabel} ${ToggleSwitch} {
      box-shadow: 0 0 0 2px var(--tina-color-grey-3);
    }
  }

  &:focus {
    + ${ToggleLabel} ${ToggleSwitch} {
      box-shadow: 0 0 0 2px var(--tina-color-primary);
    }
  }
`

const FieldWrapper = styled.div`
  position: relative;
  ${props => props.margin && css`margin-bottom: var(--tina-padding-big);`};
`

const FieldLabel = styled.label`
  all: unset;
  font-family: 'Inter', sans-serif;
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.35;
  color: var(--tina-color-grey-8);
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const FieldDescription = styled.span`
  all: unset;
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: var(--tina-font-size-0);
  font-style: italic;
  font-weight: lighter;
  color: var(--tina-color-grey-6);
  padding-top: 4px;
  white-space: normal;
  margin: 0;
`

const FieldError = styled.span`
  display: block;
  color: red;
  font-size: var(--tina-font-size-1);
  margin-top: 8px;
  font-weight: var(--tina-font-weight-regular);
`
