import { isObject } from '../../utils';

export const parseDefault = (value) => {
  // if the style is an array then assume the user wants the theme style array at [0] as a default when no index provided
  // TODO: after commenting out below, verify this still works
  // if (Array.isArray(value) && value.length) return value[0];
  if (isObject(value)) {
    if (value['default']) return value['default'];
    // TODO: after commenting out below, verify this still works
    // if (value['1']) return value['1'];
    // TODO: throw warning here. must specifify key or a default in theme
  }
  return value;
}

