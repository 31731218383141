/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react'
import { useLayoutEffect } from './useLayoutEffect'
// TODO: make this react native compatable...



function getWindowScrollPosition() {
  const isBrowser = typeof window !== `undefined`
  const win = typeof window === 'undefined' ? null : window
  const y = win.scrollY !== undefined
    ? win.scrollY
    : (win.pageYOffset !== undefined ? win.pageYOffset : 0);
  const x = win.scrollX !== undefined
    ? win.scrollX
    : (win.pageXOffset !== undefined ? win.pageXOffset : 0);
  return { x, y };
}

function getScrollPosition({ element, useWindow }) {
  const isBrowser = typeof window !== `undefined`
  const win = typeof window === 'undefined' ? null : window
  if (!isBrowser) return { x: 0, y: 0 }

  if (element || !useWindow) {
    const target = element ? element.current : document.body
    const position = target.getBoundingClientRect()
    return { x: position.left, y: position.top };
  }

  return getWindowScrollPosition();
}

export function useScrollPositionEffect(effect, deps, element, useWindow, wait) {
  const isBrowser = typeof window !== `undefined`
  const win = typeof window === 'undefined' ? null : window
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    if (!isBrowser) {
      return
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    win.addEventListener('scroll', handleScroll)

    return () => {
      win.removeEventListener('scroll', handleScroll);
      throttleTimeout && clearTimeout(throttleTimeout);
    }
  }, deps)
}

useScrollPositionEffect.defaultProps = {
  deps: [],
  element: false,
  useWindow: true,
  wait: null,
}
