import React from 'react';
import equal from 'fast-deep-equal/react';
import { Content, BackgroundImage } from '../../ui';
import { useTheme, useMatchBreakpoint, useMemoCompare } from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { GridBoxesItemBlock, gridBoxesItemBlockTemplate } from './GridBoxesItemBlock';
import { InlineBlocks } from '../InlineBlocks';
import { BlocksControls } from '../BlocksControls';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
  alignContentOptions
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';



export const GridBoxesBlock = memo(({ data, index, containerBlock, ...rest }) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const direction = useMatchBreakpoint({ xs: 'column', sm: 'row' });
  const blockStyles = { ...settings.sizeStyle, ...settings.alignSelfStyle, ...settings.overflowStyle, ...settings.shadowStyle };
  if (settings.backgroundColor) blockStyles.backgroundColor = settings.backgroundColor;
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;

  const itemProps = {
    numColumns: settings.numColumns || "2",
    containerBlock: {
      direction,
      backgroundColor: settings.backgroundColor,
    }
  }

  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      renderAs={Content}
      {...blockStyles}
    >
      {settings.backgroundImage && settings.backgroundImage.src ? <BackgroundImage {...settings.backgroundImage} /> : null}
      <InlineBlocks
        name="gridItems"
        blocks={GRID_ITEMS_BLOCKS}
        itemProps={itemProps}
        direction={direction}
        wrapperProps={{
          width: '100%',
          height: '100%',
          flexDirection: direction,
          padding: settings.padding,
          alignItems: 'stretch',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
        }}
      />
    </BlocksControls>
  )
})

const GRID_ITEMS_BLOCKS = {
  [gridBoxesItemBlockTemplate.type]: {
    Component: GridBoxesItemBlock,
    template: gridBoxesItemBlockTemplate
  }
}

const gridBoxesSettingFields = [
  {
    name: 'numColumns',
    label: 'Items Per Row',
    component: 'select',
    options: ['1', '2', '3', '4'],
  },
  layoutFields.unitBased.width,
  layoutFields.alignSelf,
  layoutFields.padding,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]

const defaultGridBoxesSettings = {
  numColumns: "2",
  width: {
    value: '100',
    unit: '%',
  },
  alignSelf: settingsDefaultItems.alignSelf,
  padding: settingsDefaultItems.padding,
  backgroundColor: settingsDefaultItems.backgroundColor,
  opacity: settingsDefaultItems.opacity,
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  backgroundImage: settingsDefaultItems.backgroundImage,
  shadow: settingsDefaultItems.shadow,
  clipContent: settingsDefaultItems.clipContent
}

export const gridBoxesBlockTemplate = {
  type: 'gridBoxesBlockTemplate',
  name: 'gridBoxesBlock',
  key: 'grid-list',
  label: 'Grid Boxes',
  component: 'grouped',
  defaultItem: {
    gridItems: [],
    settings: {
      sm: defaultGridBoxesSettings,
      xs: {
        ...applyEnabled({
          ...defaultGridBoxesSettings,
          numColumns: '1',
        }, false, ['padding', 'border', 'borderRadius', 'backgroundImage', 'shadow']),
        numColumns_enabled: true,
      },
    },
  },
  fields: [
    {
      name: 'gridItems',
      label: 'Grid Items',
      component: 'blocks',
      templates: {
        gridBoxesItemBlockTemplate,
      },
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: gridBoxesSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: gridBoxesSettingFields
        })
      ]
    }
  ]
}
