import { useState, useCallback, useEffect, useRef } from 'react'

export function useLayout() {
  const mounted = useRef(true);

  useEffect(() => {
    return () => { mounted.current = false; }
  },[])

  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  const onLayout = useCallback((e) => {
    if (mounted.current) {
      setLayout(e.nativeEvent.layout);
    }
  }, [])

  return {
    onLayout,
    ...layout,
  }
}
