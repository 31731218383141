import React from 'react';
import { InlineTextarea } from '../InlineTextarea';
import { Overline } from '../../ui';
import { BlocksControls } from '../BlocksControls';
import {
  textSettingFields,
  defaultTextBlockSettings,
  useTextBlockSettings,
} from '../TextBlock';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { applyEnabledToggles, applyEnabled } from '../fieldConfigs';
import { memo } from '../../ui/utils';

export const OverlineBlock = memo(({
  index,
  data,
  containerBlock = { },
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const textProps = useTextBlockSettings(settings, containerBlock);
  return (
    <Overline {...textProps}>
      <BlocksControls index={index}>
        <InlineTextarea uppercase={textProps.uppercase || false} name="text" focusRing={false} />
      </BlocksControls>
    </Overline>
  )
})

const defaultOverlineSettings = {
  ...defaultTextBlockSettings,
  bolded: true,
  textSize: 'xLarge',
  textSpaceAfter: true,
  uppercased: true,
}

export const overlineBlockTemplate = {
  type: 'overlineBlockTemplate',
  name: 'overline-block-template',
  key: 'overline-block',
  label: 'Overline',
  component: 'grouped',
  defaultItem: {
    text: 'Overline text',
    settings: {
      sm: defaultOverlineSettings,
      xs: applyEnabled(defaultOverlineSettings, false, ['padding'])
    },
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'textarea'
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: textSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: textSettingFields
        })
      ]
    }
  ]
}
