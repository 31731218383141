import * as React from 'react'
import { useCMS } from '@tinacms/react-core'
import { Field as FinalField } from 'react-final-form'
import styled from 'styled-components'

export function FieldsBuilder({ form, fields, wrapper }) {
  const cms = useCMS()
  const Wrapper = wrapper || FieldsGroup;
  return (
    <Wrapper>
      {fields.map((field) => {
        if (field.component === null) return null

        const plugin = cms.plugins
          .findOrCreateMap('field')
          .find(field.component)

        let type;
        if (plugin && plugin.type) {
          type = plugin.type
        }

        const parse = getProp('parse', field, plugin)
        const validate = getProp('validate', field, plugin)

        let format = field.format

        if (!format && plugin && plugin.format) {
          format = plugin.format
        }

        let defaultValue = field.defaultValue

        if (!parse && plugin && plugin.defaultValue) {
          defaultValue = plugin.defaultValue
        }

        return (
          <FinalField
            name={field.name}
            key={field.name}
            type={type}
            parse={
              parse
                ? (value, name) => parse(value, name, field)
                : undefined
            }
            format={
              format
                ? (value, name) => format(value, name, field)
                : undefined
            }
            defaultValue={defaultValue}
            validate={(value, values, meta) => {
              if (validate) {
                return validate(value, values, meta, field)
              }
            }}
          >
            {fieldProps => {
              if (
                typeof field.component !== 'string' &&
                field.component !== null
              ) {
                return (
                  <field.component
                    {...fieldProps}
                    form={form.finalForm}
                    tinaForm={form}
                    field={field}
                  />
                )
              }

              if (plugin) {
                return (
                  <plugin.Component
                    {...fieldProps}
                    form={form.finalForm}
                    tinaForm={form}
                    field={field}
                  />
                )
              }

              return <p>Unrecognized field type</p>
            }}
          </FinalField>
        )
      })}
    </Wrapper>
  )
}

export const FieldsGroup = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 20px 0 20px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto !important;
`


function getProp(
  name,
  field,
  plugin
) {
  let prop = field[name]
  if (!prop && plugin && plugin[name]) {
    prop = plugin[name]
  }
  return prop
}
