import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { Heading } from '../Heading';
import { toolbarFormatYear, toolbarFormatDate } from './formats';

const DatePickerToolbar = React.forwardRef(function DatePickerToolbar(props, ref) {
  const {
    date,
    view,
    views,
    changeView,
    initialView,
    background,
    backgroundColor,
    bg = '$primary',
    rowWidth,
    rowPadding,
    columnPadding,
    formatYear = toolbarFormatYear,
    formatDate = toolbarFormatDate,
    renderYear,
    renderDate,
    onPress,
    alignItems = 'flex-start',
    justifyContent = 'center',
    ...rest
  } = props;

  const color = background || backgroundColor || bg;

  const handlePress = onPress
    ? onPress(date, props)
    : changeView && initialView
    ? () => changeView(initialView, date)
    : null;

  return (
    <Box
      ref={ref}
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg={color}
      {...rest}
    >
      <Box
        width={rowWidth}
        justifyContent={justifyContent}
        alignItems={alignItems}
        py="$2"
        px={columnPadding}
        onPress={handlePress}
      >
        {renderYear ? (
          renderYear(date, { formatYear, onColor: color })
        ) : (
          <Text small dim bold onColor={color} selectable={false}>
            {formatYear && formatYear(date)}
          </Text>
        )}
        {renderDate ? (
          renderDate(date, { formatDate, onColor: color })
        ) : (
          <Heading level={3} onColor={color} selectable={false}>
            {formatDate && formatDate(date)}
          </Heading>
        )}
      </Box>
    </Box>
  );
});

export { DatePickerToolbar };
