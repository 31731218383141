import { wrapFieldsWithMeta } from './components/wrapFieldsWithMeta'
import { Select } from './components/Select'

export const SelectField = wrapFieldsWithMeta(Select)

export const SelectFieldPlugin = {
  name: 'select',
  type: 'select',
  Component: SelectField,
  parse: value => value || '',
}
