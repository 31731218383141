import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createStyles } from './createStyles';
import {
  filterProps,
  hoist,
  getComponentName,
  useProps as defaultUseProps,
} from './utils';
import { uniqueId, distinctArray } from '../utils';
import { useTheme } from '../hooks';

const withStyles = (styleDef, options = { }) => Component => {
  const name = options.name ? options.name : getComponentName(Component);
  const useStyles = createStyles(styleDef, { ...options, name });
  let filterPropsOption = options.filterProps;
  if (filterPropsOption && Array.isArray(filterPropsOption)) {
    filterPropsOption = distinctArray(filterPropsOption);
  }
  const useProps = options.useProps || defaultUseProps;
  // ^^ useProps is useful if a hook is needed or props need to be modified prior to useStyles
  const forward = options.forward;
  // ^^ forward skips resolving styles and preserves the style prop and passes the styleDef in styles
  // for the child component to resolve. This is mostly useful in passing root with a different name and merging that into the next withStyles/styled
  const preserveStyleProp = options.preserveStyleProp === true;
  // ^^ if true then styles.root will not be automatically placed into the style prop
  // in which case it is up to the component to handle passed style prop and styles.root
  const WithStyles = React.forwardRef(function WithStyles(p, ref) {
    const theme = useTheme();
    let { props: overrideProps } = theme.overrides[name] || {};
    const props = useProps({
      ...Component.defaultProps,
      ...overrideProps,
      ...p,
    });
    const styles = useStyles(props);

    let computedProps = null;
    if (forward) {
      const filteredProps =
        typeof filterPropsOption === 'function'
          ? filterPropsOption(props)
          : filterProps(props, filterPropsOption);
      computedProps = { ...filteredProps, styles };
    } else {
      let computedStyle = null;
      if (preserveStyleProp) {
        computedStyle = props.style;
      } else {
        if (props.style) {
          if (Array.isArray(props.style)) {
            computedStyle = [styles.root, ...props.style];
          } else {
            computedStyle = [styles.root, props.style];
          }
        } else if (styles.root) {
          computedStyle = styles.root;
        }
      }

      const filteredProps =
        typeof filterPropsOption === 'function'
          ? filterPropsOption(props)
          : filterProps(props, filterPropsOption);

      computedProps = {
        ...filteredProps,
        styles,
        ...styles.props.root,
        style: computedStyle,
      };
    }

    return <Component ref={ref} {...computedProps} />;
  })

  // TODO: only hoist what's necessary for production
  WithStyles.propTypes = { styles: PropTypes.any }
  WithStyles.displayName = `WithStyles(${name})`;
  WithStyles.name = name;
  WithStyles.component = Component;
  WithStyles.styles = styleDef;
  WithStyles.useStyles = useStyles;

  WithStyles.toString = () => WithStyles.name;

  hoist(WithStyles, Component, {
    displayName: true,
    name: true,
    component: true,
    styles: true,
    useStyles: true
  });

  WithStyles.withProps = (objOrFunc, o = { }) => {
    const n = o.name || uniqueId(`withProps(${WithStyles.name})`);
    const WithProps = React.forwardRef(function WithProps(props, ref) {
      const theme = useTheme();
      let { props: overrideProps } = theme.overrides[n] || {};
      const computedProps =
        typeof objOrFunc === 'function'
          ? objOrFunc({ ...overrideProps, ...props })
          : { ...objOrFunc, ...overrideProps, ...props };
      return <WithStyles ref={ref} {...computedProps} />
    });
    WithProps.name = n;
    WithProps.displayName = n;

    return (withStyles({}, { ...o, name: n, forward: true })(WithProps));
  }

  return WithStyles;
}

export { withStyles }
