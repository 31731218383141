import {
  any,
  arrayOf,
  shape,
  string,
  number,
  oneOfType,
} from 'prop-types';

export const SystemPropTypes = {
  variant: any,
}

export const PaddingPropTypes = {
  padding: any,
  pad: any,
  p: any,
  paddingHorizontal: any,
  paddingX: any,
  padX: any,
  px: any,
  paddingVertical: any,
  paddingY: any,
  padY: any,
  py: any,
  paddingTop: any,
  padTop: any,
  pTop: any,
  pt: any,
  paddingBottom: any,
  padBottom: any,
  pBottom: any,
  pb: any,
  paddingLeft: any,
  padLeft: any,
  pLeft: any,
  pl: any,
  paddingRight: any,
  padRight: any,
  pRight: any,
  pr: any,
};

export const FlexPropTypes = {
  flex: any,
  flexDirection: any,
  flexGrow: any,
  flexShrink: any,
  flexBasis: any,
  flexWrap: any,
  justifyContent: any,
  alignItems: any,
  alignContent: any,
  alignSelf: any,
};

export const PositionPropTypes = {
  position: any,
  top: any,
  bottom: any,
  left: any,
  right: any,
  zIndex: any,
};

export const LayoutPropTypes = {
  display: any,
  overflow: any,
  // flex
  ...FlexPropTypes,
  // position
  ...PositionPropTypes,
  // width/height/size
  size: any,
  width: any,
  height: any,
  minSize: any,
  minWidth: any,
  minHeight: any,
  maxSize: any,
  maxWidth: any,
  maxHeight: any,
  // margin
  margin: any,
  m: any,
  marginHorizontal: any,
  marginX: any,
  mx: any,
  marginVertical: any,
  marginY: any,
  my: any,
  marginTop: any,
  mTop: any,
  mt: any,
  marginBottom: any,
  mBottom: any,
  mb: any,
  marginLeft: any,
  mLeft: any,
  ml: any,
  marginRight: any,
  mRight: any,
  mr: any,
  ...PaddingPropTypes,
};

export const BorderPropTypes = {
  border: any, // object or func
  radius: any, // object or func,
  // borderColor
  borderColor: any,
  bColor: any,
  borderXColor: any,
  bxColor: any,
  borderYColor: any,
  byColor: any,
  borderLeftColor: any,
  blColor: any,
  borderRightColor: any,
  brColor: any,
  borderTopColor: any,
  btColor: any,
  borderBottomColor: any,
  bbColor: any,
  // borderStyle
  borderStyle: any,
  bStyle: any,
  borderXStyle: any,
  bxStyle: any,
  borderYStyle: any,
  byStyle: any,
  borderLeftStyle: any,
  blStyle: any,
  borderRightStyle: any,
  brStyle: any,
  borderTopStyle: any,
  btStyle: any,
  borderBottomStyle: any,
  bbStyle: any,
  // borderWidth,
  borderWidth: any,
  bWidth: any,
  borderXWidth: any,
  bxWidth: any,
  borderYWidth: any,
  byWidth: any,
  borderLeftWidth: any,
  blWidth: any,
  borderRightWidth: any,
  brWidth: any,
  borderTopWidth: any,
  btWidth: any,
  borderBottomWidth: any,
  bbWidth: any,
  // borderRadius,
  borderRadius: any,
  bRadius: any,
  borderLeftRadius: any,
  blRadius: any,
  borderRightRadius: any,
  brRadius: any,
  borderTopRadius: any,
  btRadius: any,
  borderBottomRadius: any,
  bbRadius: any,
  borderTopLeftRadius: any,
  borderTopRightRadius: any,
  borderBottomLeftRadius: any,
  borderBottomRightRadius: any,
}

export const TransformPropTypes = {
  backfaceVisibility: any, // only matters for transforms hence it is here
  perspective: any,
  translateX: any,
  tx: any,
  translateY: any,
  ty: any,
  rotate: any,
  rotateX: any,
  rotateY: any,
  rotateZ: any,
  scale: any,
  scaleX: any,
  scaleY: any,
  skewX: any,
  skewY: any,
  transform: arrayOf(oneOfType([
    shape({ perspective: number }),
    shape({ rotate: string }),
    shape({ rotateX: string }),
    shape({ rotateY: string }),
    shape({ rotateZ: string }),
    shape({ scale: number }),
    shape({ scaleX: number }),
    shape({ scaleY: number }),
    shape({ translateX: number }),
    shape({ translateY: number }),
    shape({ skewX: string }),
    shape({ skewY: string }),
  ])),
}

export const ShadowPropTypes = {
  shadow: any,
  shadowColor: any,
  shadowOpacity: any,
  shadowRadius: any,
  shadowOffset: any,
  shadowOffsetX: any,
  shadowOffsetY: any,
}

export const BackgroundColorPropTypes = {
  backgroundColor: any,
  background: any, // alias for backgroundColor. not like css
  bg: any,
};

export const ViewStylePropTypes = {
  ...SystemPropTypes,
  ...LayoutPropTypes,
  ...BorderPropTypes,
  ...TransformPropTypes,
  ...ShadowPropTypes,
  ...BackgroundColorPropTypes,
  opacity: any,
};

export const ImageStylePropTypes = {
  ...ViewStylePropTypes,
  resizeMode: any, // enum('cover', 'contain', 'stretch', 'repeat', 'center')
  tintColor: any,
};

export const TextPropTypes = {
  color: any,
  lineHeight: any,
  letterSpacing: any,
  fontFamily: any,
  fontSize: any,
  fontWeight: any,
  fontStyle: any,
  textAlign: any,
  textAlignVertical: any,
  includeFontPadding: any,
  textTransform: any,
  textDecorationLine: any,
  textDecorationColor: any,
  textDecorationStyle: any,
  textShadow: any,
  textShadowColor: any,
  textShadowOffset: any,
  textShadowRadius: any,
};

export const TextStylePropTypes = {
  ...ViewStylePropTypes,
  ...TextPropTypes,
};
