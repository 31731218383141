import React, { useRef, useEffect } from 'react';
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';
import {
  View,
  StyleSheet,
  unstable_createElement,
  Platform,
  UIManager,
} from 'react-native';

const FocusBracket = ({ zIndex }) => {
  return Platform.OS === 'web' && unstable_createElement('div', {
    accessibilityRole: 'none',
    tabIndex: 0,
    style: [styles.focusBracket, { zIndex }],
  });
}

const styles =
  Platform.OS === 'web'
    ? StyleSheet.create({ focusBracket: { outlineStyle: 'none' } })
    : {};

function attemptFocus(element) {
  if (!canUseDOM) {
    return false;
  }

  try {
    element.focus();
  } catch (e) {
    // Do nothing
  }

  return document.activeElement === element;
}

function focusFirstDescendant(element) {
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i];
    if (attemptFocus(child) || focusFirstDescendant(child)) {
      return true;
    }
  }
  return false;
}

function focusLastDescendant(element) {
  for (let i = element.childNodes.length - 1; i >= 0; i--) {
    const child = element.childNodes[i];
    if (attemptFocus(child) || focusLastDescendant(child)) {
      return true;
    }
  }
  return false;
}

const ModalFocusTrap = ({ active, children, disableTrapFocus, zIndex }) => {
  const trapElementRef = useRef();
  const focusRef = useRef({
    trapFocusInProgress: false,
    lastFocusedElement: null
  });

  useEffect(() => {
    if (canUseDOM && !disableTrapFocus) {
      const trapFocus = () => {
        // We should not trap focus if:
        // - The modal hasn't fully initialized with an HTMLElement ref
        // - Focus is already in the process of being trapped (e.g., we're refocusing)
        // - isTrapActive prop being falsey tells us to do nothing
        if (trapElementRef.current == null || focusRef.current.trapFocusInProgress || !active) {
          return;
        }

        try {
          focusRef.current.trapFocusInProgress = true;
          if (
            document.activeElement instanceof Node &&
            !trapElementRef.current.contains(document.activeElement)
          ) {
            // To handle keyboard focusing we can make an assumption here.
            // If you're tabbing through the focusable elements, the previously
            // active element will either be the first or the last.
            // If the previously selected element is the "first" descendant
            // and we're leaving it - this means that we should be looping
            // around to the other side of the modal.
            let hasFocused = focusFirstDescendant(trapElementRef.current);
            if (focusRef.current.lastFocusedElement === document.activeElement) {
              hasFocused = focusLastDescendant(trapElementRef.current);
            }
            // If we couldn't focus a new element then we need to focus onto the trap target
            if (!hasFocused && trapElementRef.current != null && document.activeElement) {
              UIManager.focus(trapElementRef.current);
            }
          }
        } finally {
          focusRef.current.trapFocusInProgress = false;
        }
        focusRef.current.lastFocusedElement = document.activeElement;
      };

      // Call the trapFocus callback at least once when this modal has been activated.
      trapFocus();

      document.addEventListener('focus', trapFocus, true);
      return () => document.removeEventListener('focus', trapFocus, true);
    }
  }, [active, disableTrapFocus]);

  if (Platform.OS !== 'web') {
    return children;
  }

  if (disableTrapFocus) {
    return (
      <View style={[{ zIndex }]}>
        {children}
      </View>
    );
  }

  return (
    <>
      <FocusBracket zIndex={zIndex - 1} />
      <View ref={trapElementRef} style={[{ zIndex }]}>
        {children}
      </View>
      <FocusBracket zIndex={zIndex + 1}/>
    </>
  );
};

export { ModalFocusTrap };
