import { TextInput } from './TextInput';
import { withStyles } from '../../styling';
import { border, padding } from '../../system/props';

const filterProps = [...border.filterProps, ...padding.filterProps, 'color'];

export const UnderlinedInput = withStyles(
  ({ color = '$secondary', onColor = '$background', theme, includeLabelSpacing, ...props }) => {
    const lineColor = theme.colors.on(onColor);
    const borderBottomColor = theme.colors.alpha(lineColor, 0.3);
    const padY = theme.spacing(1.25);
    let padTop = padY;
    let padBottom = padY;
    if (includeLabelSpacing) {
      padTop = padTop * 2.5;
    }
    return {
      root: { props: { onColor } },
      textBox: {
        ...padding({
          padX: 2,
          padTop,
          padBottom: padBottom - 1,
          ...props,
        }),
        ...border({
          borderRadius: 0,
          ...props,
          borderBottomColor,
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderXWidth: 0,
          borderTopColor: 'transparent',
          borderXColor: 'transparent',
        }),
        animations: {
          default: {
            borderBottomColor,
          },
          focused: {
            borderBottomColor: theme.colors.alpha(color, 1),
          },
          hovered: {
            borderBottomColor: theme.colors.alpha(lineColor, 0.6),
          },
          error: {
            borderBottomColor: '$error',
          },
        },
      },
      focused: {
        padBottom: padBottom - 2,
        borderBottomWidth: 2,
      },
      hovered: {
        padBottom: padBottom - 2,
        borderBottomWidth: 1.5,
      },
    };
  },
  { name: 'UnderlinedInput', filterProps, forward: true }
)(TextInput);
