import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { CalendarViewTypes } from './constants';

const Year = React.forwardRef(function Year(props, ref) {
  const { year, selected, focused, disabled, onSelect, ...rest } = props;

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      ref={ref}
      disabled={disabled}
      opacity={disabled ? 0.3 : 1}
      scale={selected ? 1.4 : 1}
      onPress={
        onSelect && !disabled
          ? () => onSelect(year, CalendarViewTypes.month)
          : null
      }
      {...rest}
    >
      <Text
        center
        large
        bold={selected}
        color={selected ? '$primary' : null}
        selectable={false}
      >
        {year}
      </Text>
    </Box>
  );
});

export { Year };
