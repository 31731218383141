import React from 'react';
import { Popover } from '../Popover';
import { MenuList } from '../MenuList';
import { withStyles } from '../../styling';

const Menu = withStyles({
    root: {},
    menuList: {},
  },
  { name: 'Menu' }
)(
  React.forwardRef(function Menu(props, ref) {
    const {
      MenuListProps = {},
      children,
      open,
      accessibility,
      styles,
      ...rest
    } = props;

    const menuListProps = MenuListProps.style ? {
      style: [styles.menuList, MenuListProps.style],
      ...styles.props.menuList,
      ...MenuListProps,
    } : { ...styles.props.menuList, ...MenuListProps, style: styles.menuList };

    return (
      <Popover
        ref={ref}
        open={open}
        accessibility={{
          accessibilityState: { expanded: open },
          ...accessibility,
        }}
        {...rest}
      >
        <MenuList {...menuListProps}>
          {children}
        </MenuList>
      </Popover>
    )
  })
);

export { Menu };
