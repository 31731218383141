import React from 'react';
import { InlineTextarea } from '../InlineTextarea';
import { Subheading } from '../../ui';
import { BlocksControls } from '../BlocksControls';
import {
  textSettingFields,
  defaultTextBlockSettings,
  useTextBlockSettings,
} from '../TextBlock';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { applyEnabledToggles, applyEnabled } from '../fieldConfigs';
import { memo } from '../../ui/utils';

export const SubheadingBlock = memo(({
  index,
  data,
  containerBlock = { },
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const textProps = useTextBlockSettings(settings, containerBlock);

  return (
    <Subheading {...textProps}>
      <BlocksControls index={index}>
        <InlineTextarea name="text" focusRing={false} />
      </BlocksControls>
    </Subheading>
  )
})

const defaultSubheadingSettings = {
  ...defaultTextBlockSettings,
  bolded: false,
  textSpaceAfter: true,
}

export const subheadingBlockTemplate = {
  type: 'subheadingBlockTemplate',
  name: 'subheading-block-template',
  key: 'subheading-block',
  label: 'Subheading',
  component: 'grouped',
  defaultItem: {
    text: 'Subheading text content. Click into here to edit the subheading.',
    settings: {
      sm: defaultSubheadingSettings,
      xs: applyEnabled(defaultSubheadingSettings, false, ['padding'])
    },
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'textarea'
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: textSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: textSettingFields
        })
      ]
    }
  ]
}
