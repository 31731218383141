const idCounter = { };

export function uniqueId(prefix = 'noPrefix') {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  idCounter[prefix] += 1;
  const id = idCounter[prefix];

  if (prefix === 'noPrefix') {
    return `${id}`;
  }

  return `${prefix + id}`;
};

export default uniqueId;
