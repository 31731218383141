import React, { useMemo, useRef } from 'react';
import {
  View,
  StyleSheet,
  unstable_createElement,
  Platform,
} from 'react-native';
import { Pressable } from '../Pressable';
import { Touchable } from '../Touchable';
import { styled, getStyle, withStyles, animated } from '../../styling';
import { isNull, isObject, uniqueId } from '../../utils';
import { ViewStylePropTypes } from '../../system';

const baseStyles = StyleSheet.create({
  view: {
    alignItems: 'stretch',
    // borderTopWidth: 0,
    // borderBottomWidth: 0,
    // borderLeftWidth: 0,
    // borderRightWidth: 0,
    // borderTopColor: 'transparent',
    // borderBottomColor: 'transparent',
    // borderLeftColor: 'transparent',
    // borderRightColor: 'transparent',
    // borderTopStyle: 'solid',
    // borderLeftStyle: 'solid',
    // borderRightStyle: 'solid',
    // borderBottomStyle: 'solid',
    display: 'flex',
    // flexBasis: 'auto',
    flexDirection: 'column',
    // flexShrink: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    minWidth: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative',
    zIndex: 0,
    ...(Platform.OS === 'web' ? { boxSizing: 'border-box' } : {}),
  },
  web: { textDecorationLine: 'none' },
});

const webStyle = Platform.OS === 'web' ? baseStyles.web : null;

const AnimatedView = animated(View);
AnimatedView.displayName = 'animated(View)';

const BaseDiv = ({
  innerRef,
  styles,
  accessibility,
  className,
  domClassName,
  log,
  ...props
}) => {
  let classes = typeof className === 'string' ? className : '';
  if (domClassName) {
    classes = `${domClassName} ${classes}`;
  }
  return (
    <div {...accessibility} {...props} className={classes} ref={innerRef} />
  );
};

const RDiv = (props) => unstable_createElement(BaseDiv, props);
export const AnimatedDiv = animated(RDiv);
AnimatedDiv.displayName = 'animated(div)';

export const BoxDiv = styled(AnimatedDiv)({
  flexBasis: 'auto'
  // alignItems: 'stretch',
  // boxSizing: 'border-box',
  // border: '0 solid black',
  // display: 'flex',
  // flexBasis: 'auto',
  // flexDirection: 'column',
  // flexShrink: 0,
  // margin: 0,
  // minHeight: 0,
  // minWidth: 0,
  // padding: 0,
  // position: 'relative',
  // zIndex: 0
});

const Box = withStyles({
  root: props => {
    const styles = {
      // flexBasis: 'auto',
      ...(Platform.OS === 'web' ? { cursor: props.cursor || (props.onPress ? 'pointer' : null) } : {}),
      animations: props.disableAnimationDefaults ? props.animations : {
        hovered: {
          opacity: 0.72,
        },
        pressed: {
          opacity: 0.4,
        },
        default: {
          opacity: !isNull(props.opacity) ? props.opacity : 1,
          delay: 80,
        },
        ...props.animations,
      },
    };
    for (const key in ViewStylePropTypes) {
      if (props[key] !== undefined) {
        styles[key] = props[key];
      }
    }
    return styles;
  }
}, { name: 'Box', filterProps: Object.keys(ViewStylePropTypes) })(React.forwardRef((props, ref) => {
  const {
    onPress,
    onPressIn,
    onPressOut,
    onLongPress,
    onHoverIn,
    onHoverOut,
    disabled,
    gap,
    style,
    gapBorder,
    debug,
    children,
    debugMe,
    renderBefore,
    renderAfter,
    component: Component = AnimatedView,
    accessibility = {},
    disableAnimationDefaults,
    className, // only for rendering a regular div (BoxDiv). RNWeb ignores className so need to pass as domClassName
    hovered = false, // if parent is <Hoverable> apply to animate if null
    ...boxProps
  } = props;

  if (Component === BoxDiv && className !== null && className !== '') {
    boxProps.domClassName = className;
  }

  const boxId = useRef(uniqueId('box'));

  boxProps.style = Component === BoxDiv ? [baseStyles.view, webStyle, style] : [webStyle, style];

  // TODO: rethink this
  // if (
  //   hovered &&
  //   boxProps.animations &&
  //   boxProps.animations.hovered
  // ) {
  //   if (boxProps.animate) {
  //     if (Array.isArray(boxProps.animate)) {
  //       if (!boxProps.animate.includes('hovered')) {
  //         boxProps.animate = ['hovered', ...boxProps.animate];
  //       }
  //     } else if (boxProps.animate !== 'hovered' && boxProps.animate === 'default') {
  //       boxProps.animate = [boxProps.animate, 'hovered'];
  //     }
  //   } else {
  //     boxProps.animate = 'hovered';
  //   }
  // }

  const pressable = useMemo(() => (
    onPress || onPressIn || onPressOut || onLongPress
  ), [onPress, onPressIn, onPressOut, onLongPress]);

  if (pressable) {
    if (Component === AnimatedView || Component === Pressable) {
      return (
        <Pressable
          onPress={onPress}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          onLongPress={onLongPress}
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          disabled={disabled}
          accessibility={{
            accessible: true,
            ...accessibility,
          }}
          {...boxProps}
          ref={ref}
        >
          {(pressableState) => {
              let contents =
                typeof children === 'function'
                  ? children(pressableState)
                  : children;

              if (gap) {
                contents = [];
                let firstIndex;
                React.Children.forEach(children, (child, index) => {
                  if (child) {
                    if (firstIndex === undefined) {
                      firstIndex = index;
                    } else {
                      contents.push(
                        <Gap
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${boxId.current}-gap-${index}`}
                          boxStyle={props.style}
                          debug={debug}
                          gap={gap}
                          gapBorder={gapBorder}
                        />
                      );
                    }
                  }
                  contents.push(child);
                });
              }
              return (
                <>
                  {renderBefore}
                  {contents}
                  {renderAfter}
                </>
              );
          }}
        </Pressable>
      );
    }

    return (
      <Touchable
        onPress={onPress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        onLongPress={onLongPress}
        disabled={disabled}
      >
        {(pressableState) => {
            let contents =
              typeof children === 'function'
                ? children(pressableState)
                : children;

            if (gap) {
              contents = [];
              let firstIndex;
              React.Children.forEach(children, (child, index) => {
                if (child) {
                  if (firstIndex === undefined) {
                    firstIndex = index;
                  } else {
                    contents.push(
                      <Gap
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${boxId.current}-gap-${index}`}
                        boxStyle={props.style}
                        debug={debug}
                        gap={gap}
                        gapBorder={gapBorder}
                      />
                    );
                  }
                }
                contents.push(child);
              });
            }
            return (
              <Component
                {...{
                  accessible: true,
                  ...accessibility,
                  accessibilityState: {
                    disabled: disabled,
                    ...accessibility.accessibilityState,
                  },
                }}
                ref={ref}
                {...boxProps}
              >
                {renderBefore}
                {contents}
                {renderAfter}
              </Component>
            );
        }}
      </Touchable>
    )
  }

  let contents =
    typeof children === 'function'
      ? children(...args)
      : children;

  if (gap) {
    contents = [];
    let firstIndex;
    React.Children.forEach(children, (child, index) => {
      if (child) {
        if (firstIndex === undefined) {
          firstIndex = index;
        } else {
          contents.push(
            <Gap
              // eslint-disable-next-line react/no-array-index-key
              key={`${boxId.current}-gap-${index}`}
              boxStyle={props.style}
              debug={debug}
              gap={gap}
              gapBorder={gapBorder}
            />
          );
        }
      }
      contents.push(child);
    });
  }

  return (
    <Component ref={ref} {...accessibility} {...boxProps}>
      {renderBefore}
      {contents}
      {renderAfter}
    </Component>
  )
}))

function canRenderGap({ gap, boxStyle }) {
  if (gap && !isNull(gap)) {
    const box = getStyle(boxStyle);
    // if direction is wrap then this will not render properly
    if (box && box.flexWrap && box.flexWrap === 'wrap') {
      return false;
    }
    if (isObject(gap) && !gap.spacing) {
      return false;
    }
    return true;
  }
  return false;
}

const Gap = withStyles({
  root: props => {
    if (canRenderGap(props)) {
      const { gap } = props;
      const box = props.boxStyle ? getStyle(props.boxStyle) : null;

      let gapStyle;
      let spacing;
      if (isObject(gap)) {
        const { spacing: gapSpacing, border, ...rest } = gap;
        spacing = gapSpacing;
        gapStyle = rest;
      } else {
        spacing = gap;
        gapStyle = { };
      }
      const isColumnLayout = !box || !box.flexDirection || !box.flexDirection.startsWith('row');
      const width = isColumnLayout ? 'auto' : spacing;
      const height = isColumnLayout ? spacing : 'auto';
      const flexBasis = isColumnLayout ? height : width;

      return {
        width,
        height,
        flexBasis,
        justifyContent: 'center',
        alignItems: 'center',
        ...gapStyle
      }
    }
    return null;
  },
  gapBorder: props => {
    if (canRenderGap(props)) {
      const { gap, gapBorder } = props;
      const box = props.boxStyle ? getStyle(props.boxStyle) : null;
      let border;
      if (isObject(gap)) {
        if (gapBorder || gap.border) {
          border = { ...gap.border, ...gapBorder };
        }
      } else {
        border = gapBorder;
      }

      if (!border) return null;

      const isColumnLayout = !box || !box.flexDirection || !box.flexDirection.startsWith('row');

      return {
        width: isColumnLayout ? '100%' : 1,
        height: isColumnLayout ? 1 : '100%',
        border: isColumnLayout ? { bottom: border } : { right: border },
      }

    }
    return null;
  }
}, { name: 'Gap' })(props => {
  const { styles, debug } = props;
  if (!styles.root) return null;
  return (
    <AnimatedView style={styles.root} {...styles.props.root}>
      {styles.gapBorder && <View style={styles.gapBorder} {...styles.props.gapBorder} />}
    </AnimatedView>
  )
});

export { Box };
