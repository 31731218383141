import React, {
  useContext,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import equal from 'fast-deep-equal/react';
import { useMemoCompare } from '../../ui/hooks';
import { useCMS } from 'tinacms';

export const FormBlockContext = React.createContext();

export const useFormBlock = () => useContext(FormBlockContext);

export function withFormFieldData(Component) {
  return (props) => {
    const { index } = props;
    const form = useFormBlock();
    if (!form || index === null || index === undefined) {
      console.error('Cannot render FormFieldBlock with no FormBlock Context or undefined Index', index, form);
      return null;
    }
    const { fields, updateField, loading, disabled } = form;

    const field = !disabled ? fields[index] : null;
    return <Component {...props} field={field} updateField={updateField} formLoading={loading} formDisabled={disabled} />

  }
}

export const useFormState = ({ formBlocks, formConfig: formConfigOptions }, fieldTypes) => {
  const mounted = useRef(true);

  useEffect(() => {
    return () => { mounted.current = false; }
  },[])

  const cms = useCMS();
  const formConfig = useMemoCompare(formConfigOptions, equal);

  const [fields, setFields] = useState(() => {
    if (cms.enabled && process.env.NODE_ENV !== 'production') {
      return [];
    }
    let fieldConfigs = [];
    for (let i = 0; i < formBlocks.length; i += 1) {
      const data = formBlocks[i];
      const field = { };
      field.name = data.fieldName;
      field.isValid = data.fieldName ? true : false;
      field.label = data.fieldLabel;
      field.placeholder = data.placeholder;
      field.required = data.required;
      field.type = fieldTypes ? fieldTypes[data._template] : null;
      field.messages = {
        required: data.requiredMessage || '',
        helpful: data.helperText || '',
        invalid: data.invalidMessage || '',
      };
      if (field.type) field.messages[field.type] = data.invalidMessage || '';
      field.helperText = field.messages.helpful;
      field.value = data.defaultValue || '';
      field.maxLength = data.maxLength || null;
      field.defaultValue = data.defaultValue || '';
      field.value = field.defaultValue;
      field.tests = [];
      field.error = false;
      if (field.required) field.tests.push({ type: 'required', test: inputTests.required, required: true });
      if (field.type && inputTests[field.type]) {
        field.tests.push({ type: field.type, test: inputTests[field.type], required: field.required });
      }

      fieldConfigs.push(field);
    }
    return fieldConfigs;
  });

  const [formState, setFormState] = useState(() => ({
    loading: false,
    error: false,
    message: "",
    submitted: false,
    payload: null,
  }));

  const updateField = useCallback((index, value) => {
    if (fields.length && index < fields.length) {
      const next = [...fields];
      next[index].value = value;
      setFields(next);
    }
  }, [fields])

  const test = useCallback((callback) => {
    if (!fields.length) return;
    let next = [...fields];
    let valid = true;
    for (let i = 0; i < fields.length; i += 1) {
      const { value, tests } = fields[i];
      if (tests.length) {
        for (let j = 0; j < tests.length; j+=1) {
          const { test, type, required } = tests[j];
          if (!test(value, required)) {
            valid = false;
            next[i].error = true;
            next[i].helperText = next[i].messages[type] || '';
            break;
          } else {
            next[i].error = false;
            next[i].helperText = next[i].messages.helpful || '';
          }
        }
      }
    }
    callback && callback(valid, next);
  }, [fields])

  const validate = useCallback((callback) => {
    if (!fields.length) return;
    test((valid, fields) => {
      if (!valid) {
        setFields(fields);
      }
      callback && callback(valid, fields);
    })
  }, [fields, test]);


  const submit = useCallback(() => {
    if (!fields.length) return;
    validate((valid, fields) => {
      if (valid) {
        const { addData, failureMessage, successMessage, url, failureFunc, successFunc, method } = formConfig;
        if (!url || !method) return;
        const payload = { url, method, failureMessage, successMessage, body: { }, failureFunc, successFunc };
        for (let i = 0; i < fields.length; i += 1) {
          const field = fields[i];
          if (field.isValid) {
            payload.body[field.name] = field.value;
          }
        }
        if (addData && addData.length) {
          for (let i = 0; i < addData.length; i += 1) {
            const add = addData[i];
            if (add.fieldName) {
              payload.body[add.fieldName] = add.value;
            }
          }
        }
        if (process.env.NODE_ENV !== "production") console.log('form payload', payload);
        setFormState((prev) => ({ ...prev, error: false, message: "", loading: true, submitted: false, payload }));
      }
    })
  }, [fields.length, formConfig, validate])

  const payload = useMemoCompare(formState.payload, equal);

  useEffect(() => {
    if (!mounted.current || !payload) return;
    const sendRequest = async (payload) => {
      const { method, body, url, successMessage, failureMessage, successFunc, failureFunc } = payload;
      try {
        const config = { headers: { }, method };
        config.headers['Content-Type'] = 'application/json';
        if (body) config.body = JSON.stringify(body);

        if (process.env.NODE_ENV !== "production") console.log('posting', url, config);
        const response = await fetch(url, config);
        if (process.env.NODE_ENV !== "production") console.log('fetchResponse', response);
        if (mounted.current) {
          setFields((fields) => {
            const clearedFields = [...fields];
            for (let i = 0; i < fields.length; i += 1) {
              clearedFields[i].value = clearedFields[i].defaultValue;
              clearedFields[i].helperText = clearedFields[i].messages.helpful || '';
              clearedFields[i].error = false;
            }
            return clearedFields;
          })
          setFormState((prev) => ({
            ...prev,
            loading: false,
            error: false,
            message: successMessage,
            payload: null,
            submitted: true,
          }));
          if (successFunc) {
            try {
              const successEvent = new Function(successFunc);
              successEvent();
            } catch (error) {
              console.log('form success event script error', error);
            }
          }
        }
      } catch (error) {
        console.error('Form Error', error);
        if (mounted.current) {
          setFormState((prev) => ({
            ...prev,
            loading: false,
            error: true,
            message: failureMessage,
            payload: null,
            submitted: false,
          }));
        }
        if (failureFunc) {
          try {
            const failureEvent = new Function(failureFunc);
            failureEvent();
          } catch (error) {
            console.log('form failure event script error', error);
          }
        }
      }
    }
    sendRequest(payload);
  }, [payload])

  const formBlockState = {
    fields,
    updateField,
    test,
    validate,
    submit,
    disabled: cms.enabled && process.env.NODE_ENV !== 'production',
    ...formState
  }
  return formBlockState;
  // return useMemoCompare(result, equal);
}

export const inputTests = {
  email(input = '', required = false) {
    // eslint-disable-next-line
    if (typeof input === 'string' && !input.trim() && !required) return true;
    if (!/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(input)) {
      return false;
    }
    return true;
  },
  number(input = '', required = false) {
    if (typeof input === 'string' && !input.trim() && !required) return true;
    if (!/^([1-9]\d*|0)(\.\d+)?$/g.test(input)) {
      return false;
    }
    return true;
  },
  // nonZeroNumberAmount(input) {
  //   if (!/^([1-9]\d*|0)(\.\d+)?$/g.test(input)) {
  //     throw INVALID_NUMBER;
  //   }
  //   if (input === '0' || !input.length) {
  //     throw NO_AMOUNT;
  //   }
  // },
  phone(input = '', required = false) {
    if (typeof input === 'string' && !input.trim() && !required) return true;
    if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/g.test(input)) {
      return false;
    }
    return true;
  },
  notEmpty(input = '') {
    if (!input.replace(/\s/g, '').length) {
      return false;
    }
    return true;
  },
  required(input) {
    return inputTests.notEmpty(input);
  }
}
