import React, { useState, useMemo, useCallback } from 'react';
import { Pressable as RNPressable, Platform } from 'react-native';
import { withStyles, animated } from '../../styling';
import { ViewStylePropTypes } from '../../system';
import { useHoverable } from '../Hoverable';

const AnimatedPressable = animated(RNPressable);
AnimatedPressable.displayName = 'animated(Pressable)';

function usePressable({
  onPressIn: propOnPressIn,
  onPressOut: propOnPressOut,
  ...hoverConfig
} = {}) {
  const [pressed, setPressed] = useState(false);
  const onPressIn = useCallback(() => {
    setPressed(true);
    if (propOnPressIn) {
      propOnPressIn();
    }
  }, [propOnPressIn]);

  const onPressOut = useCallback(() => {
    setPressed(false);
    if (propOnPressOut) {
      propOnPressOut();
    }
  }, [propOnPressOut]);

  const hoverState = useHoverable(hoverConfig);

  return {
    pressed,
    onPressIn,
    onPressOut,
    ...hoverState,
  };
}

const Pressable = withStyles({
  root: (props) => {
    const styles = { };
    for (const key in ViewStylePropTypes) {
      if (props[key] !== undefined) {
        styles[key] = props[key];
      }
    }

    return styles;
  },
}, {
  name: 'Pressable',
  filterProps: Object.keys(ViewStylePropTypes)
})(React.forwardRef(function Pressable(props, ref) {
  const {
    onPressIn,
    onPressOut,
    onHoverIn,
    onHoverOut,
    onHoverChange,
    onResponderGrant,
    onResponderRelease,
    disabled,
    accessibility = {},
    children,
    ...rest
  } = props;

  const { pressed, hovered, ...handlers } = usePressable({
    onPressIn,
    onPressOut,
    onHoverIn,
    onHoverOut,
    onHoverChange,
    onResponderGrant,
    onResponderRelease,
  });

  const animate = useMemo(() => {
    if (pressed) {
      return 'pressed';
    }
    if (hovered) {
      return 'hovered';
    }
    return 'default';
  }, [pressed, hovered]);

  return (
    <AnimatedPressable
      animate={animate}
      ref={ref}
      disabled={disabled}
      {...{
        ...accessibility,
        accessibilityState: {
          disabled: disabled,
          ...accessibility.accessibilityState,
        },
      }}
      {...handlers}
      {...rest}
    >
      {typeof children === 'function'
        ? ({ focused }) => children({ focused, hovered, pressed })
        : children}
    </AnimatedPressable>
  );
}));

export { Pressable, usePressable };
