import * as React from 'react'
import styled from 'styled-components'

import {
  FieldsBuilder,
  Modal,
  ModalPopup,
  ModalHeader,
  ModalBody,
  ModalActions,
} from 'tinacms'
import { DragDropContext } from 'react-beautiful-dnd'
import { Button } from '@tinacms/styles'
import { EditIcon } from '@tinacms/icons'
import { FormPortalProvider } from '@tinacms/react-forms'
import { BlockAction, useInlineForm, InlineFieldContext } from 'react-tinacms-inline'
import { isNull } from '../ui/utils';

export function InlineSettings({ fields }) {
  const [open, setOpen] = React.useState(false)
  const noExtraFields = !(fields && fields.length)

  if (noExtraFields) {
    return null
  }

  return (
    <>
      <BlockAction onClick={() => setOpen(p => !p)}>
        <EditIcon />
      </BlockAction>
      {open && <SettingsModal fields={fields} close={() => setOpen(false)} />}
    </>
  )
}

function SettingsModal({ fields, close }) {
  const { form } = useInlineForm()
  const { name } = React.useContext(InlineFieldContext)
  const [initialValues] = React.useState(form.values)
  // const name = !isNull(nameForSettings) ? nameForSettings : baseName;
  function handleCancel() {
    form.updateValues(initialValues)
    close()
  }

  let formFields = fields

  if (name) {
    formFields = fields.map((field) => ({
      ...field,
      name: `${name}.${field.name}`,
    }))
  }
  const moveArrayItem = React.useCallback(
    (result) => {
      if (!result.destination || !form) return
      const name = result.type
      form.mutators.move(name, result.source.index, result.destination.index)
    },
    [form]
  )
  return (
    <Modal onClick={e => e.stopPropagation()}>
      <ModalPopup>
        <ModalHeader close={close}>Settings</ModalHeader>
        <ModalBody>
          <DragDropContext onDragEnd={moveArrayItem}>
            <FormBody>
              <Wrapper>
                <FieldsBuilder form={form} fields={formFields} />
              </Wrapper>
            </FormBody>
          </DragDropContext>
        </ModalBody>
        <ModalActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={close}
            disabled={form.values === initialValues}
            primary
          >
            Confirm
          </Button>
        </ModalActions>
      </ModalPopup>
    </Modal>
  )
}

const FormBody = styled(FormPortalProvider)`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  border-top: 1px solid var(--tina-color-grey-2);
  background-color: #f6f6f9;
`

const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
`
