import React from 'react';
import { Box } from '../Box';
import { Transition } from '../../styling';

const ModalBackdrop = ({
  invisible = false,
  open,
  animations,
  disableAnimate = false,
  ...props
}) => {
  const backdrop = open ? (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      bg={invisible ? 'transparent' : 'rgba(0,0,0, 0.18)'}
      pointerEvents={'auto'}
      disableAnimationDefaults
      disableAnimate={disableAnimate}
      aria-hidden
      accessibility={{ accessible: false }}
      {...props}
    />
  ) : null;

  return !disableAnimate && !invisible ? (
    <Transition animations={animations}>{backdrop}</Transition>
  ) : (
    backdrop
  );
};

ModalBackdrop.displayName = 'ModalBackdrop';

ModalBackdrop.defaultProps = {
  animations: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 500,
      friction: 30,
      clamp: true,
    },
  },
};

export { ModalBackdrop };
