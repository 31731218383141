import React from 'react';
import styled from 'styled-components';
import { InlineField } from 'react-tinacms-inline';
import { useCMS } from 'tinacms';
import { Image } from '../ui';
// import { useDropzone } from 'react-dropzone';


export const InlineImage = ({
  name,
  previewSrc,
  uploadDir,
  parse,
  component: Component,
  children,
  focusRing = false,
  ...rest
}) => {
  const cms = useCMS();

  return (
    <InlineField name={name}>
      {({ input, form }) => {
        const _previewSrc = previewSrc(form.finalForm.getState().values, { input })

        async function handleUploadImage([file]) {
          console.log('inlineImageUpload', file);
          const directory = uploadDir(form)
          const [media] = await cms.media.store.persist([
            {
              directory,
              file,
            },
          ])
          if (media?.filename) {
            input.onChange(parse(media.filename))
          } else {
            console.error(
              'TinaCMS Image Upload Failed: This could be due to media store configuration, file size, or if the image is a duplicate (has already been uploaded).'
            )
            cms.alerts.error('Image Upload Failed.')
          }
          return null
        }

        if (cms.enabled && process.env.NODE_ENV !== 'production') {
          return (
            <>

              <Component src={_previewSrc} zIndex={-1} {...rest}>
                {children}
              </Component>
              {
                focusRing ? (
                  <InputFocusWrapper>
                    <InlineImageUpload onReceive={handleUploadImage} />
                  </InputFocusWrapper>
                ) : <InlineImageUpload onReceive={handleUploadImage} />
              }
            </>
          )
        }
        return <Component {...rest} src={input.value}>{children}</Component>
      }}
    </InlineField>
  )
}

InlineImage.defaultProps = {
  component: Image
}

export const InlineImageUpload = ({
  onReceive
}) => {
  return (
    <label style={{ opacity: 0, /*width: '100%', height: '100%',*/ cursor: 'pointer', position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
      <input type="file"
        style={{
          width: 0.1,
          height: 0.1,
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
        accept="image/*"
        onChange={e => { onReceive([...e.target.files]); }}
      />
    </label>
  )
}

const InputFocusWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  &:focus-within {
    &:after {
      opacity: 1;
    }
  }

  &:hover:not(:focus-within) {
    &:after {
      opacity: 0.3;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    border: 1px solid var(--tina-color-primary);
    border-radius: var(--tina-radius-big);
    opacity: 0;
    pointer-events: none;
    transition: all var(--tina-timing-medium) ease-out;
  }
`
