import { any } from 'prop-types';

// all svg components can accept these
// only <SVG /> can accept all normal ViewStylePropTypes
export const CSSPropTypes = {
  display: any,
  cursor: any,
  visibility: any,
  overflow: any,
  opacity: any,
  color: any,
};

export const ColorPropTypes = {
  color: any,
  stopColor: any,
  fill: any,
  stroke: any,
};

export const TransformPropTypes = {
  translate: any,
  translateX: any,
  translateY: any,
  origin: any,
  originX: any,
  originY: any,
  scale: any,
  scaleX: any,
  scaleY: any,
  skew: any,
  skewX: any,
  skewY: any,
  rotation: any,
  x: any,
  y: any,
  transform: any,
};

export const ClipPropTypes = {
  clipPath: any,
  clipRule: any, // evenodd | nonzero
};

export const FillPropTypes = {
  fill: any,
  fillRule: any,
  fillOpacity: any,
};

export const StrokePropTypes = {
  stroke: any,
  strokeWidth: any,
  strokeOpacity: any,
  strokeDasharray: any,
  strokeDashoffset: any,
  strokeLinecap: any,
  strokeLinejoin: any,
  strokeMiterlimit: any,
  vectorEffect: any,
};

export const FontPropTypes = {
  fontData: any,
  fontStyle: any,
  fontVariant: any,
  fontWeight: any,
  fontStretch: any,
  fontSize: any,
  fontFamily: any,
  textAnchor: any,
  textDecoration: any,
  letterSpacing: any,
  wordSpacing: any,
  kerning: any,
  fontFeatureSettings: any,
  fontVariantLigatures: any,
  fontVariationSettings: any,
  font: any,
};

export const RectPropTypes = {
  x: any,
  y: any,
  width: any,
  height: any,
};

export const CirclePropTypes = {
  cx: any,
  cy: any,
  r: any,
};

export const EllipsePropTypes = {
  cx: any,
  cy: any,
  rx: any,
  ry: any,
};

export const TextPropTypes = {
  x: any,
  y: any,
  dx: any,
  dy: any,
  rotate: any, // TODO: <- this conflicts with normal styling FYI
  inlineSize: any,
  baselineShift: any,
  verticalAlign: any,
  alignmentBaseline: any,
};

export const TSpanPropTypes = TextPropTypes;

export const TextPathPropTypes = {
  href: any,
  startOffset: any,
  method: any,
  spacing: any,
  side: any,
  midLine: any,
};

export const PathPropTypes = {
  d: any,
};

export const PolygonPropTypes = {
  points: any,
};

export const PolylinePropTypes = PolygonPropTypes;

export const LinePropTypes = {
  x1: any,
  x2: any,
  y1: any,
  y2: any,
};

export const UsePropTypes = {
  href: any,
  ...RectPropTypes,
};

export const ImagePropTypes = {
  href: any,
  preserveAspectRatio: any,
  ...RectPropTypes,
};

export const SymbolPropTypes = {
  viewBox: any,
  preserveAspectRatio: any,
  ...RectPropTypes,
};

export const MarkerPropTypes = {
  id: any,
  viewBox: any,
  preserveAspectRatio: any,
  markerHeight: any,
  markerWidth: any,
  markerUnits: any,
  orient: any,
  refX: any,
  refY: any,
};

export const LinearGradientPropTypes = {
  id: any,
  x1: any,
  x2: any,
  y1: any,
  y2: any,
  gradientTransform: any,
  gradientUnits: any,
};

export const RadialGradientPropTypes = {
  id: any,
  fx: any,
  fy: any,
  rx: any,
  ry: any,
  r: any,
  cx: any,
  cy: any,
  gradientTransform: any,
  gradientUnits: any,
};

export const StopPropTypes = {
  offset: any,
  stopColor: any,
  stopOpacity: any,
};

export const PatternPropTypes = {
  viewBox: any,
  preserveAspectRatio: any,
  patternTransform: any,
  patternUnits: any,
  patternContentUnits: any,
  ...RectPropTypes,
};

export const MaskPropTypes = {
  ...RectPropTypes,
  id: any,
  maskTransform: any,
  maskUnits: any,
  maskContentUnits: any,
};

export const SharedComponentPropTypes = {
  mask: any, // use of mask component id
  marker: any, // use of marker component id,
  markerStart: any,
  markerMid: any,
  markerEnd: any,
  ...FillPropTypes,
  ...StrokePropTypes,
  ...ClipPropTypes,
  ...CSSPropTypes,
  ...TransformPropTypes,
  ...FontPropTypes,
};

export const SvgPropTypes = {
  ...RectPropTypes,
  ...FillPropTypes,
  ...StrokePropTypes,
  ...ClipPropTypes,
  ...CSSPropTypes,
  ...FontPropTypes,
  viewBox: any,
};
