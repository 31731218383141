import React from 'react';
import equal from 'fast-deep-equal/react';
import { Content, BackgroundImage, Box } from '../../ui';
import { useTheme, useMatchBreakpoint, useMemoCompare } from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { TimelineItemBlock, timelineItemBlockTemplate } from './TimelineItemBlock';
import { InlineBlocks } from '../InlineBlocks';
import { BlocksControls } from '../BlocksControls';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';



export const TimelineBlock = memo(({ data, index, containerBlock, ...rest }) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const blockStyles = { ...settings.sizeStyle, ...settings.alignSelfStyle, ...settings.overflowStyle, ...settings.shadowStyle };
  if (settings.backgroundColor) {
    blockStyles.backgroundColor = settings.backgroundColor;
  } else if (containerBlock.backgroundColor) {
    blockStyles.backgroundColor = containerBlock.backgroundColor;
  }
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;

  let { lineColor, lineOpacity } = settings;
  if (lineColor) lineColor = `$${lineColor}`;
  const { spacing } = useTheme();
  const contentSpacing = spacing(settings.contentSpacing || 0);

  const itemProps = {
    lineColor,
    lineOpacity,
    stepSpacing: contentSpacing || 0,
    lastIndex: data.timelineBlocks && data.timelineBlocks.length ? data.timelineBlocks.length - 1 : 0,
    containerBlock: {
      direction: 'column',
      backgroundColor: blockStyles.backgroundColor,
    }
  }



  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      renderAs={Content}
      {...blockStyles}
    >
      {settings.backgroundImage && settings.backgroundImage.src ? <BackgroundImage {...settings.backgroundImage} /> : null}
      <InlineBlocks
        name="timelineBlocks"
        blocks={TIMELINE_BLOCKS}
        itemProps={itemProps}
        direction="column"
        wrapperProps={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          padding: settings.padding,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </BlocksControls>
  )
})

const TIMELINE_BLOCKS = {
  [timelineItemBlockTemplate.type]: {
    Component: TimelineItemBlock,
    template: timelineItemBlockTemplate
  }
}

const timeLineSettingsFields = [
  {
    ...styleFields.backgroundColor,
    name: 'lineColor',
    label: 'Line Color',
  },
  {
    ...styleFields.opacity,
    name: 'lineOpacity',
    label: 'Line Transparency'
  },
  layoutFields.contentSpacing,
  layoutFields.unitBased.width,
  layoutFields.alignSelf,
  layoutFields.padding,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]

const defaultTimelineSettings = {
  lineColor: {
    name: 'secondary',
    alpha: '',
  },
  lineOpacity: '0.4',
  contentSpacing: '4',
  width: {
    value: '100',
    unit: '%',
  },
  alignSelf: settingsDefaultItems.alignSelf,
  padding: {
    top: '',
    bottom: '20',
    left: '',
    right: ''
  },
  backgroundColor: {
    name: '',
    alpha: '',
  },
  opacity: settingsDefaultItems.opacity,
  border: {
    width: '1',
    color: {
      name: 'secondary',
      alpha: '',
    },
    style: 'solid',
    top: false,
    bottom: true,
    left: false,
    right: false
  },
  borderRadius: settingsDefaultItems.borderRadius,
  backgroundImage: settingsDefaultItems.backgroundImage,
  shadow: settingsDefaultItems.shadow,
  clipContent: settingsDefaultItems.clipContent
}

export const timelineBlockTemplate = {
  type: 'timelineBlockTemplate',
  name: 'timelineBlock',
  key: 'timeline-block',
  label: 'Timeline',
  component: 'grouped',
  defaultItem: {
    timelineBlocks: [],
    settings: {
      sm: defaultTimelineSettings,
      xs: applyEnabled(defaultTimelineSettings, false, ['padding', 'border', 'borderRadius', 'backgroundImage', 'shadow']),
    },
  },
  fields: [
    {
      name: 'timelineBlocks',
      label: 'Timeline Blocks',
      component: 'blocks',
      templates: {
        timelineItemBlockTemplate,
      },
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: timeLineSettingsFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: timeLineSettingsFields
        })
      ]
    }
  ]
}
