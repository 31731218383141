// TODO: this. The notch/fieldset notch for the label will be tricky. SVG might come in handy to apply a mask...
// otherwise the topborder will have to be two boxes with the mini label rendered transparent as reference for
// size (<Box flex={0} minWidth={4} ...border/><Text flex={0} px="4">{label}</Text><Box flex={1} ...border />)
import { FilledInput } from './FilledInput';
import { withStyles } from '../../styling';

export const OutlinedInput = withStyles(
  {},
  { name: 'OutlinedInput', forward: true }
)(FilledInput);
