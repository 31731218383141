import React, { useMemo } from 'react';
import equal from 'fast-deep-equal/react';
import { mergeAndConcat } from 'merge-anything';
import { Content } from '../../ui';
import { useTheme, useMatchBreakpoint, useMemoCompare } from '../../ui/hooks';
import cloneDeep from 'lodash.clonedeep';
import { memo, get } from '../../ui/utils';
import { NULL_STYLE } from '../../ui/system';
import { TextBlock, TextBlockTemplate } from '../TextBlock';
import { HeadingBlock, HeadingBlockTemplate } from '../HeadingBlock';
import { SubheadingBlock, subheadingBlockTemplate } from '../SubheadingBlock';
import { OverlineBlock, overlineBlockTemplate } from '../OverlineBlock';
import { TextLinkBlock, textLinkBlockTemplate } from '../TextLinkBlock';
import { ButtonBlock, ButtonBlockTemplate } from '../ButtonBlock';
import { SpacingBlock, SpacingBlockTemplate } from '../SpacingBlock';
import { ImageBlock, ImageBlockTemplate } from '../ImageBlock';
import { VideoEmbedBlock, videoEmbedBlockTemplate } from '../VideoEmbedBlock';
import { InlineBlocks } from '../InlineBlocks';
import { BlocksControls } from '../BlocksControls';
import { BackgroundImage } from '../../ui';
import { FormBlock, formBlockTemplate } from '../FormBlock';
import { HtmlBlock, htmlBlockTemplate } from '../HtmlBlock';
import {
  styleFields,
  layoutFields,
  settingsDefaultItems,
  applyEnabled,
  applyEnabledToggles
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { useButtonLinkToHandler } from '../ButtonBlock';
import { useCMS } from 'tinacms';

const defaultGroupBlockSettings = {
  width: {
    value: '100',
    unit: '%',
  },
  height: '',
  maxWidth: {
    value: '',
    unit: '%',
  },
  maxHeight: '',
  direction: 'column',
  alignContent: 'top-left',
  justifyContent: settingsDefaultItems.justifyContent,
  contentSpacing: settingsDefaultItems.contentSpacing,
  padding: settingsDefaultItems.padding,
  alignSelf: settingsDefaultItems.alignSelf,
  backgroundColor: settingsDefaultItems.backgroundColor,
  opacity: settingsDefaultItems.opacity,
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  backgroundImage: settingsDefaultItems.backgroundImage,
  shadow: settingsDefaultItems.shadow,
  clipContent: settingsDefaultItems.clipContent,
}

const settingsFields = [
  layoutFields.unitBased.width,
  layoutFields.height,
  layoutFields.unitBased.maxWidth,
  layoutFields.maxHeight,
  layoutFields.direction,
  layoutFields.alignContent,
  layoutFields.justifyContent,
  layoutFields.contentSpacing,
  layoutFields.padding,
  layoutFields.alignSelf,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]

const BLOCKS_FIELD_NAME = 'groupContentBlocks';
export const GROUP_TEMPLATE_TYPE = 'groupBlockTemplate';

export const groupBlockTemplate = {
  type: GROUP_TEMPLATE_TYPE,
  name: 'groupBlock',
  key: 'group-block',
  label: 'Group',
  component: 'grouped',
  itemProps: item => ({
    label: item.displayName,
  }),
  defaultItem: {
    displayName: 'Group',
    linkTo: '',
    [BLOCKS_FIELD_NAME]: [],
    settings: {
      sm: defaultGroupBlockSettings,
      xs: applyEnabled(defaultGroupBlockSettings, false, ['backgroundImage', 'borderRadius', 'shadow', 'border', 'padding']),
    },
  },
  fields: [
    {
      name: 'displayName',
      label: 'Name Group',
      component: 'text',
    },
    {
      name: 'linkTo',
      label: 'Link',
      description: 'If it\'s a link within the site (www.understoryweather.com + YOUR_LINK) make sure to prefix it with a "/". The link to the home page is just "/".',
      component: 'text',
    },
    {
      name: BLOCKS_FIELD_NAME,
      label: 'Content',
      component: 'blocks',
      templates: {
        TextBlockTemplate,
        HeadingBlockTemplate,
        subheadingBlockTemplate,
        overlineBlockTemplate,
        ImageBlockTemplate,
        ButtonBlockTemplate,
        textLinkBlockTemplate,
        SpacingBlockTemplate,
        formBlockTemplate,
        videoEmbedBlockTemplate,
        htmlBlockTemplate,
      }
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: settingsFields
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          // description: 'Configure this first. Desktop will always default to these settings if a value is empty or automatic',
          component: 'grouped',
          fields: settingsFields
        }),
      ]
    },

  ],
}

export const nestedGroupBlockTemplate = cloneDeep(groupBlockTemplate);
for (let i = 0; i < groupBlockTemplate.fields.length; i += 1) {
  if (groupBlockTemplate.fields[i].name === BLOCKS_FIELD_NAME) {
    groupBlockTemplate.fields[i].templates[GROUP_TEMPLATE_TYPE] = nestedGroupBlockTemplate;
    break;
  }
}

export const GroupBlock = memo(({ data, index, containerBlock = { }, blocks }) => {
  
  const { linkTo } = data;
  const onPress = useButtonLinkToHandler(linkTo);
  const cms = useCMS();

  const container = useMemoCompare(containerBlock, equal);
  const settings = useBreakpointLayouts(data.settings, containerBlock);

  const itemProps = useMemo(() => {
    let { direction, alignContent, backgroundColor } = settings;

    if (container) {
      if (!backgroundColor || backgroundColor === 'transparent' || backgroundColor === '$transparent') {
        backgroundColor = container.backgroundColor;
      }
    }
    return { containerBlock: { direction, backgroundColor, alignContent } };
  }, [settings, container]);

  const blockStyles = { ...settings.sizeStyle, ...settings.alignSelfStyle, ...settings.overflowStyle, ...settings.shadowStyle };
  if (settings.backgroundColor) blockStyles.backgroundColor = settings.backgroundColor;
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;

  const accessiblility = (!cms.enabled || process.env.NODE_ENV === 'production') && linkTo ? {
    accessibilityRole: 'link',
    href: linkTo,
  } : undefined;
  // if (settings.shadow) blockStyles.shadow = settings.shadow;
  // console.log('GroupBlock', settings);
  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      renderAs={Content}
      accessibility={accessiblility}
      onPress={!cms.enabled && typeof linkTo === 'string' && linkTo.trim() ? onPress : null}
      {...blockStyles}
    >
      {settings.backgroundImage && settings.backgroundImage.src ? <BackgroundImage {...settings.backgroundImage} /> : null}
      <InlineBlocks
        name={BLOCKS_FIELD_NAME}
        blocks={blocks || GROUP_CONTENT_BLOCKS}
        itemProps={itemProps}
        direction={settings.direction}
        wrapperProps={{
          width:"100%",
          height: '100%',
          flexDirection: settings.direction,
          ...settings.layoutStyle,
        }}
      />
    </BlocksControls>
  )
})


export const CONTENT_BLOCKS = {
  [TextBlockTemplate.type]: {
    Component: TextBlock,
    template: TextBlockTemplate
  },
  [HeadingBlockTemplate.type]: {
    Component: HeadingBlock,
    template: HeadingBlockTemplate
  },
  [subheadingBlockTemplate.type]: {
    Component: SubheadingBlock,
    template: subheadingBlockTemplate
  },
  [overlineBlockTemplate.type]: {
    Component: OverlineBlock,
    template: overlineBlockTemplate
  },
  [ImageBlockTemplate.type]: {
    Component: ImageBlock,
    template: ImageBlockTemplate
  },
  [ButtonBlockTemplate.type]: {
    Component: ButtonBlock,
    template: ButtonBlockTemplate,
  },
  [textLinkBlockTemplate.type]: {
    Component: TextLinkBlock,
    template: textLinkBlockTemplate
  },
  [SpacingBlockTemplate.type]: {
    Component: SpacingBlock,
    template: SpacingBlockTemplate
  },
  [formBlockTemplate.type]: {
    Component: FormBlock,
    template: formBlockTemplate
  },
  [videoEmbedBlockTemplate.type]: {
    Component: VideoEmbedBlock,
    template: videoEmbedBlockTemplate,
  },
  [htmlBlockTemplate.type]: {
    Component: HtmlBlock,
    template: htmlBlockTemplate
  }
}

export const NestedGroupBlock = props => {
  return <GroupBlock blocks={CONTENT_BLOCKS} {...props} />
}

export const GROUP_CONTENT_BLOCKS = {
  [nestedGroupBlockTemplate.type]: {
    Component: NestedGroupBlock,
    template: nestedGroupBlockTemplate,
  },
  ...CONTENT_BLOCKS
}

export const GROUP_BLOCKS = {
  [groupBlockTemplate.type]: {
    Component: GroupBlock,
    template: groupBlockTemplate,
  }
}
