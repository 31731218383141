const FONT = 'Centra No2';
const NORMAL = '300';
const BOLD = '500';

export const fonts = {
  default: FONT,
  // if using fontFamily instead of font weights
  light: FONT,
  regular: FONT,
  medium: FONT,
  bold: FONT,
}

export const fontWeights = {
  default: NORMAL,
  // USAGE: Light should be paired with Medium. Regular with Bold. (if different)
  light: NORMAL,
  regular: NORMAL,
  medium: BOLD,
  bold: BOLD,
}
