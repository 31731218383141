import React, {
  useState,
  useImperativeHandle,
  useRef,
  useCallback,
} from 'react';
import { Box } from '../Box';
import { DatePickerToolbar } from './DatePickerToolbar';
import {
  Calendar,
  CalendarViewTypes,
  DEFAULT_ROW_WIDTH,
  DEFAULT_ROW_PADDING,
  DEFAULT_COLUMN_PADDING,
} from '../Calendar';

const DatePicker = React.forwardRef(function DatePicker(props, ref) {
  const {
    onChange, // onValueChange
    date, // momentDate
    // disableKeyboardControl = false, // TODO: implement keyboard controls
    enableToolbar = false,
    minDate, // moment date (min selectable date)
    maxDate, // moment date (max selectable date)
    initialDate, // moment date used if date = null,
    initialView = CalendarViewTypes.day, // 'date', 'year', or 'month'
    views = Object.keys(CalendarViewTypes), // array of views available to show
    ToolbarComponent = DatePickerToolbar,
    ToolbarProps,
    CalendarProps,
    renderDay, // customer render function for day
    renderMonth,
    renderYear,
    renderLoading,
    rowWidth = DEFAULT_ROW_WIDTH,
    rowPadding = DEFAULT_ROW_PADDING,
    columnPadding = DEFAULT_COLUMN_PADDING,
    isDateDisabled, // function that returns true or false for moment date
    onMonthChange, // function that can return a promise if should show loading until return
    onYearChange, // function that can return a promise if should show loading until return
    insets,
    ...rest
  } = props;

  const initialDateRef = useRef(initialDate);

  const [view, setView] = useState(() => {
    if (!Array.isArray(views) || !views.length) {
      console.warn(`DatePicker expects an array of at least one valid viewType: ${Object.keys(CalendarViewTypes).toString()}`);
    }
    if (!initialView || !CalendarViewTypes[initialView]) {
      if (views && views.length && CalendarViewTypes[views[0]]) {
        return views[0];
      }
      console.warn(`DatePicker expects initialView to be a valid viewType: ${Object.keys(CalendarViewTypes).toString()}`);
      return CalendarViewTypes.day;
    }
    return CalendarViewTypes[initialView];
  });

  const startView = useRef(view);

  const calendar = useRef(null);

  const nodeRef = useRef(null);

  const changeView = useCallback((viewTo, d) => {
    if (viewTo) {
      setView(viewTo);
      if (calendar.current && d) {
        calendar.current.setCurrentMonth(d.format('YYYYMM'));
      }
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      setView: changeView,
    }),
    [changeView]
  );

  return (
    <Box justifyContent="center" alignItems="center" ref={nodeRef} {...rest}>
      {enableToolbar && (
        <ToolbarComponent
          date={date}
          view={view}
          views={views}
          changeView={changeView}
          initialView={startView.current}
          rowWidth={rowWidth}
          rowPadding={rowPadding}
          columnPadding={columnPadding}
          {...ToolbarProps}
        />
      )}
      <Calendar
        ref={calendar}
        date={date ? date : initialDate}
        view={view}
        views={views}
        initialDate={initialDateRef.current}
        changeView={setView}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        renderDay={renderDay}
        renderMonth={renderMonth}
        renderYear={renderYear}
        renderLoading={renderLoading}
        isDateDisabled={isDateDisabled}
        onMonthChange={onMonthChange}
        onYearChange={onYearChange}
        rowWidth={rowWidth}
        rowPadding={rowPadding}
        columnPadding={columnPadding}
        insets={insets}
        {...CalendarProps}
      />
    </Box>
  );
});

export { DatePicker };