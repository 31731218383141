import React, { useMemo } from 'react';
import { Content, BackgroundImage } from '../../ui';
import { useTheme } from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { InlineBlocks } from '../InlineBlocks';
import { BlocksControls } from '../BlocksControls';
import { TextBlockTemplate } from '../TextBlock';
import { HeadingBlockTemplate } from '../HeadingBlock';
import { overlineBlockTemplate } from '../OverlineBlock';
import { subheadingBlockTemplate } from '../SubheadingBlock';
import { textLinkBlockTemplate } from '../TextLinkBlock';
import { ButtonBlockTemplate } from '../ButtonBlock';
import { SpacingBlockTemplate } from '../SpacingBlock';
import { ImageBlockTemplate } from '../ImageBlock';
import { HtmlBlock, htmlBlockTemplate } from '../HtmlBlock';
import { GROUP_BLOCKS, CONTENT_BLOCKS, groupBlockTemplate } from '../GroupBlock';
import { GridBoxesBlock, gridBoxesBlockTemplate } from '../GridBoxesBlock';
import { TimelineBlock, timelineBlockTemplate } from '../TimelineBlock';
import {
  styleFields,
  layoutFields,
  applyEnabled,
  settingsDefaultItems,
  applyEnabledToggles,
  marginWidthStyles
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { formBlockTemplate } from '../FormBlock';
import { VsBlock, vsBlockTemplate } from '../VsBlock';
import { VideoEmbedBlock, videoEmbedBlockTemplate } from '../VideoEmbedBlock';

const defaultPageBlockSettings = {
  marginWidth: 'medium',
  height: settingsDefaultItems.height,
  maxHeight: settingsDefaultItems.maxHeight,
  direction: 'row',
  alignContent: 'center',
  justifyContent: '',
  contentSpacing: '0',
  padding: {
    ...settingsDefaultItems.padding,
    top: '16',
    bottom: '16',
  },
  backgroundColor: {
    name: 'gray.50',
    alpha: '',
  },
  opacity: settingsDefaultItems.opacity,
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  backgroundImage: settingsDefaultItems.backgroundImage,
  shadow: settingsDefaultItems.shadow,
  clipContent: false,
}

const settingsFields = [
  layoutFields.marginWidth,
  layoutFields.height,
  layoutFields.maxHeight,
  layoutFields.direction,
  layoutFields.alignContent,
  layoutFields.justifyContent,
  layoutFields.contentSpacing,
  layoutFields.padding,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]


export const pageBlockTemplate = {
  type: 'pageBlockTemplate',
  name: 'pageBlock',
  key: 'page-block',
  label: 'Section',
  component: 'grouped',
  itemProps: item => ({
    label: item.displayName,
  }),
  defaultItem: {
    displayName: 'Block',
    contentBlocks: [],
    settings: {
      sm: defaultPageBlockSettings,
      xs: {
        ...applyEnabled(defaultPageBlockSettings, false, ['backgroundImage', 'borderRadius', 'shadow', 'border', 'padding']),
        direction: 'column',
        direction_enabled: true,
      }
    },
  },
  fields: [
    {
      name: 'displayName',
      label: 'Section Name',
      description: 'Named Sections can be linked to with a button url.',
      component: 'text',
    },
    {
      name: 'contentBlocks',
      label: 'Content Blocks',
      description: 'Highly recommend you use the Group block to better contain and layout parts to a Section.',
      component: 'blocks',
      templates: {
        groupBlockTemplate,
        TextBlockTemplate,
        HeadingBlockTemplate,
        subheadingBlockTemplate,
        overlineBlockTemplate,
        ImageBlockTemplate,
        ButtonBlockTemplate,
        textLinkBlockTemplate,
        SpacingBlockTemplate,
        formBlockTemplate,
        videoEmbedBlockTemplate,
        gridBoxesBlockTemplate,
        timelineBlockTemplate,
        vsBlockTemplate,
        htmlBlockTemplate
      }
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: settingsFields
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          // description: 'Configure this first. Desktop will always default to these settings if a value is empty or automatic',
          component: 'grouped',
          fields: settingsFields
        }),
      ]
    }
  ],
}




const PAGE_INLINE_BLOCKS = {
  ...GROUP_BLOCKS,
  ...CONTENT_BLOCKS,
  [videoEmbedBlockTemplate.type]: {
    Component: VideoEmbedBlock,
    template: videoEmbedBlockTemplate,
  },
  [gridBoxesBlockTemplate.type]: {
    Component: GridBoxesBlock,
    template: gridBoxesBlockTemplate
  },
  [timelineBlockTemplate.type]: {
    Component: TimelineBlock,
    template: timelineBlockTemplate
  },
  [vsBlockTemplate.type]: {
    Component: VsBlock,
    template: vsBlockTemplate
  },
  [htmlBlockTemplate.type]: {
    Component: HtmlBlock,
    template: htmlBlockTemplate
  }
}

export const PageBlock = memo(({ data, index }) => {
  const { displayName } = data;
  const settings = useBreakpointLayouts(data.settings);
  const itemProps = useMemo(() => {
    return { containerBlock: {
      direction: settings.direction,
      backgroundColor: settings.backgroundColor,
      alignContent: settings.alignContent,
    }}
  }, [settings]);


  // console.log('settings', settings);
  // const { settings } = data;
  // const { xs, sm } = settings;
  const theme = useTheme();
  // const { breakpoints } = theme;
  const blockStyles = { ...settings.sizeStyle, ...settings.overflowStyle, ...settings.shadowStyle };
  if (settings.backgroundColor) blockStyles.backgroundColor = settings.backgroundColor;
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;
  // console.log('PageBlock', settings);
  const inset = index === 0 ? { top: -90, bottom: 0 } : true;
  return (
    <BlocksControls
      {...(displayName ? { id: displayName } : null)}
      focusRing={{offset: -20 }}
      index={index}
      renderAs={Content}
      insetControls={inset}
      {...blockStyles}
      width="100%"
    >
      {settings.backgroundImage && settings.backgroundImage.src ? <BackgroundImage {...settings.backgroundImage} /> : null}
      <InlineBlocks
        name="rawJson.contentBlocks"
        itemProps={itemProps}
        direction={settings.direction}
        wrapper={Content}
        blocks={PAGE_INLINE_BLOCKS}
        wrapperProps={{
          width:"100%",
          flexDirection: settings.direction,
          log: displayName === 'hero',
          ...settings.layoutStyle,
          ...(settings.marginWidth ? marginWidthStyles[settings.marginWidth](theme) : null)
        }}
      />
    </BlocksControls>
  )
})




export const PAGE_BLOCKS = {
  [pageBlockTemplate.type]: {
    Component: PageBlock,
    template: pageBlockTemplate,
  }
}

export const InlinePageBlocks = ({ name = 'pageBlocks', itemProps }) => {
  return (
    <InlineBlocks
      name={name}
      blocks={PAGE_BLOCKS}
      direction="vertical"
      itemProps={itemProps}
    />
  )
}
