import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from '@reach/router';
import { Box, Text, Menu, MenuItem, Image } from '../ui';
import { useMatchLocalizedData } from '../editing/useEditingLocale';

const globeIcon = require('../../static/assets/locale_globe_icon.png');
const globeArrowIcon = require('../../static/assets/locale_globe_dropdown_icon.png');

export const SiteLocaleSelect = props => {
  const json = useStaticQuery(graphql`
    query LocalesQuery {
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        defaultLocale
        locales {
          country
          countryCode
          language
          languageCode
          url
        }
        configs {
          locale
        }
      }
    }
  `);

  const {
    hidden,
    small,
    menuProps,
    ...rest
  } = props;

  const data = json.site
  const [site,] = useMatchLocalizedData(data.configs, { locale: data.defaultLocale });

  const btnRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (hidden && menuOpen) {
      setMenuOpen(false);
    }
  }, [hidden, menuOpen])


  let currLocale = null;
  for (const locale of data.locales) {
    const code = `${locale.languageCode}-${locale.countryCode}`;
    if (site && site.locale === code) {
      currLocale = locale;
    }
  }

  if (!currLocale || hidden) {
    return null;
  }

  const options = data.locales.map((locale) => {
    const { country, language, url, countryCode, languageCode } = locale;
    return (
      <MenuItem
        key={`${languageCode}-${countryCode}option`}
        onPress={() => {
          setMenuOpen(false);
          navigate(url);
        }}
      >
        <Text noWrap>{language}</Text>
        <Text noWrap dim small>{country}</Text>
      </MenuItem>
    )
  })

  return (
    <>
      <Box
        px="$2.5"
        py="$1.25"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        onPress={() => {
          setMenuOpen(open => !open);
        }}
        ref={btnRef}
        {...rest}
      >
        {
          small ? (
            <Image src={globeArrowIcon} height={16} opacity={0.7} width={40} />
          ) : (
            <>
              <Image src={globeIcon} width={16} height={16} opacity={0.7} />
              <Text bold color={"$gray.600"} padLeft={8}>
                {`${currLocale.language} (${currLocale.countryCode})`}
              </Text>
            </>
          )
        }
      </Box>
      <Menu
        open={menuOpen}
        anchorNode={btnRef.current}
        onClose={() => {
          setMenuOpen(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorOffset={{ vertical: 0, horizontal: 0 }}
        disableScrollLock
        {...menuProps}
      >
        {options}
      </Menu>
    </>
  )
}