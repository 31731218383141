import React from 'react';
import { Text } from '../Text';
import { Box } from '../Box';
import { withStyles } from '../../styling';


const TextCount = withStyles(
  ({ onColor, theme }) => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '100%',
      height: '100%',
      zIndex: 5,
      props: {
        pointerEvents: 'box-none',
      },
    },
    'textCount.text': {
      color: theme.colors.on(onColor) || 'black',
      opacity: 0.6,
      props: {
        size: 'xSmall',
        maxLines: 1,
      },
    },
  }),
  { name: 'TextCount', filterProps: ['onColor'] }
)(React.forwardRef(function TextCount({ value, renderCount, maxLength, styles, ...props }, ref) {
  const count = typeof value === 'string' ? value.length : 0;
  return (
    <Box disableAnimate {...props}>
      <Text style={styles['textCount.text']} {...styles.props['textCount.text']}>
        {renderCount ? renderCount({ count, value, maxLength }) : maxLength ? (
          `${count} / ${maxLength}`
        ) : count ? `${count}` : null}
      </Text>
    </Box>
  )
}));

export { TextCount };
