import { useRef } from 'react';

// returns the latest ref each time
const useLatest = current => {
  const storedValue = useRef(current);
  storedValue.current = current;
  return storedValue;
}

export { useLatest };
