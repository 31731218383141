import React from 'react';
import { Select } from '../Select';
import { withStyles } from '../../styling';
import { FilledInput, UnderlinedInput, OutlinedInput } from '../TextInput';
import { Label } from './Label';
import { HelperText } from './HelperText';

const variantInputComponents = {
  filled: FilledInput,
  underlined: UnderlinedInput,
  outlined: OutlinedInput,
};

const TextField = withStyles(
  ({ onColor: onColorProp = '$background', label, theme } = {}) => {
    const onColor = theme.colors.on(onColorProp) || 'white';
    const placeholderTextColor = theme.colors.alpha(onColor, 0.5);
    return {
      root: {
        marginY: '$1',
      },
      textInput: {},
      textBox: {},
      input: {
        props: {
          placeholderTextColor: label ? theme.colors.alpha(onColor, 0) : placeholderTextColor,
        },
      },
      inputFocused: {
        props: {
          placeholderTextColor: placeholderTextColor,
        },
      },
      inputFilled: {
        props: {
          placeholderTextColor: placeholderTextColor,
        },
      },
      label: {},
      helperText: {},
    };
  },
  {
    name: 'TextField',
  }
)(
  React.forwardRef(function TextField(props, ref) {
    const {
      label,
      LabelProps,
      required = false,
      HelperTextProps,
      helperText,
      InputProps,
      inputRef,
      styles,
      variant = 'filled',
      type,
      color,
      onColor,
      // TextInput props
      ...rest
    } = props;
    let Component = variantInputComponents[variant];
    const inputProps = {
      ...InputProps,
      ...rest,
    };
    // const filled = (inputProps.value === '' || inputProps.value === null || inputProps.value === undefined);

    if (type === 'select') {
      inputProps.variant = variant;
      Component = Select;
    }

    return (
      <Component
        styles={styles}
        inputRef={inputRef}
        includeLabelSpacing={label ? true : false}
        {...inputProps}
        onColor={onColor}
        color={color}
        renderBefore={label ? ({ styles: s, ...p }) => (
          <Label
            style={s.label}
            required={required}
            size={s.props.input.size}
            textBoxStyle={s.textBox}
            {...s.props.label}
            {...LabelProps}
            color={color}
            inputVariant={variant}
            {...p}
          >
            {label}
          </Label>
        ) : null}
        renderBelow={({ styles: s, ...p }) => (
          <HelperText
            style={s.helperText}
            {...s.props.helperText}
            {...HelperTextProps}
            inputVariant={variant}
            {...p}
          >
            {helperText || " "}
          </HelperText>
        )}
      />
    );
  })
);

TextField.Label = Label;
TextField.HelperText = HelperText;

export { TextField };
