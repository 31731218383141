import React, { useMemo, useRef, useCallback } from 'react';
import moment from 'moment-timezone';
import { Box } from '../Box';
import { Text } from '../Text';
import { Day } from './Day';

const stored = {};
function getWeeksWithDaysForMonth(monthString) {
  if (monthString && stored[monthString]) {
    return stored[monthString];
  }
  const month = moment(monthString, 'YYYYMM');
  let currMonth = month.month(); //get this month number (1-12)
  let currDate = month.clone().startOf('month'); //get first day in this month (moment obj)

  let weeks = [];

  do {
    let days = [];
    for (let i = 0; i < 7; i += 1) {
      const day = { hidden: false };
      // if the currDate is still within the 7 days / index and still this month
      if (i === currDate.days() && currDate.month() === currMonth) {
        day.isCurrentMonth = true;
        day.date = currDate.clone();
        currDate.add(1, 'day');
      } else {
        day.isCurrentMonth = false;
        if (currDate.month() === currMonth) {
          day.date = currDate.clone().subtract(currDate.days() - i, 'day');
        } else {
          day.date = currDate.clone();
          currDate.add(1, 'day');
        }
      }
      day.day = day.date.format('YYYYMMDD');
      days.push(day);
    }

    weeks.push(days);
  } while (currDate.month() === currMonth);

  if (weeks.length) {
    // add in a hidden week to maintain height of calendar and save resources from mounting/unmounting days
    if (weeks.length < 6) {
      const hiddenDays = [];
      for (let i = 0; i < 7; i += 1) {
        const hiddenDay = { ...weeks[weeks.length - 1][i] };
        hiddenDay.hidden = true;
        hiddenDays.push(hiddenDay);
      }
      weeks.push(hiddenDays);
    }
    stored[monthString] = weeks;
  }
  return weeks;
}

const DaySelect = React.memo(function DaySelect(props) {
  const {
    date: selectedDate,
    month,
    minDate,
    maxDate,
    renderDay,
    onChange,
    isDateDisabled,
    loading,
    renderLoading,
    viewHeight,
    rowWidth,
    rowPadding,
    columnPadding,
    hideWeekdays = false,
    hideDaysOutsideCurrentMonth = false,
    disableHighlightToday = false,
    ...rest
  } = props;

  const todayRef = useRef(moment().format('YYYYMMDD'));
  const weeks = useMemo(() => {
    return getWeeksWithDaysForMonth(month);
  }, [month])

  const today = todayRef.current;
  const minDay = minDate ? minDate.format('YYYYMMDD') : null;
  const maxDay = maxDate ? maxDate.format('YYYYMMDD') : null;
  const selectedDay = selectedDate ? selectedDate.format('YYYYMMDD') : null;

  const daySize = useMemo(() => {
    const size = rowWidth / 7;
    return {
      size,
      columnPadding,
      rowPadding,
    };
  }, [rowWidth, rowPadding, columnPadding]);

  return (
    <Box
      justifyContent="flex-start"
      alignItems="center"
      maxHeight={viewHeight}
      {...rest}
    >
      {!hideWeekdays && (
        <Weekdays
          dayWidth={daySize.size}
          marginY={rowPadding}
        />
      )}
      {weeks.map((days, i) => (
        <WeekRow key={`week${i}`}>
          {days.map((dayConfig, j) => {
            const { day, date, isCurrentMonth, hidden } = dayConfig;
            let disabled = false;
            if (isDateDisabled) {
              disabled = isDateDisabled(date);
            } else {
              if (minDay && day < minDay) {
                disabled = true;
              }
              if (!disabled && maxDay && day > maxDay) {
                disabled = true;
              }
            }
            const dayProps = {
              key: `day${j}`,
              isToday: !hidden && day === today,
              selected: !hidden && selectedDay === day,
              focused: !hidden && selectedDay === day, // focused primarily used for multiple selected dates in a date range where only the start and end dates are focused and rest are not
              disabled,
              minDate,
              maxDate,
              date,
              isCurrentMonth,
              hideDaysOutsideCurrentMonth,
              disableHighlightToday,
              onSelect: onChange,
              hidden: hidden,
              ...daySize,
            };
            if (renderDay) {
              return renderDay(date, selectedDate, dayProps);
            }
            return <Day {...dayProps} />;
          })}
        </WeekRow>
      ))}
    </Box>
  );
});

const WeekRow = React.memo(function WeekRow(props) {
  return <Box flexDirection="row" width="100%" {...props} />;
});

const Weekdays = React.memo(function Weekdays(props) {
  const { dayWidth, TextProps, ...rest } = props;
  const border = {
    bottom: {
      width: 1,
      color: '$gray.50',
      style: 'solid',
    },
  };
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      height={dayWidth}
      border={border}
      {...rest}
    >
      {moment.weekdaysMin().map((day, i) => (
        <Text
          width={dayWidth || '14.285%'}
          center
          small
          dim={0.9}
          key={`weekday${i}`}
          selectable={false}
          {...TextProps}
        >
          {day}
        </Text>
      ))}
    </Box>
  );
});

export { DaySelect };
