import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Dimensions } from 'react-native';
import equal from 'fast-deep-equal/react';
import { useForkRef } from '../../hooks';
import { debounce, resolveNode } from '../../utils';
import { Box } from '../Box';
import { Modal } from '../Modal';
import { withStyles } from '../../styling';

const Popover = withStyles({
  root: {},
  contentContainer: {
    position: 'absolute',
  },
}, { name: 'Popover' })(React.forwardRef(function Popover(props, ref) {
  const {
    open, // modal prop indicating visiblity/focus state
    ContentContainerProps, // props for the popover container box
    anchorNode, // node to anchor to (ref.current should be whats passed)
    anchorOrigin = {
      vertical: 'top', // [top, center, bottom]
      horizontal: 'left', // [left, center, right]
    },
    anchorPosition, // alternative to anchorOrigin. { top, left } - object with numbers (px)
    anchorOffset = {
      vertical: 0,
      horizontal: 0,
    }, // { x: 0, y: 0 } px
    transformOrigin = {
      vertical: 'top', // [top, center, bottom, px-number]
      horizontal: 'left', // [top, center, bottom, px-number]
    },
    adjustPositionStyle,
    marginThreshold = 16, // how close to the edge of the window the popover can appear,
    onEnter,
    children,
    styles,
    ...rest
  } = props;

  const mounted = useRef(true);
  useEffect(() => { return () => { mounted.current = false; } }, []);

  const modalRef = useRef(null);
  const handleRef = useForkRef(modalRef, ref);
  const contentContainerRef = useRef(null);
  const prevStylesRef = useRef();
  const [positionStyles, setPositionStyles] = useState(() => null);

  const measureAnchorPosition = useCallback((callback) => {
    const pos = { top: 0, left: 0 };
    let anchorLayout = { };
    if (anchorPosition) {
      pos.top = (anchorPosition.top || 0) + (anchorOffset.vertical || 0);
      pos.left = (anchorPosition.left || 0) + (anchorOffset.horizontal || 0);
      callback(pos, anchorLayout);
      return
    }
    const anchor = resolveNode(anchorNode);
    if (anchor && anchor.measure) {
      // TODO: (reverse order of all this and have modal measure come first? maybe?)
      anchor.measure((x, y, width, height, pageX, pageY) => {
        pos.top = pageY + (anchorOffset.vertical || 0);
        pos.left = pageX + (anchorOffset.horizontal || 0);
        if (anchorOrigin.vertical === 'bottom') {
          pos.top += height;
        } else if (anchorOrigin.vertical === 'center') {
          pos.top += height / 2;
        }
        if (anchorOrigin.horizontal === 'right') {
          pos.left += width;
        } else if (anchorOrigin.horizontal === 'center') {
          pos.left += width / 2;
        }

        anchorLayout = { x, y, width, height, pageX, pageY };
        callback(pos, anchorLayout);
      })
    } else {
      console.warn(
        'Cannot measure popover anchor node.',
        'Forwarded ref should be passed down to raw RN component.',
        anchorNode,
      );
      callback(pos, anchorLayout);
    }
  }, [
    anchorNode,
    anchorPosition,
    anchorOrigin.vertical,
    anchorOrigin.horizontal,
    anchorOffset.vertical,
    anchorOffset.horizontal,
  ])


  const computePositionStyles = useCallback((callback, node) => {
    measureAnchorPosition((anchorPos, anchorLayout) => {
      const styles = {
        translateX: 0,
        translateY: 0,
        ...anchorPos
      };
      const layoutItems = {
        anchor: anchorLayout,
        contentContainer: { },
        parentContainer: { },
      }
      const contentContainer = resolveNode(node);
      if (contentContainer && contentContainer.measure) {
        contentContainer.measure((x, y, width, height, pageX, pageY) => {
          layoutItems.contentContainer = { x, y, width, height, pageX, pageY };
          if (typeof transformOrigin.vertical === 'number' || !isNaN(transformOrigin.vertical * 1)) {
            styles.translateY = transformOrigin.vertical * 1;
          } else if (transformOrigin.vertical === 'bottom') {
            styles.translateY -= height;
          } else if (transformOrigin.vertical === 'center') {
            styles.translateY -= height / 2;
          }

          if (typeof transformOrigin.horizontal === 'number' || !isNaN(transformOrigin.horizontal * 1)) {
            styles.translateX = transformOrigin.horizontal * 1;
          } else if (transformOrigin.horizontal === 'bottom') {
            styles.translateX -= height;
          } else if (transformOrigin.horizontal === 'center') {
            styles.translateX -= height / 2;
          }

          const parentContainer = resolveNode(modalRef.current);
          if (parentContainer && parentContainer.measure) {
            parentContainer.measure((px, py, pWidth, pHeight, pPageX, pPageY) => {
              layoutItems.parentContainer = { x: px, y: py, width: pWidth, height: pHeight, pageX: pPageX, pageY: pPageY };
              const top = styles.top + styles.translateY;
              const left = styles.left + styles.translateX;
              const bottom = top + height;
              const right = left + width;

              const heightThreshold = pHeight - marginThreshold;
              const widthThreshold = pWidth - marginThreshold;

              if (top < marginThreshold) {
                const diff = marginThreshold - top;
                styles.translateY += diff;
              } else if (bottom > heightThreshold) {
                const diff = bottom - heightThreshold;
                styles.translateY -= diff;
              }

              if (left < marginThreshold) {
                const diff = marginThreshold - left;
                styles.translateX += diff;
              } else if (right > widthThreshold) {
                const diff = right - widthThreshold;
                styles.translateX -= diff;
              }

              callback(styles, layoutItems);
            })
          } else {
            console.warn(
              'Cannot measure modal parent node.',
              'Forwarded ref should be passed down to raw RN component.',
              modalRef.current,
            );
            callback(styles, layoutItems);
          }
        })
      } else {
        console.warn(
          'Cannot measure container anchor node.',
          'Forwarded ref should be passed down to raw RN component.',
          contentContainerRef.current,
        );
        callback(styles, layoutItems);
      }
    })
  }, [
    measureAnchorPosition,
    marginThreshold,
    transformOrigin.vertical,
    transformOrigin.horizontal,
  ])

  const updatePositionStyles = useCallback((node) => {
    const element = contentContainerRef.current || node;
    if (!element || !mounted.current) {
      return;
    }
    computePositionStyles((positionStyles, layoutItems) => {
      if (mounted.current && positionStyles) {
        let styles = roundStyles(positionStyles);
        if (adjustPositionStyle && typeof adjustPositionStyle === 'function') {
          styles = adjustPositionStyle(styles, layoutItems) || styles;
        }
        if (!equal(prevStylesRef.current, styles)) {
          prevStylesRef.current = styles;
          setPositionStyles(() => styles);
        }
      }
    }, element);
  }, [computePositionStyles, adjustPositionStyle])

  const handleEntering = useCallback((node) => {
    if (onEnter) {
      onEnter(node);
    }
    updatePositionStyles(node);
  }, [onEnter, updatePositionStyles]);

  useEffect(() => {
    if (open && mounted.current) {
      updatePositionStyles();
    }
  });

  useEffect(() => {
    if (!open) {
      return undefined;
    }

    const handleResize = debounce(() => {
      mounted.current && updatePositionStyles();
    });

    Dimensions.addEventListener('change', handleResize)
    return () => {
      handleResize.clear();
      Dimensions.removeEventListener('change', handleResize)
    }
  }, [open, updatePositionStyles]);

  return (
    <Modal
      open={open}
      ref={handleRef}
      BackdropProps={{ invisible: true }}
      onEnter={handleEntering}
      {...rest}
    >
      <Box
        style={styles.contentContainer}
        disableAnimationDefaults
        onPress={() => {}}
        ref={contentContainerRef}
        {...styles.props.contentContainer}
        {...ContentContainerProps}
        {...positionStyles}
      >
        {children}
      </Box>
    </Modal>
  )
}));

function roundStyles({ left = 0, top = 0, translateX = 0, translateY = 0 } = { }) {
  return {
    left: Math.round(left),
    top: Math.round(top),
    translateX: Math.round(translateX),
    translateY: Math.round(translateY)
  };
}

export { Popover }
