import React from 'react';
import { Platform } from 'react-native';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { backgroundColor, border, flexBox, shadow } from '../../system/props';

const filterProps = [
  'maxWidth',
  ...backgroundColor.filterProps,
  ...border.filterProps,
  ...flexBox.filterProps,
  ...shadow.filterProps,
];

const MenuList = withStyles(({ maxWidth = 360, ...props }) => ({
  root: {
    maxWidth,
    ...backgroundColor({ bg: '$white', ...props }),
    ...border({
      borderWidth: 1,
      borderRadius: 3,
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.04)',
      ...props,
    }),
    ...flexBox({
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      ...props,
    }),
    ...shadow({
      shadow: {
        color: 'rgb(49, 51, 51)',
        offset: { y: 5 },
        opacity: 0.07,
        radius: 28,
      },
      ...props,
    }),
  },
}), { name: 'MenuList', filterProps })(React.forwardRef(function MenuList(props, ref) {
  const { accessibility, autofocus, styles, ...rest } = props;
  const menuProps = {
    accessibility: {
      accessible: true,
      accessibilityRole: 'menu',
      ...accessibility,
    },
  };
  if (Platform.OS === 'web') {
    menuProps.tabIndex = autofocus ? 0 : -1;
  }
  return (
    <Box
      ref={ref}
      {...menuProps}
      {...rest}
    />
  );
}));

export { MenuList }