import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useLocation, navigate } from '@reach/router';
import { useCMS } from 'tinacms';
import { useMemoCompare } from '../ui/hooks';
import { sleep } from '../ui/utils';

export const DEFAULT_LOCALE = 'en-US';

export const SupportedLocales = [
  'en-US',
  'fr-FR',
  'es-ES',
  'it-IT',
];

export function matchPathToLocale(path = '/') {
  for (let i = 0; i < SupportedLocales.length; i += 1) {
    const locale = SupportedLocales[i];
    const localePath = `/${locale}`;
    if (path.startsWith(localePath)) {
      return locale;
    }
  }
  return DEFAULT_LOCALE;
}

export function usePathLocale() {
  const { pathname } = useLocation() || { pathname: '/' };
  return matchPathToLocale(pathname);
}

export function useLocaleLinkInDevelopment(href) {
  const locale = usePathLocale();
  return getLocaleLinkInDevelopment(href, locale);
}

export function getLocaleLinkInDevelopment(href, locale) {
  if (href && process.env.NODE_ENV !== 'production' && locale !== DEFAULT_LOCALE) {
    if (href.startsWith('/') || href.startsWith('#')) {
      return `/${locale}${href}`;
    }
  }
  return href;
}

export function useMatchLocalizedData(dataSet = [], options = { }) {
  const pathLocale = usePathLocale();
  const siteLocale = options.locale || pathLocale || DEFAULT_LOCALE;
  const data = useMemoCompare(dataSet);
  const localeIndex = useMemo(() => {
    for (let i = 0; i < data.length; i += 1) {
      if (siteLocale === data[i].locale) {
        return i;
      }
    }
    return 0;
  }, [data, siteLocale])
  const match = useMemo(() => {
    if (!data.length) {
      return [null, null];
    }
    return [data[localeIndex], localeIndex];
  }, [localeIndex, data])
  return useMemoCompare(match);
}

export const EditingLocaleLocationUpdater = () => {
  const cms = useCMS();
  const locale = useEditingLocale();
  const pathLocale = usePathLocale();
  useEffect(() => {
    if (cms.enabled && process.env.NODE_ENV !== 'production' && pathLocale !== locale.current && pathLocale !== locale.next) {
      locale.switchTo(pathLocale);
    }
  }, [pathLocale, locale, cms.enabled])
  return null;
}

export const editingLocaleContext = React.createContext({
  current: DEFAULT_LOCALE,
  next: DEFAULT_LOCALE,
  list: SupportedLocales,
  switchTo: () => null,
})

export const ProvideEditingLocale = ({ children }) => {
  // see if the locale is matchable from the path instead of falling back to
  // default, since that triggers a switchTo and redirects (clobbering whatever
  // else was on the path) once the state loads
  const initialLocale = matchPathToLocale(window.location.pathname);

  const [current, setCurrent] = useState(initialLocale);
  const [next, setNext] = useState(initialLocale);

  const localeState = useMemo(() => ({
    current,
    next,
    list: SupportedLocales,
    switchTo: async (opt, subPath) => {
      let locale = opt;
      if (locale === current && !subPath) return;
      if (SupportedLocales.indexOf(locale) === -1) {
        console.warn(
          `Cannot switch to unsupported locale: ${locale}. `,
          `Supported locales: ${SupportedLocales.toString()}`,
        );
        return;
      }
      const localePath = locale === DEFAULT_LOCALE ? '/' : `/${locale}`;
      let navTo = localePath;
      if (subPath) {
        if (localePath === '/') {
          navTo = subPath;
        } else {
          navTo = `${localePath}${subPath}`;
        }
      }
      console.log(locale, navTo);
      setNext(locale);
      navigate(navTo);
      await sleep(300);
      setCurrent(locale);
    }
  }), [current, next]);

  return (
    <editingLocaleContext.Provider value={localeState}>
      {children}
    </editingLocaleContext.Provider>
  )
}

export function useEditingLocale() {
  return useContext(editingLocaleContext);
}


