import React from 'react';
import { Button } from '../../ui';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';
import { applyEnabledToggles, layoutFields, styleFields, settingsDefaultItems, applyEnabled } from '../fieldConfigs';
import { useBreakpointLayouts, getUnitValue } from '../useBreakpointLayouts';
import { useFormBlock } from './useFormBlock';

export const SubmitFormButtonBlock = memo(({ data, index, containerBlock = { }, ...rest }) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const { buttonType, buttonSize, buttonColor, bolded, padding, alignSelfStyle, shadowStyle } = settings;
  const blockStyles = { ...alignSelfStyle };
  if (padding) blockStyles.padding = padding;
  const buttonSizeStyle = { };
  let bWidth = null;
  if (settings.width) bWidth = getUnitValue(settings.width);
  if (bWidth) {
    buttonSizeStyle.width = '100%';
    blockStyles.width = bWidth;
  }
  const form = useFormBlock();

  return (
    <BlocksControls {...blockStyles} index={index}>
      <Button
        onPress={() => (!form.disabled && !form.loading) && form.submit()}
        variant={buttonType}
        disabled={form.loading}
        size={buttonSize}
        color={buttonColor ? `$${buttonColor}` : null}
        regular={bolded ? false : true}
        {...shadowStyle}
        {...buttonSizeStyle}>
        {form.loading ? '...' : data.text}
      </Button>
    </BlocksControls>
  )
});

const buttonSettingFields = [
  {
    name: 'buttonType',
    label: 'Type',
    component: 'select',
    options: [
      { label: 'Contained', value: 'contained' },
      { label: 'Outlined', value: 'outlined' },
      { label: 'Text', value: 'text' },
    ],
  },
  {
    name: 'buttonSize',
    label: 'Size',
    component: 'select',
    options: [
      { label: 'Large', value: 'large' },
      { label: 'Medium', value: 'medium' },
      { label: 'Small', value: 'small' },
    ]
  },
  {
    ...styleFields.backgroundColor,
    name: 'buttonColor',
    label: 'Color',
  },
  {
    name: 'bolded',
    label: 'Bold Text',
    component: 'toggle',
  },
  layoutFields.unitBased.width,
  layoutFields.padding,
  layoutFields.alignSelf,
  styleFields.shadow,
]

const defaultButtonBlockSettings = {
  buttonType: 'contained',
  buttonSize: 'large',
  buttonColor: {
    name: '',
    alpha: '',
  },
  bolded: true,
  width: {
    value: '',
    unit: '%',
  },
  padding: settingsDefaultItems.padding,
  alignSelf: settingsDefaultItems.alignSelf,
  shadow: settingsDefaultItems.shadow
}

export const submitFormButtonBlockTemplate = {
  type: 'submitFormButtonBlockTemplate',
  name: 'submitFormButtonBlock',
  key: 'submit-Form-Button-Block',
  label: 'Submit Button',
  component: 'grouped',
  defaultItem: {
    text: 'Submit',
    settings: {
      sm: defaultButtonBlockSettings,
      xs: applyEnabled(defaultButtonBlockSettings, false, ['padding', 'shadow'])
    },
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'text'
    },
    // TODO: onClick field for button, text, and group blocks... most likely a dropdown and different fields per option
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: buttonSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: buttonSettingFields
        })
      ]
    }
  ]
}
