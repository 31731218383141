import { withFormFieldData } from './useFormBlock';
import {
  BaseFormFieldBlock,
  createFormFieldTemplate,
} from './BaseFormFieldBlock';

export const TextFormFieldBlock = withFormFieldData(BaseFormFieldBlock);

export const textFormFieldBlockTemplate = createFormFieldTemplate({
  type: 'textFormFieldBlockTemplate',
  name: 'textFormFieldBlock',
  key: 'text-Form-Field-Block',
  label: 'Text Field',
});

export const emailFormFieldBlockTemplate = createFormFieldTemplate({
  type: 'emailFormFieldBlockTemplate',
  name: 'emailFormFieldBlock',
  key: 'email-Form-Field-Block',
  label: 'Email Field',
  defaultItem: {
    requiredMessage: '* Email required',
    invalidMessage: 'Please input a valid email address',
  },
})

export const phoneFormFieldBlockTemplate = createFormFieldTemplate({
  type: 'phoneFormFieldBlockTemplate',
  name: 'phoneFormFieldBlock',
  key: 'phone-Form-Field-Block',
  label: 'Phone Number Field',
  defaultItem: {
    requiredMessage: '* Phone number required',
    invalidMessage: 'Please input a valid phone number',
  },
})
