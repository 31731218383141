import React, { useState, useRef, useMemo } from 'react';
import { ScrollView } from 'react-native';
import { useStaticQuery, graphql } from 'gatsby';
import { useJsonForm } from 'gatsby-tinacms-json';
import { useFormScreenPlugin } from 'tinacms';
import { styled, withStyles } from '../ui/styling';
import { navigate } from '@reach/router';
import { useCMS } from 'tinacms';
import {
  Box,
  Content,
  Header as BaseHeader,
  Menu,
  MenuItem,
  Modal,
} from '../ui';
import {
  useForkRef,
  useScrollPositionEffect,
  useMatchBreakpoint,
  useTheme,
} from '../ui/hooks';
import { memo, debounce } from '../ui/utils';
import {
  ImageBlockTemplate,
  ImageBlock,
  ButtonBlock,
  ButtonBlockTemplate,
  BaseButtonBlock,
  useButtonLinkToHandler,
  settingsDefaultItems,
  applyEnabled,
  useBreakpointLayouts
} from '../blocks';
import { useMatchLocalizedData, useLocaleLinkInDevelopment } from '../editing/useEditingLocale';
import { SiteLocaleSelect } from './SiteLocaleSelect';
import { useHoverable } from '../ui/components/Hoverable';

const HEADER_HEIGHT_BREAKPOINTS = { xs: 64, sm: 84 };

const NavBar = withStyles({
  root: ({ theme: { colors, shadows, spacing }}) => ({
    bg: colors.white,
    shadow: shadows.header,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    animations: {
      default: {
        backgroundColor: colors.opacity(colors.white, 1),
        shadow: shadows.header,
        paddingTop: spacing(1),
        borderBottomColor: colors.opacity(colors.gray['100'], 1),
        config: {
          tension: 500,
          friction: 40,
        }
      },
      top: {
        backgroundColor: colors.opacity(colors.white, 0.75),
        shadow: { ...shadows.header, opacity: 0 },
        paddingTop: spacing(3),
        borderBottomColor: colors.opacity(colors.gray['100'], 0.25),
      },
      hovered: {
        backgroundColor: colors.opacity(colors.white, 1),
      }
    }
  }),
  [Content]: ({ theme: { breakpoints }}) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: breakpoints(HEADER_HEIGHT_BREAKPOINTS),
    width: '100%',
    marginX: 'auto',
    maxWidth: breakpoints({ xs: '88%', lg: 1380 })
  }),
})(({ styles, children, ...props }) => {
  const [animate, setAnimate] = useState('top');
  const { hovered, ...handlers } = useHoverable();
  useScrollPositionEffect(({ prevPos, currPos }) => {
    if (currPos.y > -20) {
      setAnimate('top');
    } else {
      setAnimate('default');
    }
  });

  return (
    <BaseHeader {...handlers} animate={hovered ? [animate, 'hovered'] : animate} fixed style={styles.root} {...styles.props.root} {...props}>
      <Content variant="container" style={styles[Content]}>
        {children}
      </Content>
    </BaseHeader>
  )
})

NavBar.displayName = 'NavBar';

const MemoNavBar = React.memo(NavBar);

MemoNavBar.displayName = 'MemoNavBar';

const MenuIcon = ({ mobileNavOpen, setMobileNavOpen }) => {
  const open = mobileNavOpen;

  const lineProps = {
    width: '100%',
    height: 2,
    borderRadius: '$circle',
    backgroundColor: '$gray.500',
  }

  const animations = {
    opened: { rotate: '45deg' },
    default: { rotate: '0deg' }
  }

  const hiddenLineAnimations = {
    opened: { ...animations.opened, scale: 1 },
    default: { ...animations.default, scale: 0 }
  }

  const animate = open ? 'opened' : 'default';

  const otherLineProps = {
    ...lineProps,
    animations: {
      opened: { scale: 0 },
      default: { scale: 1 }
    },
    animate
  }

  return (
    <Box
      height="100%"
      padX="$2"
      padTop={8}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      onPress={() => setMobileNavOpen(currState => !currState)}
      disableAnimationDefaults
    >
      <Box
        width={25}
        gap={4}
        disableAnimationDefaults
        animations={animations}
        animate={animate}
      >
        <Box {...otherLineProps} disableAnimationDefaults />
        <Box {...lineProps} disableAnimationDefaults />
        <Box {...otherLineProps} disableAnimationDefaults />
      </Box>
      <Box
        position="absolute"
        width="2"
        height="25"
        borderRadius="$circle"
        bg="$gray.500"
        disableAnimationDefaults
        animations={hiddenLineAnimations}
        animate={animate}
      />
    </Box>
  );
}

const Nav = styled(React.forwardRef(function Nav(props, ref) {
  const { navItems, mobileNavOpen, setMobileNavOpen, ...rest } = props;
  const display = useMatchBreakpoint({ sm: 'none', md: 'flex' });
  return (
    <Box {...rest}>
      {
        display === 'none' ? (
          <MenuIcon mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
        ) : (
          <>
            {
              navItems && navItems.length ? navItems.map((item, i) => (
                <NavButtonBlock
                  index={i}
                  key={`navBarButton${i}`}
                  data={navItems[i]}
                  inlined={false}
                />
              )) : null
            }
            {
              // removed due to issues taken from French gov on wording. Keep removed until otherwise noted.
              // <SiteLocaleSelect marginTop={8} small />
            }
          </>
        )
      }
    </Box>
  )
}))(({ theme }) => ({
  // display: theme.breakpoints({ xs: 'none', sm: 'flex' }),
  flexDirection: 'row',
  flex: 2,
  justifyContent: 'flex-end',
  alignItems: 'center',
  px: theme.breakpoints({ xs: 0, sm: theme.spacing(1), md: theme.spacing(2) })
}))

Nav.displayName = 'Nav';

export const Header = () => {
  const settings = useStaticQuery(graphql`
    query navQuery {
      settingsJson(fileRelativePath: { eq: "/content/settings/nav.json" }) {
        ...nav
        defaultLocale
        rawJson
        fileRelativePath
      }
    }
  `);

  const { settingsJson: json } = settings;

  const [data, localeIndex] = useMatchLocalizedData(json.configs, { locale: json.defaultLocale });

  const navFormConfig = useMemo(() => {
    return getNavForm(localeIndex);
  }, [localeIndex])

  const [, form] = useJsonForm(json, navFormConfig);
  const cms = useCMS();
  useFormScreenPlugin(form);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const homeURL = useLocaleLinkInDevelopment('/');

  return (
    <>
      <NavBar>
        <ImageBlock
          data={data.logoImage}
          inlined={false}
          accessibility={{ accessibilityRole: 'link', href: homeURL }}
          onPress={() => {
            if (mobileNavOpen) {
              setMobileNavOpen(false);
            }
            if (!cms.enabled || process.env.NODE_ENV === 'production') {
              navigate(homeURL)
            }
          }}
        />
        <Nav navItems={data.navItems} mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
      </NavBar>
      <MobileNav navItems={data.navItems} mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen}/>
    </>
  )

}

const MobileNav = ({ navItems, mobileNavOpen, setMobileNavOpen }) => {
  const theme = useTheme();
  const cms = useCMS();
  // const animate = React.useMemo(() => mobileNavOpen ? 'opened' : 'default', [mobileNavOpen]);

  const display = useMatchBreakpoint({ sm: 'flex', md: 'none' });

  React.useEffect(() => {
    if (display === 'none' && mobileNavOpen) {
      setMobileNavOpen(false);
    }
  }, [display, mobileNavOpen, setMobileNavOpen])

  return (
    <Modal
      BackdropProps={{
        bg: theme.colors.white
      }}
      open={mobileNavOpen}
      onClose={() => setMobileNavOpen(false)}
      justifyContent="flex-start"
      alignItems="flex-start"
      padTop={(cms.enabled && process.env.NODE_ENV !== 'production') ? 65 : 0}
      zIndex={theme.zIndex.header - 1}
    >
      <Box
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        height="100%"
        paddingTop={theme.breakpoints(HEADER_HEIGHT_BREAKPOINTS)}
      >
        <ScrollView
          contentContainerStyle={{ width: '100%', flex: 1, alignItems: 'center' }}
          style={{ width: '100%', height: '100%' }}
        >
        <Box
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          maxWidth={400}
          padLeft="$4"
          padRight="$4"
          padBottom="$5"
          padTop={theme.breakpoints({ xs: theme.spacing(11), sm: theme.spacing(15) })}
          scale={theme.breakpoints({ xs: 1.1, sm: 1.2 })}
        >
          {
            navItems && navItems.length ? navItems.map((item, i) => (
              <NavButtonBlock
                index={i}
                key={`navBarButton${i}`}
                data={navItems[i]}
                inlined={false}
                setMobileNavOpen={setMobileNavOpen}
                mobile
              />
            )) : null
          }
        </Box>
        </ScrollView>
      </Box>
    </Modal>
  )
  // return (
  //   <Box
  //     position="fixed"
  //     padTop={theme.breakpoints(HEADER_HEIGHT_BREAKPOINTS)}
  //     left={0}
  //     zIndex={theme.zIndex.header - 1}
  //     height="100%"
  //     width="100%"
  //     maxHeight="100vh"
  //     maxWidth="100vw"
  //     bg={theme.colors.opacity(theme.colors.white, 0.90)}
  //     justifyContent="center"
  //     alignItems="center"
  //     animations={{
  //       opened: {
  //         opacity: 1
  //       },
  //       default: {
  //         opacity: 0
  //       }
  //     }}
  //     animate={animate}
  //   />
  // )
}

const NavButtonBlock = React.forwardRef(function NavButtonBlock(props, ref) {
  const { data, mobile, setMobileNavOpen, ...rest } = props;
  const { subLinks } = data;
  if (subLinks && Array.isArray(subLinks) && subLinks.length) {
    if (mobile) {
      return <MobileSubNavMenuButton setMobileNavOpen={setMobileNavOpen} ref={ref} data={data} subLinks={subLinks} {...rest}  />
    }
    return <SubNavMenuButton ref={ref} data={data} subLinks={subLinks} {...rest}  />
  } else {
    if (mobile) {
      return <MobileButton ref={ref} setMobileNavOpen={setMobileNavOpen} data={data} {...rest} />
    }
    return <ButtonBlock ref={ref} data={data} {...rest}  />
  }
})

const MobileButton = ({ data, setMobileNavOpen, containerBlock = {}, ...rest }) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const handleMenuItemPress = useButtonLinkToHandler(data.linkTo);
  const onPress = () => {
    handleMenuItemPress();
    setMobileNavOpen(false);
  }
  if (settings.buttonType === 'text') {
    return (
      <MenuItem
        onPress={onPress}
        accessibility={data.linkTo ? {
          accessibilityRole: "link",
          href: data.linkTo
        } : null}
      >
        {data.text}
      </MenuItem>
    )
  } else {
    return <ButtonBlock my="$2" containerBlock={containerBlock} data={data} onPress={onPress} {...rest}  />
  }

}

const SubNavMenuButton = memo(React.forwardRef(function SubNavMenuButton(props, ref) {
  const { subLinks, data, ...rest } = props;
  const btnRef = useRef(null);
  const handleRef = useForkRef(btnRef, ref);
  const [menuOpen, setMenuOpen] = useState(false);

  const mounted = useRef(true);
  React.useEffect(() => { return () => { mounted.current = false; } }, []);
  const handleOnPress = () => setMenuOpen(currState => !currState);
  const handleOnHoverIn = () => setMenuOpen(true);
  const handleOnHoverOut = () => setMenuOpen(false);
  const handleMenuOnHoverIn = () => menuOpen && setMenuOpen(true);
  const handleMenuOnHoverOut = () => {
    menuOpen && setMenuOpen(false);
  }
  const handleOnBlur = useRef(null);

  const handleMenuItemPress = useButtonLinkToHandler();

  const display = useMatchBreakpoint({ sm: 'none', md: 'flex' });

  const { hovered, ...handlers } = useHoverable({
    onHoverIn: handleMenuOnHoverIn,
    onHoverOut: handleMenuOnHoverOut,
  });

  React.useEffect(() => {
    if (display === 'none' && menuOpen) {
      setMenuOpen(false);
    }
  }, [display, menuOpen])

  React.useEffect(() => {
    if (mounted.current) {
      handleOnBlur.current = debounce(() => {
        if (mounted.current) {
          setMenuOpen(false);
        }
      });
    }

    return () => {
      if (handleOnBlur.current) {
        handleOnBlur.current.clear();
      }
    }
  }, []);


  return (
    <>
      <BaseButtonBlock
        onPress={data.linkTo ? (event) => {
          setMenuOpen(false);
          handleMenuItemPress(event, data.linkTo);
        } : undefined}
        onHoverIn={handleOnHoverIn}
        onHoverOut={handleOnHoverOut}
        ref={handleRef}
        onBlur={handleOnBlur.current}
        data={data}
        accessibility={data.linkTo ? {
          accessibilityRole: "link",
          href: data.linkTo
        } : null}
        {...rest}
      />
      <Menu
        open={menuOpen}
        MenuListProps={{ ...handlers, shadowColor: 'transparent', borderColor: 'transparent' }}
        anchorNode={btnRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOffset={{ vertical: -4, horizontal: 0 }}
        onClose={() => setMenuOpen(false)}
        disableScrollLock
        hideBackdrop
        disableTrapFocus
      >
        {
          subLinks.map((item, i) => (
            <MenuItem
              key={`navBarButton${props.index}SubMenuButton${i}`}
              onPress={(event) => {
                setMenuOpen(false);
                handleMenuItemPress(event, item.linkTo);
              }}
              accessibility={item.linkTo ? {
                accessibilityRole: "link",
                href: item.linkTo
              } : null}
            >
              {item.text}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}))

const MobileSubNavMenuButton = memo(React.forwardRef(function MobileSubNavMenuButton(props, ref) {
  const { subLinks, data, setMobileNavOpen } = props;
  const handleMenuItemPress = useButtonLinkToHandler();

  return (
    <>
      {data.linkTo ? (
        <MenuItem
          onPressIn={(event) => {
            setMobileNavOpen(false);
            handleMenuItemPress(event, data.linkTo);
          }}
          accessibility={{
            accessibilityRole: "link",
            href: data.linkTo
          }}
        >
          {data.text}
        </MenuItem>
      ) : null}
      {
        subLinks.map((item, i) => (
          <MenuItem
            key={`navBarButton${props.index}SubMenuButton${i}`}
            onPressIn={(event) => {
              setMobileNavOpen(false);
              handleMenuItemPress(event, item.linkTo);
            }}
            accessibility={item.linkTo ? {
              accessibilityRole: "link",
              href: item.linkTo
            } : null}
          >
            {item.text}
          </MenuItem>
        ))
      }
    </>
  )
}))
// old implementation with dropdown
// const MobileSubNavMenuButton = memo(React.forwardRef(function MobileSubNavMenuButton(props, ref) {
//   const { subLinks, data, setMobileNavOpen } = props;
//   const [menuOpen, setMenuOpen] = useState(false);
//   console.log(props);
//   const handleOnPress = () => setMenuOpen(currState => !currState);
//   const handleMenuItemPress = useButtonLinkToHandler();

//   return (
//     <>
//       <MenuItem onPress={handleOnPress}>
//         {data.text}
//       </MenuItem>
//       {
//         menuOpen ? (
//           <Box
//             width="100%"
//             padLeft="$2"
//             padTop="$1"
//             padBottom="$2"
//             justifyContent="flex-start"
//             alignItems="flex-start"
//             flexDirection="column"
//             height={menuOpen ? 'auto' : '1'}
//             opacity={menuOpen ? 1 : 0}
//             display={menuOpen ? 'flex' : 'none'}
//           >
//           {
//             subLinks.map((item, i) => (
//               <MenuItem
//                 key={`navBarButton${props.index}SubMenuButton${i}`}
//                 onPressIn={(event) => {
//                   setMobileNavOpen(false);
//                   handleMenuItemPress(event, item.linkTo);
//                 }}
//                 accessibility={item.linkTo ? {
//                   accessibilityRole: "link",
//                   href: item.linkTo
//                 } : null}
//               >
//                 {item.text}
//               </MenuItem>
//             ))
//           }
//           </Box>
//         ) : null
//       }

//     </>
//   )
// }))


const defaultNavButtonSettings = {
  buttonType: 'text',
  buttonSize: 'large',
  buttonColor: {
    name: '',
    alpha: '',
  },
  bolded: false,
  padding: {
    top: '1',
    bottom: '0',
    left: '0',
    right: '0'
  },
  alignSelf: settingsDefaultItems.alignSelf,
  shadow: settingsDefaultItems.shadow
}

const navButtonFields = [...ButtonBlockTemplate.fields];
navButtonFields.splice(2, 0, {
  name: 'subLinks',
  label: 'Sub Menu Links',
  description: 'Use menu dropdown list instead of one Link',
  component: 'group-list',
  defaultItem: {
    text: 'Sub Menu Link',
    linkTo: '/',
  },
  itemProps: item => ({
    label: `${item.text || 'Sub Menu Link'}  ${item.linkTo}`,
  }),
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'text'
    },
    {
      name: 'linkTo',
      label: 'Link',
      description: 'If it\'s a link within the site (www.understoryweather.com + YOUR_LINK) make sure to prefix it with a "/". The link to the home page is just "/".',
      component: 'text',
    },
  ]
})
const navButtonBlockTemplate = {
  ...ButtonBlockTemplate,
  label: "Nav Button",
  type: "navButtonBlockTemplate",
  defaultItem: {
    text: 'Nav Item',
    linkTo: '/',
    subLinks: [],
    settings: {
      sm: defaultNavButtonSettings,
      xs: applyEnabled(defaultNavButtonSettings, false, ['padding', 'shadow'])
    },
  },
  itemProps: item => ({
    label: `${item.text || 'Nav Link'}  ${item.linkTo}`,
  }),
  fields: navButtonFields
}

export const getNavForm = (index = 0) => ({
  label: "Site Nav",
  fields: [
    {
      label: "Logo",
      name: `rawJson.configs.${index}.logoImage`,
      component: "grouped",
      fields: [
        ...ImageBlockTemplate.fields
      ]
    },
    {
      label: "Nav Items",
      name: `rawJson.configs.${index}.navItems`,
      component: "blocks",
      templates: {
        navButtonBlockTemplate
      }
    }
  ]
})

export const navFragment = graphql`
  fragment nav on SettingsJson {
    configs {
      locale
      navItems {
        text
        linkTo
        subLinks {
          text
          linkTo
        }
        settings {
          xs {
            buttonType
            buttonType_enabled
            buttonSize
            buttonSize_enabled
            buttonColor {
              name
              alpha
            }
            buttonColor_enabled
            bolded
            bolded_enabled
            padding {
              top
              top_enabled
              bottom
              bottom_enabled
              left
              left_enabled
              right
              right_enabled
            }
            alignSelf
            alignSelf_enabled
            shadow {
              elevation
              elevation_enabled
              color {
                name
                alpha
              }
              color_enabled
              opacity
              opacity_enabled
              invert
              invert_enabled
            }
          }
          sm {
            buttonType
            buttonSize
            buttonColor {
              name
              alpha
            }
            bolded
            padding {
              top
              bottom
              left
              right
            }
            alignSelf
            shadow {
              elevation
              color {
                name
                alpha
              }
              opacity
              invert
            }
          }
        }
      }
      logoImage {
        imageSrc
        settings {
          xs {
            resizeMode
            resizeMode_enabled
            alignImage
            alignImage_enabled
            width {
              value
              unit
            }
            width_enabled
            height
            height_enabled
            alignSelf
            alignSelf_enabled
            padding {
              top
              top_enabled
              bottom
              bottom_enabled
              left
              left_enabled
              right
              right_enabled
            }
            backgroundColor {
              name
              alpha
            }
            backgroundColor_enabled
            opacity
            opacity_enabled
            border {
              width
              width_enabled
              color {
                name
                alpha
              }
              color_enabled
              style
              style_enabled
              top
              top_enabled
              bottom
              bottom_enabled
              left
              left_enabled
              right
              right_enabled
            }
            borderRadius {
              value
              value_enabled
              topLeft
              topLeft_enabled
              topRight
              topRight_enabled
              bottomLeft
              bottomLeft_enabled
              bottomRight
              bottomRight_enabled
            }
            shadow {
              elevation
              elevation_enabled
              color {
                name
                alpha
              }
              color_enabled
              opacity
              opacity_enabled
              invert
              invert_enabled
            }
            clipContent
            clipContent_enabled
          }
          sm {
            resizeMode
            alignImage
            width {
              value
              unit
            }
            height
            alignSelf
            padding {
              top
              bottom
              left
              right
            }
            backgroundColor {
              name
              alpha
            }
            opacity
            border {
              width
              color {
                name
                alpha
              }
              style
              top
              bottom
              left
              right
            }
            borderRadius {
              value
              topLeft
              topRight
              bottomLeft
              bottomRight
            }
            shadow {
              elevation
              color {
                name
                alpha
              }
              opacity
              invert
            }
            clipContent
          }
        }
      }
    }
  }
`;
