import React from 'react';
import { InlineTextarea } from '../InlineTextarea';
import { Heading } from '../../ui';
import { BlocksControls } from '../BlocksControls';
import {
  textSettingFields,
  defaultTextBlockSettings,
  useTextBlockSettings,
} from '../TextBlock';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { applyEnabledToggles, applyEnabled } from '../fieldConfigs';
import { memo } from '../../ui/utils';

export const HeadingBlock = memo(({
  index,
  data,
  containerBlock = { },
  inlined,
  ...rest
}) => {
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const textProps = useTextBlockSettings(settings, containerBlock);
  const level = settings.headingType;
  if (!inlined) {
    return <Heading level={level} {...textProps}>{data.text}</Heading>
  }
  return (
    <Heading level={level} {...textProps}>
      <BlocksControls index={index}>
        <InlineTextarea name="text" focusRing={false} />
      </BlocksControls>
    </Heading>
  )
})

HeadingBlock.defaultProps = { inlined: true }

export const headingSettingFields = [
  {
    name: 'headingType',
    label: 'Heading Type',
    component: 'select',
    options: [
      {
        label: 'Heading 1',
        value: 1,
      },
      {
        label: 'Heading 2',
        value: 2,
      },
      {
        label: 'Heading 3',
        value: 3,
      },
      {
        label: 'Heading 4',
        value: 4,
      },
      {
        label: 'Heading 5',
        value: 5,
      },
      {
        label: 'Heading 6',
        value: 6,
      }
    ]
  },
  ...textSettingFields
];

export const defaultHeadingBlockSettings = {
  headingType: '1',
  ...defaultTextBlockSettings,
  bolded: true,
  textSpaceAfter: true,
}

export const HeadingBlockTemplate = {
  type: 'HeadingBlockTemplate',
  name: 'heading',
  key: 'heading-block',
  label: 'Heading',
  component: 'grouped',
  defaultItem: {
    text: 'Headline...',
    settings: {
      sm: defaultHeadingBlockSettings,
      xs: applyEnabled(defaultHeadingBlockSettings, false, ['padding'])
    },
  },
  fields: [
    {
      name: 'text',
      label: 'Text',
      component: 'textarea'
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: headingSettingFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: headingSettingFields
        })
      ]
    }
  ]
}
