import { useStaticQuery, graphql } from "gatsby"
import { useRef } from 'react';
import equal from 'fast-deep-equal/react';
import { useMemoCompare } from '../ui/hooks';

export function usePageBlocksQuery(dataIds = []) {
  const results = useStaticQuery(graphql`
    query allPageBlocksQuery {
      allPageBlocksJson {
        edges {
          node {
            displayName
            _template
            blockId
            fileRelativePath
            rawJson
            contentBlocks {
              _template
              displayName
              text
              linkTo
              innerHtml
              imageSrc
              embedSrc
              title
              allowfullscreen
              mainColor {
                name
                alpha
              }
              rowBorderColor {
                name
                alpha
              }
              rowBorderStyle
              vsItems {
                items
                headingText
                _template
              }
              gridItems {
                _template
                headingText
                bodyText
                imageSrc
              }
              formConfig {
                method
                url
                successMessage
                failureMessage
                addData {
                  fieldName
                  value
                }
                failureFunc
                successFunc
              }
              formBlocks {
                _template
                text
                fieldName
                fieldLabel
                placeholder
                defaultValue
                required
                requiredMessage
                helperText
                settings {
                  xs {
                    activeColor {
                      name
                      alpha
                    }
                    activeColor_enabled
                    width {
                      value
                      unit
                    }
                    width_enabled
                    maxWidth {
                      value
                      unit
                    }
                    maxWidth_enabled
                    buttonType
                    buttonType_enabled
                    buttonSize
                    buttonSize_enabled
                    buttonColor {
                      name
                      alpha
                    }
                    buttonColor_enabled
                    bolded
                    bolded_enabled
                    padding {
                      top
                      top_enabled
                      bottom
                      bottom_enabled
                      left
                      left_enabled
                      right
                      right_enabled
                    }
                    alignSelf
                    alignSelf_enabled
                    shadow {
                      elevation
                      elevation_enabled
                      color {
                        name
                        alpha
                      }
                      color_enabled
                      opacity
                      opacity_enabled
                      invert
                      invert_enabled
                    }
                    height
                    height_enabled
                  }
                  sm {
                    activeColor {
                      name
                      alpha
                    }
                    width {
                      value
                      unit
                    }
                    maxWidth {
                      value
                      unit
                    }
                    buttonType
                    buttonSize
                    buttonColor {
                      name
                      alpha
                    }
                    bolded
                    padding {
                      top
                      bottom
                      left
                      right
                    }
                    alignSelf
                    shadow {
                      elevation
                      color {
                        name
                        alpha
                      }
                      opacity
                      invert
                    }
                    height
                  }
                }
                linkTo
                invalidMessage
                maxLength
                selectItems {
                  text
                  value
                }
              }
              groupContentBlocks {
                _template
                text
                linkTo
                innerHtml
                embedSrc
                title
                allowfullscreen
                settings {
                  xs {
                    resizeMode
                    resizeMode_enabled
                    alignImage
                    alignImage_enabled
                    buttonType
                    buttonType_enabled
                    buttonSize
                    buttonSize_enabled
                    buttonColor {
                      name
                      alpha
                    }
                    buttonColor_enabled
                    headingType
                    headingType_enabled
                    textSize
                    textSize_enabled
                    textAlign
                    textAlign_enabled
                    textColor {
                      name
                      alpha
                    }
                    textColor_enabled
                    textSpaceAfter
                    textSpaceAfter_enabled
                    maxLines
                    maxLines_enabled
                    bolded
                    bolded_enabled
                    underlined
                    underlined_enabled
                    uppercased
                    italic
                    uppercased_enabled
                    italic_enabled
                    maxWidth {
                      value
                      unit
                    }
                    maxWidth_enabled
                    padding {
                      top
                      top_enabled
                      bottom
                      bottom_enabled
                      left
                      left_enabled
                      right
                      right_enabled
                    }
                    alignSelf
                    alignSelf_enabled
                    opacity
                    opacity_enabled
                    spacingAmount
                    spacingAmount_enabled
                    shadow {
                      elevation
                      elevation_enabled
                      color {
                        name
                        alpha
                      }
                      color_enabled
                      opacity
                      opacity_enabled
                      invert
                      invert_enabled
                    }
                    alignContent
                    alignContent_enabled
                    backgroundColor {
                      name
                      alpha
                    }
                    backgroundColor_enabled
                    backgroundImage {
                      alignImage
                      alignImage_enabled
                      height {
                        unit
                        value
                      }
                      height_enabled
                      resizeMode
                      resizeMode_enabled
                      src
                      src_enabled
                      width {
                        unit
                        value
                      }
                      width_enabled
                    }
                    clipContent
                    clipContent_enabled
                    contentSpacing
                    contentSpacing_enabled
                    direction
                    direction_enabled
                    height
                    height_enabled
                    maxHeight
                    maxHeight_enabled
                    width_enabled
                    width {
                      unit
                      value
                    }
                    border {
                      bottom
                      bottom_enabled
                      color {
                        name
                        alpha
                      }
                      color_enabled
                      left
                      left_enabled
                      right
                      right_enabled
                      style
                      style_enabled
                      top
                      top_enabled
                      width
                      width_enabled
                    }
                    borderRadius {
                      bottomLeft
                      bottomLeft_enabled
                      bottomRight
                      bottomRight_enabled
                      topLeft
                      topLeft_enabled
                      topRight
                      topRight_enabled
                      value
                      value_enabled
                    }
                    hoverStyle_enabled
                    hoverStyle {
                      color {
                        name
                        alpha
                      }
                      underlined
                    }
                    justifyContent
                    justifyContent_enabled
                  }
                  sm {
                    resizeMode
                    alignImage
                    buttonType
                    buttonSize
                    buttonColor {
                      name
                      alpha
                    }
                    headingType
                    textSize
                    textAlign
                    textColor {
                      name
                      alpha
                    }
                    textSpaceAfter
                    maxLines
                    bolded
                    underlined
                    uppercased
                    italic
                    maxWidth {
                      value
                      unit
                    }
                    padding {
                      top
                      bottom
                      left
                      right
                    }
                    alignSelf
                    opacity
                    spacingAmount
                    shadow {
                      elevation
                      color {
                        name
                        alpha
                      }
                      opacity
                      invert
                    }
                    alignContent
                    backgroundColor {
                      name
                      alpha
                    }
                    backgroundImage {
                      alignImage
                      height {
                        unit
                        value
                      }
                      resizeMode
                      src
                      width {
                        unit
                        value
                      }
                    }
                    border {
                      bottom
                      color {
                        name
                        alpha
                      }
                      left
                      right
                      style
                      top
                      width
                    }
                    borderRadius {
                      bottomLeft
                      bottomRight
                      topLeft
                      topRight
                      value
                    }
                    clipContent
                    contentSpacing
                    direction
                    height
                    maxHeight
                    width {
                      unit
                      value
                    }
                    justifyContent
                    hoverStyle {
                      color {
                        name
                        alpha
                      }
                      underlined
                    }
                  }
                }
                displayName
                formBlocks {
                  _template
                  defaultValue
                  fieldLabel
                  fieldName
                  helperText
                  placeholder
                  required
                  requiredMessage
                  settings {
                    sm {
                      activeColor {
                        name
                        alpha
                      }
                      alignSelf
                      bolded
                      buttonColor {
                        name
                        alpha
                      }
                      buttonSize
                      buttonType
                      maxWidth {
                        unit
                        value
                      }
                      padding {
                        bottom
                        left
                        right
                        top
                      }
                      shadow {
                        color {
                          name
                          alpha
                        }
                        elevation
                        invert
                        opacity
                      }
                      width {
                        unit
                        value
                      }
                      height
                    }
                    xs {
                      activeColor {
                        name
                        alpha
                      }
                      activeColor_enabled
                      alignSelf
                      alignSelf_enabled
                      bolded
                      bolded_enabled
                      buttonColor {
                        name
                        alpha
                      }
                      buttonColor_enabled
                      buttonSize
                      buttonSize_enabled
                      buttonType
                      buttonType_enabled
                      maxWidth {
                        unit
                        value
                      }
                      maxWidth_enabled
                      padding {
                        bottom
                        bottom_enabled
                        left
                        left_enabled
                        right
                        right_enabled
                        top
                        top_enabled
                      }
                      shadow {
                        color {
                          name
                          alpha
                        }
                        color_enabled
                        elevation
                        elevation_enabled
                        invert
                        invert_enabled
                        opacity
                        opacity_enabled
                      }
                      width {
                        unit
                        value
                      }
                      width_enabled
                      height
                      height_enabled
                    }
                  }
                  text
                  invalidMessage
                  maxLength
                  selectItems {
                    text
                    value
                  }
                }
                formConfig {
                  addData {
                    fieldName
                    value
                  }
                  failureMessage
                  method
                  successMessage
                  url
                  failureFunc
                  successFunc
                }
                imageSrc
                groupContentBlocks {
                  _template
                  embedSrc
                  innerHtml
                  title
                  allowfullscreen
                  formBlocks {
                    _template
                    defaultValue
                    fieldLabel
                    helperText
                    fieldName
                    placeholder
                    required
                    requiredMessage
                    settings {
                      sm {
                        activeColor {
                          name
                          alpha
                        }
                        alignSelf
                        bolded
                        buttonColor {
                          name
                          alpha
                        }
                        buttonSize
                        buttonType
                        maxWidth {
                          unit
                          value
                        }
                        shadow {
                          color {
                            name
                            alpha
                          }
                          elevation
                          invert
                          opacity
                        }
                        padding {
                          bottom
                          left
                          right
                          top
                        }
                        width {
                          unit
                          value
                        }
                        height
                      }
                      xs {
                        activeColor {
                          name
                          alpha
                        }
                        activeColor_enabled
                        alignSelf
                        alignSelf_enabled
                        bolded
                        bolded_enabled
                        buttonColor {
                          name
                          alpha
                        }
                        buttonColor_enabled
                        buttonSize
                        buttonSize_enabled
                        buttonType
                        buttonType_enabled
                        maxWidth {
                          unit
                          value
                        }
                        maxWidth_enabled
                        padding {
                          bottom
                          bottom_enabled
                          left
                          left_enabled
                          right
                          right_enabled
                          top
                          top_enabled
                        }
                        shadow {
                          color {
                            name
                            alpha
                          }
                          color_enabled
                          elevation
                          elevation_enabled
                          invert
                          invert_enabled
                          opacity
                          opacity_enabled
                        }
                        width {
                          unit
                          value
                        }
                        width_enabled
                        height
                        height_enabled
                      }
                    }
                    text
                    invalidMessage
                    maxLength
                    selectItems {
                      text
                      value
                    }
                  }
                  formConfig {
                    addData {
                      fieldName
                      value
                    }
                    failureMessage
                    method
                    successMessage
                    url
                    failureFunc
                    successFunc
                  }
                  imageSrc
                  linkTo
                  settings {
                    sm {
                      alignContent
                      alignImage
                      alignSelf
                      backgroundColor {
                        name
                        alpha
                      }
                      backgroundImage {
                        alignImage
                        height {
                          unit
                          value
                        }
                        resizeMode
                        src
                        width {
                          unit
                          value
                        }
                      }
                      bolded
                      border {
                        bottom
                        color {
                          name
                          alpha
                        }
                        left
                        right
                        style
                        top
                        width
                      }
                      borderRadius {
                        bottomLeft
                        bottomRight
                        topLeft
                        topRight
                        value
                      }
                      buttonColor {
                        name
                        alpha
                      }
                      buttonSize
                      buttonType
                      clipContent
                      contentSpacing
                      direction
                      headingType
                      height
                      maxLines
                      maxHeight
                      maxWidth {
                        unit
                        value
                      }
                      opacity
                      resizeMode
                      padding {
                        bottom
                        right
                        top
                        left
                      }
                      shadow {
                        color {
                          name
                          alpha
                        }
                        elevation
                        invert
                        opacity
                      }
                      spacingAmount
                      textAlign
                      textColor {
                        name
                        alpha
                      }
                      textSize
                      textSpaceAfter
                      underlined
                      uppercased
                      italic
                      width {
                        unit
                        value
                      }
                      hoverStyle {
                        color {
                          name
                          alpha
                        }
                        underlined
                      }
                      justifyContent
                    }
                    xs {
                      alignContent
                      alignContent_enabled
                      width_enabled
                      width {
                        unit
                        value
                      }
                      uppercased_enabled
                      italic_enabled
                      uppercased
                      italic
                      underlined_enabled
                      underlined
                      textSpaceAfter_enabled
                      textSpaceAfter
                      textSize_enabled
                      textSize
                      textColor_enabled
                      textColor {
                        name
                        alpha
                      }
                      textAlign_enabled
                      textAlign
                      spacingAmount_enabled
                      spacingAmount
                      shadow {
                        color {
                          name
                          alpha
                        }
                        color_enabled
                        elevation
                        elevation_enabled
                        invert
                        invert_enabled
                        opacity
                        opacity_enabled
                      }
                      resizeMode_enabled
                      resizeMode
                      padding {
                        bottom
                        bottom_enabled
                        left
                        left_enabled
                        right
                        right_enabled
                        top
                        top_enabled
                      }
                      opacity_enabled
                      opacity
                      maxWidth_enabled
                      maxWidth {
                        unit
                        value
                      }
                      maxLines_enabled
                      maxLines
                      maxHeight_enabled
                      maxHeight
                      height_enabled
                      height
                      headingType_enabled
                      headingType
                      direction_enabled
                      direction
                      contentSpacing_enabled
                      contentSpacing
                      clipContent_enabled
                      clipContent
                      buttonType_enabled
                      buttonType
                      buttonSize_enabled
                      buttonSize
                      buttonColor_enabled
                      buttonColor {
                        name
                        alpha
                      }
                      borderRadius {
                        bottomLeft
                        bottomLeft_enabled
                        bottomRight
                        bottomRight_enabled
                        topLeft
                        topLeft_enabled
                        topRight
                        topRight_enabled
                        value
                        value_enabled
                      }
                      border {
                        bottom
                        bottom_enabled
                        color {
                          name
                          alpha
                        }
                        color_enabled
                        left
                        left_enabled
                        right
                        right_enabled
                        style
                        style_enabled
                        top
                        top_enabled
                        width
                        width_enabled
                      }
                      bolded_enabled
                      bolded
                      backgroundImage {
                        alignImage
                        alignImage_enabled
                        height_enabled
                        height {
                          unit
                          value
                        }
                        resizeMode
                        resizeMode_enabled
                        src
                        src_enabled
                        width {
                          unit
                          value
                        }
                        width_enabled
                      }
                      backgroundColor_enabled
                      backgroundColor {
                        name
                        alpha
                      }
                      alignSelf_enabled
                      alignSelf
                      alignImage_enabled
                      alignImage
                      hoverStyle {
                        color {
                          name
                          alpha
                        }
                        underlined
                      }
                      hoverStyle_enabled
                    }
                  }
                  text
                  href
                }
                href
              }
              href
              timelineBlocks {
                _template
                bodyText
                headingText
                overlineText
              }
              settings {
                xs {
                  numColumns
                  numColumns_enabled
                  resizeMode
                  resizeMode_enabled
                  alignImage
                  alignImage_enabled
                  buttonType
                  buttonType_enabled
                  buttonSize
                  buttonSize_enabled
                  buttonColor {
                    name
                    alpha
                  }
                  buttonColor_enabled
                  headingType
                  headingType_enabled
                  textSize
                  textSize_enabled
                  textAlign
                  textAlign_enabled
                  textColor {
                    name
                    alpha
                  }
                  textColor_enabled
                  textSpaceAfter
                  textSpaceAfter_enabled
                  maxLines
                  maxLines_enabled
                  bolded
                  bolded_enabled
                  underlined
                  underlined_enabled
                  uppercased
                  italic
                  uppercased_enabled
                  italic_enabled
                  spacingAmount
                  spacingAmount_enabled
                  width {
                    value
                    unit
                  }
                  width_enabled
                  height
                  height_enabled
                  maxWidth {
                    value
                    unit
                  }
                  maxWidth_enabled
                  maxHeight
                  maxHeight_enabled
                  direction
                  direction_enabled
                  alignContent
                  alignContent_enabled
                  alignSelf
                  alignSelf_enabled
                  contentSpacing
                  contentSpacing_enabled
                  padding {
                    top
                    top_enabled
                    bottom
                    bottom_enabled
                    left
                    left_enabled
                    right
                    right_enabled
                  }
                  backgroundColor {
                    name
                    alpha
                  }
                  backgroundColor_enabled
                  border {
                    width
                    width_enabled
                    color {
                      name
                      alpha
                    }
                    color_enabled
                    style
                    style_enabled
                    top
                    top_enabled
                    bottom
                    bottom_enabled
                    left
                    left_enabled
                    right
                    right_enabled
                  }
                  borderRadius {
                    value
                    value_enabled
                    topLeft
                    topLeft_enabled
                    topRight
                    topRight_enabled
                    bottomLeft
                    bottomLeft_enabled
                    bottomRight
                    bottomRight_enabled
                  }
                  backgroundImage {
                    src
                    src_enabled
                    alignImage
                    alignImage_enabled
                    resizeMode
                    resizeMode_enabled
                    width {
                      value
                      unit
                    }
                    width_enabled
                    height {
                      value
                      unit
                    }
                    height_enabled
                  }
                  shadow {
                    elevation
                    elevation_enabled
                    color {
                      name
                      alpha
                    }
                    color_enabled
                    opacity
                    opacity_enabled
                    invert
                    invert_enabled
                  }
                  clipContent
                  clipContent_enabled
                  opacity
                  opacity_enabled
                  lineColor {
                    name
                    alpha
                  }
                  lineColor_enabled
                  lineOpacity
                  lineOpacity_enabled
                  hoverStyle_enabled
                  hoverStyle {
                    color {
                      name
                      alpha
                    }
                    underlined
                  }
                  justifyContent
                  justifyContent_enabled
                }
                sm {
                  numColumns
                  resizeMode
                  alignImage
                  buttonType
                  buttonSize
                  buttonColor {
                    name
                    alpha
                  }
                  headingType
                  textSize
                  textAlign
                  textColor {
                    name
                    alpha
                  }
                  textSpaceAfter
                  maxLines
                  bolded
                  underlined
                  uppercased
                  italic
                  spacingAmount
                  width {
                    value
                    unit
                  }
                  height
                  maxWidth {
                    value
                    unit
                  }
                  maxHeight
                  direction
                  alignContent
                  alignSelf
                  contentSpacing
                  padding {
                    top
                    bottom
                    left
                    right
                  }
                  backgroundColor {
                    name
                    alpha
                  }
                  border {
                    width
                    color {
                      name
                      alpha
                    }
                    style
                    top
                    bottom
                    left
                    right
                  }
                  borderRadius {
                    value
                    topLeft
                    topRight
                    bottomLeft
                    bottomRight
                  }
                  backgroundImage {
                    src
                    alignImage
                    resizeMode
                    width {
                      value
                      unit
                    }
                    height {
                      value
                      unit
                    }
                  }
                  shadow {
                    elevation
                    color {
                      name
                      alpha
                    }
                    opacity
                    invert
                  }
                  clipContent
                  opacity
                  lineColor {
                    name
                    alpha
                  }
                  lineOpacity
                  hoverStyle {
                    color {
                      name
                      alpha
                    }
                    underlined
                  }
                  justifyContent
                }
              }
              rowSettings {
                sm {
                  altBackgroundColor {
                    name
                    alpha
                  }
                  altTextColor {
                    name
                    alpha
                  }
                  backgroundColor {
                    name
                    alpha
                  }
                  padding {
                    bottom
                    left
                    right
                    top
                  }
                  textColor {
                    name
                    alpha
                  }
                }
                xs {
                  altBackgroundColor {
                    name
                    alpha
                  }
                  altBackgroundColor_enabled
                  altTextColor {
                    name
                    alpha
                  }
                  altTextColor_enabled
                  backgroundColor {
                    name
                    alpha
                  }
                  backgroundColor_enabled
                  padding {
                    bottom
                    bottom_enabled
                    left
                    left_enabled
                    right
                    right_enabled
                    top
                    top_enabled
                  }
                  textColor {
                    name
                    alpha
                  }
                  textColor_enabled
                }
              }
              rowHeadingSettings {
                sm {
                  alignSelf
                  bolded
                  headingType
                  maxLines
                  maxWidth {
                    unit
                    value
                  }
                  opacity
                  padding {
                    bottom
                    left
                    right
                    top
                  }
                  textAlign
                  textColor {
                    name
                    alpha
                  }
                  textSize
                  textSpaceAfter
                  underlined
                  uppercased
                  italic
                  width {
                    unit
                    value
                  }
                }
                xs {
                  alignSelf
                  alignSelf_enabled
                  bolded
                  bolded_enabled
                  headingType
                  headingType_enabled
                  maxLines
                  maxLines_enabled
                  maxWidth {
                    unit
                    value
                  }
                  maxWidth_enabled
                  opacity
                  opacity_enabled
                  padding {
                    bottom
                    bottom_enabled
                    left
                    left_enabled
                    right
                    right_enabled
                    top
                    top_enabled
                  }
                  textAlign
                  textAlign_enabled
                  textColor {
                    name
                    alpha
                  }
                  textColor_enabled
                  textSize
                  textSize_enabled
                  textSpaceAfter
                  textSpaceAfter_enabled
                  underlined
                  underlined_enabled
                  uppercased
                  italic
                  uppercased_enabled
                  italic_enabled
                  width {
                    unit
                    value
                  }
                  width_enabled
                }
              }
            }
            settings {
              xs {
                marginWidth
                marginWidth_enabled
                height
                height_enabled
                maxHeight
                maxHeight_enabled
                direction
                direction_enabled
                alignContent
                alignContent_enabled
                contentSpacing
                contentSpacing_enabled
                padding {
                  top
                  top_enabled
                  bottom
                  bottom_enabled
                  left
                  left_enabled
                  right
                  right_enabled
                }
                backgroundColor {
                  name
                  alpha
                }
                backgroundColor_enabled
                border {
                  width
                  width_enabled
                  color {
                    name
                    alpha
                  }
                  color_enabled
                  style
                  style_enabled
                  top
                  top_enabled
                  bottom
                  bottom_enabled
                  left
                  left_enabled
                  right
                  right_enabled
                }
                borderRadius {
                  value
                  value_enabled
                  topLeft
                  topLeft_enabled
                  topRight
                  topRight_enabled
                  bottomLeft
                  bottomLeft_enabled
                  bottomRight
                  bottomRight_enabled
                }
                backgroundImage {
                  src
                  src_enabled
                  alignImage
                  alignImage_enabled
                  resizeMode
                  resizeMode_enabled
                  width {
                    value
                    unit
                  }
                  width_enabled
                  height {
                    value
                    unit
                  }
                  height_enabled
                }
                shadow {
                  elevation
                  elevation_enabled
                  color {
                    name
                    alpha
                  }
                  color_enabled
                  opacity
                  opacity_enabled
                  invert
                  invert_enabled
                }
                clipContent
                clipContent_enabled
                opacity
                opacity_enabled
                justifyContent
                justifyContent_enabled
              }
              sm {
                marginWidth
                height
                maxHeight
                direction
                alignContent
                contentSpacing
                padding {
                  top
                  bottom
                  left
                  right
                }
                backgroundColor {
                  name
                  alpha
                }
                border {
                  width
                  color {
                    name
                    alpha
                  }
                  style
                  top
                  bottom
                  left
                  right
                }
                borderRadius {
                  value
                  topLeft
                  topRight
                  bottomLeft
                  bottomRight
                }
                backgroundImage {
                  src
                  alignImage
                  resizeMode
                  width {
                    value
                    unit
                  }
                  height {
                    value
                    unit
                  }
                }
                shadow {
                  elevation
                  color {
                    name
                    alpha
                  }
                  opacity
                  invert
                }
                clipContent
                opacity
                justifyContent
              }
            }
          }
        }
      }
    }
  `)
  // const lastResults = useRef(null);
  // if (!lastResults.current) {
  //   lastResults.current = results;
  // }
  let allBlocks = results;
  // if (dataIds.length !== lastResults.current.allPageBlocksJson.edges.length) {
  //   allBlocks = lastResults.current;
  // }
  const data = [];
  for (let i = 0; i < dataIds.length; i += 1) {
    const blockEdge = allBlocks.allPageBlocksJson.edges.find((edge) => {
      return edge.node.blockId === dataIds[i];
    })
    if (blockEdge) {
      data.push(blockEdge.node);
    }
  }
  // const data = dataIds.map((blockId) => {
  //   const blockEdge = allBlocks.allPageBlocksJson.edges.find((edge) => {
  //     return edge.node.blockId === blockId;
  //   })
  //   console.log(blockId, allBlocks.allPageBlocksJson.edges);
  //   return blockEdge.node;
  // });
  // lastResults.current = results;

  return useMemoCompare(data, equal);
}
