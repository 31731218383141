import * as React from 'react'

// default to useEffect if running on server as useLayoutEffect does not work on server side
const useLayoutEffect =
  React[
    typeof document !== 'undefined' && document.createElement !== void 0
      ? 'useLayoutEffect'
      : 'useEffect'
  ]

export { useLayoutEffect };
