import { useRef, useCallback } from 'react';
import { useLayoutEffect } from './useLayoutEffect';

// https://github.com/facebook/react/issues/14099#issuecomment-440013892
// ^^ optimized use of useCallback without re-rendering
function useEventCallback(fn) {
  const ref = useRef(fn);
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => (0, ref.current)(...args), []);
}

export { useEventCallback };
