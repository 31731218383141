import * as React from 'react';
import { Theme } from './src/components/Theme';
import { PortalProvider } from './src/ui';
import './global.css';
import { TinaProvider } from 'tinacms';
import { GitClient, GitMediaStore } from '@tinacms/git-client'
import { CustomGroupFieldPlugin } from "./src/fields/CustomGroupFieldPlugin"
import { GroupListFieldPlugin } from "./src/fields/GroupListFieldPlugin"
import { TabGroupFieldPlugin } from './src/fields/TabGroupFieldPlugin';
import { ToggleEnabledFieldPlugin } from './src/fields/ToggleEnabledFieldPlugin';
import { GroupDropdownFieldPlugin } from './src/fields/GroupDropdownFieldPlugin';
import { RowGroupFieldPlugin } from './src/fields/RowGroupFieldPlugin';
import { NumberStringFieldPlugin } from './src/fields/NumberStringFieldPlugin';
import { SelectFieldPlugin } from './src/fields/CustomSelectFieldPlugin';
import { ImageFieldPlugin } from './src/fields/CustomImageFieldPlugin';
import { JsonFormBlocksFieldPlugin } from './src/fields/JsonFormBlocksFieldPlugin';
import { BlocksFieldPlugin } from './src/fields/BlocksFieldPlugin';
import { ProvideCopyBlockData } from './src/editing/useCopyBlockData';
import { ProvideEditingLocale } from './src/editing/useEditingLocale';
import { RepoClient } from './src/editing/repo-client';

export const onClientEntry = () => {
  if (window.tinacms) {
    // custom override of gatsby-browser.js in gatsby-tinacms-git
    const { protocol, hostname, port } = window.location
    const baseUrl = `${protocol}//${hostname}${
      port != '80' ? `:${port}` : ''
    }/___tina`

    const client = new GitClient(baseUrl)
    window.tinacms.registerApi('git', client)
    window.tinacms.media.store = new GitMediaStore(client)


    const repoClient = new RepoClient(baseUrl);
    window.tinacms.registerApi('repo', repoClient);


    window.tinacms.fields.add(CustomGroupFieldPlugin)
    window.tinacms.fields.add({
      name: 'grouped',
      Component: CustomGroupFieldPlugin.Component
    });
    window.tinacms.fields.add(GroupListFieldPlugin)
    window.tinacms.fields.add(TabGroupFieldPlugin);
    window.tinacms.fields.add(ToggleEnabledFieldPlugin);
    window.tinacms.fields.add(GroupDropdownFieldPlugin);
    window.tinacms.fields.add(RowGroupFieldPlugin);
    window.tinacms.fields.add(NumberStringFieldPlugin);
    window.tinacms.fields.add(SelectFieldPlugin);
    window.tinacms.fields.add(ImageFieldPlugin);
    window.tinacms.fields.add(JsonFormBlocksFieldPlugin);
    window.tinacms.fields.add(BlocksFieldPlugin);
  } else {
    console.error('No CMS defined. Plugins may not work correctly.')
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <ProvideEditingLocale>
      <ProvideCopyBlockData>
        <PortalProvider>
          <TinaProvider cms={window.tinacms}>
            <Theme>
              {element}
            </Theme>
          </TinaProvider>
        </PortalProvider>
      </ProvideCopyBlockData>
    </ProvideEditingLocale>
  )
}
