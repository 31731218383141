import React from 'react';
import { animated as makeAnimatable } from 'react-spring/native';
import { Animate } from './Animate';

function animated(Component) {
  const AnimateComponent = makeAnimatable(Component);

  return React.forwardRef(function AnimatedComponent(props, ref) {
    const {
      animate, // dont pass
      animation, // dont pass
      animations, // dont pass
      disableAnimate, // dont pass
      style,
      ...rest
    } = props;

    if (disableAnimate || !animate || !animations) {
      // TODO: ^^-- this was originally noAnimate. Check use in website/cms during update...
      if (animation && !disableAnimate) {
        return (
          <AnimateComponent ref={ref} style={[style, animation]} {...rest} />
        );
      }
      return <Component ref={ref} style={style} {...rest} />;
    }

    return (
      <Animate {...props}>
        {({ props: animStyle }) => (
          <AnimateComponent
            ref={ref}
            style={[style, animStyle, animation]}
            {...rest}
          />
        )}
      </Animate>
    );
  });
}

export { animated };

/*
just animated = fine
const AnimatedView = animated(View)((props, style) => ({
  whileHover: {
    from: {
      opacity: style.opacity || 1,
      bg: props.theme.colors.primary,
    },
    opacity: 0.8,
    bg: props.theme.colors.darken('primary')
  }
}));
// AnimatedView.displayName = 'animated(View)';

styled + animated = yuck (no)
const StyledBox = styled(animated(View)((props, style) => ({
  whileHover: {
    from: {
      opacity: style.opacity || 1,
      bg: props.theme.colors.primary,
    },
    opacity: 0.8,
    bg: props.theme.colors.darken('primary')
  }
})), { name: 'StyledBox', filterProps })({
  ...
  ...
  ...
});

withStyles + withAnimations = Looks good
const Button = withStyles({
  ...
  ...
  ...
})(withAnimations({
  whileHover: (props, style) => {
    ...
  }
}))

styled + withAnimations = Looks good
Const Button = styled(Box)({
  ...
}.withAnimations({
  ...
}))

const Button = styled(Box)(({ theme: { breakpoints, colors, spacing }}) => ({
  padding: breakpoints({ xs: spacing(8), sm: spacing(9) }),
  border: {
    right: {
      width: 1,
      color: colors.darken(colors.primary),
    }
  }
}))
*/
