import React from 'react';
import { useTransition as useSpringTransition } from 'react-spring/native';
import { flattenStyles } from './utils';
import { resolveAnimation } from './resolveAnimation';
import { useMemoCompare } from '../hooks';
import { useStyleSystemProps } from './useStyleSystemProps';

function useTransition(itemState, itemKeyFunc, transitionOptions, props) {
  const styleSystemProps = useStyleSystemProps(props);
  const config = useMemoCompare(
    resolveAnimation(transitionOptions, styleSystemProps, true)
  );
  const transitions = useSpringTransition(itemState, itemKeyFunc, config);
  for (const transition of transitions) {
    transition.props = flattenStyles(transition.props);
  }
  return transitions;
}

// Either provide child components and no data OR data with a child render func
const Transition = (props) => {
  const {
    data,
    keyExtractor = null,
    animations = { },
    children,
    ...rest
  } = props;
  if (data && typeof children !== 'function') {
    console.warn(
      '<Transition> expects just child components and no data prop OR data prop with a child render func'
    );
  }

  let items = null;
  if (typeof children === 'function') {
    items = data;
  } else if (children && React.isValidElement(children)) {
    items = React.Children.toArray(children);
  }

  const transitionOptions = [{ unique: true }, { ...rest, ...animations }];

  const transitions = useTransition(
    items,
    keyExtractor,
    transitionOptions,
    rest
  );

  if (children && typeof children === 'function') {
    return transitions.map((transition) => {
      return children(transition);
    });
  }

  return transitions.map(({ item, key, props: animationProps }) => {
    if (item && React.isValidElement(item)) {
      return (
        <React.Fragment key={key}>
          {React.cloneElement(item, {
            animation: [item.props.animation, animationProps],
          })}
        </React.Fragment>
      );
    } else {
      return null;
    }
  });
};

export { Transition, useTransition };
