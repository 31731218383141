import React from 'react';
import { MenuItem } from '../../ui';
import { withFormFieldData } from './useFormBlock';
import {
  BaseFormFieldBlock,
  createFormFieldTemplate,
} from './BaseFormFieldBlock';

export const SelectFormFieldBlock = withFormFieldData(props => {
  const inputProps = { type: 'select' };
  if (props.data
      && props.data.selectItems
      && Array.isArray(props.data.selectItems)
      && props.data.selectItems.length
  ) {
    inputProps.children = props.data.selectItems.map((item, i) => {
      const { value = '', text = '' } = item;
      return (
        <MenuItem
          key={`select${props.index}item${i}`}
          value={value}
        >
          {text}
        </MenuItem>
      )
    });
  }
  return (
    <BaseFormFieldBlock
      {...props}
      inputProps={inputProps}
    />
  );
})

export const selectFormFieldBlockTemplate = createFormFieldTemplate({
  type: 'selectFormFieldBlockTemplate',
  name: 'selectFormFieldBlock',
  key: 'select-Form-Field-Block',
  label: 'Select Field',
  component: 'grouped',
  defaultItem: {
    requiredMessage: '* A selection is required',
    selectItems: [{ value: '', text: '' }]
  },
}, template => {
  const fields = [...template.fields];
  fields.splice(2, 0, {
    name: 'selectItems',
    label: 'Option Values for Select',
    component: 'group-list',
    defaultItem: {
      value: '',
      text: '',
    },
    itemProps: item => ({
      label: item.value,
    }),
    fields: [
      {
        name: 'value',
        label: 'Value',
        description: 'The actual data value of the option (text/string)',
        component: 'text'
      },
      {
        name: 'text',
        label: 'Text',
        description: 'The text displayed for selecting the option',
        component: 'text',
      },
    ]
  })
  template.fields = fields;
  return template;
})
/*
<Select
  mt="$4"
  value={selectVal}
  maxWidth={200}
  width="100%"
  onChangeValue={(next) => setSelectVal(next)}
>
  <MenuItem value={""}>{""}</MenuItem>
  <MenuItem value={1}>One</MenuItem>
  <MenuItem value={2}>Two</MenuItem>
  <MenuItem value={3}>Three</MenuItem>
</Select>
*/