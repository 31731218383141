/* eslint-disable no-restricted-globals */
export class RepoClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  isBrowser() {
    return typeof window !== `undefined`;
  }

  canContinue() {
    if (this.isBrowser() && window.location && window.location.host) {
      if (window.location.host.startsWith('localhost')) {
        const message = 'Prevented this action while on localhost. ' +
                    'This function is for edit server only.';
        console.log(message);
        confirm(message);
        return false;
      }
    }
    return true;
  }

  async status() {
    try {
      const response = await fetch(`${this.baseUrl}/status`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
      const json = await response.json();
      const { status, ...data } = json;
      if (status === 'success') {
        return data;
      } else {
        throw json;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async isClean() {
    try {
      const response = await fetch(`${this.baseUrl}/isClean`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
      const json = await response.json();
      if (json.status === 'success') {
        return json.isClean;
      } else {
        throw json;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async saveAll(commitOptions = { }) {
    try {
      if (!this.canContinue()) {
        return false;
      }
      const isClean = await this.isClean();
      console.log(isClean, 'isClean');
      const response = await fetch(`${this.baseUrl}/saveAll`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(commitOptions),
      })
      const json = await response.json();
      if (response.status === 'success') {
        return true;
      } else {
        throw json;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async removeChanges() {
    try {
      if (!this.canContinue()) {
        return false;
      }
      const response = await fetch(`${this.baseUrl}/removeChanges`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        }
      })
      const json = await response.json();
      if (response.status === 'success') {
        return true;
      } else {
        throw json;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async pushToDev() {
    try {
      if (!this.canContinue()) {
        return false;
      }
      const isClean = await this.isClean();
      console.log(isClean, 'isClean');
      if (!isClean) {
        return 'needsSaving';
      }
      const response = await fetch(`${this.baseUrl}/pushToDev`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        }
      })
      const json = await response.json();
      if (response.status === 'success') {
        return true;
      } else {
        throw json;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }


}