import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { useSpring } from 'react-spring/native';
import { flatten, flattenStyles } from './utils';
import { resolveAnimation } from './resolveAnimation';
import { useStyleSystemProps } from './useStyleSystemProps';
import { merge } from 'merge-anything';

// NOTE: this was reintroduced in RNWEB 0.14.x
// worked in 0.12.x
const notAnimatable = { shadowOffsetX: true, shadowOffsetY: true, shadowOffset: true };

function parseNotAnimatable(a = {}) {
  if (!a.to || Platform.OS !== 'web') {
    return [a, null];
  }
  const { to: animation, ...rest } = a;
  let next = { ...rest, to: {} };
  let removed = {};
  for (const key in animation) {
    if (notAnimatable[key]) {
      removed[key] = animation[key];
    } else {
      next.to[key] = animation[key];
    }
  }
  return [next, removed];
}

function useAnimate(props) {
  const { animations: anims, animate: a } = props;
  const styleSystemProps = useStyleSystemProps(props);
  const animations = flatten(anims, styleSystemProps);
  const { format } = animations || {};
  const lastRun = useRef({});
  let animation = {};
  let animate = [];
  if (animations) {
    if (Array.isArray(a) && a.length) {
      for (let i = 0; i < a.length; i += 1) {
        if (animations[a[i]]) {
          animate.push(a[i]);
        }
      }
    } else if (a && animations[a]) {
      animate.push(a);
    }
  }
  if (animate.length) {
    if (animations.config) {
      animation.config = { ...animations.config };
    }
    for (let i = 0; i < animate.length; i += 1) {
      let next = resolveAnimation(animations[animate[i]], styleSystemProps);
      // TODO: instead of merging and combining the animations. Use parallel or sequence springs...research useSpring apis more for this
      animation = merge(animation, next);
    }
    // TODO: parse all other animation options and add if they dont exist on the animation itself...
  } else {
    animation = lastRun.current;
  }
  lastRun.current = animation;

  const [animatableAnimation, notAnimatableStyles] = parseNotAnimatable(animation);
  const spring = useSpring(animatableAnimation);
  const toRun = notAnimatableStyles ? { ...spring, ...notAnimatableStyles } : spring;
  return format ? format(toRun, animate) : flattenStyles(toRun);
}

const Animate = React.memo(function Animate(props) {
  const { style: styleProp, children } = props;
  const animation = useAnimate(props);
  // NOTE: animStyle takes higher precedence over passed styles.
  // NOTE: styles are flattened after because useSpring does not handle nested array/objects
  //       used for RN transforms (like rotate). So the conversion to RN transform array happens after
  if (typeof children === 'function') {
    return children({ props: animation });
  }

  const style = [styleProp, animation];
  return React.cloneElement(React.Children.only(children), { style });
});

export { useAnimate, Animate };
