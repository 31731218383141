import React, { useState, useRef, useEffect, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import equal from 'fast-deep-equal/react';
import { Box, BoxDiv } from '../ui';
import { useMemoCompare } from '../ui/hooks';
import { memo } from '../ui/utils';
import { useCMS } from 'tinacms';
import {
  InlineField,
  useInlineForm,
  useInlineBlocks,
  InlineFieldContext,
  InlineBlocksContext,
} from 'react-tinacms-inline';
import { Droppable } from 'react-beautiful-dnd'
import { AddBlockMenu } from './AddBlockMenu';
import { useCopyBlockData } from '../editing/useCopyBlockData';
// export function InlineBlocks2({
//   name,
//   blocks,
//   direction = 'vertical',
//   itemProps,
//   min,
//   max,
//   wrapper: Wrapper,
//   wrapperProps,
// }) {
//   const cms = useCMS();
//   const [activeBlock, setActiveBlock] = useState(-1)
//   const { setFocussedField } = useInlineForm()
//
//   return (
//     <InlineField name={name}>
//       {({ input, form }) => {
//         const name = input.name
//         const allData = input.value || []
//         console.log(form, input)
//         const move = (from, to) => {
//           const movement = to - from
//           setActiveBlock(activeBlock => activeBlock + movement)
//           form.mutators.move(name, from, to)
//           setFocussedField(`${name}.${to}`)
//         }
//
//         const remove = (index) => {
//           form.mutators.remove(name, index)
//
//           const isOnlyItem = input.value.length === 1
//           const isLastItem = input.value.length - 1 === index
//
//           if (isOnlyItem) {
//             setFocussedField('')
//           } else if (isLastItem) {
//             setFocussedField(`${input.name}.${index - 1}`)
//           } else {
//             setFocussedField(`${input.name}.${index}`)
//           }
//         }
//
//         const insert = (index, block) => {
//           form.mutators.insert(name, index, block)
//           setFocussedField(`${name}.${index}`)
//         }
//
//         return (
//
//           <Droppable droppableId={name} type={name} direction={direction}>
//           {
//             provider => (
//               <InlineBlocksContext.Provider
//                 value={{
//                   insert,
//                   move,
//                   remove,
//                   blocks,
//                   count: allData.length,
//                   activeBlock,
//                   setActiveBlock,
//                   direction,
//                   min,
//                   max,
//                 }}
//               >
//                   <Wrapper component={BoxDiv} renderAfter={provider.placeholder} innerRef={provider.innerRef} {...wrapperProps}>
//
//                   {allData.length < 1 && cms.enabled && (
//                     <BlocksEmptyState>
//                       <AddBlockMenu
//                         addBlock={block => insert(0, block)}
//                         blocks={blocks}
//                       />
//                     </BlocksEmptyState>
//                   )}
//
//                   {allData.map((data, index) => {
//                     const Block = blocks[data._template]
//
//                     if (!Block) {
//                       console.warn(
//                         'Unrecognized Block of type:',
//                         data._template
//                       )
//                       return null
//                     }
//
//                     const blockName = `${input.name}.${index}`
//
//                     return (
//                       <InlineBlock
//                         itemProps={itemProps}
//                         key={blockName}
//                         index={index}
//                         name={blockName}
//                         data={data}
//                         block={Block}
//                       />
//                     )
//                   })}
//                 </Wrapper>
//                           </InlineBlocksContext.Provider>
//             )
//           }
//           </Droppable>
//
//         )
//       }}
//       </InlineField>
//     )
// }

export const InlineBlocks = ({ name, direction: dir, ...rest }) => {
  let direction = dir || 'vertical';
  if (direction === 'row' || direction === 'row-reverse') {
    direction = 'horizontal';
  } else if (direction === 'column' || direction === 'column-reverse') {
    direction = 'vertical';
  }
  // const formState = useInlineForm();
  // const parentField = useContext(InlineFieldContext);

  return (
    <InlineField name={name}>
      {({ input, form }) => {
        const name = input.name;
        const data = input.value || [];
        return <BlocksWrapper name={name} data={data} form={form} direction={direction} {...rest} />
      }}
    </InlineField>
  )
}

InlineBlocks.defaultProps = {
  wrapper: Box
}

InlineBlocks.displayName = 'InlineBlocks';

const BlocksWrapper = memo(({
  form,
  min,
  max,
  ...compareProps
}) => {
  // const comparedProps = useMemoCompare(compareProps, equal);

  const {
    name,
    data,
    blocks,
    wrapper,
    wrapperProps,
    itemProps,
    direction
  } = compareProps;

  const count = data.length;
  // const contents = useMemo(() => (
  //   <DroppableWrapper name={name} direction={direction}>
  //   {
  //     provider => (
  //       <BlocksContent
  //         dropProvider={provider}
  //         name={name}
  //         data={data}
  //         blocks={blocks}
  //         wrapper={wrapper}
  //         wrapperProps={wrapperProps}
  //         itemProps={itemProps}
  //       />
  //     )
  //   }
  //   </DroppableWrapper>
  // ), [comparedProps, count])
  return (
    <ProvideInlineBlocksControls
      form={form}
      blocks={blocks}
      direction={direction}
      min={min}
      max={max}
      name={name}
      count={count}
      data={data}
      wrapperProps={wrapperProps}
      itemProps={itemProps}
    >
    {
      () => (
        <DroppableWrapper data={data} name={name} wrapperProps={wrapperProps} itemProps={itemProps} direction={direction}>
        {
          provider => (
            <BlocksContent
              dropProvider={provider}
              count={count}
              name={name}
              data={data}
              blocks={blocks}
              wrapper={wrapper}
              wrapperProps={wrapperProps}
              itemProps={itemProps}
            />
          )
        }
        </DroppableWrapper>
      )
    }
    </ProvideInlineBlocksControls>
  )
}, ['data', 'name', 'direction', 'itemProps', 'wrapperProps', 'min', 'max', 'blocks', 'wrapper'])

BlocksWrapper.name = 'BlocksWrapper';

const noData = { noData: 'noData' };

const ProvideInlineBlocksControls = memo(({
  form,
  blocks: blockTemplates,
  direction,
  min,
  max,
  name,
  count,
  children,
  data,
}) => {
  // const [activeBlock, setActiveBlock] = useState(-1);
  const { setFocussedField, parentName } = useInlineForm();
  const blocks = useMemoCompare(blockTemplates, equal);
  const templatedBlocks = useMemo(() => {
    if (blocks && typeof blocks === 'object') {
      const templates = { };
      for (const key in blocks) {
        templates[key] = blocks[key].template;
      }
      return templates;
    }
    return null;
  }, [blocks])
  const templates = useMemoCompare(templatedBlocks, equal);
  // const copyBlock = useCopyBlockData({ templates });
  // const { data: copyBlockData, setData: setCopyBlockData, canPaste } = useMemoCompare(copyBlock, equal);

  const formRef = useRef(null);
  formRef.current = form;
  const controls = useMemo(() => {
    return {
      move: (from, to) => {
        // const movement = to - from
        // setActiveBlock(activeBlock => activeBlock + movement)
        formRef.current.mutators.move(name, from, to)
        const focussedField = parentName ? `${parentName}.${name}.${to}` : `${name}.${to}`;
        setFocussedField(focussedField)
      },
      remove: (index) => {
        formRef.current.mutators.remove(name, index)

        const isOnlyItem = count === 1
        const isLastItem = count - 1 === index

        let focussedField = '';
        if (isOnlyItem) {
          focussedField = '';
        } else if (isLastItem) {
          focussedField = `${name}.${index - 1}`;
        } else {
          focussedField = `${name}.${index}`;
        }

        if (parentName) {
          if (!focussedField) {
            focussedField = parentName;
          } else {
            focussedField = `${parentName}.${focussedField}`;
          }
        }

        setFocussedField(focussedField);
      },
      insert: (index, block) => {
        formRef.current.mutators.insert(name, index, block)
        const focussedField = parentName ? `${parentName}.${name}.${index}` : `${name}.${index}`;
        setFocussedField(focussedField)
      },
      // copyBlockData,
      // setCopyBlockData,
      // canPaste,
      blocks,
      count,
      direction,
      min,
      max,
      templates
    }
  }, [
    name,
    blocks,
    setFocussedField,
    parentName,
    count,
    max,
    min,
    direction,
    templates,
    // canPaste,
    // copyBlockData,
    // setCopyBlockData,
  ]);

  // console.log(name, controls)
  // const content = useRef();
  // const lastContent = useRef();
  // if (!content.current) content.current = children;
  // content.current = lastContent.current;
  //
  // useEffect(() => {
  //   content.current =
  // }, [activeBlock])

  return (
    <InlineBlocksContext.Provider value={controls}>
      {children()}
    </InlineBlocksContext.Provider>
  );
}, ['blocks', 'direction', 'min', 'max', 'name', 'count', 'data', 'wrapperProps', 'itemProps'])

ProvideInlineBlocksControls.displayName = 'ProvideInlineBlocksControls';

const DroppableWrapper = memo(({ name, direction, children, data, count, wrapperProps }) => {
  return (
    <Droppable droppableId={name} type={name} direction={direction}>
    {
      provider => children(provider)
    }
    </Droppable>
  );
}, ['name', 'direction', 'data', 'count', 'wrapperProps', 'itemProps'])

DroppableWrapper.displayName = 'DroppableWrapper';

const BlocksContent = memo(({
  dropProvider: provider,
  data: allData,
  wrapper: Wrapper,
  wrapperProps,
  itemProps,
  blocks,
  name,
}) => {
  const cms = useCMS();
  const { insert } = useInlineBlocks();
  const addBlock = useCallback(data => insert(0, data), [insert])
  return (
      <Wrapper component={BoxDiv} renderAfter={provider.placeholder} innerRef={provider.innerRef} {...wrapperProps}>
      {allData.length < 1 && (cms.enabled && process.env.NODE_ENV !== 'production') && (
        <BlocksEmptyState>
          <AddBlockMenu
            addBlock={addBlock}
            blocks={blocks}
          />
        </BlocksEmptyState>
      )}

      {allData.map((data, index) => {
        const Block = blocks[data._template]

        if (!Block) {
          console.warn(
            'Unrecognized Block of type:',
            data._template
          )
          return null
        }

        const blockName = `${name}.${index}`

        return (
          <InlineBlock
            itemProps={itemProps}
            key={`${index}-${blockName}`}
            index={index}
            name={blockName}
            data={data}
            block={Block}
          />
        )
      })}
    </Wrapper>
  )
}, ['data', 'wrapper', 'wrapperProps', 'itemProps', 'blocks', 'name', 'dropProvider'])

export const InlineBlock = ({
  name,
  data,
  block,
  index,
  itemProps
}) => {
  return (
    <InlineFieldContext.Provider value={{ name, ...block }}>
      <block.Component data={data} index={index} {...itemProps} />
    </InlineFieldContext.Provider>
  )
}

export const BlocksEmptyState = styled.div`
  padding: var(--tina-padding-small);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

BlocksContent.displayName = 'BlocksContent';
