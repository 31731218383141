import React, { useCallback, useRef, useImperativeHandle } from 'react';
import { CalendarViewTypes, Day } from '../Calendar';
import { DatePicker } from './DatePicker';
import { DateRangePickerToolbar } from './DateRangePickerToolbar';

function getDatesAsStrings(dateArray = []) {
  return dateArray.map((date) => {
    return date && date.format('YYYYMMDD');
  });
}

const DateRangePicker = React.forwardRef(function DateRangePicker(props, ref) {
  const {
    onChange, // onValueChange
    startDate, // momentDate
    endDate, // momentDate
    date, // dont pass and not used here
    initialView = CalendarViewTypes.day, // 'date', 'year', or 'month'
    views = [CalendarViewTypes.day], // array of views available to show
    //ToolbarComponent = DatePickerToolbar,
    renderDay, // customer render function for day
    ToolbarComponent = DateRangePickerToolbar,
    ToolbarProps,
    ...rest
  } = props;

  const lastChangeWasStartDate = useRef(!startDate && endDate ? false : true);

  const handleOnChange = useCallback((date) => {
      if (onChange) {
        let start = startDate;
        let end = endDate;
        if (!startDate) {
          start = date;
          lastChangeWasStartDate.current = true;
        } else if (!endDate) {
          const [d, s] = getDatesAsStrings([date, startDate]);
          if (d <= s) {
            start = date;
            lastChangeWasStartDate.current = true;
          } else {
            end = date;
            lastChangeWasStartDate.current = false;
          }
        } else if (lastChangeWasStartDate.current) {
          const [d, s] = getDatesAsStrings([date, startDate]);
          if (d <= s) {
            start = date;
            end = null;
            lastChangeWasStartDate.current = true;
          } else {
            end = date;
            lastChangeWasStartDate.current = false;
          }
        } else {
          const [d, e] = getDatesAsStrings([date, endDate]);
          if (d >= e) {
            start = date;
            end = null;
            lastChangeWasStartDate.current = true;
          } else {
            start = date;
            lastChangeWasStartDate.current = true;
          }
        }
        onChange(start, end);
      }
    },
    [onChange, startDate, endDate]
  );

  const handleRenderDay = useCallback((date, selectedDate, dProps) => {
    const [day, start, end] = getDatesAsStrings([date, startDate, endDate]);
    const addProps = {};
    const bg = '$gray.100';
    if (startDate && endDate) {
      if (day === start) {
        addProps.selected = true;
        addProps.backgroundColor = bg;
        addProps.borderBottomLeftRadius = 99999;
        addProps.borderTopLeftRadius = 99999;
      } else if (day === end) {
        addProps.selected = true;
        addProps.backgroundColor = bg;
        addProps.borderBottomRightRadius = 99999;
        addProps.borderTopRightRadius = 99999;
      } else if (day > start && day < end) {
        addProps.HighlightProps = { bg };
        addProps.backgroundColor = bg;
      }
    }
    const dayProps = { ...dProps, ...addProps };
    if (renderDay) {
      return renderDay(date, [startDate, endDate], dayProps);
    }
    return <Day {...dayProps} />;
  }, [renderDay, startDate, endDate]);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      setView: (...args) => {
        if (nodeRef.current) {
          nodeRef.current.setView(...args);
        }
      },
    }),
    []
  );

  return (
    <DatePicker
      ref={nodeRef}
      initialDate={startDate || endDate}
      onChange={handleOnChange}
      renderDay={handleRenderDay}
      views={views}
      initialView={initialView}
      ToolbarComponent={ToolbarComponent}
      ToolbarProps={{ startDate, endDate, ...ToolbarProps }}
      {...rest}
    />
  );
});

export { DateRangePicker };
