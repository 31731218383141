import { useRef, useEffect } from 'react';
import { Dimensions } from 'react-native';
import { useLayoutEffect } from './useLayoutEffect'
import { useDimensions } from './useDimensions';

// export function useOnViewportChange(callback, init = true) {
//   const force = useRef(init);
//   useLayoutEffect(() => {
//     const onViewportChange = () => {
//       const viewport = Dimensions.get('window');
//       callback(viewport);
//     }
//
//     if (force.current) {
//       force.current = false;
//       onViewportChange();
//     }
//
//     Dimensions.addEventListener('change', onViewportChange);
//
//     return () => { Dimensions.removeEventListener('change', onViewportChange) }
//   }, []);
// }

export function useOnViewportChange(callback, init = true) {
  const force = useRef(init);

  if (force.current) {
    force.current = false;
    callback(Dimensions.get('window'));
  }

  const onChange = (viewport) => {
    callback(viewport.window);
  }

  useLayoutEffect(() => {


    Dimensions.addEventListener('change', onChange);

    return () => { Dimensions.removeEventListener('change', onChange) }
  }, []);
}

// export function useOnViewportChange(callback, init = true) {
//   const force = useRef(init);
//   const { window: viewport } = useDimensions();
//   if (force.current) {
//     console.log('once');
//     force.current = false;
//     callback(viewport);
//   }
//
//   // const onChange = (viewport) => {
//   //   callback(viewport.window);
//   // }
//
//   useLayoutEffect(() => {
//     callback(viewport);
//   }, [viewport]);
// }
