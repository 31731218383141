import React from 'react';
import { ScrollView as NativeScrollView, Platform } from 'react-native';
import { withStyles, animated } from '../../styling';
import {
  ViewStylePropTypes,
  PaddingPropTypes,
  BackgroundColorPropTypes,
} from '../../system';

const AnimatedScrollView = animated(NativeScrollView);
AnimatedScrollView.displayName = 'animated(ScrollView)';

const ScrollView = withStyles({
  root: (props) => {
    const styles = { };
    for (const key in ViewStylePropTypes) {
      // dont include padding here
      if (props[key] !== undefined && PaddingPropTypes[key] === undefined) {
        styles[key] = props[key];
      }
    }
    return styles;
  },
  contentContainer: (props) => {
    const styles = { };
    for (const key in PaddingPropTypes) {
      if (props[key]) {
        styles[key] = props[key];
      }
    }
    for (const key in BackgroundColorPropTypes) {
      if (props[key]) {
        styles[key] = props[key];
      }
    }
    if (props.containerStyle) {
      if (typeof props.containerStyle === 'function') {
        return [styles, props.containerStyle];
      } else if (typeof props.containerStyle === 'object') {
        for (const key in ViewStylePropTypes) {
          if (props.containerStyle[key] !== undefined) {
            styles[key] = props.containerStyle[key];
          }
        }
      }
    }
    return styles;
  },
}, { name: 'ScrollView', filterProps: Object.keys(ViewStylePropTypes) })(
  React.forwardRef(function ScrollView(props, ref) {
    const {
      showsVerticalScrollIndicator = Platform.OS === 'web', // TODO: rename some of the default ScrollView props to match rest of UI component (enableScrollViewIndicators would be better)
      contentContainerStyle,
      containerStyle,
      styles,
      ...rest
    } = props;

    return (
      <NativeScrollView
        ref={ref}
        contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        {...rest}
      />
    );
  })
);

export { ScrollView };
