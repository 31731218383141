import React from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';

const Spacing = withStyles(({ vertical, horizontal, theme }) => {
  const root = {
    alignSelf: 'stretch',
    width: null,
    height: null,
  };
  if (vertical) {
    root.width = '100%';
    root.height = theme.spacing(vertical);
  } else if (horizontal) {
    root.height = '100%';
    root.width = theme.spacing(horizontal);
  }
  return { root };
}, { name: 'Spacing', filterProps: ['vertical', 'horizontal'] })(
  React.forwardRef(function Spacing(props, ref) {
    const { styles, ...rest } = props;
    return <Box ref={ref} {...rest} />;
  })
);

export { Spacing };
