// system styles are styles that derivable from the theme context and resolved through system functions
// style values that are strings and start with '$' are denoted as system values
// style values that are an array and are the only item in the array ['primary'] are denoted as system values
export function isSystemStyle(value) {
  if (typeof value === 'string') {
    return value[0] === '$';
  }
  if (Array.isArray(value)) {
    return value.length === 1;
  }
  return false;
}
