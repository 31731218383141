import { Platform } from 'react-native';

const resolveAsNumberOrPercentage = (value) => {
  if (!value || typeof value === 'number') return value;
  if (typeof value === 'string') {
    if (value.endsWith('%') || (Platform.OS === 'web' && (value.endsWith('vh') || value.endsWith('vw'))) || value === 'inherit' || value === 'auto') {
      return value;
    }

    const num = Number(value);
    if (!isNaN(num)) return num;
  }
  return value;
}

const resolveAsNumber = (value) => {
  if (!value || typeof value === 'number') return value;
  if (typeof value === 'string') {
    const num = Number(value);
    if (!isNaN(num)) return num;
  }
  return value;
}

function resolveAsString(value) {
  if (Platform.OS === 'web' && value !== null && value !== undefined) {
    return `${value}`;
  }
  return value;
}

export const parsers = {
  flex: resolveAsString,
  width: resolveAsNumberOrPercentage,
  height: resolveAsNumberOrPercentage,
  minWidth: resolveAsNumberOrPercentage,
  minHeight: resolveAsNumberOrPercentage,
  maxWidth: resolveAsNumberOrPercentage,
  maxHeight: resolveAsNumberOrPercentage,
  padding: resolveAsNumberOrPercentage,
  paddingLeft: resolveAsNumberOrPercentage,
  paddingRight: resolveAsNumberOrPercentage,
  paddingTop: resolveAsNumberOrPercentage,
  paddingBottom: resolveAsNumberOrPercentage,
  margin: resolveAsNumberOrPercentage,
  marginLeft: resolveAsNumberOrPercentage,
  marginRight: resolveAsNumberOrPercentage,
  marginTop: resolveAsNumberOrPercentage,
  marginBottom: resolveAsNumberOrPercentage,
  borderWidth: resolveAsNumberOrPercentage,
  borderBottomWidth: resolveAsNumberOrPercentage,
  borderTopWidth: resolveAsNumberOrPercentage,
  borderRightWidth: resolveAsNumberOrPercentage,
  borderLeftWidth: resolveAsNumberOrPercentage,
  marginX: resolveAsNumberOrPercentage,
  marginY: resolveAsNumberOrPercentage,
  paddingX: resolveAsNumberOrPercentage,
  paddingY: resolveAsNumberOrPercentage,
  size: resolveAsNumberOrPercentage,
  minSize: resolveAsNumberOrPercentage,
  maxSize: resolveAsNumberOrPercentage,
  borderXWidth: resolveAsNumberOrPercentage,
  borderYWidth: resolveAsNumberOrPercentage,
  shadowOffset: resolveAsNumber,
  shadowOffsetX: resolveAsNumber,
  shadowOffsetY: resolveAsNumber,
}
