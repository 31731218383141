import { get, isNull, isObject } from '../utils';
import { getVariant } from './variants';
import { defaultStyleSystemOptions } from './getStyleSystemOptions';
import { isSystemStyle, parseSystemStyle } from './utils';

export const NULL_STYLE = 'NULL';

function resolveStyleFunc(value, props) {
  if (typeof value !== 'function') {
    return value;
  }
  return resolveStyleFunc(value(props), props);
}

export function parseVariantStyle(value, props, variants) {
  let variantStyle = resolveStyleFunc(getVariant(value, props, variants), props);
  if (variantStyle) {
    return parseStyles(variantStyle, props);
  }
  return undefined;
}

export function parseThemeStyle({ prop, value: v, theme } = {}, props = { }) {
  const styleSystemOptions =
    props.styleSystemOptions || defaultStyleSystemOptions;
  const { scales, transforms, defaults } = styleSystemOptions;
  let value = resolveStyleFunc(v, props);
  const valueIsDerivableFromTheme = theme && isSystemStyle(value);
  if (valueIsDerivableFromTheme) {
    value = parseSystemStyle(value);
    // get the name of the scale array or scale object if the property is mapped to one (width => 'sizes', padding => 'spacing')
    const scaleName = get(scales, prop)
    // get the scale defined in theme if there is a scale for the property and in theme
    // use defaults or empty object otherwise
    const scale = get(theme, scaleName, get(theme, prop, {}));

    if (typeof scale === 'function') {
      value = scale(value);
    }
    // get the get value function to get the value (either get or one of the transforms for special props like margins and top, bottom, etc...)
    const transform = get(transforms, prop, get);
    // resolve style value for default theme values or lack thereof
    const resolveScaleValue = get(defaults, scaleName, defaults.default);
    // get and set the value. If it can be derived from theme then it will otherwise it will be the initial value[0]
    value = resolveScaleValue(transform(scale, value, value));
    return parseThemeStyle({ prop, value, theme }, props);
  }
  return value;
}

function shouldSkip(value, prop, _skip) {
  if (isNull(value)) {
    return true;
  }

  if (_skip[prop]) {
    if (typeof _skip[prop] === 'function') {
      return _skip[prop](value);
    }

    return _skip[prop];
  }

  return false;
}

export function parseStyles(stylesOrStylesFunc, props) {
  const styleSystemOptions =
    props.styleSystemOptions || defaultStyleSystemOptions;

  const getStyle = props.getStyle || null;
  const { aliases, multiples, objects, skip, parsers } = styleSystemOptions;

  const styles = resolveStyleFunc(stylesOrStylesFunc, props);
  const variants = styles && styles.variants ? styles.variants : null;
  const includedStyle = styles && styles.style && getStyle ? getStyle(styles.style) : null;
  let resolved = { ...includedStyle };
  for (const key in styles) {
    let value = styles[key];
    const isVariantResolver = typeof value === 'function' && value.isVariantResolver;
    value = resolveStyleFunc(value, props);

    if (isVariantResolver) {
      if (value) {
        resolved = { ...resolved, ...parseStyles(value, props) };
      }
      continue;
    }
    // ^^ function is checked twice. this is redundant.
    const prop = get(aliases, key, key);

    if (shouldSkip(value, prop, skip)) {
      continue;
    }

    if (prop === 'variants' || prop === 'style') {
      continue;
    }

    if (prop === 'variant') {
      let variantStyle = parseVariantStyle(value, props, variants);
      resolved = variantStyle ? { ...resolved, ...variantStyle } : resolved;
      continue;
    }

    const { theme } = props;

    if (isObject(value) && objects[prop]) {
      let objectStyles = objects[prop](value, theme);
      resolved = objectStyles ?  { ...resolved, ...parseStyles(objectStyles, props) } : resolved;
      continue;
    }

    if (value === NULL_STYLE) {
      value = null;
    }

    value = parseThemeStyle({ prop, value, theme }, props);

    if (isObject(value) && objects[prop]) {
      let objectStyles = objects[prop](value, theme);
      resolved = objectStyles ?  { ...resolved, ...parseStyles(objectStyles, props) } : resolved;
      continue;
    }

    if (parsers[prop]) {
      value = parsers[prop](value, props);
      if (isObject(value)) {
        resolved = { ...resolved, ...parseStyles(value, props) };
        continue;
      }
    }
    // if the property represents a multiple set/shorthand, like marginX, break out the marginX value into marginLeft prop and marginRight prop
    if (multiples[prop]) {
      const dirs = multiples[prop]

      for (let i = 0; i < dirs.length; i++) {
        resolved[dirs[i]] = value
      }
    } else {
      resolved[prop] = value
    }
  }

  return resolved;
}
