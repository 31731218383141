import React from 'react';
import equal from 'fast-deep-equal/react';


const defaultCompare = (prev, next) => equal(prev, next)


const compareKeysFromArray = (keys) => {
  return (prev, next) => {
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (!equal(prev[key], next[key])) {
        return false;
      }
    }
    return true;
  }
}


export function memo(component, compare) {
  let compareForMemo = defaultCompare;
  if (compare) {
    if (typeof compare === 'function') {
      compareForMemo = compare;
    }
    if (Array.isArray(compare)) {
      compareForMemo = compareKeysFromArray(compare);
    }
  }

  return React.memo(component, compareForMemo)
}

memo.displayName = 'CustomMemo';
