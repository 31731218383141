import React from 'react';
import { useTheme, useMatchBreakpoint } from '../../ui/hooks';
import cloneDeep from 'lodash.clonedeep';
import { memo } from '../../ui/utils';
import { BlocksControls } from '../BlocksControls';
import { VsRowItems, VsRowHeader } from './VsRowContent';


export const VsRowBlock = memo(({ data, index, asRowHeading = false, containerBlock, componentStyles, numColumns, ...rest }) => {
  const { headingText, items } = data;
  const {
    altItems,
    headings: hLines,
    numRows,
  } = containerBlock;
  const { breakpoints } = useTheme();

  const display = useMatchBreakpoint({ xs: asRowHeading ? 'none' : 'flex', sm: 'flex' });
  if (display === 'none') return null;

  const headings = hLines && hLines.length && index < hLines.length ? hLines[index] : ['', '']
  const isLast = (index === numRows - 1)
    // || (hLines && hLines.length
    //     && index + 1 < hLines.length
    //     && hLines[index + 1] && hLines[index + 1].length
    //     && (hLines[index + 1][0] || hLines[index + 1][1]))

  const contentProps = {
    leftBody: items[0] || '',
    rightBody: items[0] || '',
    headline: headingText || '',
    isLast,
    headings
  }

  const { row: rowStyle, altBackgroundColor, altTextColor, ...styles } = cloneDeep(componentStyles);

  if (altItems[index] && !asRowHeading) {
    if (altBackgroundColor) {
      rowStyle.backgroundColor = `$${altBackgroundColor}`;
      styles.columnHeadingText.onColor = `$${altBackgroundColor}`;
      styles.columnItemText.onColor = `$${altBackgroundColor}`;
    }

    if (altTextColor) {
      styles.columnHeadingText.color = styles.columnHeadingText.color || `$${altTextColor}`;
      styles.columnItemText.color = styles.columnItemText.color || `$${altTextColor}`;
    }
  }

  if (asRowHeading) {
    rowStyle.backgroundColor = 'transparent';
  }

  contentProps.componentStyles = styles;

  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      flexDirection={breakpoints({ xs: 'column', sm: 'row' })}
      justifyContent="center"
      alignItems="stretch"
      width="100%"
      {...rowStyle}
      // padLeft={breakpoints({ xs: 0, sm: spacing(5) })}
    >
      {asRowHeading ? <VsRowHeader {...contentProps} /> : <VsRowItems {...contentProps} />}
    </BlocksControls>
  )
})

VsRowBlock.displayName = 'VsRowBlock';

export const vsRowBlockTemplate = {
  type: 'vsRowBlockTemplate',
  name: 'vsRowBlock',
  key: 'vs-Row-Block',
  label: 'VS/Compare Row',
  component: 'grouped',
  defaultItem: {
    headingText: 'Topic/Feature',
    items: ['Item 1...', 'Item 2...']
  },
  fields: [
    {
      name: 'headingText',
      label: 'Heading',
      component: 'textarea',
    },
    {
      name: 'items.0',
      label: 'First Item (Highlight)',
      component: 'textarea',
    },
    {
      name: 'items.1',
      label: 'Second Item',
      component: 'textarea'
    }
  ]
}

export const VsRowHeadingBlock = props => <VsRowBlock asRowHeading {...props} />
VsRowHeadingBlock.displayName = 'VsRowHeadingBlock';

export const vsRowHeadingBlockTemplate = {
  type: 'vsRowHeadingBlockTemplate',
  name: 'vsRowHeadingBlock',
  key: 'vs-Row-Heading-Block',
  label: 'VS/Compare Row Heading',
  component: 'grouped',
  defaultItem: {
    items: ['Thing 1', 'Thing 2'],
  },
  fields: [
    {
      name: 'items.0',
      label: 'First Item Column Heading',
      component: 'textarea',
    },
    {
      name: 'items.1',
      label: 'Second Item Column Heading',
      component: 'textarea'
    }
  ]
}
