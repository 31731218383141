export function omit(item, remove = []) {
  if (Array.isArray(item)) {
    return item.filter((val) => !remove.includes(val));
  } else if (typeof item === 'object') {
    const result = {};
    for (const key in item) {
      if (remove.includes(key)) {
        continue;
      }
      result[key] = item[key];
    }
    return result;
  }
  return item;
}
