import React from 'react';
import { TextArea } from '../../ui';
import { withFormFieldData } from './useFormBlock';
import {
  BaseFormFieldBlock,
  createFormFieldTemplate,
} from './BaseFormFieldBlock';

export const TextareaFormFieldBlock = withFormFieldData(props => {
  const inputProps = {
    height: 200,
    minHeight: 200,
    multiline: true,
  };
  if (props.field && props.field.maxLength) {
    inputProps.maxLength = props.field.maxLength;
    inputProps.showTextCount = true;
  }
  return <BaseFormFieldBlock {...props} inputProps={inputProps} />
});

export const textareaFormFieldBlockTemplate = createFormFieldTemplate({
  type: 'textareaFormFieldBlockTemplate',
  name: 'textareaFormFieldBlock',
  key: 'textarea-Form-Field-Block',
  label: 'Textarea Field',
  component: 'grouped',
  defaultItem: {
    maxLength: ''
  }
}, template => {
  const fields = [...template.fields];
  fields.splice(template.fields.length - 1, 0, {
    name: 'maxLength',
    label: 'Max Character Count',
    component: 'number-string',
    step: 1,
    placeholder: 'No limit'
  })
  template.fields = fields;
  return template;
})

