import { colors } from './colors';
import { text, fontSizes, lineHeights } from './text';
import { fonts, fontWeights } from './font';
import { modulate, isNull } from '../../../utils';


text.button = {
  large: {
    variant: 'text.medium',
    fontWeight: '$bold',
  },
  medium: {
    variant: 'text.small',
    fontWeight: '$bold',
  },
  small: {
    variant: 'text.xSmall',
    fontWeight: '$bold',
  }
}

export const theme = {
  fonts,
  fontWeights,
  fontSizes,
  lineHeights,
  text,
  colors,
  breakpoints: {
    xs: 0,
    sm: 748,
    md: 1128,
    lg: 1440,
    xl: 1920
  },
  density: 4,
  spacing: 8,
  radii: { // radii, radius, or shape? cirlce is a shape
    default: 3,
    circle: 9999
  },
  shadows: {
    // TODO: shadows with elevation for android
    header: {
      color: 'rgb(49, 51, 51)',
      offset: { y: 8 },
      opacity: 0.07,
      radius: 50,
    },
    popover: {
      color: 'rgb(49, 51, 51)',
      offset: { y: 5 },
      opacity: 0.07,
      radius: 28,
    },
    switch: {
      color: '$gray.800',
      offset: { y: 2 },
      opacity: 0.18,
      radius: 3,
    },
    opacity: 0.06,
    color: '$gray.800',
    elevationFactor: 2,

    elevation: (num = 0, { theme: t, opacity: o, color: c, factor: f, offsetX = 0, invert = false } = { }) => {
      const themeObj = t || theme;
      const shadows = themeObj && themeObj.shadows ? themeObj.shadows : { opacity: 0.12, color: 'rgb(49, 51, 51)' };

      const opacity = !isNull(o) ? o : shadows.opacity || 0.12;
      const color = c || shadows.color || 'rgb(49, 51, 51)';
      const factor = f || shadows.elevationFactor || 2;

      if (num) {
        return {
          shadowOffsetY: Math.ceil(modulate(num, [1, 24], [1, 10])) * (invert ? -1 : 1),
          shadowOffsetX: offsetX * (invert ? -1 : 1),
          shadowRadius: num * factor,
          shadowOpacity: opacity,
          shadowColor: color
        }
      } else {
        return null;
      }
    }
  },
  zIndex: {
    stepper: 1000,
    fab: 1050,
    header: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    drop: 1500, // tooltip but more generic. look at grommet
  },
  layout: {
    container: {
      maxWidth: { xs: '84%', lg: 1160 },
      width: '100%',
      marginX: 'auto',
    }
    // TODO: change from 84% based to fix number spacing(3)/24px and make innerContent add another 24px
    // container: {
    //   maxWidth: { xs: '100%', sm: '84%', lg: 1160 },
    //   marginX: 'auto',
    //   width: '100%',
    //   paddingX: '$3'
    // }
  },
  button: {
    base: {
      borderRadius: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    contained: { borderWidth: 0 },
    outlined: { borderWidth: 1 },
    text: { borderWidth: 0 },
    sizes: {
      large: {
        px: '$2.5',
        py: '$1.25',
      },
      medium: {
        px: '$2',
        py: '$1',
      },
      small: {
        px: '$1.75',
        py: '$0.75',
      },
    }
  },
  iconButton: {
    base: {
      borderRadius: '$circle',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sizes: {
      small: {
        padding: '$0.5',
      },
      medium: {
        padding: '$1.5',
      },
    },
  },
  overrides: {
    /*
      Button: {
        root: {
          borderRadius: '$circle',
          ...more styles in root
          props: {
            size: 'large'
          }
        },
        ^^ props defined above will be what's passed to root component
        where as props defined below is are passed into the styled component itself before other props (as defaultprops essentially)
        NOTE that defaultProps should not be used on the actual UI components defined in overrides if desire to use prop overrides
        props: {
          size: 'large',
        }
      }
    }
    */
  },
};
