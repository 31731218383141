import React, { useMemo } from 'react';
import equal from 'fast-deep-equal/react';
import { Content, BackgroundImage, Box, Text } from '../../ui';
import { useMemoCompare } from '../../ui/hooks';
import { memo } from '../../ui/utils';
import { InlineBlocks } from '../InlineBlocks';
import { BlocksControls } from '../BlocksControls';
import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import {
  TextFormFieldBlock,
  textFormFieldBlockTemplate,
  emailFormFieldBlockTemplate,
  phoneFormFieldBlockTemplate
} from './TextFormFieldBlock';
import {
  TextareaFormFieldBlock,
  textareaFormFieldBlockTemplate
} from './TextareaFormFieldBlock';
import {
  SelectFormFieldBlock,
  selectFormFieldBlockTemplate,
} from './SelectFormFieldBlock';
import {
  SubmitFormButtonBlock,
  submitFormButtonBlockTemplate,
} from './SubmitFormButtonBlock';
import { useFormState, FormBlockContext } from './useFormBlock';


export const FIELD_TEMPLATE_TYPES = {
  [textFormFieldBlockTemplate.type]: 'text',
  [emailFormFieldBlockTemplate.type]: 'email',
  [phoneFormFieldBlockTemplate.type]: 'phone',
}



export const FormBlock = memo(({ data, index, containerBlock, ...rest }) => {
  const container = useMemoCompare(containerBlock, equal);
  const settings = useBreakpointLayouts(data.settings, containerBlock);
  const itemProps = useMemo(() => {
    let { direction, alignContent, backgroundColor } = settings;

    if (container) {
      if (!backgroundColor || backgroundColor === 'transparent' || backgroundColor === '$transparent') {
        backgroundColor = container.backgroundColor;
      }
    }
    return { containerBlock: { direction, backgroundColor, alignContent } };
  }, [settings, container]);

  const blockStyles = { ...settings.sizeStyle, ...settings.alignSelfStyle, ...settings.overflowStyle, ...settings.shadowStyle };
  if (settings.backgroundColor) blockStyles.backgroundColor = settings.backgroundColor;
  if (settings.border) blockStyles.border = settings.border;
  if (settings.borderRadius) blockStyles.radius = settings.borderRadius;
  if (settings.opacity && settings.opacity < 1) blockStyles.opacity = settings.opacity;

  const formState = useFormState(data, FIELD_TEMPLATE_TYPES);

  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      renderAs={Content}
      {...blockStyles}
    >
      {settings.backgroundImage && settings.backgroundImage.src ? <BackgroundImage {...settings.backgroundImage} /> : null}
      <FormBlockContext.Provider value={formState}>
        <InlineBlocks
          name="formBlocks"
          blocks={FORM_BLOCKS}
          itemProps={itemProps}
          direction={settings.direction}
          wrapperProps={{
            width:"100%",
            height: '100%',
            flexDirection: settings.direction,
            ...settings.layoutStyle,
          }}
        />
      </FormBlockContext.Provider>
      {formState.message ? (
        <Box
          {...settings.layoutStyle}
          paddingTop={0}
        >
          <Text color={formState.error ? 'red' : '$secondary'} bold>{formState.message}</Text>
        </Box>
      ) : null}
    </BlocksControls>
  )
})

const FORM_BLOCKS = {
  [textFormFieldBlockTemplate.type]: {
    Component: TextFormFieldBlock,
    template: textFormFieldBlockTemplate,
  },
  [emailFormFieldBlockTemplate.type]: {
    Component: TextFormFieldBlock,
    template: emailFormFieldBlockTemplate,
  },
  [phoneFormFieldBlockTemplate.type]: {
    Component: TextFormFieldBlock,
    template: phoneFormFieldBlockTemplate,
  },
  [textareaFormFieldBlockTemplate.type]: {
    Component: TextareaFormFieldBlock,
    template: textareaFormFieldBlockTemplate,
  },
  [selectFormFieldBlockTemplate.type]: {
    Component: SelectFormFieldBlock,
    template: selectFormFieldBlockTemplate
  },
  [submitFormButtonBlockTemplate.type]: {
    Component: SubmitFormButtonBlock,
    template: submitFormButtonBlockTemplate
  },
  // [selectFormFieldBlockTemplate.type]: {
  //   Component: SelectFormFieldBlock,
  //   template: selectFormFieldBlockTemplate,
  // },
  // [submitFormButtonBlockTemplate.type]: {
  //   Component: SubmitFormButtonBlock,
  //   template: submitFormButtonBlockTemplate,
  // }
}

const formSettingsFields = [
  layoutFields.unitBased.width,
  layoutFields.height,
  layoutFields.unitBased.maxWidth,
  layoutFields.maxHeight,
  layoutFields.direction,
  layoutFields.alignContent,
  layoutFields.justifyContent,
  layoutFields.contentSpacing,
  layoutFields.padding,
  layoutFields.alignSelf,
  styleFields.backgroundColor,
  styleFields.opacity,
  styleFields.border,
  styleFields.borderRadius,
  styleFields.backgroundImage,
  styleFields.shadow,
  styleFields.clipContent,
]

const defaultFormSettings = {
  width: settingsDefaultItems.width,
  height: settingsDefaultItems.height,
  maxWidth: settingsDefaultItems.maxWidth,
  maxHeight: settingsDefaultItems.maxHeight,
  direction: 'column',
  alignContent: 'center-left',
  justifyContent: settingsDefaultItems.justifyContent,
  contentSpacing: settingsDefaultItems.contentSpacing,
  padding: {
    top: '4',
    bottom: '4',
    left: '4',
    right: '4'
  },
  alignSelf: settingsDefaultItems.alignSelf,
  backgroundColor: settingsDefaultItems.backgroundColor,
  opacity: settingsDefaultItems.opacity,
  border: settingsDefaultItems.border,
  borderRadius: settingsDefaultItems.borderRadius,
  backgroundImage: settingsDefaultItems.backgroundImage,
  shadow: settingsDefaultItems.shadow,
  clipContent: settingsDefaultItems.clipContent
}

export const formBlockTemplate = {
  type: 'formBlockTemplate',
  name: 'formBlock',
  key: 'form-block',
  label: 'Form',
  component: 'grouped',
  defaultItem: {
    formConfig: {
      method: 'POST',
      url: 'https://',
      successMessage: '',
      failureMessage: '',
      addData: [],
      successFunc: '',
      failureFunc: '',
    },
    formBlocks: [],
    settings: {
      sm: defaultFormSettings,
      xs: applyEnabled(defaultFormSettings, false, ['padding', 'border', 'borderRadius', 'backgroundImage', 'shadow']),
    },
  },
  fields: [
    {
      name: 'formConfig',
      label: 'Configure Form',
      component: 'grouped',
      fields: [
        {
          name: 'method',
          label: 'Method',
          component: 'text',
        },
        {
          name: 'url',
          label: 'URL',
          component: 'text',
        },
        {
          name: 'successMessage',
          label: 'Success Message',
          component: 'text',
        },
        {
          name: 'failureMessage',
          label: 'Failure Message',
          component: 'text',
        },
        {
          name: 'addData',
          label: 'Add Data',
          component: 'group-list',
          defaultItem: {
            fieldName: '',
            value: ''
          },
          itemProps: item => ({
            label: item.fieldName || 'Data',
          }),
          fields: [
            {
              name: 'fieldName',
              label: 'Name',
              component: 'text',
            },
            {
              name: 'value',
              label: 'Value',
              component: 'text'
            }
          ]
        },
        {
          name: 'successFunc',
          label: 'Success Event Script',
          description: 'A javascript function that will be evaluated with eval on a successful post',
          component: 'textarea',
        },
        {
          name: 'failureFunc',
          label: 'Failure Event Script',
          description: 'A javascript function that will be evaluated with eval on a failed post',
          component: 'textarea',
        },
      ]
    },
    {
      name: 'formBlocks',
      label: 'Fields',
      component: 'blocks',
      templates: {
        textFormFieldBlockTemplate,
        emailFormFieldBlockTemplate,
        phoneFormFieldBlockTemplate,
        textareaFormFieldBlockTemplate,
        selectFormFieldBlockTemplate,
        submitFormButtonBlockTemplate,
      },
    },
    {
      name: 'settings',
      label: 'Settings',
      component: 'tabGroup',
      fields: [
        {
          name: 'sm',
          label: 'Desktop',
          component: 'grouped',
          fields: formSettingsFields,
        },
        applyEnabledToggles({
          name: 'xs',
          label: 'Mobile',
          component: 'grouped',
          fields: formSettingsFields
        })
      ]
    }
  ]
}
