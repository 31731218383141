import { Image as NativeImage, Platform } from 'react-native';
import { styled, animated } from '../../styling';
import { ImageStylePropTypes } from '../../system';
import { useImageDimensions } from '../../hooks';

const filterProps = Object.keys(ImageStylePropTypes);
filterProps.push('cover');
filterProps.push('aspectRatio');
const AnimatedImage = animated(NativeImage);

const Image = styled(AnimatedImage, {
  name: 'Image',
  filterProps,
  useProps: (props) => {
    const {
      src = '',
      onLayout: onLayoutProp,
      accessibility,
      description,
      resizeMode = null, // defaults to contain if not set and cover is false
      // ^ cover, contain, stretch, repeat, center
      cover = false,
      ...rest
    } = props;

    const { aspectRatio, width, height } = useImageDimensions(src);
    let sourceProps = {};
    if (typeof src === 'string') {
      sourceProps.source = { uri: src };
      if (width && height) {
        if (Platform.OS === 'web') {
          sourceProps.source.width = width;
          sourceProps.source.height = height;
        } else {
          sourceProps.width = width;
          sourceProps.height = height;
        }
      }
    } else if (typeof src === 'number') {
      sourceProps.source = src;
      if (width && height) {
        sourceProps.width = width;
        sourceProps.height = height;
      }
    }

    return {
      aspectRatio: aspectRatio || 1,
      cover,
      resizeMode,
      ...sourceProps,
      ...{
        accessible: true,
        accessibilityRole: Platform.OS === 'web' ? 'img' : 'image',
        accessibilityLabel: description || '',
        ...accessibility,
      },
      ...rest,
    }
  },
})((props) => {
  const { aspectRatio, ...rest } = props;
  const styles = { };
  const resizeMode = getImageResizeMode(rest);
  for (const key in ImageStylePropTypes) {
    if (rest[key] !== undefined) {
      styles[key] = rest[key];
    }
  }
  styles.resizeMode = resizeMode;
  if (resizeMode === 'contain') {
    if (!styles.maxWidth) {
      styles.maxWidth = '100%';
    }
    if (!styles.maxHeight) {
      styles.maxHeight = '100%';
    }
  } else if (resizeMode === 'cover') {
    if (aspectRatio > 1 && !styles.maxWidth) {
      styles.maxWidth = '100%';
    } else if (!styles.maxHeight) {
      styles.maxHeight = '100%';
    }
  }

  return styles;
});

// export const Image = ({ src, onLayout: onLayoutProp, accessibility, description, ...props }) => {
//   const { aspectRatio, width, height } = useImageDimensions(src);
//   let sourceProps = {};
//   if (typeof src === 'string') {
//     sourceProps.source = { uri: src };
//     if (width && height) {
//       if (Platform.OS === 'web') {
//         sourceProps.source.width = width;
//         sourceProps.source.height = height;
//       } else {
//         sourceProps.width = width;
//         sourceProps.height = height;
//       }
//     }
//   } else if (typeof src === 'number') {
//     sourceProps.source = src;
//     if (width && height) {
//       sourceProps.width = width;
//       sourceProps.height = height;
//     }
//   }
//   return (
//     <StyledImage
//       aspectRatio={aspectRatio || 1}
//       {...sourceProps}
//       {...{
//         accessible: true,
//         accessibilityRole: Platform.OS === 'web' ? 'img' : 'image',
//         accessibilityLabel: description || '',
//         ...accessibility,
//       }}
//       {...props}
//     />
//   );
// }
//
// Image.defaultProps = {
//   resizeMode: null, // defaults to contain if not set and cover is false
//   // ^ cover, contain, stretch, repeat, center
//   cover: false,
//   src: '',
// }

function getImageResizeMode({ resizeMode, cover, style } = { }) {
  if (!resizeMode) {
    return cover ? 'cover' : 'contain';
  }
  return resizeMode;
}

export { Image, getImageResizeMode };
