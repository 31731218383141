/* eslint-disable */
import React from 'react';
import mitt from 'mitt';
import PropTypes from 'prop-types';
import { uniqueId } from '../../utils';

const oContextTypes = {
  portalSub: PropTypes.func,
  portalUnsub: PropTypes.func,
  portalSet: PropTypes.func,
  portalGet: PropTypes.func,
};

export class PortalProvider extends React.Component {
  static childContextTypes = oContextTypes;
  
  _emitter;
  portals = new Map();

  getChildContext() {
    return {
      portalSub: this.portalSub,
      portalUnsub: this.portalUnsub,
      portalSet: this.portalSet,
      portalGet: this.portalGet,
    };
  }

  UNSAFE_componentWillMount() {
    this._emitter = new mitt();
  }

  componentWillUnmount() {
    this._emitter = null;
  }

  // get portal map object
  getPortal(name) {
    let portal = this.portals.get(name) || null;
    if (!portal && name) {
      portal = new Map();
      this.portals.set(name, new Map());
    }
    return portal;
  };

  portalSub = (name, callback) => {
    const emitter = this._emitter;
    if (emitter) {
      emitter.on(name, callback);
    }
  };

  portalUnsub = (name, callback) => {
    const emitter = this._emitter;
    if (emitter) {
      emitter.off(name, callback);
    }
  };

  portalSet = (name, id, value) => {
    const portal = this.getPortal(name);
    if (portal && id) {
      // undefined value signals a delete. Null is still a set
      if (value === undefined) {
        portal.delete(id);
      } else {
        portal.set(id, value);
      }
      if (this._emitter) {
        this._emitter.emit(name);
      }
    }
  };

  // get portal map object and return map values as a renderable array or null
  portalGet = name => {
    const portal = this.getPortal(name);
    const children = portal ? Array.from(portal.values()) : null;
    return children;
  };

  render() {
    return this.props.children;
  }
}

export class Portal extends React.PureComponent {
  static contextTypes = oContextTypes;

  // static propTypes: {
  //   name: PropTypes.string.isRequired,
  //   children: PropTypes.any,
  // };

  portalIds = new Map();

  constructor(props) {
    super(props);
    this.getPortalId(props.name);
  }

  getPortalId(name) {
    if (!name) {
      console.warn('No name passed to portal');
      return null;
    }
    let id = this.portalIds.get(name) || null;
    if (!id) {
      id = uniqueId(name);
      this.portalIds.set(name, id);
    }
    return id;
  }

  componentDidMount() {
    const { name, children } = this.props;
    const { portalSet } = this.context;
    const id = this.getPortalId(name);
    portalSet && portalSet(name, id, children);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    const { name, children } = newProps;
    const { portalSet } = this.context;
    if (oldProps.name && oldProps.name !== newProps.name) {
      const oldId = this.getPortalId(oldProps.name);
      portalSet && portalSet(name, oldId, null);
      const id = this.getPortalId(name);
      portalSet && portalSet(name, id, children);
    } else if (oldProps.children != newProps.children) {
      const id = this.getPortalId(name);
      portalSet && portalSet(name, id, children);
    }
  }

  componentWillUnmount() {
    const { portalSet } = this.context;
    this.portalIds.forEach((id, name) => {
      portalSet && portalSet(name, id, undefined);
    });
  }

  render() {
    const { name } = this.props;
    return null;
  }
}

export class PortalDestination extends React.PureComponent {
  static contextTypes = oContextTypes;

  // static propTypes: {
  //   name: PropTypes.string.isRequired,
  //   children: PropTypes.any,
  //   childrenProps: PropTypes.objectOf(PropTypes.any)
  // };

  UNSAFE_componentWillMount() {
    const { name } = this.props;
    const { portalSub } = this.context;
    portalSub && portalSub(name, this.forceUpdater);
  }

  componentWillUnmount() {
    const { name } = this.props;
    const { portalUnsub } = this.context;
    portalUnsub && portalUnsub(name, this.forceUpdater);
  }

  forceUpdater = () => this.forceUpdate();

  render() {
    const { name, children, childrenProps } = this.props;
    const { portalGet } = this.context;
    const portalChildren = (portalGet && portalGet(name)) || children;

    return (
      (childrenProps && portalChildren
        ? React.cloneElement(React.Children.only(portalChildren), childrenProps)
        : portalChildren) || null
    );
  }
}
