import { get, isNull, isObject } from '../ui/utils';
import { mergeAndConcat } from 'merge-anything';
import cloneDeep from 'lodash.clonedeep';

export const colorOptions = [
  {
    label: 'auto',
    value: '',
  },
  {
    label: 'Transparent',
    value: 'transparent',
  },
  {
    label: 'Primary',
    value: 'primary',
  },
  {
    label: 'Primary Dark',
    value: 'primary.dark',
  },
  {
    label: 'Primary Light',
    value: 'primary.light',
  },
  {
    label: 'Secondary (Forest)',
    value: 'secondary',
  },
  {
    label: 'Secondary Light (Ocean)',
    value: 'secondary.light',
  },
  {
    label: 'Sky',
    value: 'sky.bright'
  },
  {
    label: 'Mist',
    value: 'mist',
  },
  {
    label: 'Leaf',
    value: 'leaf',
  },
  {
    label: 'Navy',
    value: 'navy'
  },
  {
    label: 'Coral',
    value: 'coral'
  },
  {
    label: 'Peach',
    value: 'orange.light'
  },
  {
    label: 'Orange',
    value: "brandOrange"
  },
  {
    label: 'White',
    value: 'white',
  },
  {
    label: 'Gray 50',
    value: 'gray.50',
  },
  {
    label: 'Gray 100',
    value: 'gray.100',
  },
  {
    label: 'Gray 200',
    value: 'gray.200',
  },
  {
    label: 'Gray 300',
    value: 'gray.300',
  },
  {
    label: 'Gray 400',
    value: 'gray.400',
  },
  {
    label: 'Gray 500',
    value: 'gray.500',
  },
  {
    label: 'Gray 600',
    value: 'gray.600',
  },
  {
    label: 'Gray 700',
    value: 'gray.700',
  },
  {
    label: 'Gray 800',
    value: 'gray.800',
  },
  {
    label: 'Gray 900',
    value: 'gray.900',
  },
]

export const marginWidthStyles = {
  none: () => ({
    maxWidth: '100%',
  }),
  large: ({breakpoints}) => ({
    marginX: 'auto',
    maxWidth: breakpoints({ xs: '84%', lg: 1380 })
  }),
  medium: ({breakpoints}) => ({
    marginX: 'auto',
    maxWidth: breakpoints({ xs: '84%', lg: 1160 })
  }),
  small: ({ breakpoints }) => ({
    marginX: 'auto',
    maxWidth: breakpoints({ xs: '84%', md: 780 })
  })
}

export const alignContentOptions = [
  'top-left',
  'top-center',
  'top-right',
  'center',
  'center-left',
  'center-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
];

export const alignSelfOptions = [
  { value: '', label: 'auto' },
  'stretch',
  'start',
  'center',
  'end'
];

export const justifyContentOptions = [
  { value: '', label: 'not set' },
  'space-between',
  'space-evenly',
  'space-around',
]

const layoutUnitFields = [
  { name: 'value', label: 'Value', component: 'number-string',
    step: 1,
    hideLabel: true,
    placeholder: 'auto'
  },
  { name: 'unit', label: 'Unit', component: 'select',
    options: ['%', 'px'],
    hideLabel: true
  }
]


export const layoutFields = {
  unitBased: {
    width: { name: 'width', label: 'Width', component: 'row-group',
      relatedSubfields: true,
      fields: layoutUnitFields
    },
    height: { name: 'height', label: 'Height', component: 'row-group',
      relatedSubfields: true,
      fields: layoutUnitFields
    },
    maxWidth: { name: 'maxWidth', label: 'Max Width', component: 'row-group',
      relatedSubfields: true,
      fields: layoutUnitFields
    },
    maxHeight: { name: 'maxHeight', label: 'Max Height', component: 'row-group',
      relatedSubfields: true,
      fields: layoutUnitFields
    },
  },
  width: { name: 'width', label: 'Width', component: 'number-string', step: 1, placeholder: 'auto' },
  marginWidth: {
    name: 'marginWidth',
    label: 'Horizontal Insets',
    component: 'select',
    options: [
      'none',
      'large',
      'medium',
      'small',
    ]
  },
  height: { name: 'height', label: 'Height', component: 'number-string', step: 1, placeholder: 'auto' },
  maxHeight: { name: 'maxHeight', label: 'Max Height', component: 'number-string', step: 1, placeholder: 'auto' },
  maxWidth: { name: 'maxWidth', label: 'Max Width', component: 'number-string', step: 1, placeholder: 'auto' },
  direction: { name: 'direction', label: 'Layout Type', component: 'select',
    options: ['row', 'column', 'row-reverse', 'column-reverse'],
  },
  alignContent: { name: 'alignContent',label: 'Align Content', component: 'select',
    options: alignContentOptions
  },
  alignSelf: { name: 'alignSelf',label: 'Align Self', component: 'select',
    options: alignSelfOptions
  },
  contentSpacing: {
    name: 'contentSpacing',
    label: 'Gap spacing',
    description: 'Spacing between content items',
    component: 'number-string',
    step: 1,
    placeholder: '0'
  },
  justifyContent: {
    name: 'justifyContent',
    label: 'Spacing Type',
    description: 'Additional spacing options for content items which overrides Gap spacing, if set.',
    component: 'select',
    options: justifyContentOptions
  },
  padding: { name: 'padding', label: 'Padding', component: 'group-dropdown',
    fields: [
      { name: 'top', label: 'Top', component: 'number-string', step: 1, placeholder: '0' },
      { name: 'bottom', label: 'Bottom', component: 'number-string', step: 1, placeholder: '0' },
      { name: 'left', label: 'Left', component: 'number-string', step: 1, placeholder: '0' },
      { name: 'right', label: 'Right', component: 'number-string', step: 1, placeholder: '0' },
    ],
  },
}

const colorGroupFields = [
  { name: 'name', label: 'Options', component: 'select', options: colorOptions },
  { name: 'alpha', label: 'Alpha', component: 'number-string', step: 1, placeholder: '100' },
]


const colorField = { name: 'color', label: 'Color', component: 'row-group',
  relatedSubfields: true,
  fields: colorGroupFields
}

export const styleFields = {
  color: colorField,
  backgroundColor: { ...colorField, name: 'backgroundColor', label: 'Fill Color' },
  textColor: { ...colorField, name: 'textColor', label: 'Text Color' },
  opacity: { name: 'opacity', label: 'Transparency (%)', component: 'number-string', step: 1, placeholder: '100' },
  border: { name: 'border', label: 'Stroke', component: 'group-dropdown',
    fields: [
      layoutFields.width,
      { ...colorField, name: 'color', label: 'Color' },
      { name: 'style', label: 'Style', component: 'select',
        options: ['solid', 'dashed', 'dotted'],
      },
      { name: 'top', label: 'Top', component: 'toggle' },
      { name: 'bottom', label: 'Bottom', component: 'toggle' },
      { name: 'left', label: 'Left', component: 'toggle' },
      { name: 'right', label: 'Right', component: 'toggle' },
    ]
  },
  borderRadius: { name: 'borderRadius', label: 'Corners', component: 'group-dropdown',
    fields: [
      { name: 'value', label: 'Roundness', component: 'number-string', step: 1, placeholder: '0' },
      { name: 'topLeft', label: 'Top Left', component: 'toggle' },
      { name: 'topRight', label: 'Top Right', component: 'toggle' },
      { name: 'bottomLeft', label: 'Bottom Left', component: 'toggle' },
      { name: 'bottomRight', label: 'Bottom Right', component: 'toggle' },
    ]
  },
  backgroundImage: { name: 'backgroundImage', label: 'Background Image', component: 'group-dropdown',
    fields: [
      {
        name: 'src',
        label: 'Image',
        component: 'image',
        uploadDir: props => `/static/assets`,
        parse: filename => `/assets/${filename}`,
        previewSrc: (formValues, fieldProps) => {
          const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode');
          const imageSrc = get(formValues, pathName);
          if (imageSrc) {
            if (typeof imageSrc === 'string') {
              return imageSrc;
            }
            if (typeof imageSrc === 'object' && imageSrc.childImageSharp) {
              return imageSrc.childImageSharp.fluid.src;
            }
          }
          return '';
        }
      },
      { name: 'alignImage', label: 'Align Image', component: 'select', options: alignContentOptions },
      { name: 'resizeMode', label: 'Resize Mode', component: 'select',
        options: ['cover', 'contain'],
      },
      layoutFields.unitBased.width,
      layoutFields.unitBased.height,
    ]
  },
  shadow: { name: 'shadow', label: 'Shadow', component: 'group-dropdown',
    fields: [
      { name: 'elevation', label: 'Elevation', component: 'number-string', step: 1, placeholder: '0' },
      {  ...colorField, name: 'color', label: 'Color' },
      { name: 'opacity', label: 'Transparency (%)', component: 'number-string', step: 1, placeholder: 'auto' },
      { name: 'invert', label: 'Invert', component: 'toggle' },
    ]
  },
  clipContent: { name: 'clipContent', label: 'Clip contents', component: 'toggle' },
}

export const settingsDefaultItems = {
  marginWidth: 'medium',
  width: {
    value: '100',
    unit: '%',
  },
  height: '',
  maxWidth: {
    value: '',
    unit: '%',
  },
  maxHeight: '',
  direction: 'column',
  alignContent: 'center-left',
  alignSelf: '',
  contentSpacing: '',
  justifyContent: '',
  padding: {
    top: '',
    bottom: '',
    left: '',
    right: ''
  },
  backgroundColor: {
    name: '',
    alpha: '',
  },
  opacity: '100',
  textColor: {
    name: '',
    alpha: '',
  },
  border: {
    color: {
      name: 'gray.200',
      alpha: '',
    },
    width: '',
    style: 'solid',
    top: true,
    bottom: true,
    left: true,
    right: true,
  },
  borderRadius: {
    value: '',
    topLeft: true,
    topRight: true,
    bottomLeft: true,
    bottomRight: true,
  },
  backgroundImage: {
    src:  '',
    resizeMode: 'cover',
    alignImage: 'center',
    width: {
      value: '',
      unit: '%',
    },
    height: {
      value: '',
      unit: '%',
    },
  },
  shadow: {
    elevation: '',
    color: {
      name: 'gray.900',
      alpha: '',
    },
    opacity: '10',
    invert: false,
  },
  clipContent: false,
}

// TODO: delete this when no longer needed as a reference for array of keys used in applyEnabled
function getDefaultFieldConfigItems({ applyEnabledDefaults = false, defaultEnabled = true } = { }) {
  const items = cloneDeep(settingsDefaultItems);
  if (applyEnabledDefaults) {
    const result = applyEnabled(items, defaultEnabled, [
      'backgroundImage',
      'borderRadius',
      'shadow',
      'border',
      'padding'
    ]);
    // console.log('fieldConfigs', result);
    return result;
    // const { backgroundImage, borderRadius, shadow, border, padding, ...rest } = items;
    // const nonGroupedFields = applyEnabled(rest, defaultEnabled)
    // return {
    //   ...nonGroupedFields,
    //   padding: applyEnabled(padding, defaultEnabled),
    //   border: applyEnabled(border, defaultEnabled),
    //   borderRadius: applyEnabled(borderRadius, defaultEnabled),
    //   backgroundImage: applyEnabled(backgroundImage, defaultEnabled),
    //   shadow: applyEnabled(shadow, defaultEnabled)
    // }
  }

  return items;
}

export function applyEnabled(obje = { }, enabled = true, groupItems = []) {
  const obj = cloneDeep(obje); // this too much?
  const next = { };
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null && groupItems.includes(key)) {
      next[key] = applyEnabled(obj[key], enabled);
    } else {
      next[key] = obj[key];
      const keyEnabled = `${key}_enabled`;
      if (next[keyEnabled] === null || next[keyEnabled] === undefined) {
        next[keyEnabled] = enabled;
      }
    }
  }
  return next;
}

export const applyEnabledToggles = (field, ignoreSubfields = false) => {
  if (field.component === 'toggleEnabled') return field;
  if (field.fields && !ignoreSubfields && !field.relatedSubfields) {
    let { fields, ...result } = field;
    result.fields = [ ];
    for (let i = 0; i < fields.length; i += 1) {
      result.fields.push(applyEnabledToggles(fields[i]));
    }
    // console.log('result', result);
    return result;
  }
  const result = {
    name: `${field.name}_enabled`,
    component: 'toggleEnabled',
    fields: [field],
  }

  return result;
}

export function getColorData(value, theme) {
  if (value && typeof value === 'object' && value.name) {
    if (value.alpha) {
      const alpha = value.alpha * 1;
      if (alpha && alpha < 100) {
        const newColor = theme.colors.opacity(value.name, alpha/100);
        if (newColor) return newColor;
      }
    }
    return `$${value.name}`;
  } else if (value && typeof value === 'string') {
    return value;
  }
  return '';
}

export function resolveColorData(obj = { }, theme) {
  let next = { };
  for (const key in obj) {
    if (isObject(obj[key])) {
      if (key.endsWith('color') || key.endsWith('Color')) {
        next[key] = getColorData(obj[key], theme);
      } else {
        next[key] = resolveColorData(obj[key], theme);
      }
    } else {
      next[key] = obj[key];
    }
  }
  return next;
}

export function removeEmptySettings(obj = { }, checkEnabled = true) {
  let next = { };
  for (const key in obj) {
    if (!checkEnabled || obj[`${key}_enabled`] !== false) {
      if (isObject(obj[key])) {
        next[key] = removeEmptySettings(obj[key]);
      } else if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined && !key.endsWith('_enabled')) {
        next[key] = obj[key];
      }
    }
  }
  return next;
}

export function getAlignContentStyles(value, direction = 'column') {
  if (!value) return null;
  const attr = direction.startsWith('row') ? ['alignItems', 'justifyContent'] : ['justifyContent', 'alignItems'];
  switch(value) {
    case 'top-left':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-start' }
    case 'top-center':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'center' }
    case 'top-right':
      return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-end' }
    case 'center-left':
      return { [attr[0]]: 'center', [attr[1]]: 'flex-start' }
    case 'center':
      return { [attr[0]]: 'center', [attr[1]]: 'center' }
    case 'center-right':
      return { [attr[0]]: 'center', [attr[1]]: 'flex-end' }
    case 'bottom-left':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'flex-start' }
    case 'bottom-center':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'center'}
    case 'bottom-right':
      return { [attr[0]]: 'flex-end', [attr[1]]: 'flex-end' }
    default:
      return null;
      // return { [attr[0]]: 'flex-start', [attr[1]]: 'flex-start' }
  }
}

export function getAlignSelfStyles(value) {
  if (!value) return null;
  switch(value) {
    case 'stretch':
      return { alignSelf: 'stretch' }
    case 'start':
      return { alignSelf: 'flex-start' }
    case 'end':
      return { alignSelf: 'flex-end' }
    case 'center':
      return { alignSelf: 'center' }
    default:
      return null;
  }
}
