import React from 'react';
import { merge, mergeAndConcat } from 'merge-anything';
import { createStyles } from './createStyles';
import { StyleSheet } from 'react-native';
import {
  filterProps,
  hoist,
  getComponentName,
  useProps as defaultUseProps,
} from './utils';
import { uniqueId, distinctArray } from '../utils';
import { useTheme } from '../hooks';

function createStyledComponent(component, styleOptions = { }, style) {
  const isStyledComponent = component.isStyledComponent;
  const Component = isStyledComponent ? component.component : component;

  const opts = styleOptions;
  if (opts.postApply) opts.postApply = [opts.postApply];
  const options = isStyledComponent ? mergeAndConcat(component.options, opts) : opts;
  const name = options.name ? options.name : getComponentName(Component);

  const styleDef = { root: style };
  const styles = isStyledComponent ? [ ...component.styles, styleDef ] : [styleDef];

  let filterPropsOption = options.filterProps;
  if (filterPropsOption && Array.isArray(filterPropsOption)) {
    filterPropsOption = distinctArray(filterPropsOption);
  }

  const useStyles = createStyles(styles, { ...options, name });
  const useProps = options.useProps || defaultUseProps;
  // const FinalComponent = memo(Component);
  const StyledComponent = React.forwardRef(function StyledComponent(p, ref) {
    const theme = useTheme();
    let { props: overrideProps } = theme.overrides[name] || {};
    const props = useProps({
      ...overrideProps,
      ...p,
    });
    const styles = useStyles(props);
    const { style, ...rest } = props;
    const filteredProps =
      typeof filterPropsOption === 'function'
        ? filterPropsOption(rest)
        : filterProps(rest, filterPropsOption);
    return <Component ref={ref} {...filteredProps} {...styles.props.root} style={StyleSheet.flatten([styles.root, style])} />
  })

  // TODO: only hoist what's necessary for production
  StyledComponent.displayName = uniqueId(`Styled(${name})`);
  StyledComponent.name = name;
  StyledComponent.options = options;
  StyledComponent.isStyledComponent = true;
  StyledComponent.component = Component;
  StyledComponent.styleDef = styleDef;
  StyledComponent.styles = styles;
  StyledComponent.style = style;
  StyledComponent.useStyles = useStyles;

  StyledComponent.toString = () => StyledComponent.name;

  Object.defineProperty(StyledComponent, 'defaultProps', {
    get() { return this._foldedDefaultProps; },
    set(obj) {
      this._foldedDefaultProps = isStyledComponent ? merge(component.defaultProps, obj) : obj;
    },
  });

  hoist(StyledComponent, Component, {
    displayName: true,
    name: true,
    options: true,
    isStyledComponent: true,
    component: true,
    styleDef: true,
    styles: true,
    style: true,
    useStyles: true,
  })

  StyledComponent.withProps = (objOrFunc, options = { }) => {
    const name = options.name || uniqueId(`withProps(${StyledComponent.name})`);
    const WithProps = React.forwardRef(function WithProps(props, ref) {
      const theme = useTheme();
      let { props: overrideProps } = theme.overrides[name] || {};
      const computedProps =
        typeof objOrFunc === 'function'
          ? objOrFunc({ ...overrideProps, ...props })
          : { ...objOrFunc, ...overrideProps, ...props };
      return <StyledComponent ref={ref} {...computedProps} />
    });
    WithProps.name = name;
    WithProps.displayName = name;

    return createStyledComponent(WithProps, { ...options, name }, { });
  }

  return StyledComponent;
}

function styled(Component, options) {
  const template = style => createStyledComponent(Component, options, style);
  return template;
}

export { styled };
