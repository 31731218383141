import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { Text } from '../Text';
import { useHoverable } from '../Hoverable';
import { withStyles } from '../../styling';

const Link = withStyles({
  root: {},
  hovered: {
    props: {
      color: '$secondary',
      underline: true,
    },
  },
}, { name: 'Link' })(React.forwardRef(function Link(props, ref) {
  const { href, handler, accessibility, styles, style, ...textProps } = props;

  const onPress = useCallback(() => {
    if (handler && typeof handler === 'function') {
      handler(href);
    } else {
      console.warn('No handler for Text Link with href: ', href);
    }
  }, [handler, href]);

  const { hovered, ...hoverProps } = useHoverable();

  return (
    <Text
      ref={ref}
      {...textProps}
      onPress={onPress}
      {...(Platform.OS === 'web' ? {
        target: '_blank',
      } : null)}
      accessibility={href ? {
        accessibilityRole: 'link',
        href: href,
        ...accessibility,
      } : {
        accessibilityRole: 'button',
        ...accessibility,
      }}
      style={[style, hovered ? styles.hovered : null]}
      {...(hovered ? styles.props.hovered : null)}
      {...hoverProps}
    />
  );
}));

// backwards compatability with website.
// TODO: remove use of this
const TextLink = Link;

export { Link, TextLink };
