import React from 'react';
import equal from 'fast-deep-equal/react';
import { Content, Box, Heading, Paragraph, Text, Overline } from '../../ui';
import { useTheme, useMatchBreakpoint, useMemoCompare } from '../../ui/hooks';
import { memo, get, isNumberOdd } from '../../ui/utils';
import { GroupBlock } from '../GroupBlock';
import { InlineTextarea } from '../InlineTextarea';

import {
  applyEnabledToggles,
  layoutFields,
  styleFields,
  settingsDefaultItems,
  applyEnabled,
  alignContentOptions
} from '../fieldConfigs';
import { useBreakpointLayouts } from '../useBreakpointLayouts';
import { BlocksControls } from '../BlocksControls';


export const TimelineItemBlock = memo(({
  data,
  index,
  containerBlock,
  lineColor,
  lineOpacity,
  stepSpacing,
  lastIndex,
  ...rest
}) => {
  const { overlineText, headingText, bodyText } = data;
  const { breakpoints, spacing } = useTheme();

  const isOdd = isNumberOdd(index + 1);

  const blockStyle = {
    width: '100%',
    padY: stepSpacing,
    flexDirection: breakpoints({ xs: isOdd ? 'column-reverse' : 'column', sm: 'row' }),
    alignItems: breakpoints({ xs: 'center', sm: 'stretch' }),
    justifyContent: 'space-evenly',
  }

  const maxBoxWidth = breakpoints({ xs: '100%', sm: '30%' });
  const textMaxWidth = 285;
  const boxPadding = stepSpacing;
  const textVariant = breakpoints({ xs: 'text.medium', sm: 'text.large' });
  const backgroundColor = containerBlock.backgroundColor;
  const stepperMargin = boxPadding;
  const stepperSize = 40;
  return (
    <BlocksControls
      focusRing={{offset:0}}
      insetControls
      index={index}
      width="100%"
    >
    {
      lastIndex !== 0 && (
        <Box
          position="absolute"
          width="100%"
          height={lastIndex === index ? stepSpacing + stepperSize / 2 + stepperMargin : '100%'}
          justifyContent="center"
          alignItems="center"
          padTop={index === 0 ? stepSpacing + stepperMargin : 0}
          opacity={lineOpacity}
        >
          <Box width={1} height="100%" bg={lineColor} />
        </Box>
      )
    }
    <Box {...blockStyle}>
    { isOdd ? (
      <Box flex={1} padY={boxPadding} bg={backgroundColor} maxWidth={maxBoxWidth} alignItems={breakpoints({ xs: 'center', sm: "flex-end" })}>
        <Overline large color={lineColor} align={breakpoints({ xs: 'center', sm: "right" })} width="100%">
          <InlineTextarea name="overlineText" focusRing={false} />
        </Overline>
        <Heading level={3} align={breakpoints({ xs: 'center', sm: "right" })} spaceAfter width="100%">
          <InlineTextarea name="headingText" focusRing={false} />
        </Heading>
        <Text variant={textVariant} align={breakpoints({ xs: 'center', sm: "right" })} maxWidth={textMaxWidth} width="100%">
          <InlineTextarea name="bodyText" focusRing={false} />
        </Text>
      </Box>
    ) : <Box maxWidth={maxBoxWidth} padY={boxPadding} flex={1} display={breakpoints({ xs: 'none', sm: 'flex' })}/>
    }
    <Box
      flex={1}
      // display={breakpoints({ xs: i === 0 ? 'none' : 'flex', sm: 'flex' })}
      display={breakpoints({ xs: 'none', sm: 'flex' })}
      maxWidth={maxBoxWidth}
      alignItems="center"
      justifyContent="center"
      flexDirection={breakpoints({ xs: 'column-reverse', sm: 'row' })}
      marginY={stepperMargin}
      height={stepperSize}>
      <Box
        flex={1}
        opacity={0.4}
        // width={breakpoints({ xs: 1, sm: 'auto' })}
        minHeight={breakpoints({ xs: spacing(10), sm: 1 })}
        bg={breakpoints({ xs: lineColor, sm: isOdd ? lineColor : 'transparent' })}
      />
      <Box height={stepperSize} width={stepperSize} bg={'$white'} borderRadius={9999} borderWidth={1} borderColor={lineColor} justifyContent="center" alignItems="center">
        <Box height={stepperSize/2} width={stepperSize/2} borderRadius={9999} bg={lineColor} />
      </Box>
      <Box
        flex={1}
        opacity={0.4}
        // width={breakpoints({ xs: 1, sm: 'auto' })}
        minHeight={breakpoints({ xs: spacing(10), sm: 1 })}
        bg={breakpoints({ xs: lineColor, sm: isOdd ? 'transparent' : lineColor })}
      />
    </Box>
    { isOdd ? <Box maxWidth={maxBoxWidth} flex={1} padY={boxPadding} display={breakpoints({ xs: 'none', sm: 'flex' })} /> : (
      <Box flex={1} padY={boxPadding} bg={backgroundColor} maxWidth={maxBoxWidth} alignItems={breakpoints({ xs: 'center', sm: "flex-start" })}>
        <Overline color={lineColor} align={breakpoints({ xs: 'center', sm: "left" })} width="100%">
          <InlineTextarea name="overlineText" focusRing={false} />
        </Overline>
        <Heading spaceAfter level={3} align={breakpoints({ xs: 'center', sm: "left" })} width="100%">
          <InlineTextarea name="headingText" focusRing={false} />
        </Heading>
        <Text variant={textVariant} align={breakpoints({ xs: 'center', sm: "left" })} maxWidth={textMaxWidth} width="100%">
          <InlineTextarea name="bodyText" focusRing={false} />
        </Text>
      </Box>
      )
    }
    </Box>
    </BlocksControls>
  )
})

export const timelineItemBlockTemplate = {
  type: 'timelineItemBlockTemplate',
  name: 'timelineItemBlock',
  key: 'timeline-item-block',
  label: 'Timeline Block',
  component: 'grouped',
  defaultItem: {
    overlineText: 'Step #',
    headingText: 'Block Heading',
    bodyText: 'Description of this block...',
  },
  fields: [
    {
      name: 'overlineText',
      label: 'Overline',
      component: 'text',
    },
    {
      name: 'headingText',
      label: 'Heading',
      component: 'textarea',
    },
    {
      name: 'bodyText',
      label: 'Body Paragraph',
      component: 'textarea',
    },
  ]
}
