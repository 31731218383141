import * as React from 'react'
import { wrapFieldsWithMeta } from './components/wrapFieldsWithMeta'
import { ImageUpload } from './components/ImageUpload'
import { useCMS } from '@tinacms/react-core'
import { useState, useEffect } from 'react'

export const ImageField = wrapFieldsWithMeta(props => {
  const cms = useCMS()

  const [srcIsLoading, setSrcIsLoading] = useState(true)
  const [src, setSrc] = useState('')
  useEffect(() => {
    let canceled = false
    ;(async () => {
      setSrcIsLoading(true)
      let imageSrc = ''
      try {
        imageSrc = await props.field.previewSrc(
          props.form.getState().values,
          props
        )
      } catch (e) {
        if (!canceled) {
          setSrc('')
          // @ts-ignore cms.alerts
          cms.alerts.error(
            `Failed to generate preview for '${props.field.name}': ${e.message}`
          )
        }
      }
      if (!canceled) {
        setSrc(imageSrc)
      }
      setSrcIsLoading(false)
    })()
    return () => {
      canceled = true
    }
  }, [props.input.value])

  return (
    <ImageUpload
      value={props.input.value}
      previewSrc={src}
      loading={srcIsLoading}
      onDrop={async ([file]) => {
        const directory = props.field.uploadDir(props.form.getState().values)
        // @ts-ignore cms.media
        try {
          const [media] = await cms.media.store.persist([
            {
              directory,
              file,
            },
          ])

          if (media) {
            if (media.filename == props.input.value) {
              props.input.onChange('') // trigger rerender
            }
            props.input.onChange(media.filename)
          } else {
            // TODO Handle failure
          }
        } catch (error) {
          console.log('error', error);
        }



      }}
      onClear={
        props.field.clearable === false
          ? undefined
          : () => {
              props.input.onChange('')
            }
      }
    />
  )
})

export const ImageFieldPlugin = {
  name: 'image',
  Component: ImageField,
  parse: value => value || '',
}
