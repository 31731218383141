import { useEffect, useState } from 'react';
import { isNull, uniqueId } from '../utils';

function useId(value) {
  const [defaultId, setDefaultId] = useState(value);
  const id = value || defaultId;
  useEffect(() => {
    if (isNull(defaultId)) {
      setDefaultId(uniqueId('__el__-'));
    }
  }, [defaultId]);
  return id;
}

export { useId };
